import { Component, OnInit, Input, Output, Inject, EventEmitter, ViewChild, ElementRef, SimpleChanges, NgZone, Renderer2 } from '@angular/core';
import { OwlOptions,CarouselComponent, SlidesOutputData } from 'ngx-owl-carousel-o';
import { AssetsService } from "../../core/services/assets.service";
import { UserService } from '../../core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment'; // add this 1 of 4
import { CatlougeTypeConstants } from "src/app/core/constants/index";
import { PlayListService } from "src/app/views/playlist/playlist/playlist.service";
import { CommonService } from "src/app/core/services/common.service";
import { PageTitleService } from 'src/app/services/title/page-title.service';
import { DOCUMENT } from '@angular/common';
import { LocalStorageService } from '../../core/services/local-stroage.service'
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { customOptionsForBigSlider, customOptionsForEightMiniSlider, customOptionsForFourMiniSlider, customOptionsForSevenMiniSlider, customOptionsForSixMiniSlider, customOptionsForThreeMiniSlider, customOptionsForFiveMiniSlider } from '../owl-slider-config';
import { VideoPlayerService } from "src/app/core/services/videoPlayerService";
import { environment } from 'src/environments/environment';

declare var videojs;
declare var $;
@Component({
  selector: 'app-owl-slider',
  templateUrl: './owl-slider.component.html',
  styleUrls: ['./owl-slider.component.css'],
  providers: [UserService, PlayListService]
})
export class OwlSliderComponent implements OnInit {

  @Input() owlOption: any;
  @Input() data: any = {};
  @Input() owlSliderData;
  @Input() page_view: string;
  @Input() languages: any;

  @ViewChild('owlCarousel', { static: false }) owlCarousel: CarouselComponent;
  @ViewChild('owlCarousel', { read: ElementRef }) owlCarouselEl: ElementRef;


  customOptionsForBigSlider = customOptionsForBigSlider;
  customOptionsForFourMiniSlider = customOptionsForFourMiniSlider;
  customOptionsForFiveMiniSlider = customOptionsForFiveMiniSlider;
  customOptionsForSevenMiniSlider = customOptionsForSevenMiniSlider;
  customOptionsForSixMiniSlider = customOptionsForSixMiniSlider;
  customOptionsForThreeMiniSlider = customOptionsForThreeMiniSlider;
  customOptionsForEightMiniSlider = customOptionsForEightMiniSlider;

  @Output() deleteRecentWatch: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteContinueWatching: EventEmitter<any> = new EventEmitter<any>();
  userInfo: any;
  currentTime: number = Math.floor(Date.now() / 1000);
  totalPlayed: any = 0;
  routeName: string;
  categoryId: any;
  playList: any;
  owlSlides: boolean = false;
  setPlayListItem: any;
  @ViewChild('closeAddtoWatchListModal') closeAddtoWatchListModal;
  @ViewChild('closeContinueModal') closeContinueModal;
  @ViewChild('addToWatchListSuccess') addToWatchListSuccess;
  @ViewChild('itemAddedIntoWach') itemAddedIntoWach: ElementRef;
  @ViewChild('removeToWatchListSuccessModal') removeToWatchListSuccessModal;

  progrssBarValue: number = 0;
  progressInc: number = 0;
  playListId: string;
  error_String: any;
  error_code: any;
  removeItemFromDetail: any;
  assets_hover: boolean;
  streamInfo: any;
  addedAssetIndex: any;
  playListAvailable: boolean;
  playlist: any;
  tempAsset_id: any;
  deletedItem: any;
  deleteAsset_id: any;
  thumbnailType: any;
  thumbnailClass: string;
  notFoundImg: string = "landscape";
  assetImgParam: any;
  showViewAll: number = 4;

  isCircle: any;
  enlargedCircle: any;
  previewData: any;
  playermodal: any = [];
  checkPrview: any;
  hoverAndExpend: any;
  nameOutsideOverlay: any;
  blankSliderCount: number;
  isPreviewVideoMuted: boolean = true;

  currentHoveredIndex: number;
  assetHeight: any;
  assetWidth: any;
  expandWidth: any;
  hoverExitTimeout: any;
  firstSlideIndex: number;
  lastSlideIndex: number;
  freeIcon: string = environment.freeIcon;
  paidIcon: string = environment.paidIcon;

  constructor(
    public AssetsService: AssetsService,
    public UserService: UserService,
    public route: ActivatedRoute,
    public router: Router,
    private modalService: NgbModal,
    public playListService: PlayListService,
    public commonService: CommonService,
    public pageTitleService: PageTitleService,
    @Inject(DOCUMENT) private document: Document,
    public localStorageService: LocalStorageService,
    private googleEvent: EventTrackingService,
    public VideoPlayerService: VideoPlayerService,
    private renderer: Renderer2,
    private ngZone: NgZone
  ) {

    this.router.events.subscribe((res) => {

    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data.currentValue) {
      this.blankSliderCount = this.owlOption.items - this.data.assets.length;
    }
  }

  ngOnInit() {

    setTimeout(() => {
      const lastSlide = document.querySelector('.new_asset_expend_type .owl-item.lastActiveItem');
      const sliderContainer = document.querySelector('.new_asset_expend_type');

      // Add event listeners for hover effect on the last slide
      lastSlide?.addEventListener('mouseenter', () => {
        sliderContainer.classList.add('expend_left');
      });

      lastSlide?.addEventListener('mouseleave', () => {
        sliderContainer.classList.remove('expend_left');
      });
    }, 3500);

    this.router.events.subscribe((res) => {
      this.routeName = this.router.url;
    });


    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (this.userInfo) {
      this.playListId = localStorage.getItem('playListId');
    }

    this.data = this.data;
    this.owlSliderData = this.owlSliderData;
    // Format the Time to display inside the template
    if (this.data.category_type === "continue-watching") {
      this.data.assets.forEach((item) => {
          if (item.duration) {
              const remainingMinutes = item.duration - item.stop;
              const duration = moment.duration(remainingMinutes, 'minutes');
              const remainingHours = Math.floor(duration.asHours());
              const remainingMinutesFormatted = duration.minutes();
              
              item.remaining_time = `${remainingHours ? remainingHours + 'h ' : ''}${remainingMinutesFormatted}m Left`;
              item.formatted_duration = `${Math.floor(item.duration / 60)}h ${item.duration % 60}m`;
          }
      });
  }
  
    // this.thumbnailType = this.data.thumbnail_type?.slug;

    if (!this.data.thumbnail_type || (this.data.thumbnail_type && Object.keys(this.data.thumbnail_type).length === 0)) {
      this.thumbnailType = 'landscape';
    } else {
      this.thumbnailType = this.data.thumbnail_type.slug;
    }

    switch (this.thumbnailType) {
      case 'landscape':
        this.owlOption = customOptionsForFiveMiniSlider;
        this.assetImgParam = { 'width': 304, 'height': 171 };
        this.thumbnailClass = 'landscape_assets';
        this.notFoundImg = 'landscape';
        this.showViewAll = 4;
        // this.hoverAndExpend = this.data.he_templatetype != '' ? this.data.he_templatetype == 'hover' ? 'new_asset_hover_type' : 'new_asset_hover_type' : '';
        this.hoverAndExpend = 'new_asset_hover_type';
        this.nameOutsideOverlay = this.data.he_displaytext != '' ? this.data.he_displaytext_layout == 'Overlay' ? 'name_overlay' : 'name_outside' : '';

        setTimeout(() => {
          this.landscapeAssets();
          
          const landscapeAssets = document.querySelectorAll('.landscape_assets .owl-nav');
          landscapeAssets.forEach(nav => {
              nav.addEventListener('click', (evt) => this.landscapeAssets());
          });
        }, 2000);

        break;
      case 'enlarged-landscape':
        this.owlOption = customOptionsForFourMiniSlider;
        this.assetImgParam = { 'width': 400, 'height': 225 };
        this.thumbnailClass = 'enlarged_landscape_assets';
        this.notFoundImg = 'enlarged_landscape';
        this.showViewAll = 3;
        this.hoverAndExpend = 'new_asset_hover_type';
        this.nameOutsideOverlay = this.data.he_displaytext != '' ? this.data.he_displaytext_layout == 'Overlay' ? 'name_overlay' : 'name_outside' : '';

        setTimeout(() => {
          this.enlargedLandscapeAssets();
          const enlargedLandscapeAssets = document.querySelectorAll('.enlarged_landscape_assets .owl-nav');
          enlargedLandscapeAssets.forEach(nav => {
            nav.addEventListener('click', (evt) => this.enlargedLandscapeAssets());
          });
        }, 2000);

        break;
      case 'stretched-landscape':
        this.owlOption = customOptionsForFourMiniSlider;
        this.assetImgParam = { 'width': 368, 'height': 161 };
        this.thumbnailClass = 'stretched_landscape_assets';
        this.notFoundImg = 'stretched_landscape';
        this.showViewAll = 3;
        this.hoverAndExpend = 'new_asset_hover_type';
        this.nameOutsideOverlay = this.data.he_displaytext != '' ? this.data.he_displaytext_layout == 'Overlay' ? 'name_overlay' : 'name_outside' : '';


        setTimeout(() => {
          this.stretchedLandscapeAssets();
          const stretchedLandscapeAssets = document.querySelectorAll('.stretched_landscape_assets .owl-nav');
          stretchedLandscapeAssets.forEach(nav => {
            nav.addEventListener('click', (evt) => this.stretchedLandscapeAssets());
          });
        }, 2000);
        break;
      case 'portrait':
        this.owlOption = customOptionsForSevenMiniSlider;
        this.assetImgParam = { 'width': 220, 'height': 330 };
        this.thumbnailClass = 'portrait_assets';
        this.notFoundImg = 'portrait';
        this.showViewAll = 8;
        this.hoverAndExpend = this.data.he_templatetype === 'expend' ? 'new_asset_expend_type' : 'new_asset_hover_type' ;
        this.nameOutsideOverlay = this.data.he_displaytext != '' ? this.data.he_displaytext_layout == 'Overlay' ? 'name_overlay' : 'name_outside' : '';

        setTimeout(() => {
          this.portraitAssets();
          const portraitAssets = document.querySelectorAll('.portrait_assets .owl-nav');
          portraitAssets.forEach(nav => {
            nav.addEventListener('click', (evt) => this.portraitAssets());
          });
          
        }, 2000)


        break;
      case 'square':
        this.owlOption = customOptionsForSevenMiniSlider;
        this.assetImgParam = { 'width': 220, 'height': 220 };
        this.thumbnailClass = 'square_assets';
        this.notFoundImg = 'square';
        this.showViewAll = 7;
        this.hoverAndExpend = 'new_asset_hover_type';
        this.nameOutsideOverlay = this.data.he_displaytext != '' ? this.data.he_displaytext_layout == 'Overlay' ? 'name_overlay' : 'name_outside' : '';

        setTimeout(() => {
          this.squareAssets();
          const squareAssets = document.querySelectorAll('.square_assets .owl-nav');
          squareAssets.forEach(nav => {
            nav.addEventListener('click', (evt) => this.squareAssets());
          });
        }, 2000)
        break;
      case 'enlarged-square':
        this.owlOption = customOptionsForFiveMiniSlider;
        this.assetImgParam = { 'width': 320, 'height': 320 };
        this.thumbnailClass = 'enlarged_square_assets';
        this.notFoundImg = 'enlarged_square';
        this.showViewAll = 8;
        this.hoverAndExpend = 'new_asset_hover_type';
        this.nameOutsideOverlay = this.data.he_displaytext != '' ? this.data.he_displaytext_layout == 'Overlay' ? 'name_overlay' : 'name_outside' : '';

        setTimeout(() => {
          this.enlargedSquareAssets();
          const enlargedSquareAssets = document.querySelectorAll('.enlarged_square_assets .owl-nav');
          enlargedSquareAssets.forEach(nav => {
            nav.addEventListener('click', (evt) => this.enlargedSquareAssets());
          });
        }, 2000)
        break;
      case 'circular':
        this.owlOption = customOptionsForSevenMiniSlider;
        this.assetImgParam = { "roundCrop": true, 'width': 220, 'height': 220 };
        this.thumbnailClass = 'circular_assets';
        this.notFoundImg = 'circle';
        this.showViewAll = 7;
        this.hoverAndExpend = 'new_asset_hover_type';
        this.nameOutsideOverlay = this.data.he_displaytext != '' ? this.data.he_displaytext_layout == 'Overlay' ? 'name_overlay' : 'name_outside' : '';

        setTimeout(() => {
          this.circularAssets();
          const circularAssets = document.querySelectorAll('.circular_assets .owl-nav');
          circularAssets.forEach(nav => {
            nav.addEventListener('click', (evt) => this.circularAssets());
          });
        }, 2000)
        break;
      case 'enlarged-circle':
        this.owlOption = customOptionsForSixMiniSlider;
        this.assetImgParam = { "roundCrop": true, 'width': 320, 'height': 320 };
        this.thumbnailClass = 'enlarged-circular_assets';
        this.notFoundImg = 'circle';
        this.showViewAll = 5;
        this.hoverAndExpend = 'new_asset_hover_type';
        this.nameOutsideOverlay = this.data.he_displaytext != '' ? this.data.he_displaytext_layout == 'Overlay' ? 'name_overlay' : 'name_outside' : '';

        setTimeout(() => {
          this.enlargedCircularAssets();
          const enlargedCircularAssets = document.querySelectorAll('.enlarged-circular_assets .owl-nav');
          enlargedCircularAssets.forEach(nav => {
            nav.addEventListener('click', (evt) => this.enlargedCircularAssets());
          });
        }, 2000)
        break;

      default: this.thumbnailClass = 'landscape_assets';
        this.assetImgParam = { 'width': 304, 'height': 171 };
        this.owlOption = customOptionsForFiveMiniSlider;
        this.showViewAll = 4;
        this.notFoundImg = 'landscape';
        this.hoverAndExpend = 'new_asset_hover_type';
        this.nameOutsideOverlay = this.data.he_displaytext != '' ? this.data.he_displaytext_layout == 'Overlay' ? 'name_overlay' : 'name_outside' : '';

        setTimeout(() => {
          this.landscapeAssets();
          const landscapeAssets = document.querySelectorAll('.landscape_assets .owl-nav');
          landscapeAssets.forEach(nav => {
            nav.addEventListener('click', (evt) => this.landscapeAssets());
          });

        }, 2000);
        break;
    }

    // if(this.owlOption.items > this.data.assets.length) {
      
    // }
    this.blankSliderCount = this.owlOption.items - this.data.assets.length;

    this.owlSlides = this.data.assets && this.data.assets.length >= 4 ? true : false;
    if (this.data && this.data.assets)
      this.data.assets.forEach((ele, index) => {
        if (ele.type == 'livetv') {

          let endTime = moment.unix(parseInt(ele.end_timestamp)).format();
          let currentTime = moment().format();
          let diff = moment.duration(moment(endTime).diff(moment(currentTime))).minutes();
          this.data.assets[index].totalPlayed = ele.duration - diff;

        }

      });

  }

  onCarouselInitialized(event: SlidesOutputData) {
      this.firstSlideIndex = event.startPosition;
      this.lastSlideIndex = event.startPosition + this.owlOption.items - 1;
      if (this.data.he_templatetype === 'expend') {
        setTimeout(() => {
          const owlItem = this.owlCarouselEl.nativeElement.querySelector('.owl-item .new_asset_img img');
          this.assetWidth = owlItem.offsetWidth;
          this.assetHeight = owlItem.offsetHeight || this.assetWidth * 3 / 2;
          this.expandWidth = this.assetHeight ? (this.assetHeight * 16 / 9 + (this.owlOption?.margin || 10)) : 450;

          const assetImages = this.owlCarouselEl.nativeElement.querySelectorAll('.new_asset_img');
          assetImages.forEach((slide) => {
            this.renderer.setStyle(slide, 'height', this.assetHeight + 'px');
            this.renderer.setStyle(slide, 'width', this.assetWidth + 'px');
          });
        }, 100);
    }
  }

  counter(count: number): any[] {
    return Array(count).fill(0).map((x, i) => i);
  }

  onCarouselTranslated(event: SlidesOutputData) {
    this.firstSlideIndex = event.startPosition;
    this.lastSlideIndex = event.startPosition + this.owlOption.items - 1;
  }


  landscapeAssets(time=2000) {
    $('.landscape_assets .owl-stage').each(function(index) {
      var $slider = $(this);
      setTimeout(() => {
          var total = customOptionsForFiveMiniSlider.items;

          $slider.find('.owl-item').removeClass('firstActiveItem lastActiveItem');
          if (total > 1) {
              $slider.find('.owl-item.active').each(function(index) {
                  // console.log("index ===", index + "total===", total + "  index === total - 1 && total>1 ====" + (index === total - 1 && total > 1));
                  if (index === 0) {
                    // console.log(1);
                    
                      // this is the first one
                      $(this).addClass('firstActiveItem');
                  }
                  if (index === total - 1) {
                    // console.log(2);
                      // this is the last one
                      $(this).addClass('lastActiveItem');
                  }
              });
          } else {
            // console.log(3);
              $slider.find('.owl-item.active').addClass('firstActiveItem');
          }
      }, time);
  });
  }

  enlargedLandscapeAssets(time=2000) {
    $('.enlarged_landscape_assets .owl-stage').each(function() {
      var $slider = $(this);
      setTimeout(() => {
          var total = customOptionsForFourMiniSlider.items;

          $slider.find('.owl-item').removeClass('firstActiveItem lastActiveItem');
          if (total > 1) {
              $slider.find('.owl-item.active').each(function(index) {
                  // console.log("index ===", index + "total===", total + "  index === total - 1 && total>1 ====" + (index === total - 1 && total > 1));
                  if (index === 0) {
                      // this is the first one
                      $(this).addClass('firstActiveItem');
                  }
                  if (index === total - 1) {
                      // this is the last one
                      $(this).addClass('lastActiveItem');
                  }
              });
          } else {
              $slider.find('.owl-item.active').addClass('firstActiveItem');
          }
      }, time);
    });
  }


  stretchedLandscapeAssets(time=1000) {
    // alert("yes");
    $('.stretched_landscape_assets .owl-stage').each(function() {
      var $slider = $(this);
      setTimeout(() => {
          var total = customOptionsForFourMiniSlider.items; 

          $slider.find('.owl-item').removeClass('firstActiveItem lastActiveItem');
          if (total > 1) {
              $slider.find('.owl-item.active').each(function(index) {
                  // console.log("index ===", index + "total===", total + "  index === total - 1 && total>1 ====" + (index === total - 1 && total > 1));
                  if (index === 0) {
                      // this is the first one
                      $(this).addClass('firstActiveItem');
                  }
                  if (index === total - 1) {
                      // this is the last one
                      $(this).addClass('lastActiveItem');
                  }
              });
          } else {
              $slider.find('.owl-item.active').addClass('firstActiveItem');
          }
      }, time);
    });
  }


  


  portraitAssets(time=1000) {
    // alert("yes");
    $('.portrait_assets .owl-stage').each(function() {
      var $slider = $(this);
      setTimeout(() => {
          var total = customOptionsForSevenMiniSlider.items;

          $slider.find('.owl-item').removeClass('firstActiveItem lastActiveItem');
          if (total > 1) {
              $slider.find('.owl-item.active').each(function(index) {
                  // console.log("index ===", index + "total===", total + "  index === total - 1 && total>1 ====" + (index === total - 1 && total > 1));
                  if (index === 0) {
                      // this is the first one
                      $(this).addClass('firstActiveItem');
                  }
                  if (index === total - 1) {
                      // this is the last one
                      $(this).addClass('lastActiveItem');
                  }
              });
          } else {
              $slider.find('.owl-item.active').addClass('firstActiveItem');
          }
      }, time);
    });

    // Define reusable handler functions
    function onMouseEnter() {
      sliderContainer.classList.add('expend_left');
    }

    function onMouseLeave() {
      sliderContainer.classList.remove('expend_left');
    }

    // Cache the slider container
    const sliderContainer = document.querySelector('.new_asset_expend_type');

    setTimeout(() => {
      // Select all the slides
      const lastSlides = document.querySelectorAll('.new_asset_expend_type .owl-item');

      // Remove event listeners from all the slides
      lastSlides.forEach(element => {
        element.removeEventListener('mouseenter', onMouseEnter);
        element.removeEventListener('mouseleave', onMouseLeave);
      });
      
      // Find the last active slide
      const lastSlide = document.querySelector('.new_asset_expend_type .owl-item.lastActiveItem');
      if (lastSlide) {

        // Add event listeners for hover effect on the last slide
        lastSlide.addEventListener('mouseenter', onMouseEnter);
        lastSlide.addEventListener('mouseleave', onMouseLeave);
      }

    }, time+100);
  }


  squareAssets(time=1000) {
    // alert("yes");
    $('.square_assets .owl-stage').each(function() {
      var $slider = $(this);
      setTimeout(() => {
          var total = customOptionsForSevenMiniSlider.items;

          $slider.find('.owl-item').removeClass('firstActiveItem lastActiveItem');
          if (total > 1) {
              $slider.find('.owl-item.active').each(function(index) {
                  // console.log("index ===", index + "total===", total + "  index === total - 1 && total>1 ====" + (index === total - 1 && total > 1));
                  if (index === 0) {
                      // this is the first one
                      $(this).addClass('firstActiveItem');
                  }
                  if (index === total - 1) {
                      // this is the last one
                      $(this).addClass('lastActiveItem');
                  }
              });
          } else {
              $slider.find('.owl-item.active').addClass('firstActiveItem');
          }
      }, time);
    });
  }


  enlargedSquareAssets(time=1000) {
    // alert("yes");
    $('.enlarged_square_assets .owl-stage').each(function() {
      var $slider = $(this);
      setTimeout(() => {
          var total = customOptionsForFiveMiniSlider.items;

          $slider.find('.owl-item').removeClass('firstActiveItem lastActiveItem');
          if (total > 1) {
              $slider.find('.owl-item.active').each(function(index) {
                  // console.log("index ===", index + "total===", total + "  index === total - 1 && total>1 ====" + (index === total - 1 && total > 1));
                  if (index === 0) {
                      // this is the first one
                      $(this).addClass('firstActiveItem');
                  }
                  if (index === total - 1) {
                      // this is the last one
                      $(this).addClass('lastActiveItem');
                  }
              });
          } else {
              $slider.find('.owl-item.active').addClass('firstActiveItem');
          }
      }, time);
    });
  }


  circularAssets(time=1000) {
    // alert("yes");
    $('.circular_assets .owl-stage').each(function() {
      var $slider = $(this);
      setTimeout(() => {
          var total = customOptionsForSevenMiniSlider.items; 

          $slider.find('.owl-item').removeClass('firstActiveItem lastActiveItem');
          if (total > 1) {
              $slider.find('.owl-item.active').each(function(index) {
                  // console.log("index ===", index + "total===", total + "  index === total - 1 && total>1 ====" + (index === total - 1 && total > 1));
                  if (index === 0) {
                      // this is the first one
                      $(this).addClass('firstActiveItem');
                  }
                  if (index === total - 1) {
                      // this is the last one
                      $(this).addClass('lastActiveItem');
                  }
              });
          } else {
              $slider.find('.owl-item.active').addClass('firstActiveItem');
          }
      }, time);
    });
  }


  enlargedCircularAssets(time=1000) {
    $('.enlarged-circular_assets .owl-stage').each(function() {
      var $slider = $(this);
      setTimeout(() => {
          var total = customOptionsForSixMiniSlider.items; 

          $slider.find('.owl-item').removeClass('firstActiveItem lastActiveItem');
          if (total > 1) {
              $slider.find('.owl-item.active').each(function(index) {
                  // console.log("index ===", index + "total===", total + "  index === total - 1 && total>1 ====" + (index === total - 1 && total > 1));
                  if (index === 0) {
                      // this is the first one
                      $(this).addClass('firstActiveItem');
                  }
                  if (index === total - 1) {
                      // this is the last one
                      $(this).addClass('lastActiveItem');
                  }
              });
          } else {
              $slider.find('.owl-item.active').addClass('firstActiveItem');
          }
      }, time);
    });
  }



  liniarProgressBar() {
    this.progressInc = 0;
    let progrssInterval = setInterval(() => {
      this.progrssBarValue = this.progressInc * 10;

      if (this.progressInc >= 10) {
        clearInterval(progrssInterval);
      }
      this.progressInc++;
    }, 500)

  }

  setDeleteItemForDelete(item) {
    localStorage.setItem('deleteItem', item);
  }

  getRemainingTime(item) {
    const remainingTime = moment.utc(moment.duration(item.duration - item.stop, 'minutes').asMilliseconds()).format('h[h]:m[m] Left');
    return remainingTime;
  }

  deleteWatch(e, item) {
    // console.log('deleteWatch');
    e.stopPropagation();
    this.removeItemFromDetail = item;
    this.deleteRecentWatch.emit(item);

    // this.closeContinueModal.nativeElement.click();
    this.document.getElementById('removeContinueModal').style.display = 'block';
    setTimeout(() => {
      this.document.getElementById('removeContinueModal').style.display = 'none';
    }, 3500);

  }

  openMOdelWindow(content) {

  }

  redirectUrl(data, content, jwPlayerDom, event) {
    this.userInfo = localStorage.getItem('userInfo');


    if (this.userInfo) {
      let itemForDetailPage = {
        code: data.code,
        type: data.type,
      }
      let pagename = this.strToLower(this.data.category_name);
      this.page_view = this.page_view + '_' + pagename.split(/\s/).join('');

      if (data.asset_cat_type == 'recentlywatched') {

        this.commonService.goToDetail(data, this.page_view);

      } else {
        this.commonService.goToDetail(data, this.page_view);

      }
    } else {
      // this.commonService.setBeforeLoginState(data);
      const assetData = {
        redirect_to_detail: true,
        name: data.name,
        code: data.code,
        type: data.type,
      }
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: assetData,
        queryParamsHandling: 'merge',
      });
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => {
      });
  
      // this.router.navigateByUrl('/login');
    }
  }

  viewall(data) {
    let viewAlldParams = {
      categoryId: data.category_type == 'continue-watching' ? 'continue-watching' : data.category_mongo_id
    }

    if (data.category_code == "HCTG043") {
      viewAlldParams['type'] = 'livetv'
    }

    this.routeName = this.router.url;
    if (this.routeName == '/') {
      viewAlldParams['homecatid'] = data.category_mongo_id;
      viewAlldParams['catlogue'] = CatlougeTypeConstants.catlogue;
    }
    if (this.routeName == '/livetv') {
      viewAlldParams['genre'] = data.genre_id;
      viewAlldParams['type'] = 'livetv';
      viewAlldParams['language_id'] = this.languages.toString();
      viewAlldParams['catlogue'] = CatlougeTypeConstants.catlogue;
    }

    if (this.routeName == '/movies') {
      viewAlldParams['genre'] = data.genre_id;
      viewAlldParams['type'] = 'movie';
      viewAlldParams['catlogue'] = CatlougeTypeConstants.catlogue;
    }

    if (this.routeName == '/tvshows') {

      viewAlldParams['show_id'] = data.category_mongo_id;
      viewAlldParams['genre'] = data.genre_id;
      viewAlldParams['type'] = 'tvshow';
      viewAlldParams['catlogue'] = CatlougeTypeConstants.catlogue;

    }

    if (this.routeName == '/videos') {
      viewAlldParams['category_code'] = data.category_code;
      viewAlldParams['type'] = 'vod';
      viewAlldParams['catlogue'] = CatlougeTypeConstants.catlogue;
    }
    viewAlldParams['type'] = data.tab_slug;
    this.router.navigate(['/viewall'], { queryParams: viewAlldParams })
  }

  AddToPlayListItems(e, item, content) {
    e.stopPropagation();
    this.routeName = this.router.url;
    if (this.userInfo && Object.keys(this.userInfo.result).length > 0) {
      this.setPlayListItem = item;
      let userUnique = '';
      if (this.userInfo.result.register_by == "mobile") {
        userUnique = this.userInfo.result.mobile;
      } else {
        userUnique = this.userInfo.result.emailaddress;
      }

      this.googleEvent.eventEmitter("Watchlist", "Video_view", userUnique + '-' + item.code + '-' + item.name, 0, userUnique, userUnique + '-' + item.code + '-' + item.name);

      let addToplayListParams = {
        playlistid: this.playListId ? this.playListId : '',
        catlogue: CatlougeTypeConstants.catlogue,
        plateform: "web",
        playingtype: "Video",
        assetId: item.asset_mongo_id ? item.asset_mongo_id : item._id,
        show_id: item.tvshowId ? item.tvshowId : '',
        assetType: item.type ? item.type : '',
        action: "saveAssetInPlaylist",
      }


      if (this.routeName == '/') {
        this.addedAssetIndex = this.data.assets.findIndex(d => d['asset_mongo_id'] == item.asset_mongo_id)
      } else if (this.routeName == '/tvshows') {
        this.addedAssetIndex = this.data.assets.findIndex(d => d['asset_mongo_id'] == item.asset_mongo_id)
      } else if (this.routeName == '/movies') {
        this.addedAssetIndex = this.data.assets.findIndex(d => d['_id'] == item._id)
      } else if (this.routeName == '/livetv') {
        this.addedAssetIndex = this.data.assets.findIndex(d => d['_id'] == item._id)
      }

      let urlEncodedToplayListParams = this.UserService.convertoJSONToUrlEncoded(addToplayListParams);
      if ((item.asset_mongo_id ? item.asset_mongo_id : item._id) != undefined) {

        this.playListService.addToPlayList(urlEncodedToplayListParams).subscribe((data) => {
          this.error_code = data.error_code;
          if (data.error_code == '200') {
            this.data.assets[this.addedAssetIndex].is_watchlist_added = true;
            this.document.getElementById('itemAddSuccessMsg').innerHTML = `Successfully added to Watchlist.`;

          }
          if (data.error_code == '209') {
            this.data.assets[this.addedAssetIndex].is_watchlist_added = false;
            this.deleteWatchListItem(item);
          }
          this.addToWatchListSuccess.nativeElement.click();
          setTimeout(() => {
            this.document.getElementById('closeAddToWatchList').click();
          }, 1500);


        });
      }


    } else {
      // this.router.navigateByUrl('/login');
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => {
      });
    }
  }

  addRemoveFromWatchlist(item: any, button: HTMLButtonElement) {

    button.disabled = true;

    if (item.is_watchlist_added == 0) {
      let params = {
        type: item.type,
        code: item.code,
      };
      this.AssetsService.addToWatchList(params).subscribe(
        (data) => {
          if (data.response_code === 200 || data.response_code === 201) {
            item.is_watchlist_added = 1;
            button.disabled = false;
          }
        },
        (error) => {
          console.error('Error adding to watchlist:', error);
          button.disabled = false;
        }
      );
    } else {
      let params = {
        code: [item.code],
      };
      this.AssetsService.deleteFromWatchlist(params).subscribe(
        (data) => {
          if (data.response_code === 200 || data.response_code === 201) {
            item.is_watchlist_added = 0;
            button.disabled = false;
          }
        },
        (error) => {
          console.error('Error deleting from watchlist:', error);
          button.disabled = false;
        }
      );
    }
  }

  item_hover_enter(asset, i?) {
    console.log('hover index: ', i);
    if (i === this.lastSlideIndex + 1) {
        console.log('outside slide');
        return;
    }
    this.assets_hover = true;

    if (this.data.he_templatetype === 'expend') {
        if (this.hoverExitTimeout) clearTimeout(this.hoverExitTimeout);

        this.currentHoveredIndex = i;
        const allSlides = this.owlCarouselEl.nativeElement.querySelectorAll('.owl-stage .owl-item');

        allSlides.forEach((slide, index) => {
            if (index === i) {
                // Increase the width of the hovered slide
                this.renderer.setStyle(slide, 'width', this.expandWidth + 'px');
            } else {
                // Decrease the width of all other slides
                this.renderer.setStyle(slide, 'width', this.assetWidth + 'px');
            }
        });

        const owlStage = this.owlCarouselEl.nativeElement.querySelector('.owl-stage');
        const style = window.getComputedStyle(owlStage);
        const currentMarginLeft = parseFloat(style.getPropertyValue('margin-left').replace('px', '')) || 0;
        if (i === this.lastSlideIndex) {
            const translateWidth = this.expandWidth - this.assetWidth - (this.owlOption?.margin || 10);
            setTimeout(() => {
                this.renderer.setStyle(owlStage, 'margin-left', `-${translateWidth}px`);
            }, 0);
        } else if (i === this.lastSlideIndex - 1) {
            // Check the current margin-left value of the owl-stage
            const style = window.getComputedStyle(owlStage);
            const currentMarginLeft = parseFloat(style.getPropertyValue('margin-left').replace('px', '')) || 0;
            console.log('currentMarginLeft: ', currentMarginLeft);

            if (currentMarginLeft === 0) {
                const translateWidth = this.assetWidth ? (this.assetWidth - (this.owlOption?.margin * 6 || 60)) : 150;
                console.log('translateWidth: ', translateWidth);
                console.log('this.assetWidth: ', this.assetWidth);
                console.log('this.owlOption?.margin: ', this.owlOption?.margin);
                console.log('this.assetWidth + (this.owlOption?.margin || 10): ', this.assetWidth + (this.owlOption?.margin || 10));
                setTimeout(() => {
                    this.renderer.setStyle(owlStage, 'margin-left', `-${translateWidth}px`);
                }, 0);
            }
        } else if (i === this.firstSlideIndex) {
            setTimeout(() => {
                this.renderer.setStyle(owlStage, 'margin-left', `0px`);
            }, 0);
        } else if (i === this.firstSlideIndex + 1 && (currentMarginLeft < 0)) { // this.assetWidth + (this.owlOption?.margin || 10)
          const translateWidth = this.assetWidth ? (this.assetWidth + (this.owlOption?.margin || 10)) : 100;
          setTimeout(() => {
            this.renderer.setStyle(owlStage, 'margin-left', `-${translateWidth}px`);
        }, 0);
        }
    }



    // Check if the player is already initialized
    if (asset?.code == this.checkPrview || videojs.getPlayers()[`hoverPreviewVideo${asset?.code}`]) {
      // console.log('return from item_hover_enter\n', 'item_hover_enter checkPrview:', this.checkPrview, '\nitem_hover_enter player:', videojs.getPlayers()[`hoverPreviewVideo${asset?.code}`]);

      return;
    }
    
    let assetDetail = {
      "code": asset.code,
    }

    let assetInfo = this.UserService.convertoJSONToUrlEncoded(assetDetail);

    this.VideoPlayerService.playPreview(assetInfo).subscribe(
      (playObj) => {
        this.previewData = playObj.result;
        // console.log("get preview data", playObj);

        let videoPlayUrl: any;
        if (
          // this.previewData.cloud_front &&
          // Object.keys(this.previewData.cloud_front).length > 0 &&
          this.previewData.cloudfront_key_pair_id != "" && this.previewData.video_url != "" && this.previewData.cloudfront_policy != "" && this.previewData.cloudfront_signature != ""
        ) {
          // console.log('inside if block');
          setTimeout(() => {
            this.checkPrview = asset.code;
          }, 5000);
          setTimeout(() => {
            videoPlayUrl = this.previewData.cloud_front 
            ? this.previewData.cloud_front.video_url
            : this.previewData.video_url; //this.previewData.cloud_front.video_url;
            
            this.setCookie(
              "CloudFront-Key-Pair-Id",
              this.previewData.cloud_front 
              ? this.previewData.cloud_front.cloudfront_key_pair_id.trim()
              : this.previewData.cloudfront_key_pair_id.trim(),
              1
            );
            this.setCookie(
              "CloudFront-Policy",
              this.previewData.cloud_front 
              ? this.previewData.cloud_front.cloudfront_policy.trim()
              :this.previewData.cloudfront_policy.trim(),
              1
            );
            this.setCookie(
              "CloudFront-Signature",
              this.previewData.cloud_front 
              ? this.previewData.cloud_front.cloudfront_signature.trim()
              :this.previewData.cloudfront_signature.trim(),
              1
            );
            ("http://j-cdr.digivive.com/HLS_Aaj/Aaj.m3u8");

            let new_source = {
              // //sample source
              // sources: [
              //   {
              //     type: 'video/mp4',
              //     src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
              //   },
              // ],
              sources: [{ src: videoPlayUrl, type: "application/x-mpegURL" }],
              controls: false,
              autoplay: true,
              withCredentials: true,
              crossDomain: true,
              fluid: true,
              controlBar: {
                children: [
                  // "playToggle",
                  // "volumeMenuButton",
                  // "durationDisplay",
                  // "timeDivider",
                  // "currentTimeDisplay",
                  // "progressControl",
                  // "remainingTimeDisplay",
                  // "fullscreenToggle"
                ]
              },
            };

            this.playermodal = videojs("hoverPreviewVideo" + asset.code, new_source);
            // console.log(this.playermodal);

            this.playermodal.userActive(false);
            this.playermodal.muted(true);
            this.playermodal.on("ended", (event) => {
              videojs("hoverPreviewVideo" + asset.code).dispose();
              this.checkPrview = "no";
              this.isPreviewVideoMuted = true;
            });
          }, 5000)
        } else {
          this.checkPrview = "no";
        }
      });


  }

  item_hover_leave(asset, i?) {
    this.assets_hover = false;

    if (this.data.he_templatetype === 'expend') {

      this.hoverExitTimeout = setTimeout(() => {
        this.currentHoveredIndex = null;
    
        const activeSlides = this.owlCarouselEl.nativeElement.querySelectorAll('.owl-carousel .owl-item.active');
    
        activeSlides.forEach((slide, index) => {
          if (index !== this.currentHoveredIndex) {}
          this.renderer.setStyle(slide, 'width', this.assetWidth + 'px');
          // this.renderer.setStyle(slide, 'height', this.assetHeight + 'px');
        });
        const owlStage = this.owlCarouselEl.nativeElement.querySelector('.owl-carousel .owl-stage');
        this.renderer.setStyle(owlStage, 'margin-left', `0px`);
      }, 100);
    }

    if (asset.code == this.checkPrview || videojs.getPlayers()[`hoverPreviewVideo${asset?.code}`]) {
      videojs("hoverPreviewVideo" + asset.code).dispose();
      this.isPreviewVideoMuted = true;
      // console.log('video disposed from item_hover_leave: ', 'hoverPreviewVideo', asset.code);
      this.checkPrview = "no";
    }
  }

  strToString(str) {
    return str?.toString();
  }

  // isPreviewVideoMuted(asset): boolean {
  //   if (asset.code == this.checkPrview && videojs("hoverPreviewVideo" + asset.code)) {
  //     const player = videojs("hoverPreviewVideo" + asset.code);
  //     return player.muted();
  //   }
  // }

  togglePreviewVideoMute(asset) {
    if (this.isPreviewVideoMuted) {
      videojs("hoverPreviewVideo" + asset.code).muted(false);
      this.isPreviewVideoMuted = false;
    } else {
      videojs("hoverPreviewVideo" + asset.code).muted(true);
      this.isPreviewVideoMuted = true;
    }
  }


  strToLower(str: string) {

    if (str) {
      return str.toLowerCase();
    }
  }

  getWatchList() {

    this.playListId = localStorage.getItem('playListId');
    let temp = {
      playlistid: this.playListId ? this.playListId : '',
      catlogue: CatlougeTypeConstants.catlogue,
      plateform: "web",
      action: "getPlayListAsset",
    }

    let urlEncodedPlayListAssetsParams = this.UserService.convertoJSONToUrlEncoded(temp);
    this.playListService.getPlaylist().subscribe(data => {
      if (data && data.result && Array.isArray(data.result)) {
        if (Array.isArray(data.result)) {
          this.playListAvailable = true;
          this.playlist = data.result;
          return this.playlist;
        }
      }
    });

  }

  check_into_watchlist() {
    this.routeName = this.router.url;
    this.data.assets.forEach((tempAsset, tempIndex) => {

      if (this.routeName == '/') {
        this.tempAsset_id = tempAsset.asset_mongo_id
      } else if (this.routeName == '/tvshows') {
        this.tempAsset_id = tempAsset.asset_mongo_id
      } else if (this.routeName == '/movies') {
        this.tempAsset_id = tempAsset._id;
      } else if (this.routeName == '/livetv') {
        this.tempAsset_id = tempAsset._id;
      }

      if (this.playlist.findIndex(tempObj => tempObj._id == this.tempAsset_id) != -1) {
        this.data.assets[tempIndex].is_watchlist_added = true;
      } else {
        this.data.assets[tempIndex].is_watchlist_added = false;
      }
    });
  }

  deleteWatchListItem(item) {

    this.deletedItem = item;
    if (this.routeName == '/') {
      this.deleteAsset_id = item.asset_mongo_id
    } else if (this.routeName == '/tvshows') {
      this.deleteAsset_id = item.asset_mongo_id
    } else if (this.routeName == '/movies') {
      this.deleteAsset_id = item._id;
    } else if (this.routeName == '/livetv') {
      this.deleteAsset_id = item._id;
    }


    let deletePlayListAssets = {
      playlistid: this.playListId,
      assetId: this.deleteAsset_id,
      catlogue: CatlougeTypeConstants.catlogue,
      plateform: "web",
      action: "deleteAssetFromPlaylist"
    }

    let urlEncodedToplayListParams = this.UserService.convertoJSONToUrlEncoded(deletePlayListAssets);
    this.playListService.deleteFromPlayList(urlEncodedToplayListParams).subscribe((data) => {
      this.document.getElementById('itemAddSuccessMsg').innerHTML = `Successfully removed From Watchlist.`;
    });

  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  deleteFromContinueWatching(item) {
    this.deleteContinueWatching.emit(item);
  }


  ngAfterViewInit() {

    // for two finger scroll 
    // this.setupMousewheel();
  }

  setupMousewheel() {

    const carouselDiv = this.owlCarouselEl.nativeElement as HTMLElement;
    let parentClasses = [];
    carouselDiv.addEventListener("mouseover", (event) => {
      parentClasses = [];
      // Get the parent div element
     const hoverCarouselDiv=  this.owlCarouselEl.nativeElement as HTMLElement;
       // Get all classes of the parent div
       const parentDiv = hoverCarouselDiv.parentElement;
      //  parentClasses = parentDiv.classList.toString();
      parentClasses.push(...Array.from(parentDiv.classList));
       // Log the classes to the console
      //  console.log('Parent div classes:', parentClasses);
    })
    carouselDiv.addEventListener('wheel', (event: WheelEvent) => {
      // console.log('Parent div classes:', parentClasses);
      let owl_assets_type = '';
      // event.preventDefault(); 
      // deltaX use for two finger and deltaY use for mouse wheel
      if (event.deltaX > 0) {
         event.preventDefault(); 
        this.owlCarousel.next();
        // set hover on first and lastitem by add class
        // console.log('parentClasses.includes',parentClasses.includes('landscape_assets'));
        
        // parentClasses.forEach(e => {
          if(parentClasses.includes('landscape_assets')) {
            this.landscapeAssets(10)
          }else if(parentClasses.includes('enlarged_landscape_asset')) {
            this.enlargedLandscapeAssets(10)
          }else if(parentClasses.includes('stretched_landscape_assets')) {
            this.stretchedLandscapeAssets(10)
          }else if(parentClasses.includes('portrait_assets')) {
            // console.log('portrait_assets');
            this.portraitAssets(10)
          }else if(parentClasses.includes('square_assets')) {
            this.squareAssets(10)
          }else if(parentClasses.includes('enlarged_square_assets')) {
            this.enlargedSquareAssets(10)
          }else if(parentClasses.includes('circular_assets')) {
            this.circularAssets(10)
          }else if(parentClasses.includes('enlarged-circular_assets')) {
            // console.log('enlarged-circular_assets');
            
            this.enlargedCircularAssets(10)
          }
        // });
      } else if(event.deltaX < -0 && event.deltaY == -0) {
        event.preventDefault(); 
        this.owlCarousel.prev();
        // set hover on first and lastitem by add class
        parentClasses.forEach(e => {
          if(e == 'landscape_assets') {
            owl_assets_type = e;
            this.landscapeAssets(10)
          }else if(e == 'enlarged_landscape_asset') {
            owl_assets_type = e;
            this.enlargedLandscapeAssets(10)
          }else if(e == 'stretched_landscape_assets') {
            owl_assets_type = e;
            this.stretchedLandscapeAssets(10)
          }else if(e == 'portrait_assets') {
            owl_assets_type = e;
            this.portraitAssets(10)
          }else if(e == 'square_assets') {
            owl_assets_type = e;
            this.squareAssets(10)
          }else if(e == 'enlarged_square_assets') {
            owl_assets_type = e;
            this.enlargedSquareAssets(10)
          }else if(e == 'circular_assets') {
            owl_assets_type = e;
            this.circularAssets(10)
          }else if(e == 'enlarged-circular_assets') {
            owl_assets_type = e;
            this.enlargedCircularAssets(10)
          }
        });
      }
    });
  }

  onMouseEnter(item: any) {
    // Add your hover logic here
    this.ngZone.run(() => {
      // console.log('Mouse left:', item);
    });
  }

  onMouseLeave(item: any) {
    // Add your logic for when the mouse leaves the item
    this.ngZone.run(() => {
      // console.log('Mouse left:', item);
    });
  }

}


