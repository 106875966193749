<p class="_custom_progress">
  <ngb-progressbar class="_custom_progress" type="info" id="nexg_ngb-progressbar" [value]="progrssBarValue">
  </ngb-progressbar>
</p>




<div class="maincontentarea _beforeLoad" id="maincontentarea" style="margin: 0;">

  <div class="maincontentarea" style="margin:0;">

    <div class="slider_thumb_bg" > <!-- *ngIf="owlBannerData?.banner_result.banner.length>0" -->
      <!-- <app-owl-banner-slider [owlOption]="customOptionsForBigSlider" [data]="owlBannerData.banner_result.banner"
        [page_view]="'Screen_Home_Banner'">
      </app-owl-banner-slider> -->
      <app-banner-slider [owlOption]="customOptionsForBigSlider" [data]="owlBannerData?.banner_result.banner"
        [page_view]="'Screen_Home_Banner'">
      </app-banner-slider>
    </div>

    <div class="mt40" *ngIf="owlBannerData?.result?.length<=0">

    </div>
    <div [class]="owlBannerData?.banner_result.banner.length>0 ? 'video_asset' : 'mt100' " *ngIf="owlBannerData?.total_count>0">
      <div *ngFor="let item of owlBannerData?.result; let i=index">
        <div *ngIf="item.tab_slug == showContinueWatching">
          <app-owl-slider *ngIf="item?.assets_total_cnt>0" (deleteContinueWatching)="deleteContinueWatching($event)"
            [owlOption]="customOptionsForFourMiniSlider" [data]="item" [page_view]="'Screen_Home'"></app-owl-slider>
        </div>
      </div>
    </div>


    <div class="search-results"
      *ngIf="owlSliderData?.result?.length > 0"
      infiniteScroll
      [infiniteScrollDistance]="3"
      [infiniteScrollThrottle]="300"
      (scrolled)="onScroll()">

      <div>
        <div *ngFor="let item of owlSliderData?.result; let i=index">
          <div *ngIf="item.category_disp_type == '' && item.category_disp_type != 'campaign_banner'">
            <app-category *ngIf="item?.sub_category_count>0" [data]="item"></app-category>

            <app-owl-slider *ngIf="item?.sub_category_count == 0"
              [owlOption]="customOptionsForSevenMiniSlider" [owlSliderData]="scrollData" [data]="item"
              [page_view]="'Screen_Home'"></app-owl-slider>
          </div>
          <div *ngIf="item.category_disp_type != '' 
            && item.category_disp_type == 'campaign_banner' 
            && item.platforms.includes('web')
            && (item.thumbnail_type.slug=='stretched-landscape' && item.category_image!='' 
                || item.thumbnail_type.slug=='banner' && item.thumbnail_banner_stretched_landscape!='' 
                || item.thumbnail_type.slug!='stretched-landscape' && item.thumbnail_type.slug!='banner' && item.thumbnail!='')">
            <app-campaign-banner *ngIf="item.category_disp_type != '' && item.category_disp_type == 'campaign_banner'"
                                [data]="item">
            </app-campaign-banner>
          </div>



        </div>
      </div>



    </div>


    <div *ngIf="isTabDataFetched && isBannerDataFetched && owlSliderData.result.length == 0">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="_no_data_f">
              <h1>No Data Found</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showWelcomeCard && isAuthenticated" class="after-login">
      <img [src]="selectedProfileImage" />
      <div>
        <label>Hi {{selectedProfileName}}</label>
        <p>Welcome to nexGTv</p>
      </div>
    </div>

    

  </div>
</div>