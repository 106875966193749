export const environment = {
  projectName : 'nexGTv',
  production: true,
  api_url: 'https://api.digivive.com/api/v1/',
  play_url:'https://appsv2.digivive.com/',
  googleTrackingCode: 'G-9TP7B3XP8C',
  BASE_ASSETS_PATH: 'https://res.cloudinary.com/digivive/image/upload/',
  APP_CLOUD_NAME: 'v1603711950',
  SRC_EXT :'.webp' ,
  platformType: '1',
  api_base_url:'https://appsvs.digivive.com/production/',
  search_base_url: 'https://prod-search.digivive.com/multi_search',
  device_base_url: 'https://device-restriction.digivive.com/',
  payment_base_url: 'https://payments.digivive.com/nexg/charge/',
  razorpay_api_key: 'rzp_live_ee2511vPJLnhl1',
  language: 'EN',
  logoImage: 'assets/img/logo.png',
  faviconIcon: 'assets/img/brand/favicon.png',
  freeIcon: 'assets/img/Icon/free-icon.svg',
  paidIcon: 'assets/img/Icon/paid-icon.svg',
  subscribeImage: 'assets/img/subscribe_button.png',
  googleAds: true,
  ActivationCode: true
};