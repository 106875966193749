import { Injectable, Injector } from '@angular/core';
import { HttpEvent,HttpResponse, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { JwtService, UserService } from '../services';
import { map, catchError } from 'rxjs/operators';
import { UserTypeConstants,authKey } from "src/app/core/constants/userType.constant";
import { ActivatedRoute,Router } from "@angular/router";
import { throwError } from 'rxjs';



@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private jwtService: JwtService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // Get existing headers
      const headers = req.headers;
      // Check if a specific header exists for multipart form data
      const existingImgHeader = headers.get('X-Check-Image');
      // console.log("headers.get('Lang-code')",headers.get('Lang-code'));
      
      let existingLangCode = headers.get('Lang-code') ??'en';
    const headersConfig = {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      // 'Access-Control-Allow-Origin' : 'https://api.razorpay.com',
      // 'Origin' : 'https://api.razorpay.com',
      //'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      //'Accept': 'application/json',
      //'appinventiv':'digivive',
      'Accept-Language': existingLangCode,
      'Country': 'IN',
      'Catalogue': '565c8c878c86ba151d29b689',
      'Platform': 'web',
      //'Origin' : 'https://stage.platform8.tv',
    };
    if(existingImgHeader) {
      delete headersConfig['Content-Type'];
    }
    // const token = this.jwtService.getToken();
    // if (token) {
      if(localStorage.getItem('sessionId')){
        // 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIiLCJzdWIiOiJfQWNjZXNzVG9rZW4iLCJpc3MiOiIiLCJhdXRoSWQiOiI2NDAxYjdjM2EwMTdmMjJiMzc3MDAzMjAiLCJpYXQiOjE2Nzc4MzUzNTYsImV4cCI6MTczNzgzNTM1Nn0.H9WQu6xfgxEKvJLYACPOEwoenR77jbR4fXojvsOvHL-KtiLpo3g7Flt2kIcsvLgWrupZEgp1SLdMKiY9oyVCCQ';
        headersConfig['Authorization'] = 'Bearer '+ localStorage.getItem('sessionId'); 
        headersConfig['profile-id'] = localStorage.getItem('profile_id');  
      }
      //  else {
      //   headersConfig['Authorization'] = `${token}`;
      // }
    // }

    if (req.url.substring(req.url.lastIndexOf('/') + 1)=="signupnexg") {
      headersConfig['Authorization'] = authKey.defaultAuthKey;  
    }

    // if(req.url == 'https://apps.digivive.com/nexgtv_whitelabled_web/signupnexg') {
    //   headersConfig['Authorization'] = 'de6ed43f6c08ecf56536f8abcc812701';  
    // }

    let request = req;
    if (!req.url.includes('phonepe.com')) {
      request = req.clone({ setHeaders: headersConfig });
    }



    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // event.body.error_code = '220';
            // if(event.body.error_code == '401') {
            //   this.userService.purgeAuth();
            //   window.location.href = '/';
            // }
          }
          return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Handle 401 Unauthorized
          console.log('401 Unauthorized detected');
          this.userService.purgeAuth();
          window.location.href = '/';
        }
        return throwError(error);
      })
    );

   }

}
