<div class="maincontentarea mt40">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <app-myaccount-menu></app-myaccount-menu>
            </div>
            <div class="col-md-9">
                <div class="redeem-code-view">
                    <!-- <img src="../../../assets/img/Icon/redeem-code.svg" /> -->
                    <div class="lottie-redeem">
                        <div class="lottie-redeem-size">
                            <ng-lottie [options]="redeemCodeOptions"></ng-lottie>
                        </div>
                    </div>

                    <div class="redeem-code-enter">
                        <h4 class="mt-4">Promo Code/Activation Code</h4>
                        <p>Enter the code and get your subscription now</p>
                        <div >
                            <form class="redeem-code-input" (submit)="activationCode()">
                                <span><img src="../../../assets/img/Icon/redeem_code.svg" /></span>
                                <input placeholder="Enter Your Code" type="text" name="redeem-text" id=""
                                    autocomplete="off"
                                    [(ngModel)]="redeemText">
                                <button class="text-btn" type="submit">Claim Now</button>
                            </form>
                        </div>
                        <div class="popup-error-height">
                            <app-error
                                *ngIf="errorText"
                                [message]="errorText"></app-error>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #redeemCodeModal>
    <div class="auth popup_background">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <h5 class="text-white text-center" [style.visibility]="showPopupText ? 'visible' : 'hidden'">
                    Coupon code has been applied successfully.
                </h5>
                <div class="redeem-code-applied">
                    <div class="redeem-code-applied-size">
                        <ng-lottie [options]="redeemCodeAppliedOptions"></ng-lottie>
                    </div>
                </div>
                <p class="text-white text-center" [style.visibility]="showPopupText ? 'visible' : 'hidden'">
                    <!-- You have claimed Premium Pack on 3rd November 2023 and it is valid till 4th November 2023. -->
                     {{ successText }}
                     <br>
                     Redirecting you to My Account Page...
                </p>
            </div>
        </div>
    </div>
</ng-template>