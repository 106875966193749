<div class="bodyarea">
    <div class="container">
        <div class="row">
            <div class="col-md-5 ml-auto mr-auto">
                <div class="form_bg">
                    <label class="login_title">Change Password</label>
                    <form  [formGroup]="changePasswordForm" class="transparent_form" autocomplete="off">
                        <div class="form-group">
                            <div class="input_line">
                                <input [type]="'password'" class="form-control"
                                    placeholder="Current Password" formControlName="current_password">
                                <!-- <a href="javascript:;" (click)="hideRPass()">
                                    <i *ngIf="loginPassHide==true" class="fa fa-eye-slash"></i>
                                    <i *ngIf="loginPassHide==false" class="fa fa-eye"></i>
                                </a> -->
                            </div>
                         
                        </div>
                        <div>
                            <span
                                *ngIf="isSubmit && f.current_password.invalid && f.current_password.hasError('required')"
                                class="submit-error">Please Enter Current  Password</span>
                                
 
                        </div>
                        <div class="form-group">
                            <div class="input_line">
                                <input [type]="loginPassHide ? 'password' : 'text'" class="form-control"
                                    placeholder="Password" formControlName="password">
                                <a href="javascript:;" (click)="hideRPass()">
                                    <i *ngIf="loginPassHide==true" class="fa fa-eye-slash"></i>
                                    <i *ngIf="loginPassHide==false" class="fa fa-eye"></i>
                                </a>
                            </div>
                            <span class="password_length">Minimum 8 Character</span>
                        </div>
                        <div>
                            <span
                                *ngIf="isSubmit && f.password.invalid && f.password.hasError('required')"
                                class="submit-error">Please Enter Password</span>
                                <span  class="submit-error" *ngIf="api_error"> {{api_error}}</span>

                            <div
                                *ngIf="f.password.invalid  && (f.password.dirty || f.password.touched)">
                                <span *ngIf="f.password.hasError('minlength')"
                                    class="submit-error">Min Number 8</span>
                            </div>

                           
                        </div>

                        <div class="form-group">
                            <div class="input_line">
                                <input [type]="loginPassHide1 ? 'password' : 'text'" class="form-control"
                                    formControlName="confirm_password" placeholder="Confirm Password" />
                                <a href="javascript:;" (click)="hideRPass1()">
                                    <i *ngIf="loginPassHide1==true" class="fa fa-eye-slash"></i>
                                    <i *ngIf="loginPassHide1==false" class="fa fa-eye"></i>
                                </a>
                            </div>
                        </div>
                        <div>
                            <span
                                *ngIf="api_msg"
                                class="submit-success">{{api_msg}}</span>
                            <span
                                *ngIf="isSubmit && f.confirm_password.invalid && f.confirm_password.hasError('required')"
                                class="submit-error">Please Enter Confirm Password</span>

                                <span
                                *ngIf="isSubmit && confirmPass"
                                class="submit-error">Password And Confirm Password not Match</span>
                                <span *ngIf=" isSubmit && !f.confirm_password.hasError('required') && f.password.errors?.pattern" class="submit-error">Password should have 1 uppercase letter, 1 special character, 1 number, 1 lowercase letter</span>
                        </div>
                        <button (click)="changePassword()" type="submit" class="btn btn-default mt-4">Update</button>
                    </form>

                   

                </div>
            </div>
        </div>
    </div>
</div>