<section class="slider_img" *ngIf="bannerResult?.length > 0" style="z-index: auto;">

    <div class="inner_img">
      <div class="owl-carousel banner_slider owl-theme">
  
        <owl-carousel-o class="home_slide"
          #owlCarousel
          [options]="owlOption"
          (initialized)="onCarouselInitialized($event)"
          (translated)="onCarouselTranslated($event)">
          <ng-container *ngFor="let item of bannerResult;  let i=index">
            <ng-template carouselSlide class="item" [id]="strToString(i)">
              <div id="detail_banner" class="_slider_c bottom-bg-layer" >
                
                <img  alt="" class="bannervimg" [ngClass]="{ 'forshowandhide': addBefourVideo1Second[i] }"
                  [src]="item?.image_landscape" [style]="{ 'z-index': isVideoPlaying[i]==true ? '0' : '1'}">
                <div class="trailer_video" id="trailer_video">
                  <video id="bannervideo{{i}}" class="video-js vjs-fluid  vjs-16-9" controls autoplay preload="auto"
                    playsinline>
                    <p class="vjs-no-js">
                      To view this video please enable JavaScript, and consider upgrading to a
                      web browser that
                      <a href="https://videojs.com/html5-video-support/" target="_blank">supports
                        HTML5 video</a>
                    </p>
                  </video>
                </div>
  
                <div class="video_banner_layer" (click)="playVideo($event,item, content, loginContent)">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12 mt_video">
                        <div class="vidoe_slider_detail" >
                          <div class="timg" [class]="isVideoPlaying[i]==true ? 'synopsis_remove' : ''">
                            <div class="asset-logo-img">
                              <img *ngIf="item?.image_transparent!=''" alt="bannerImage"
                              [src]="item?.image_transparent" />
                            </div>
                          <div class="_s_play_btn" >
                            <!-- <div class="assets_info_label">
                              <ul>
                                <li>2018</li>
                                <li>2h 45m</li>
                                <li *ngIf="item?.languages?.length > 1">
                                  {{item.languages.length}} Languages
                                </li>
                                <li *ngIf="item?.languages?.length === 1">
                                    {{item.languages[0]}}
                                </li>
                              </ul>
                            <span class="slider_rating"
                              *ngIf="item?.viewer_rating?.rating_name">{{item.viewer_rating.rating_name}}</span>
                            </div> -->

                            <div class="assets_info_label">
                              <ul>
                                  <li *ngIf="item.languages?.length > 1">
                                      {{item.languages.length}} Languages
                                      <!-- <span class="tooltip_hover">
                                          <p *ngFor="let language of item.languages">{{language}}</p>
                                      </span> -->
                                  </li>
                                  <li *ngIf="item.languages?.length === 1">
                                      {{item.languages[0]}}
                                  </li>
                                  <li *ngIf="item.duration">{{item.formatted_duration}}</li>
                                  <li *ngIf="item.genre?.length > 1">
                                      {{item.genre.length}} Genres
                                      <!-- <span class="tooltip_hover">
                                          <p *ngFor="let genre of item.genre">{{genre}}</p>
                                      </span> -->
                                  </li>
                                  <li *ngIf="item.genre?.length === 1">
                                      {{item.genre[0]}}
                                  </li>
                                  <li *ngIf="item.genre_text?.length > 1">
                                      {{item.genre_text.length}} Genres
                                      <span class="tooltip_hover">
                                          <p *ngFor="let genre_text of item.genre_text">{{genre_text}}</p>
                                      </span>
                                  </li>
                                  <li *ngIf="item.genre_text?.length === 1">
                                      {{item.genre_text[0]}}
                                  </li>
                              </ul>
                              
                              <span *ngIf="item.type !== 'livetv'" class="slider_rating">{{item.viewer_rating?.rating_name}}</span>
                              <span *ngIf="item.type === 'livetv'" class="asset_live_tag">Live</span>
                            </div>
                            
                            <span *ngIf="item?.banner_synopsis" class="synopsis_text">{{item?.banner_synopsis}}</span>
                          </div>
                          </div>
                          <div class="watch_btn">

                            <a class="play_watch_btn btn_hover blur-btn" 
                              (click)="playVideo($event, item, content, loginContent,true)"> <!--  *ngIf="strToLower(item.content_availability) == 'free' || (strToLower(item.content_availability) == 'paid' && item?.is_pack_active == 1)" -->
                              <img alt="bannerWatchNowImage" src="../../../assets/img/Icon/play.svg">{{ item.type === 'livetv'? 'Watch Live' : 'Watch Now' }}</a>

                              <!-- <a *ngIf="strToLower(item.content_availability) == 'paid' && (!item?.is_pack_active || item?.is_pack_active == 0)" class="play_watch_btn btn_hover blur-btn subscribe-animation" 
                                (click)="playVideo(item, content, loginContent,true)">
                                <span class="top-star" [ngClass]="{ 'star-animation': isSubscribeAnimation && currentSlideIndex == i }"><img src="../../../assets/img/Icon/star.svg" /></span>
                                <div class="subscribe-animation-line" [ngClass]="{'fade-line-animation': isSubscribeAnimation && currentSlideIndex == i}">
                                  <img src="../../../assets/img/Icon/subscribe.svg" />
                                  Subscribe to Watch
                                </div>
                                <span class="bottom-star" [ngClass]="{ 'star-animation': isSubscribeAnimation && currentSlideIndex == i }"><img src="../../../assets/img/Icon/star.svg" /></span>
                              </a> -->

                            <div class="banner-add-watch">
                              <button #watchlistButton (click)="addRemoveFromWatchlist(item, $event)" class="add_watch_btn add_watch_list_btn btn_hover blur-btn">
                                <img *ngIf="item?.is_watchlist_added == undefined || item?.is_watchlist_added == 0"
                                src="../../../assets/img/Icon/plush.svg" />
                                <img *ngIf="item?.is_watchlist_added == 1" src="../../../assets/img/Icon/check.svg" />
                            </button>
                            
                            <span *ngIf="item?.is_watchlist_added == 0" class="tooltip_hover home_tooltip">Watchlist</span>
                            <span *ngIf="item?.is_watchlist_added == 1" class="tooltip_hover home_tooltip home_tooltip_add">Added to Watchlist</span>
                          </div>
                            
                          </div>
                          <a class="banner_mute_button"
                              [style]="{ 'display': isVideoPlaying[i]==true ? 'block' : 'none'}">
                              <img src="assets/img/Icon/unmute.svg"
                                *ngIf="!isPreviewVideoMuted"
                                (click)="muteUnmutePreview(false,i, $event)"/>
                              <img src="assets/img/Icon/mute.svg"
                                *ngIf="isPreviewVideoMuted"
                                (click)="muteUnmutePreview(true,i, $event)"/>
                            </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
  
        <owl-carousel-o class="slider_thumbnail"
          #thumbnailCarousel
          [options]="thumbnailCarouselOptions"
          (translated)="onThumbnailCarouselTranslated($event)"
          (initialized)="onThumbnailCarouselInitialized($event)">
          <ng-container *ngFor="let item of bannerResult;  let i=index">
            <ng-template carouselSlide class="item" [id]="i">
              <div id="bannerThumbnail{{i}}" class="thumb_slide" (click)="handleThumbnailClick(i)">
                <!-- <img alt="" class="" [src]="item?.image_landscape"> -->
                <image-manipulation [assetName]="item.name"  class="asset_hover_img"
                  [img]="item?.image_landscape" [params]="{'width':112,'height':63}"
                  [notFound]="'landscape'"></image-manipulation>
                <div *ngIf="isVideoPlaying[i]==true && anumaitionDurtion!='5'" class="thumb_slide_fill" [ngStyle]="{ 'animation-duration': anumaitionDurtion + 's' }"></div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
  
      </div>
    </div>
  
  
    
  </section>
    
  <!-- <div class="modal auth" id="add_watch_list_from_banner" *ngIf="userInfo">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Are you sure want to add?</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeAddtoWatchListModal>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="addWatchList(loginContent)">Yes</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
          <a data-toggle="modal" data-target="#pack_removed_success"></a>
        </div>
      </div>
    </div>
  </div> -->
  
  
  <ng-template #content let-modal class="_parent_jw_m">
    <app-jw-video-player [streamInfo]="streamInfo" class="_p_jw_player"></app-jw-video-player>
  </ng-template>
  
  
  <ng-template #loginContent let-modal>
    <app-login-register></app-login-register>
  </ng-template>