import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class SearchService {
  END_POINT = environment.api_url;
  private httpClient: HttpClient;
  private search_base_url = environment.search_base_url;
  isParentalControlEnabled = null;
  constructor(private httpBackend: HttpBackend) {
    this.httpClient = new HttpClient(httpBackend);
  }

  setParentalControl(status){
    this.isParentalControlEnabled = status;
    localStorage.setItem('parentControl', this.isParentalControlEnabled);
  }

   parentalControlStatus(){
    // console.log(this.isParentalControlEnabled);
    // return this.isParentalControlEnabled;
let parentControl = localStorage.getItem('parentControl');;
    return parentControl;
  }

  elsticSearchQuery(){
    // return this.apiService.post('playlist', params).pipe(map(data => {
    //   return data;
    // }));

  }

  searchResults(params: string, body: Object = {}) {
    return this.httpClient.post(
      `${this.search_base_url}?${params}`,
      body
    ).pipe(catchError(this.formatErrors));
  }

  private formatErrors(error: any): Observable<never> {
    // Customize error formatting as needed
    return throwError(() => new Error(error));
  }
  
}
