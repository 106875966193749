import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, PreloadingStrategy, Router } from '@angular/router';
import { PageTitleService } from 'src/app/services/title/page-title.service';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  fileterDataType = localStorage.getItem('filterTypeData');
  isCloudfrontEnabled = JSON.parse(this.fileterDataType).is_cloudfront_enabled;
  isbucket = JSON.parse(this.fileterDataType).bucket;

  detailPageDataArray: any = [];
  assetsImage: string = '';
  BASE_ASSETS_PATH: string = environment.BASE_ASSETS_PATH;// 'https://res.cloudinary.com/digivive/image/upload/';
  APP_CLOUD_NAME: string = environment.APP_CLOUD_NAME;
  EXT: string = environment.SRC_EXT;
  filterType: any = this.isCloudfrontEnabled;
  filterTypeBucket: any = this.isbucket;
  objectOfAllStrings: any;
  

  assetsData: any = [];
  isCpampignBanner: boolean = false;

  constructor(
    private apiService: ApiService,
    public router: Router,
    private route: ActivatedRoute,
    public pageTitleService: PageTitleService,
    private googleEvent: EventTrackingService,
    private userService: UserService
  ) { }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  numberWithAstrick(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 13 || charCode == 42 || (charCode >= 48 && charCode <= 57)) {
      return true;
    }
    return false;
  }

  charOnly(e) { // handel space in any input at fist possition
    if (e.which === 32 && !e.target.value.length)
      e.preventDefault();
  }

  alphabetOnly(e) {
    if (e.charCode == 13 || e.charCode == 32 || (e.charCode > 64 && e.charCode < 91) || (e.charCode > 96 && e.charCode < 123)) {
      return true;
    } else {
      return false;
    }
  }

  alphabetWithNumber(e) {
    if ((e.charCode > 31 && e.charCode < 48) || (e.charCode > 57 && e.charCode < 65) || (e.charCode > 90 && e.charCode < 97) || (e.charCode > 122 && e.charCode <= 126)) {
      return false;
    } else {
      return true;
    }
  }

  alphabetWithSplChrOnly(e) {
    if (e.charCode < 48 && e.charCode > 58) {
      return false;
    } else {
      return true;
    }
  }

  ipOnly(e) {
    if ((e.charCode > 64 && e.charCode < 71) || (e.charCode > 96 && e.charCode < 103) || e.charCode == 8 || e.charCode == 46 || (e.charCode >= 48 && e.charCode <= 58)) {
      return true;
    } else {
      return false;
    }
  }


  getTimezone() {
    // const url = `common/getTimeZone`;
    // return this.apiService.get(url).pipe(map(data => {
    //   return data;
    // }));
  }


  reloadPage() {
    window.location.reload();
  }

  floatOnly(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var number = evt.target.value.split('.');
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    //just one dot
    if (number.length > 1 && charCode == 46) {
      return false;
    }
    //get the carat position
    //var caratPos = getSelectionStart(el);
    var dotPos = evt.target.value.indexOf(".");
    if (dotPos > -1 && (number[1].length > 4)) {
      return false;
    }
    return true;
  }

  mobileLimit(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    let inputValue = evt.target.value;
    if (inputValue.length == 10 || charCode == 101) {
      return false;
    }
    return true;

  }

  avoidSpace(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (evt.target.value == '') {
      return true;
    }
    // if(charCode == 32 || charCode == 62){
    //   return false;
    // }
    return true;
  }

  otpLimit(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    let inputValue = evt.target.value;
    if (inputValue.length == 6 || charCode == 101) {
      return false;
    }
    return true;
  }

  onlyPositiveNumber(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode == 45 || charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  decimalWIth2digits(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var number = evt.target.value.split('.');
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    //just one dot
    if (number.length > 1 && charCode == 46) {
      return false;
    }
    //get the carat position
    //var caratPos = getSelectionStart(el);
    var dotPos = evt.target.value.indexOf(".");
    if (dotPos > -1 && (number[1].length > 1)) {
      return false;
    }
    return true;

  }
  floatWithNegativeNumber(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var number = evt.target.value.split('.');
    var number1 = evt.target.value.split('-');
    if (charCode != 45 && charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    //just one dot
    if (number.length > 1 && charCode == 46) {
      return false;
    }

    if (number1.length > 1 && charCode == 45) {
      return false;
    }
    //get the carat position
    //var caratPos = getSelectionStart(el);
    var dotPos = evt.target.value.indexOf(".");
    if (dotPos > -1 && (number[1].length > 4)) {
      return false;
    }
    return true;
  }

  saveDetailPageData(key, data) {
    this.detailPageDataArray[key] = data;
  }
  get detailPageData() {
    return this.detailPageDataArray;
  }

  // goToDetail(data, page?: string, reload?: number) {
  //   let userinfo = JSON.parse(localStorage.getItem('userInfo'));
  //   let userUnique = userinfo.result.username;
  //   // if (Object.keys(userinfo).length > 0) {
  //   //   if (userinfo.result.register_by == 'mobile') {
  //   //     userUnique = userinfo.result.mobile;
  //   //   } else {
  //   //     userUnique = userinfo.result.emailaddress;
  //   //   }
  //   // }
  //   console.log(page + ' page');
  //   let name = data.name == undefined ? data.asset_detail.name : data.name;
  //   let title = this.strToSlug(name);
  //   if (page != undefined)
  //   //this.googleEvent.eventEmitter(page, "Video_View", userUnique + '-' + data.code + '-' + title);
  //   this.googleEvent.eventEmitter(page, "Video_View", userUnique + '-' + data.code + '-' + title, 0, userUnique, userUnique + '-' + data.code + '-' + title);



  //   let slug = this.detailRoute(data);

  //   this.saveDetailPageData(data.code, data);

  //   if (reload != undefined)
  //     window.location.href = window.location.origin + '/' + slug;

  //   this.router.navigate([slug])
  // }


  goToDetail(data, page?: string, reload?: number) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let username = userInfo.result.user.username;
    // console.log(page + ' page');
    let name = data.name == undefined ? data.asset_detail.name : data.name;
    let title = this.strToSlug(name);
    if (page != undefined)
    //this.googleEvent.eventEmitter(page, "Video_View", userUnique + '-' + data.code + '-' + title);
    this.googleEvent.eventEmitter(page, "Video_View", username + '-' + data.code + '-' + title, 0, username, username + '-' + data.code + '-' + title);

    let slug = this.detailRoute(data);
    this.saveDetailPageData(data.code, data);

    if (reload != undefined)
      window.location.href = window.location.origin + '/' + slug;

    data.full_screen
    ? this.router.navigate([slug],{queryParams:{full_screen:data.full_screen}}) 
    :this.router.navigate([slug]); 
  }

  detailRoute(data) {
    if(data.type=='episode'){
      data.type = 'tvshow';
    }
    
    if(data.type=='live'){
      data.type = 'livetv';
    }

    let title = data.name == undefined ? data.asset_detail.name : data.name;
    let slug = this.strToSlug(title);
    return 'detail/' + slug + '/' + data.code + '/' + data.type;
  }

  strToSlug(title) {

    return title.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');
  }

  setDetailRedirectParams(data) {
    const assetData = {
      redirect_to_detail: true,
      name: data.name,
      code: data.code,
      type: data.type,
      full_screen: data.full_screen,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: assetData,
    });
  }

  setBeforeLoginState(data) {
    this.assetsData = data;
  }
  setBeforeLoginState2() {
    this.isCpampignBanner = true;
  }

  getBeforeLoginState() {
    return this.isCpampignBanner;
   }

  getAssetsData() {
    return this.assetsData;
  }

  activeHeaderOnAssetClick(data) {
  }

  stdNumber(e) {
    var input = e.target;
    input.onkeypress = function (e) {
      e = e || window.event;
      var charCode = (typeof e.which == "number") ? e.which : e.keyCode;
      // Allow non-printable keys
      if (!charCode || charCode == 8 /* Backspace */) {
        return;
      }

      var typedChar = String.fromCharCode(charCode);

      // Allow numeric characters
      if (/\d/.test(typedChar)) {
        return;
      }

      // Allow the minus sign (-) if the user enters it first
      if (typedChar == "+" && this.value == "") {
        return;
      }

      // In all other cases, suppress the event
      return false;
    };
  }

  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
  }


  passwordMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  pad(d) {
    let tempD = parseInt(d);
    if (tempD > 0) {
      return tempD;
    } else {
      return '';
    }
    // return (d < 10) ? d.substr(1, 1) : d.toString();
  }

  compressImgUrl(img: string, params: any = {}, notFound:string) {
    if (img == undefined || img == null || img == "") {
      this.assetsImage = "./assets/img/not-found/"+notFound+".png";
      return this.assetsImage;
    } else {
        let breckFordomin = img.split('/', 3);
        let domianName = breckFordomin.toString().replace(',,', '//');
        let index = img.lastIndexOf("/") + 2;
        let filename = img.substr(index);
        var url = img;
        var pathname = new URL(url).pathname;
        let key1 = pathname;

        if(key1.match('//')){
          var key = key1.substring(2);
        }else{
          var key = key1.substring(1);
        }

        let WithOutImageName = img.replace(filename, '');
        let bucketName = this.filterTypeBucket;


        if (Object.keys(params).length == 0 || params == undefined) {
          return this.assetsImage = WithOutImageName + domianName + '/' + filename;
        }


        if (params.crop == "scale") {
          this.objectOfAllStrings = {
            "bucket": bucketName,
            "key": key,
            "edits": {
              "normalize": false,
              "grayscale": false,
              "webp": true,
              "sharpen": false,
              "roundCrop": params.roundCrop !== "" ? params.roundCrop : false,
              "resize": {
                "width": params.width,
                "height": params.height,
                "fit": "cover"
              }
            },
            "smartCrop": {
              "padding": 40
            }
          }
        } else {
          this.objectOfAllStrings = {
            "bucket": bucketName,
            "key": key,
            "edits": {
              "normalize": false,
              "grayscale": false,
              "webp": true,
              "sharpen": false,
              "resize": {
                "width": params.width,
                "height": params.height,
                "fit": params.imgType == "square" || params.imgType == 'portrait' ? "fill" :"cover"
              }
            }
          }
        }

        let encodeBase64 = btoa(JSON.stringify(this.objectOfAllStrings));
        var fullUrl = domianName + "/" + encodeBase64;
        return this.assetsImage = fullUrl;
    }
  }


  languageChange() {
    let languageData = {};
    if (environment.language == 'EN') {
      return languageData = {
        aboutUs: 'About Us',
        addNew: 'Add New',
        addProfile: 'Add Profile',
        age: 'Age',
        allRecommendationsViewingHistory: 'All recommendations, viewing history, Watchlist and more will be permanently Deleted.',
        anOtpHasBeenSentTo: 'An OTP has been sent to ',
        areYouSureYouWantToDeleteThisProfile: 'Are you sure you want to delete this profile?',
        back: 'Back',
        cancel: 'Cancel',
        changeAvatar: 'Change Avtar',
        changePassword: 'Change Password',
        changePin: 'Change PIN',
        confirmNewPassword: 'Confirm New Password',
        confirmNewPin: 'Confirm New PIN',
        confirmPin: 'Confirm PIN',
        confirmYourNewPin: 'Confirm your new pIN',
        contentAdvisory: 'Content Advisory',
        createNewPassword: 'Create New Password',
        currentPin: 'Current PIN',
        deleteProfile : 'Delete Profile',
        didNotGetTheOtp: "Didn't get OTP?",
        digitsMobileNumber: ' digit mobile number',
        director: 'Director',
        done: 'Done',
        editProfile: 'Edit Profile',
        emailId: 'Email ID',
        enterCurrentPin: 'Enter Current PIN',
        enterNewPassword: 'Enter New Password',
        enterNewPin: 'Enter New PIN',
        enterOtpToConfirm: 'Enter OTP to confirm',
        enterOtpToLogin: 'Enter OTP to Login',
        enterOtpToRegister: 'Enter OTP to Register',
        enterOTP: 'Enter OTP',
        enterPin: 'Enter PIN',
        enterYourNewPin: 'Enter you new PIN',
        enterYourPinToAccess: 'Enter your PIN to access this profile',
        enter_username: 'Enter your user name',
        enterMobileEmail: 'Enter mobile number / email ID',
        episodes: 'Episodes',
        forgotPassword: 'Forgot Password',
        forgotPin: 'Forgot PIN',
        genre: 'Genre',
        helpCenter: 'Help Center',
        hi: 'Hi',
        kids: 'Kids',
        kidsProfile: 'Kids Profile',
        language: 'Language',
        liveEvent: 'Live Event',
        liveTv: 'Live TV',
        lockProfile: 'Lock Profile',
        login: 'Login',
        loginRegister: 'Login/Register',
        loginWithOTP: 'Login With OTP',
        loginWithPassword: 'Login With Password',
        logOut: 'Log Out',
        manageProfile: 'Manage Profile',
        manageProfiles: 'Manage Profiles',
        maturityRating: 'Maturity Rating',
        maturitySetting: 'Maturity Setting',
        mobileNumber: 'Mobile Number',
        mobileNumberEmailID: 'Mobile Number / Email ID',
        moreInfo: 'MORE INFO',
        movies: 'Movies',
        musicDirector: 'Music Director',
        myaccount : 'My Account',
        mySubscriptions: 'My Subscriptions',
        myVouchers: 'My Vouchers',
        notificationsOverWhatsApp: 'I want to receive updates and notifications over whatsApp',
        or: 'or',
        password: 'Password',
        passwordAndConfirmPasswordDoNotMatch: 'Password and Confirm Password Do Not Match',
        passwordReset: 'Password Reset',
        passwordShould: 'Password should have 1 uppercase letter, 1 special character, 1 number, 1 lowercase letter',
        passwordShouldBe: 'Password should be 8-15 characters',
        passwordShouldHaveMinimum: 'Password should have minimum 8 characters',
        passwordShouldHaveMaximum: 'Password should have maximum 15 characters',
        pinAndConfirmPinDoNotMatch: 'PIN and Confirm PIN Do Not Match',
        pleaseChooseAgeRestriction: 'Please Choose Age Restriction',
        pleaseConfirmYourIdentity: 'Please confirm your identity',
        pleaseEnter: 'Please Enter ',
        pleaseEnter10DigitMobile: 'Please enter 10 digit mobile number',
        pleaseEnterAPassword: 'Please enter a password',
        pleaseEnterConfirmPassword: 'Please Enter Confirm Password',
        pleaseEnterEmail: 'Please Enter Email',
        pleaseEnterMobile: 'Please Enter Mobile',
        pleaseEnterMobileEmail: 'Please enter a mobile number/ email ID',
        pleaseEnterNewPassword: 'Please enter new password',
        pleaseEnterOTP: 'Please Enter OTP',
        pleaseEnterConfirmPin: 'Confirm PIN',
        pleaseEnterNewPin: 'Please Enter New PIN',
        pleaseEnterPin: 'Enter PIN',
        pleaseEnterProfileName: 'Please Enter Profile Name',
        pleaseEnterValidEmail: 'Please Enter a Valid Email',
        pleaseEnterValidMobile: 'Please Enter Valid Mobile',
        pleaseEnterValidOTP: 'Please Enter Valid OTP',
        pleaseEnterValidPIN: 'Please Enter Valid PIN',
        pleaseEnterValidProfileName: 'Please Enter Valid Profile Name',
        pleaseEnterValidConfirmPIN: 'Please Enter Valid Confirm PIN',
        pleaseEnterCurrentPIN: 'Please Enter Current PIN',
        pleaseEnterYourPassword: 'Please Enter Your Password',
        pleaseSelectProfileAvatar: 'Please Select Profile Avatar',
        pleaseSetNewEightCharacterPassword: 'Please set a new 8 character password for your account.',
        playFromBeginning: 'play from beginning',
        policy: 'Privacy Policy',
        producer: 'Producer',
        proceed: 'Proceed',
        proceedingAgreeTerms: 'By proceeding you are agree to our Terms of Use and Privacy Policy',
        orContinueWith: 'Or continue with',
        redeedmCode : 'Redeem Code',
        register: 'Register',
        registerForFree: 'Register for Free',
        releaseDate: 'Release Date',
        rememberMe: 'Remember me',
        resetPassword: 'Reset Password',
        resetPin: 'Reset Pin',
        resend: 'Resend',
        save: 'Save',
        search: 'Search',
        series: 'Series',
        select:'Select',
        setNewPassword: 'Set New Password',
        setPin: 'Set Pin',
        share: 'Share',
        showingAllResultFor: 'Showing all result for',
        signin: 'Sign In',
        similar: 'Similar',
        signout: 'Sign Out',
        signup: 'Sign Up',
        submit: 'Submit',
        subscribe: 'Subscribe',
        term: 'Terms & Use',
        trailer: 'Trailer',
        verify: 'Verify',
        verifyEmail: 'Verify Email',
        verifyMobile: 'Verify Mobile Number',
        verifyOTPtoLogin: 'Verify OTP to Login',
        verifyOTPtoRegister: 'Verify OTP to Register',
        viewAll: 'View All',
        viewMore: 'View More',
        watchlist: 'Watchlist',
        watchNow: 'Watch Now',
        whoIsWatching: "Who's Watching ?",
        writer: 'Writer',
        yesLogout: 'Yes, Logout',
        youcansetupto5: 'You can set up to 5 Profiles for your family or friends',
      };
    }
    if (environment.language == 'HI') {
      return languageData = {
        aboutUs: 'हमारे बारे में',
        addNew: 'नया जोड़ें',
        addProfile: 'प्रोफ़ाइल जोड़ें',
        age: 'आयु',
        allRecommendationsViewingHistory: 'सभी सिफारिशें, देखने का इतिहास, मेरी सूची और अधिक स्थायी रूप से हटा दिए जाएंगे।',
        anOtpHasBeenSentTo: 'एक OTP भेजा गया है ',
        areYouSureYouWantToDeleteThisProfile: 'क्या आप वाकई इस प्रोफ़ाइल को हटाना चाहते हैं?',
        back: 'पीछे जाएं',
        cancel: 'रद्द करें',
        changeAvatar: 'अवतार बदलें',
        changePassword: 'पासवर्ड बदलें',
        changePin: 'पिन बदलें',
        confirmNewPassword: 'नया पासवर्ड पुष्टि करें',
        confirmNewPin: 'नये पिन की पुष्टि करें',
        confirmPin: 'पिन पुष्टि करें',
        confirmYourNewPin: 'अपने नए पिन की पुष्टि करें',
        contentAdvisory: 'सामग्री सलाहकार',
        createNewPassword: 'नया पासवर्ड बनाएं',
        currentPin: 'वर्तमान पिन',
        deleteProfile: 'प्रोफ़ाइल हटाएं',
        didNotGetTheOtp: 'क्या आपको ओटीपी नहीं मिला?',
        digitsMobileNumber: 'अंकों का मोबाइल नंबर दर्ज करें',
        director: 'निर्देशक',
        done: 'हो गया',
        editProfile: 'प्रोफ़ाइल संपादित करें',
        emailId: 'ईमेल आईडी',
        enterCurrentPin: 'वर्तमान पिन दर्ज करें',
        enterNewPassword: 'नया पासवर्ड दर्ज करें',
        enterNewPin: 'नया पिन दर्ज करें',
        enterOtpToConfirm: 'पुष्टि के लिए ओटीपी दर्ज करें',
        enterOtpToLogin: 'लॉगिन करने के लिए ओटीपी दर्ज करें',
        enterOtpToRegister: 'रजिस्टर करने के लिए ओटीपी दर्ज करें',
        enterPin: 'पिन दर्ज करें',
        enterYourPinToAccess: 'इस प्रोफ़ाइल तक पहुंचने के लिए अपना पिन दर्ज करें',
        enter_username: 'अपना उपयोगकर्ता नाम दर्ज करें',
        episodes: 'एपिसोड्स',
        forgotPassword: 'पासवर्ड भूल गए',
        forgotPin: 'पिन भूल गए',
        genre: 'शैली',
        helpCenter: 'सहायता केंद्र',
        hi: 'नमस्ते',
        kids: 'बच्चे',
        kidsProfile: 'बच्चों का प्रोफ़ाइल',
        language: 'भाषा',
        liveEvent: 'लाइव घटना',
        liveTv: 'लाइव टीवी',
        lockProfile: 'प्रोफ़ाइल ताला लगाएं',
        login: 'लॉग इन करें',
        loginRegister: 'लॉगिन/रजिस्टर करें',
        loginWithOTP: 'ओटीपी के साथ लॉग इन करें',
        loginWithPassword: 'पासवर्ड के साथ लॉग इन करें',
        logOut: 'लॉग आउट',
        manageProfile: 'प्रोफ़ाइल प्रबंधित करें',
        manageProfiles: 'प्रोफ़ाइल प्रबंधित करें',
        maturityRating: 'परिपक्वता रेटिंग',
        maturitySetting: 'परिपक्वता सेटिंग',
        mobileNumber: 'मोबाइल नंबर',
        mobileNumberEmailID: 'मोबाइल नंबर / ईमेल आईडी',
        moreInfo: 'अधिक जानकारी',
        movies: 'फिल्में',
        musicDirector: 'संगीत निर्देशक',
        myaccount: 'मेरा खाता',
        mySubscriptions: 'मेरा अनुमोदन',
        myVouchers: 'मेरे वाउचर',
        notificationsOverWhatsApp: 'मैं WhatsApp पर अपडेट और सूचनाएँ प्राप्त करना चाहता हूं',
        or: 'या',
        password: 'पासवर्ड',
        passwordAndConfirmPasswordDoNotMatch: 'पासवर्ड और पासवर्ड पुष्टि मेल नहीं खाते',
        passwordReset: 'पासवर्ड रीसेट',
        passwordShould: 'पासवर्ड में 1 अपरकेस अक्षर, 1 विशेष चरित्र, 1 संख्या, 1 लोअरकेस अक्षर होना चाहिए',
        passwordShouldBe: 'पासवर्ड 8-15 अक्षर का होना चाहिए',
        passwordShouldHaveMinimum: 'पासवर्ड में कम से कम 8 अक्षर होने चाहिए',
        passwordShouldHaveMaximum: 'पासवर्ड में अधिकतम 15 अक्षर होने चाहिए',
        pinAndConfirmPinDoNotMatch: 'पिन और पिन पुष्टि मेल नहीं खाते',
        pleaseChooseAgeRestriction: 'कृपया आयु प्रतिबंध चुनें',
        pleaseConfirmYourIdentity: 'कृपया अपनी पहचान की पुष्टि करें',
        pleaseEnter: 'Please Enter',
        pleaseEnter10DigitMobile: 'कृपया 10 अंकों का मोबाइल नंबर दर्ज करें',
        pleaseEnterAPassword: 'कृपया पासवर्ड दर्ज करें',
        pleaseEnterConfirmPassword: 'कृपया पासवर्ड पुष्टि करें',
        pleaseEnterConfirmPin: 'कृपया पुष्टि पिन दर्ज करें',
        pleaseEnterNewPin: 'कृपया नया पिन दर्ज करें',
        pleaseEnterPin: 'कृपया पिन दर्ज करें',
        pleaseEnterEmail: 'कृपया ईमेल दर्ज करें',
        pleaseEnterMobile: 'कृपया मोबाइल दर्ज करें',
        pleaseEnterMobileEmail: 'कृपया मोबाइल नंबर / ईमेल आईडी दर्ज करें',
        pleaseEnterNewPassword: 'कृपया नया पासवर्ड दर्ज करें',
        pleaseEnterOTP: 'कृपया ओटीपी दर्ज करें',
        pleaseEnterProfileName: 'कृपया प्रोफ़ाइल नाम दर्ज करें',
        pleaseEnterValidEmail: 'कृपया वैध ईमेल दर्ज करें',
        pleaseEnterValidMobile: 'कृपया वैध मोबाइल दर्ज करें',
        pleaseEnterValidOTP: 'कृपया वैध ओटीपी दर्ज करें',
        pleaseEnterValidPIN: 'कृपया वैध पिन दर्ज करें',
        pleaseEnterValidProfileName: 'कृपया वैध प्रोफ़ाइल नाम दर्ज करें',
        pleaseEnterYourPassword: 'कृपया अपना पासवर्ड दर्ज करें',
        pleaseSelectProfileAvatar: 'कृपया प्रोफ़ाइल अवतार चुनें',
        pleaseSetNewEightCharacterPassword: 'कृपया अपने खाते के लिए नया 8 अक्षर का पासवर्ड सेट करें।',
        playFromBeginning: 'शुरुआत से चलाएं',
        policy: 'गोपनीयता नीति',
        producer: 'निर्माता',
        proceed: 'आगे बढ़ें',
        proceedingAgreeTerms: 'आगे बढ़ने पर आप हमारे उपयोग की शर्तों और गोपनीयता नीति से सहमत हैं',
        orContinueWith: 'या जारी रखें',
        register: 'रजिस्टर करें',
        registerForFree: 'मुफ्त में रजिस्टर करें',
        releaseDate: 'रिलीज़ तिथि',
        rememberMe: 'मुझे याद रखें',
        resetPassword: 'पासवर्ड रीसेट',
        resetPin: 'पिन रीसेट करें',
        resend: 'पुनः भेजें',
        save: 'सहेजें',
        search: 'खोज',
        series: 'शृंखला',
        select: 'चुनें',
        setNewPassword: 'नया पासवर्ड सेट करें',
        setPin: 'पिन सेट करें',
        share: 'साझा करें',
        showingAllResultFor: 'सभी परिणाम दिखाए जा रहे हैं',
        signin: 'साइन इन करें',
        similar: 'समान',
        signout: 'साइन आउट',
        signup: 'साइन अप करें',
        submit: 'प्रस्तुत',
        subscribe: 'सब्सक्राइब करें',
        term: 'शर्तें और उपयोग',
        trailer: 'ट्रेलर',
        verify: 'सत्यापित करें',
        verifyEmail: 'ईमेल सत्यापित करें',
        verifyMobile: 'मोबाइल संख्या सत्यापित करें',
        verifyOTPtoLogin: 'लॉग इन करने के लिए ओटीपी सत्यापित करें',
        verifyOTPtoRegister: 'रजिस्टर करने के लिए ओटीपी सत्यापित करें',
        viewAll: 'सभी देखें',
        viewMore: 'अधिक देखें',
        watchlist: 'देखने की सूची',
        watchNow: 'अब देखें',
        whoIsWatching: 'कौन देख रहा है?',
        writer: 'लेखक',
        yesLogout: 'हां, लॉगआउट करें',
        youcansetupto5: 'आप अपने परिवार या दोस्तों के लिए 5 प्रोफाइल सेट कर सकते हैं',
      };
    }
  }
  


}
