<div class="bodyarea">
    <!-- <div class="container"> -->
        <div class="row">
            <div class="col-md-12 ml-auto mr-auto">
                <button  type="button" class="close popup_close" (click)="closeModal()" #closebutton>&times;</button>
                <div class="form_bg">
                    <div class="text-center mb-4">
                        <div class="mb-4"><img style="width: 150px;" src="../../../../assets/img/logo.png" /></div>
                    </div>
                    <div class="text-center" *ngIf="!resetPassSuccess">
                        <label class="login_title">{{language.passwordReset}}</label>
                        <p class="text-white">{{language.pleaseSetNewEightCharacterPassword}}</p>
                    </div>
                    <form *ngIf="!isValidField && !resetPassSuccess" [formGroup]="resetPasswordForm" class="transparent_form" autocomplete="off">
                        <div class="form-group mb-0">
                            <label class="input_heading">{{language.enterNewPassword}}</label>
                            <div class="input_line">
                                <input [type]="loginPassHide ? 'password' : 'text'" class="form-control" placeholder="Enter new password" formControlName="password">
                                <a href="javascript:;" (click)="hideRPass()">
                                    <i *ngIf="loginPassHide==true" class="fa fa-eye-slash"></i>
                                    <i *ngIf="loginPassHide==false" class="fa fa-eye"></i>
                                </a>
                            </div>
                            <div class="text-right" style="min-height: 30px;">
                                <div *ngIf="isSubmit && resetPasswordControls.password.invalid && resetPasswordControls.password.hasError('required')" class="error-toast animate__animated animate__fadeInUp">
                                    <span style="width: 25px">
                                        <ng-lottie [options]="errorOptions"></ng-lottie>
                                    </span>
                                    <span>{{language.pleaseEnterYourPassword}}</span>
                                </div>
    
                                <div class="error-toast animate__animated animate__fadeInUp" *ngIf="resetPasswordControls.password.invalid  && (resetPasswordControls.password.dirty || resetPasswordControls.password.touched)">
                                    <span style="width: 25px">
                                        <ng-lottie [options]="errorOptions"></ng-lottie>
                                    </span>
                                    <span *ngIf="resetPasswordControls.password.hasError('minlength')">{{language.passwordShouldHaveMinimum}}</span>
                                    <span *ngIf="resetPasswordControls.password.hasError('maxlength')">{{language.passwordShouldHaveMaximum}}</span>
                                </div>
                            </div>
                        </div>
                        

                        <div class="form-group mb-0">
                            <label class="input_heading">{{language.confirmNewPassword}}</label>
                            <div class="input_line">
                                <input [type]="loginPassHide1 ? 'password' : 'text'" class="form-control"
                                    formControlName="confirm_password" placeholder="Confirm Password" />
                                <a href="javascript:;" (click)="hideRPass1()">
                                    <i *ngIf="loginPassHide1==true" class="fa fa-eye-slash"></i>
                                    <i *ngIf="loginPassHide1==false" class="fa fa-eye"></i>
                                </a>
                            </div>
                            <div class="text-right" style="min-height: 30px;">
                                <small *ngIf="isSubmit && resetPasswordControls.confirm_password.invalid && resetPasswordControls.confirm_password.hasError('required')" class="submit-error">{{language.pleaseEnterConfirmPassword}}</small>
                                <small *ngIf="isSubmit && confirmPass" class="submit-error">{{language.passwordAndConfirmPasswordDoNotMatch}}</small>
                                <small *ngIf=" isSubmit && !resetPasswordControls.confirm_password.hasError('required') && resetPasswordControls.password.errors?.pattern" class="submit-error">{{language.passwordShouldHave}}</small>
                            </div>
                        </div>
                        
                        <button (click)="resetPassword()" type="submit" class="btn btn-default mt-4 btn_height btn_hover">{{language.setPassword}}</button>
                    </form>
                    <div *ngIf="resetPassSuccess">
                        <div class="success-add">
                          <h2 style="color:#fff;font-size: 20px;font-weight: 100;line-height: 35px;"
                            class="_verify_t">
                            {{api_msg}}
                          </h2>
                          <div class="lottie-icon text-center" style="width: 100%;">
                            <ng-lottie [options]="successOptions"></ng-lottie>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
</div>

<ng-template #loginRegisterContent>
    <app-login-register></app-login-register>
</ng-template>