<section class="_custom_paren_img mini_slider"
    [ngClass]="{'assets_hover': assets_hover }">

    <div *ngIf="data.sub_categories.length == 0">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="_no_data_f">
                        <h1>No Data Found</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="_custom_cont" *ngIf="data.sub_categories.length>0">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="i_head_c">
                        <div class="view_all_animation">
                            <h4>{{data.category_name}}</h4>
                            <span class="view_all_animation_hidden">
                                <a class="new_view_all" *ngIf="data.sub_categories && data.sub_categories.length > 4" (click)="viewall(data)"><span>View all </span> <i class="fa fa-angle-right"></i></a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="_custom_grid_img mb40" [ngClass]="{'assets_hover': assets_hover }">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="owl-carousel four_slides owl-theme {{thumbnailClass}} new_asset_hover_type"
                        [ngClass]="{'_less_four':data.assets.length <= 4 }">
                        <owl-carousel-o [options]="owlOption">

                            <ng-container *ngFor="let item of data.sub_categories; let i=index">
                                <ng-template carouselSlide>
                                    <div class="item"
                                        [ngClass]="{'assets_hover': assets_hover }"
                                        (mouseenter)="item_hover_enter(item)" (mouseleave)="item_hover_leave(item)"
                                        style="position: relative;">
                                        <div class="new_asset"
                                            (click)="redirectUrl(item, content, jwplaydom, $event)">
                                            <div class="new_asset_img">
                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'portrait'"
                                                    [img]="item.image_portrait || item.image_portrait"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'landscape'"
                                                    [img]="item.image_landscape" [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'circular'"
                                                    [img]="item.image_square || item.image_landscape"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'stretched-landscape'"
                                                    [img]="item.image_stretched_landscape || item.image_landscape"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'square'"
                                                    [img]="item.image_square || item.image_landscape"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'enlarged-landscape'"
                                                    [img]="item.image_landscape" [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'enlarged-square'"
                                                    [img]="item.image_square || item.image_landscape"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'enlarged-circle'"
                                                    [img]="item.image_square || item.image_landscape"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>
                                            </div>
                                            <div class="new_assets_hover category_hover" >
                                                <div class="hover_video">
                                                    <a href="javascript:;" class="hover-click">
                                                    <img class="asset_hover_img" [src]="item.image_landscape">
                                                    </a>
                                                </div>
                                                <div class="new_assets_hover_info">
                                                    <div class="assets_info_label">
                                                        <div class="transparent_img_text">
                                                            <span>{{item.category_name}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>

                            <!-- Blank Slides to maintain owl-item width -->
                            <ng-container *ngIf="owlOption.items > data.sub_categories.length">
                                <ng-container *ngFor="let i of counter(blankSliderCount)">
                                    <ng-template carouselSlide>
                                        <p style="cursor: auto;width: 100%;height: 100%;"></p>
                                    </ng-template>
                                </ng-container>
                            </ng-container>
                            
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>




<a data-toggle="modal" data-target="#addToWatchList" #addToWatchListSuccess></a>

<div class="modal auth" id="addToWatchList">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header" style="visibility: hidden;">
                <button type="button" id="closeAddToWatchList" class="close" data-dismiss="modal"
                    #removeToWatchListSuccessModal>&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="success-add">
                    <img src="../../../assets/img/success.png">
                    <p id="itemAddSuccessMsg" #itemAddedIntoWach></p>

                </div>
            </div>
        </div>
    </div>
</div>







<!-- JW Player  -->
<ng-template #jwplaydom let-modal>
    <app-videojs-player [streamInfo]="streamInfo"></app-videojs-player>
</ng-template>

<!-- Cancel subscription -->
<ng-template #content let-modal>
    <app-login></app-login>
</ng-template>