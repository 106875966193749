import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { CommonService } from "./services/common.service";
import {
  ApiService,
  AuthGuard,
  JwtService,
  DeviceService,
  NavigationService
} from './services';

// import { AssetThumbnailComponent } from './components/asset-thumbnail/asset-thumbnail.component';
// import { AssetRowComponent } from './components/asset-row/asset-row.component';
//import { ImageManipulationComponent } from './components/image-manipulation/image-manipulation.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    ApiService,
    CommonService,
    AuthGuard,
    JwtService,
    DeviceService,
    NavigationService
  ],
  // declarations: [AssetThumbnailComponent],
  // declarations: [AssetRowComponent],
 // declarations: [ImageManipulationComponent]
})
export class CoreModule { }
