import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { v4 as uuidv4 } from 'uuid';
import { ActivatedRoute, Router } from '@angular/router';
//import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { LocalStorageService } from 'src/app/core/services/local-stroage.service';
// import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { formatDate } from "@angular/common";
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { CommonService } from "src/app/core/services/common.service";
import { NgOtpInputComponent } from "ng-otp-input";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnimationOptions } from "ngx-lottie";
import { successOptions, errorOptions } from 'src/app/shared/lottie-config';
import * as moment from 'moment-timezone';
@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.css']
})
export class LoginRegisterComponent implements OnInit {
  loginPassHide: boolean = true;
  loginRegisterForm: FormGroup;
  loginWithPasswordForm: FormGroup;
  registerForm: FormGroup;
  otpForm: FormGroup;
  isSubmit: boolean = false;
  isSubmit1: boolean = false;
  isLoginWithPassSubmit: boolean = false;
  isOtpSubmit: boolean = false;
  isOtpField: boolean = false;
  isValidField: boolean = false;
  isValidField1: boolean = false;
  isMobile: boolean;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  @ViewChild('closebutton') closebutton: ElementRef;
  isValidFieldWithReg: any;
  isValidFieldWithReg1: any;
  otp: any;
  isUserExist: boolean;
  userN: any;
  api_error: any;
  api_msg: any;
  timeLeft: number = 60;
  interval;
  deviceInfo = null;
  device_token: any
  returnUrl: any;
  otp_msg: any;
  additionalFieldsArray: any[];
  authSuccess: boolean;
  showForgotPassword: boolean;


  timezone: any;
  locale: any;
  timezoneForGA: any;
  timeZoneName: any;
  countryDialCode: any;
  public afterLoagindata: any;
  public landingPage: any;
  public countryList: any;
  language: any;
  maxDate : any;
  successOptions: AnimationOptions = successOptions;
  errorOptions: AnimationOptions = errorOptions;
  isOtpInvalid: boolean;
  sendOtpSuccess: boolean;
  resendOtpSuccess: boolean;
  selectedCountry: any;
  deviceId: any;
  osVersion: string;
  emailRegex: string = "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  registrationType: string;
  @ViewChild('usernameInput') usernameInput: ElementRef;
  isOtpVerifying: boolean;

  constructor(
    private fb: FormBuilder,
    private user: UserService, 
    private deviceService: DeviceDetectorService, 
    private router: Router,
    public localStorageService: LocalStorageService, 
    private route: ActivatedRoute, 
    private googleEvent: EventTrackingService,
    public commonService: CommonService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
  ) {
    if (localStorageService.get('session')) {
      window.history.back()
    }
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Kolkata' ? 'Asia/Calcutta' : Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.timezoneForGA = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.locale = Intl.DateTimeFormat().resolvedOptions().locale;
    this.timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZoneName;
  }

  ngOnInit(): void {
    this.getBuildSummary();
    this.deviceId = localStorage.getItem('deviceId');
    this.maxDate = new Date();
    this.language = this.commonService.languageChange();
    //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';
    const deviceType = this.deviceService.isDesktop() ? 'desktop' : (this.deviceService.isTablet() ? 'tablet' : 'mobile');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.device_token = `web-${this.deviceInfo.deviceType || deviceType}-${this.deviceInfo.os_version}`;
    this.osVersion = this.deviceInfo.os_version;
    this.countryDialCode = "+91";


    let pwd_patren = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%$&^*]).{8,}';
    let emailRegex = "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
    this.registerForm = this.fb.group({
      'type': [''],
      //'age': ['22', [Validators.required]],
      //'gender': ['', [Validators.required]],
      'country_dial_code': [this.countryDialCode],
      'email': [this.userN, [Validators.required, Validators.email, Validators.pattern(emailRegex)]],
      'mobile': [this.userN, [Validators.required]],
      'password': ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
      'device_id': [this.deviceId],
      'device_token': [this.device_token],
      'os_version': [this.osVersion]
    });


    this.loginRegisterForm = this.fb.group({
      'username': ['', [Validators.required]],
      'country_dial_code': ['']
    });


    this.loginWithPasswordForm = this.fb.group({
      'username': ['', [Validators.required]],
      'password': ['', [Validators.required]],
      'country_dial_code': [this.countryDialCode],
      'device_id': [this.deviceId],
    });

    this.otpForm = this.fb.group({
      'otp': ['', [Validators.required, Validators.minLength(6)]],
      'username': [''],
      'device_id': [this.deviceId],
    });

    this.loginRegisterForm.get('country_dial_code').setValue(this.countryDialCode);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.usernameInput.nativeElement.focus();
    }, 0); 
  }
  
  ngOnDestroy(): void {
    this.removeRedirectParams();
  }

  removeRedirectParams() {
    const queryParams = { ...this.route.snapshot.queryParams };
    const paramsToRemove = ['redirect_to_detail', 'name', 'code', 'type', 'redirect_to'];
    paramsToRemove.forEach(param => delete queryParams[param]);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      // queryParamsHandling: 'merge', // Keeps the other query params intact
    });
  }
  

  get loginRegisterControls() {
    return this.loginRegisterForm.controls;
  }
  get passwordOtpControls() {
    return this.loginWithPasswordForm.controls;
  }

  get registerFormControls() {
    return this.registerForm.controls;
  }
  get otpFormControls() {
    return this.otpForm.controls;
  }

  getBuildSummary() {
    this.user.getBuildSummary().subscribe(buildRes => {
      this.registrationType = buildRes.result.registration_prefrence.registration_type.toLowerCase();
      const profileCreationLimit = buildRes.result?.registration_prefrence?.profile_creation_limit || 3;
      localStorage.setItem('profileCreationLimit', profileCreationLimit);
      if (this.registrationType === 'email') {
        this.isMobile = false;
        this.loginRegisterForm.get("username").clearValidators();
        this.registerForm.addControl('email', new FormControl('', [Validators.pattern(this.emailRegex)]))
        this.loginRegisterForm.get('username').addValidators([Validators.pattern(this.emailRegex)]);
        this.loginRegisterForm.get('username').updateValueAndValidity();
        this.registerForm.removeControl('mobile');
      } else {
        this.getCountryList();
      }
    }, (error) => {
      console.error('error fetching build summary: ', error);
    });
  }

  validateUserName(event: Event) {
    this.api_error = '';
    if (this.registrationType !== 'both') {
      return;
    }
    var checkval = this.loginRegisterForm.value.username;
    let emailRegex = "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";

    if (checkval.match(/^-?\d+$/)) {
      this.isMobile = true;
      this.loginRegisterForm.get("username").clearValidators();
      this.registerForm.addControl('mobile', new FormControl('', [Validators.minLength(this.selectedCountry.min_number_limit), Validators.maxLength(this.selectedCountry.max_number_limit)]));
      this.loginRegisterForm.get('username').addValidators([Validators.minLength(this.selectedCountry.min_number_limit), Validators.maxLength(this.selectedCountry.max_number_limit)]);
      this.loginRegisterForm.get('username').updateValueAndValidity();
      this.registerForm.removeControl('email');

    } else {
      this.isMobile = false;
      this.loginRegisterForm.get("username").clearValidators();
      this.registerForm.addControl('email', new FormControl('', [Validators.pattern(emailRegex)]))
      this.loginRegisterForm.get('username').addValidators([Validators.pattern(emailRegex)]);
      this.loginRegisterForm.get('username').updateValueAndValidity();
      this.registerForm.removeControl('mobile');

    }
  }



  getCountryList() {
    let url = 'country';
      this.user.getCountryList(url).subscribe(data => {
        this.countryList = data.result;
        this.selectedCountry = this.countryList.filter(country => country.code === 'IN')[0];
        if (this.registrationType === 'mobile') {
        this.isMobile = true;
        this.loginRegisterForm.get("username").clearValidators();
        this.registerForm.addControl('mobile', new FormControl('', [Validators.minLength(this.selectedCountry.min_number_limit), Validators.maxLength(this.selectedCountry.max_number_limit)]));
        this.loginRegisterForm.get('username').addValidators([Validators.minLength(this.selectedCountry.min_number_limit), Validators.maxLength(this.selectedCountry.max_number_limit)]);
        this.loginRegisterForm.get('username').updateValueAndValidity();
        this.registerForm.removeControl('email');
      }
      }, (error) => {
        console.log('error', error);
      })


  }

  getAdditionalFieldList() {
    this.user.getAdditionalFieldList('registration/field').subscribe(data => {
      if(data.response_code == '200' && data.result.length > 0) {
        this.additionalFieldsArray = data.result;
        // this.registerForm.addControl('dob', new FormControl('', [Validators.required]));
        // this.registerForm.addControl('gender', new FormControl('', [Validators.required]));
        console.log('Additional Fields fetched: ', this.additionalFieldsArray);
        this.additionalFieldsArray.forEach((item:any, index:any)=>{
          if(item.is_required==1){
             if (item.type == 'input_calender') {
            this.registerForm.addControl(item.slug, new FormControl('', [Validators.required]))
             }
            //  else if(item.type == 'checkbox') {
            //   this.registerForm.addControl(item.slug, new FormArray([], [Validators.required]))
            //  } 
             else{
            this.registerForm.addControl(item.slug, new FormControl('',[Validators.required]))
             }
          }else{
            if (item.type == 'input_calender') {
              this.registerForm.addControl(item.slug, new FormControl(''))
               } 
              //  else if(item.type == 'checkbox') {
              //   this.registerForm.addControl(item.slug, new FormArray([]))
              //  } 
               else{
              this.registerForm.addControl(item.slug, new FormControl(''))
               }
          }
        });
        console.log('Register Form after additional fields: ', this.registerForm);
      } 
    }, (error) => {
      console.error('error fetching getAdditionalFieldList :', error);
    })
  }


  setCountryDialCode(country) {
    this.api_error = '';
    this.countryDialCode = country.dial_code;
    this.selectedCountry = country;
    this.loginRegisterForm.get("username").clearValidators();
    this.loginRegisterForm.get('username').addValidators([Validators.minLength(this.selectedCountry.min_number_limit), Validators.maxLength(this.selectedCountry.max_number_limit)]);
    this.loginRegisterForm.get('username').updateValueAndValidity();
    this.loginWithPasswordForm.get('country_dial_code').setValue(this.countryDialCode);
    this.registerForm.get('country_dial_code').setValue(this.countryDialCode);
  }

  setAuth(userInfo) {
    localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    localStorage.setItem('sessionId', userInfo.result.oauth_token);
    localStorage.setItem('debug', 'false');
    localStorage.setItem('parentControl', userInfo.result.parental_control);
    localStorage.setItem('profile_id', '');
    let type = userInfo.result.user.type;
    let username = userInfo.result.user.username
    if (this.isUserExist) {
      this.googleEvent.eventEmitter('Login', 'Login', username + '-' + this.locale + ' - ' + this.timezoneForGA, 0, username, username + '-' + this.locale + ' - ' + this.timezoneForGA);
    } else {
      this.googleEvent.eventEmitter('sign_up', 'sign_up', this.userN + ' is successfully registered.', 0, this.userN, this.userN);
    }
    let params = {
      device_id: this.deviceId,
      device_token: this.device_token,
      device_model: `${this.deviceInfo.browser}-${this.deviceInfo.browser_version}`,
      os_version: this.osVersion,
    }
    this.user.checkDevice('/device', params).subscribe(res => {
      console.log(res);
    })
  }

  loginRegister() {

    // console.log(this.loginRegisterForm.controls);
    this.isSubmit = true;
    this.api_error = '';
    this.api_msg = '';
    if (this.loginRegisterForm.invalid || this.loginRegisterForm.controls.username.value=="") {
      this.isValidField = false;
      return;
    } 
    this.countryDialCode = this.isMobile ? this.loginRegisterForm.controls.country_dial_code.value : "";
    let params = this.loginRegisterForm.value
    // console.log(params);
    this.userN = this.loginRegisterForm.controls.username.value;
    
    this.isMobile ? this.registerForm.patchValue({ mobile: this.userN })
      : this.registerForm.patchValue({ email: this.userN });
    this.loginWithPasswordForm.patchValue({ username: this.userN });
    // console.log(this.loginRegisterForm.controls);

    if (!this.isMobile) {
      delete params['country_dial_code'];
    } 
    let url = 'checkUser';
    this.user.checkUser(url, params,).subscribe(data => {
      this.isValidField = true;
      if(data.response_code=='200' && data.result.user_exist=='ACCOUNT_EXISTS'){
        this.isUserExist = true;
        setTimeout(() => {
          const passwordField = document.querySelector('#login-password') as HTMLInputElement;
          if (passwordField) {
            passwordField.focus();
          }
        }, 0);
      }
      // User not registered or Partially Registered
      if((data.response_code=='200' || data.response_code=='202') && (data.result.user_exist=='USER_NOT_EXISTS' || data.result.user_exist=='ACCOUNT_NOT_VERIFIED')){
        // Prevent mobile registeration for countries with only email registeration
        if ((this.registrationType === 'both' || this.registrationType === 'mobile') && this.selectedCountry.registration_type === 'email') {
          this.isValidField = false;
          this.api_error = 'We do not support registration via mobile number in your geography, request you to register via email.';
          return;
        }
        this.getAdditionalFieldList();
        this.isUserExist = false;
        this.registerFormControls?.mobile?.addValidators([Validators.minLength(this.selectedCountry.min_number_limit), Validators.maxLength(this.selectedCountry.max_number_limit)]);
        this.googleEvent.eventEmitter('sign_up', 'sign_up', this.userN + ' registration pending.', 0, this.userN, this.userN + ' registration pending.');
      }
    }, (error) => {
      this.isValidField = true;
      console.error('error', error);
    });


  }



  register() {
    this.isSubmit1 = true;
    if (this.registerForm.invalid) {
      console.error('form invalid');
      console.log(this.registerForm);
      return;
    }
    // else {
      if (this.isMobile) {
        this.registerForm.controls.type.setValue("mobile");
        this.registerForm.patchValue({ country_dial_code: this.countryDialCode })
        this.otpForm.patchValue({ username: this.registerForm.value.mobile })
      } else {
        this.registerForm.controls.type.setValue("email");
        this.otpForm.patchValue({ username: this.registerForm.value.email });
        delete this.registerForm.controls.country_dial_code;
        delete this.registerForm.value.country_dial_code;
      }

      let additional_fields = {};
      this.additionalFieldsArray?.forEach(field => {
        additional_fields[field.slug] = this.registerForm.value[field.slug];
      });
      this.additionalFieldsArray?.forEach(item => {
        if(item.type === 'input_calender') {
          const newValue = moment(additional_fields[item.slug]).format('DD/MM/YYYY');
          additional_fields[item.slug] = newValue;
        }
      });
      let params = {
        ...this.registerForm.value,
        additional_fields
      };
      
      this.additionalFieldsArray?.forEach(field => {
        delete params[field.slug];
      });
      
      console.log(params);

      let url = 'register';
      this.user.register(url, params).subscribe(data => {
        this.isOtpField = true;
        this.api_msg = data.message;
        if(data.response_code === 200 || data.response_code === 201) {
          this.sendOtpSuccess = true;
          this.otp_msg = data.message;
          this.startTimer();
        }
      }, (error) => {
        this.api_error = error && error.message ? error.message : 'Something went wrong';
        this.googleEvent.eventEmitter('sign_up', 'sign_up', this.userN + ' registration failed.', 0, this.userN, this.userN);
        console.error('error registering: ', error);
      });
    // }

  }

  // otp expiry timer
  startTimer() {
    this.timeLeft = 60;
    this.interval = setInterval(() => {
      if (this.timeLeft == 0) {
        clearInterval(this.interval);
      }
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000)
  }

  loginWithPassword() {
    this.api_error = '';
    this.api_msg = '';
    this.isLoginWithPassSubmit = true;
    if (this.loginWithPasswordForm.invalid) {
      return;
    }
    // else {
      this.startTimer();
      let params = this.loginWithPasswordForm.value;
      if (!this.isMobile) {
        delete params['country_dial_code'];
      }

      let url = 'login';
      this.user.login(url, params).subscribe(res => {
        if(res.response_code=='200'){
        this.api_msg = res.message;
        this.authSuccess = true;
        this.setAuth(res);
        setTimeout(() => {
          this.isOtpSubmit = false;
          this.closebutton.nativeElement.click();
          // this.getPlaylist();
          // window.location.href = '/manage-profile';
          const queryParams = { ...this.route.snapshot.queryParams };
          delete queryParams['search'];

          this.router.navigate(['/manage-profile'], {
            queryParams: queryParams,
          });
        }, 2500);
      } else{
        this.api_error = res.message;
      }

      }, (error) => {
        this.api_error = error.message;
        this.isLoginWithPassSubmit = false;
      });
    // }

  }


  verifyOtp() {
    if (this.isOtpVerifying) {
      return
    }
    this.isOtpVerifying = true;
    this.api_error = '';
    this.api_msg = '';
    this.isOtpSubmit = true;
    this.isOtpInvalid = this.otpFormControls.otp.invalid;
    if (this.otpForm.invalid) {
      return;
    } 
    let params = this.otpForm.value;
    params.otp_type = this.isUserExist ? 'login_with_otp' : 'account_verification';
    let url = this.isUserExist ? 'verify/otp' : 'verify/otp';
    this.user.verifyOtp(url, params).subscribe(data => {
      this.isOtpVerifying = false;
      this.api_msg = data.message;
      if (data.response_code=='200') {
        this.isOtpField = false;
        this.isValidField = false;
        this.authSuccess = true;
        this.setAuth(data);
        setTimeout(() => {
          this.closebutton.nativeElement.click();
          // window.location.href = '/manage-profile';
          this.router.navigate(['/manage-profile'], {
            queryParamsHandling: 'preserve',
          });
        }, 2500);
      } else {
        this.api_error = data.message;
      }
        
      }, (error) => {
        this.isOtpVerifying = false;
        this.api_error = error.message;
      });

  }

  resendOtp() {
    this.api_error = '';
    this.api_msg = '';
    this.isOtpInvalid = false;
    this.resendOtpSuccess = false;
    let otp_type = this.isUserExist ? 'login_with_otp' : 'account_verification';
    let params = {
      username: this.otpForm.value.username,
      otp_type
    }
    if (this.isMobile) {
      params['country_dial_code'] = this.countryDialCode;
    }
    this.startTimer();
    let url = 'send/otp';
    this.user.resendOtp(url, params).subscribe(data => {
      if(data.response_code === 200 || data.response_code === 201) {
        this.resendOtpSuccess = true;
        this.api_msg = data.message;
        this.otp_msg = data.message;
      }
    }, (error) => {
      this.api_error = error.message;
    });
  }

  closeModal() {
    // this.router.navigate([], {
    //   queryParams: {}
    // });
    this.removeRedirectParams();
    try {
      document.querySelector("ngb-modal-window").classList.remove("show");
      document.querySelector("ngb-modal-backdrop").classList.remove("show");
      document.getElementById('nexgTV-app').click();
      document.querySelectorAll(".modal-backdrop").forEach(el => el.remove())
      document.querySelectorAll(".d-block").forEach(el => el.remove())
      document.body.style.overflow = "scroll";
      // this.clearPresistFormData();

    } catch (e) {

    }
  }


  editUsername() {
    // this.loginRegisterForm.controls['username'].setValue('');
    this.loginWithPasswordForm.controls['username'].setValue('');
    this.loginWithPasswordForm.controls['password'].setValue('');

    this.registerForm.reset();
    this.registerFormControls.device_id.setValue(this.deviceId);
    this.registerFormControls.country_dial_code.setValue(this.countryDialCode);
    this.registerFormControls.device_token.setValue(this.device_token);
    this.registerFormControls.os_version.setValue(this.osVersion);

    // this.loginRegisterForm.controls['username'].setValue('');
    this.isValidField = false;
    this.isOtpField = false;
    // this.isMobile = false;
    this.isSubmit = false;
    this.isSubmit1 = false;
    this.isLoginWithPassSubmit = false;
    this.resendOtpSuccess = false;
    this.isOtpSubmit = false;
    this.api_error = null;
  }

  openForgotPassword(content) {
    this.showForgotPassword = true;
    // this.closebutton.nativeElement.click();
    // this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: true, windowClass: 'tellu' }).result.then((result) => {
    // }, (reason) => {
    // });
  }


  hideRPass() {
    if (this.loginPassHide == true) {
      this.loginPassHide = false;
    } else {
      this.loginPassHide = true;
    }
  }

  showOtpFiled() {
    this.api_error = '';
    this.api_msg = '';
    clearInterval(this.interval);
    let username = this.loginWithPasswordForm.value.username;
    if (this.isOtpField == false) {
      this.otpForm.patchValue({ username });
      this.isSubmit = false;
      this.isOtpField = true;
      let otp_type = this.isUserExist ? 'login_with_otp' : 'account_verification';
      let params = {
        username,
        otp_type
      };
      if (this.isMobile) {
        params['country_dial_code'] = this.countryDialCode;
      }
      this.otp_msg = 'An OTP has been sent';
      this.startTimer();
      let url = 'send/otp';
      this.user.loginWithOtp(url, params).subscribe(data => {
        //  this.api_msg = data.message;
        // this.otp_msg = data.message;
        if(data.response_code == 200) {
          this.sendOtpSuccess = true;
          this.otp_msg = data.message;
        }
      }, (error) => {
        this.api_error = error.message;
      });

    } else {
      this.isOtpField = false;
      this.isOtpSubmit = false;
      this.isOtpInvalid = false;
    }

  }

  onOtpChange(otp) {
    this.otp = otp;
    this.otpForm.get("otp").setValue(this.otp);
  }

  onCheckChange(event, slug) {
    // Get the original comma-separated string
    let originalString = this.registerForm.get(slug).value || '';

    // Value to be added or removed
    const value = event.target.value;

    // Split the string into an array
    let array = originalString.split(",");

    // Check if the value is already in the array
    const index = array.indexOf(value);

    /* Selected */
    if (event.target.checked) {
        // Add the value if not already present
        if (index === -1) {
            array.push(value);
        }
    } 
    /* unselected */
    else {
        // Remove the value if it's present
        if (index !== -1) {
            array.splice(index, 1);
        }
    }

    // Join the array back into a string
    let newString = array.join(",");

    // Update the form control with the new string value
    this.registerForm.get(slug).setValue(newString);
}



  onDateChange() {
    console.log('age', this.registerForm.value.age);
  }


  getPlaylist() {
    // let tempGetPlay = {
    //   catlogue: CatlougeTypeConstants.catlogue,
    //   plateform: "web",
    //   action: "getplaylist",
    // }

    // let urlEncodedPlayListParams = this.userService.convertoJSONToUrlEncoded(tempGetPlay);

    // this.playListService.getPlaylist(urlEncodedPlayListParams).subscribe(data => {
    //   if (data && data.result) {
    //     localStorage.setItem('playListId', data?.result[0]?.playlistid);
    //     this.userService.loginStatus(true);
    //     this.closebutton.nativeElement.click();
    //     this.closeModal();
    //     this.responseErr = ''
    //     console.log(this.currentRoute);
        if (Object.keys(this.commonService.getAssetsData()).length > 0) {
          this.afterLoagindata = this.commonService.getAssetsData();
          this.landingPage = this.afterLoagindata.landing_page;
          if (this.landingPage) {
            switch (this.landingPage) {
              case '__url':
                this.landingUrl();
                break;
              case '__app_page':
                this.landingAppPage();
                break;
              case '__sub_category':
                this.landingSubCategory();
                break;
              case '__asset':
                this.landingAsset();
                break;
              case '__webviewurl':
                this.landingVotingPage();
                break;
              default: this.router.navigateByUrl('/');
                break;

            }
          } else {
            this.commonService.goToDetail(this.commonService.getAssetsData(), '', 1);
          }
          // this.commonService.goToDetail(this.commonService.getAssetsData(), '', 1);
        } else {
          window.location.href="/";
        }
      }

  //   });
  // }



  landingUrl() {
    let url = this.afterLoagindata.url;
    window.open(url, '_blank').focus();
  }
  landingAppPage() {
    let appPage = this.afterLoagindata.app_page;

    switch (appPage) {
      case 'about':
        this.router.navigateByUrl('/aboutus');
        break;
      case 'faq':
        this.router.navigateByUrl('/contactus');
        break;
      case 'help':
        this.router.navigateByUrl('/contactus');
        break;
      case 'my-profile':
        this.router.navigateByUrl('/myaccount');
        break;
      case 'theme-setting':
        this.router.navigateByUrl('/');
        break;
      case 'subscription':
        this.router.navigateByUrl('/subscribe/choseplan');
        break;
      case 'activation-code':
        this.router.navigateByUrl('/subscribe/choseplan');
        break;
      case 'watchlist':
        this.router.navigateByUrl('/playlist');
        break;

      default: this.router.navigateByUrl('/');
        break;

    }
  }
  landingSubCategory() {
    if (this.afterLoagindata.sub_category_id != '' || this.afterLoagindata.sub_category_id != undefined) {
      // let sub_category_id = this.data.sub_category_id;
      // let viewalltabDataParams = {
      //   "sub_category_id" :sub_category_id,
      //   "type" : 'home'
      // }

      let viewAlldParams = {
        categoryId: this.afterLoagindata.sub_category_id,
        // categoryType: this.data.category_type
      }

      viewAlldParams['type'] = this.afterLoagindata.tab_slug;
      // viewAlldParams['sub_category_id'] = this.data.sub_category_id;
      // viewAlldParams['banner'] = "campaign_banner";
      localStorage.setItem('banner', 'campaign_banner');
      // this.router.navigate(['viewall'],{queryParams:viewalltabDataParams});
      this.router.navigate(['/subcategory'], { queryParams: viewAlldParams })

    } else {
      let categoryId = this.afterLoagindata.category_id;
      let viewalltabDataParams = {
        banner: "campaign_banner",
        "categoryId": categoryId,
        "type": 'home'
      }

      this.router.navigate(['/subcategory'], { queryParams: viewalltabDataParams })

      // this.router.navigate(['viewall'],{queryParams:viewalltabDataParams});
    }
  }
  landingAsset() {
    let assetData = this.afterLoagindata.asset;
    this.router.navigate(['detail/' + this.afterLoagindata.asset.type + '/' + this.afterLoagindata.asset.code + '/' + this.afterLoagindata.asset.type])
    // code: "TSH338" type: "tvshow"
    // code: TSH336
    // catlogue: 5f48bc1eb9124f41a16c5712
    // platform: web
    // type: tvshow
  }

  landingVotingPage() {
    let url = this.afterLoagindata.webviewurl;
    const splitArr = url.split("cid=");
    let eventid =splitArr[1];
    let sessId = localStorage.getItem('sessionId');
    window.open('content/'+eventid+'/'+ sessId, '_blank');
  }

}