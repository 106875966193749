import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SlideInOutAnimation } from './../../../shared/animation';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavigationService, UserService } from 'src/app/core';
import { LocalStorageService } from 'src/app/core/services/local-stroage.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/core/services/common.service';
import { customOptionsForAvatarSelector } from 'src/app/shared/owl-slider-config';
import { DOCUMENT } from '@angular/common';
import { successOptions } from 'src/app/shared/lottie-config';
import { AnimationOptions } from 'ngx-lottie';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
declare var $: any;
@Component({
  selector: 'app-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.css'],
  animations: [SlideInOutAnimation],
  encapsulation: ViewEncapsulation.Emulated
})
export class AddProfileComponent implements OnInit {
  is_kids:boolean;
  url:any;
  addForm: FormGroup;
  message:any;
  panelOpenState = false;
  animationState = 'in';
  viewing_restriction_arr = ['U','U/A 7+','U/A 13+','U/A 16+','A'];
  is_submit:boolean;
  session_data:any;
  basePath: string;
  profileAvatars: any[];
  language: any;
  @ViewChild('owlCarousel') owlCarousel: any;
  selectedAvatar: string;
  customOptions = customOptionsForAvatarSelector;
  isChooseAvatar: boolean = true;
  api_msg: string;
  successOptions: AnimationOptions = successOptions;
  addSuccess: boolean;

  constructor(
    private fb: FormBuilder,
    public userService: UserService,
    public localService:LocalStorageService,
    public router:Router,
    public commonService: CommonService,
    private navigationService: NavigationService,
    @Inject(DOCUMENT) private _document,
    private googleEvent: EventTrackingService,
  ) {
    this.session_data = localService.get('userInfo')
   }

  ngOnInit(): void {
    this._document.body.classList.add('profile-bg');
    this.language = this.commonService.languageChange();
    this.basePath = environment.api_url.replace('api/v1/', '');
    this.addForm = this.fb.group({
      //'_userId':[this.session_data.result._id],
      'profile_name':['',[Validators.required, this.noWhitespaceValidator]],
      'profile_image': ['', [Validators.required]],
      'is_child': [false],
      // 'viewing_restriction': ['',[Validators.required]],
    });



    this.getProfileAvatars();
  }

  ngOnDestroy() {
    // remove the class form body tag
    this._document.body.classList.remove('profile-bg');
  }

  // Custom validator to check for whitespace
  noWhitespaceValidator(control: any) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  get f() {
    return this.addForm.controls;
  }

  isKids() {
    this.is_kids = !this.is_kids;
    if(this.animationState === 'out') {
      this.animationState ='in';
      // this.addForm.get('viewing_restriction').setValidators([Validators.required]);
      // this.addForm.get('viewing_restriction').updateValueAndValidity();
    } else {
      this.animationState = 'out';
      this.addForm.get('is_child').setValue(true);
      // this.addForm.get('viewing_restriction').reset();
      // this.addForm.get('viewing_restriction').clearValidators();
      // this.addForm.get('viewing_restriction').updateValueAndValidity();
    }
   
  }

  toggleChooseAvatar() {
    this.isChooseAvatar = !this.isChooseAvatar;
  }

  setSelectedAvatar() {
    if(this.owlCarousel) {
      const centerSlide = this.owlCarousel.slidesData.find(element => element.isCentered === true);
      let centerSlideId = centerSlide.id.replace('cloned-', '');
      this.selectedAvatar = centerSlideId;
      this.addForm.controls['profile_image'].setValue(centerSlideId);
      this.toggleChooseAvatar();
    }
  }

  getProfileAvatars() {
    let url = 'profile/avatar';
    this.userService.getProfileAvatar(url).subscribe(data => {
      this.profileAvatars = data.result;
      this.addForm.get('profile_image').setValue(this.profileAvatars[0].avatar_image); // Set the profile image to default
    })
  }

  addSubmit() {
    this.is_submit = true;
    if(this.addForm.valid) {
      this.setSelectedAvatar();
      let params = this.addForm.value;
      // delete params['viewing_restriction'];
      let url = 'profile/add';
      this.userService.addPrfile(url,params).subscribe(data => {
        this.addSuccess = true;
          this.api_msg = data.message;
          const username = JSON.parse(localStorage.getItem('userInfo'))?.result?.user?.username;
          this.googleEvent.eventEmitter('Add Profile', 'Add Profile', 'Add Profile', 0, username, username + ' - ' + 'Add Profile');
          setTimeout(() => {
            this.router.navigate(['manage-profile']);
            // this.navigationService.back();
          }, 2500);
       },(error)=>{
        console.error('errorrrrr',error); 
       });
    }
    
  }

  backButton(){
    this.router.navigate(['manage-profile']);
    // this.navigationService.back();
  }

}
