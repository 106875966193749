import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core';
import { CatlougeTypeConstants } from 'src/app/core/constants';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { AccountService } from '../../account/account.service';
declare var $: any;
declare var Razorpay: any;
@Component({
  selector: 'app-choose-plan-v2',
  templateUrl: './choose-plan-v2.component.html',
  styleUrls: ['./choose-plan-v2.component.css']
})
export class ChoosePlanV2Component implements OnInit {

  radioStatus: boolean;
  selectedRentPayment: boolean;
  groupPack: any = [];
  catalogue_settings: any = [];
  fristPack: any;
  grouplength: any;
  noPackFound: any;
  razorpayResponse: any;
  response: any;
  is_applied_coupon = false;

  coupon_list: any = [];
  coupon_discount: any = 0;
  coupon_code: any = 0;
  final_price: any = 0;
  pm_price:any;

  manual_coupon_code:any;
  selected_pack:any;

  constructor(private apiService: AccountService, private userService: UserService, private route: ActivatedRoute,
    public router: Router, public formBuilder: FormBuilder,
    private googleEvent: EventTrackingService, private cd: ChangeDetectorRef, private http: HttpClient) {

    this.addPromoForm = this.formBuilder.group({
      'promocode': ['']
    });
  }
  details_error: string = '';
  packs: any = [];
  rentedPacks: any = [];
  selectedChargeCode = '';
  selectedRentChargeCode = '';
  chargeCode: string = '';
  paymentArray: any = {};
  payButtonText: string = 'PAY NOW';
  searchSubscriber: any;
  userinfo: any;
  userUnique: string = 'NA';
  selected_payment_gateway: string = '';
  continuePressed: boolean = false;
  rentContinuePressed: boolean = false;
  addPromoForm: FormGroup;
  promocode_error: string = '';
  catlogue = CatlougeTypeConstants.catlogue;
  @ViewChild('add_promocodesuccess') add_promocodesuccess;
  @ViewChild('closePromoButton') closePromoButton;
  selectedIndex: number = 0;
  showModal = false;
  public isCollapsed = true;
   cardInstrument = {
    method: "card"
  };
  
   instrumentOfSomeBank = {
    method: "netbanking",
    banks: ["HDFC", "ICIC","Axis Bank"]
  };
  // instrumentOfSomeBank = {
  //   method: "upi",
  //   banks: ["HDFC", "ICIC","Axis Bank"]
  // };
  // Block creation
 myPayments = {
  name: "My Custom Block",
  instruments: ["gpay", "freecharge"]
};
  RAZORPAY_OPTIONS: any = {
    key: "rzp_test_WMHWnR6EYJOqTX",
    amount: "",
    name: "Digivive",
    order_id: "",
    description: "Digivive Services Pvt. Ltd.",
    image:
      "https://play-lh.googleusercontent.com/9Za229rv9vFZ7BjymSP-sApE5Ca0OolfwHUQf6MQjw-by-39Kg-6rklppB7cmd6LIMLB",
    prefill: {
      name: "test",
      email: "test@test.com",
      contact: "8787878783",
      method: "card"
    },
    modal: {
      backdropclose: true
    },
    theme: {
      color: "#610B5E"
    },
    config: {
      display: {
        blocks: {
          cards: { //name for HDFC block
            name: "Pay using HDFC Bank",
            instruments: [
              {
                method: "card",
                issuers: ["HDFC","ICIC"],
                networks: ["Visa", "MasterCard"],
                types: ["credit","debit"],
              },
              {
                method: "netbanking",
                banks: ["HDFC"]
              },
            ]
          },
          other: { //  name for other block
            name: "Other Payment modes",
            instruments: [
              {
                method: "card",
                issuers: ["ICIC"]
              },
              {
                method: 'netbanking',
              }
            ]
          },
          wallets: {
            name: 'Methods with Offers',
            instruments: [
              {
                method: 'wallet',
                wallets: ['PhonePe','freecharge','mobikwik','payzapp']
              }]
          },
          upis: {
            name: 'UPI Methods with Offers',
            instruments: [
              {
                method: 'upi',
                // flows: [ "qr"],
                apps: ['google_pay','bhim','paytm']
              },{
                method: 'upi',
              }]
          },
        },
        // hide: [
        //   {
        //   method: "qr"
        //   }
        // ],
        sequence: ["block.cards", "block.other","block.wallets","block.upis"],
        preferences: {
          show_default_blocks: false // Should Checkout show its default blocks?
        }
      }
    },
    // config: {
    //   display: {
    //     block: 
    //     {
    //       highlighted: this.myPayments,
    //       // code: {
    //       //   name: "The name of the block", // The title of the block
    //       //   instruments: ["gpay", "freecharge"] // The list of instruments
    //       // },
    
    //     //   anotherCode: {
    //     //     name: "Another block",
    //     //     instruments: [instrument]
    //     //   }
    //     banks: {
    //       name: "Pay using HDFC",
    //       instruments: [
    //         {
    //           method: "netbanking",
    //           banks: [
    //             "HDFC"
    //           ]
    //         }
    //       ]
    //     }
    //     },
    
    //     // hide:[this.cardInstrument, this.instrumentOfSomeBank],
    //     hide:[this.instrumentOfSomeBank],
    
    //     sequence: ["block.highlighted", "upi","card","wallet", "netbanking",], // The sequence in which blocks and methods should be shown
    
    //     preferences: {
    //       show_default_blocks: false // Should Checkout show its default blocks?
    //     }
    //   }
    // }
  
  };

  public proceed() {
    // console.log(454545);
    // this.sendPostRequest();
    // return;
    // debugger
    this.RAZORPAY_OPTIONS.amount = 100 + "00";
    this.RAZORPAY_OPTIONS.key = 'rzp_test_6qiS2gW3YxT3oq';
    // this.RAZORPAY_OPTIONS.order_id = 'fdsfdsdsf4332';

    // binding this object to both success and dismiss handler
    this.RAZORPAY_OPTIONS["handler"] = this.razorPaySuccessHandler.bind(this);

    // this.showPopup();

    let razorpay = new Razorpay(this.RAZORPAY_OPTIONS);


    razorpay.open();
  }

  public razorPaySuccessHandler(response: any) {
    console.log(response);
    this.razorpayResponse = `Razorpay Response`;
    this.showModal = true;
    this.cd.detectChanges();
    let element = document.getElementById('ButtonX') as HTMLElement;
    element.style.display = "block";
  }


  ngOnInit() {
    this.apiService
      .lazyLoadLibrary("https://checkout.razorpay.com/v1/checkout.js")
      .subscribe();

    // document.body.scrollTop = 0;

    if (localStorage.getItem('isAuthenticated') == undefined) {
      this.router.navigate(['/']);
    }
    this.userinfo = JSON.parse(localStorage.getItem('userInfo'));


    if (Object.keys(this.userinfo.result).length > 0) {
      if (this.userinfo.result.register_by == 'mobile') {
        this.userUnique = this.userinfo.result.mobile;
      } else {
        this.userUnique = this.userinfo.result.emailaddress;
      }
    }

    this.testSampleApi();
    // this.sampleApiReq();


  }

  testSampleApi() {

    this.apiService.sampleApi().subscribe((data: any) => {
      // console.log(data.groupPack.record);

      if (data.error_code == '200') {
      
      } else {
        this.details_error = data.error_string;
      }

    },
      error => {
        this.details_error = 'Something went wrong!! Please try again after some time.';
      },
      () => {
      }
    );

  }

  sendGetReq() {

    let get_url: string = ' https://api.razorpay.com/v1/methods?key_id=rzp_test_1DP5mmOlF5G5ag';
    const headers = new HttpHeaders()
      // .set('cache-control', 'no-cache')
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get(get_url, { headers })
      .subscribe(res => res);
  }

  sampleApiReq() {

    let get_url: string = 'https://stage-payments.digivive.com/test/charge';
    const headers = new HttpHeaders()
      // .set('cache-control', 'no-cache')
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get(get_url, { headers })
      .subscribe(res => res);
  }
  ngAfterContentInit() {


    this.chargeCode = '';

    if (localStorage.getItem('isAuthenticated') == undefined || localStorage.getItem('isAuthenticated') == 'false') {
      this.router.navigate(['/']);
      return;
    } else {
      this.getPlanDetail();
    }

  }
  getPlanDetail() {
    this.searchSubscriber = this.route.queryParams.subscribe(params => {
      this.chargeCode = params['code'] == undefined ? '' : params['code'];
    });

    let requestData = { catlogue: CatlougeTypeConstants.catlogue, code: this.chargeCode, platform: 'web' };
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(requestData);
    //this.apiService.postData('getpackv2', urlEncodedBodyData).subscribe((data: any) => {
    this.apiService.postData('getpacksbygroup', urlEncodedBodyData).subscribe((data: any) => {
      // console.log(data.groupPack.record);

      if (data.error_code == '200') {
        this.noPackFound = data.error_string;
        this.groupPack = data.result.groupPack;
        this.catalogue_settings = data.result.catalogue_settings;
        this.grouplength = this.groupPack.length;
        console.log('this.groupPack', this.groupPack);
        this.fristPack = this.groupPack[0].packs[0];
        console.log('this.fristPack', this.fristPack);
        // if (this.fristPack.length > 0) {
        this.selectedRentChargeCode = this.fristPack.PM_CHARGECODE;
        // this.onRentItemChange(this.fristPack.PM_CHARGECODE, this.fristPack.PM_PRICE, 0);
        // }

        // this.rentedPacks = data.groupPack.rented;
        // this.packs = data.groupPack.record;

        // console.log('this.rentedPacks', this.rentedPacks);
        // if (this.rentedPacks.length > 0) {
        //   this.onRentItemChange(this.rentedPacks[0].PM_CHARGECODE, this.rentedPacks[0].PM_PRICE, 0);
        // }

        // // this.packs = data.groupPack.record;
        // if (this.packs.length > 0) {
        //   this.onItemChange(this.packs[1].PM_CHARGECODE, this.packs[1].PM_PRICE);
        // }



        // if (data.groupPack.rented != undefined) {
        // if (data.groupPack.rented) {
        // this.rentedPacks = data.groupPack.rented;
        // console.log('this.rentedPacks', this.rentedPacks);
        // if(this.rentedPacks.length > 0){
        //   this.onRentItemChange(this.rentedPacks[0].PM_CHARGECODE, this.rentedPacks[0].PM_PRICE, 0);
        // }

        // }

        // }


      } else {
        this.details_error = data.error_string;
      }

    },
      error => {
        this.details_error = 'Something went wrong!! Please try again after some time.';
      },
      () => {
      }
    );
  }

  strToLower(str: string) {
    if (str)
      return str.toLowerCase().trim();
    else
      return str;
  }
  trimString(str: string) {
    if (str)
      return str.trim();
    else
      return str;
  }

  planName(planname: string) {
    let pack_name = '';
    switch (planname) {

      case "weekly_pack":
        pack_name = 'WEEKLY PLAN';
        break;
      case "monthly_pack":
        pack_name = 'MONTHLY PLAN';
        break;
      case "yearly_pack":
        pack_name = 'YEARLY PLAN';
        break;
      default:
        pack_name = planname;
        break;
    }

    return pack_name;

  }
  continueButtons(value, price: any, index) {
    this.paymentArray = {};
    $(".selectedRentPayment").prop("checked", false);
    // $("input[name=selectedRentPayment]").removeAttr('checked');
    $('.make_payment').addClass('active');
    // this.onRentItemChange(value, price, index);

    this.rentContinuePressed = true;
   

  }
  continueButton() {
    this.paymentArray = {};
    $('.selectedPayment').prop("checked", false);
    $('.make_payment').addClass('active');
    this.continuePressed = true;
    // window.scroll(0,document.getElementById('_subscription_amount').offsetHeight);
  }

  countObjectKey(obj) {
    //  console.log(obj);
    return Object.keys(obj).length;
  }

  // onItemChange(value, price: any) {
  //   console.log(" Value is : ", value);
  //   console.log(" price is : ", price);
  //   this.continuePressed = false;
  //   this.selectedChargeCode = value;
  //   this.paymentArray = {};


  //   let planCodeLabel = 'Buying from my account';
  //   if (this.chargeCode) {
  //     planCodeLabel = this.chargeCode;
  //   }

  //   this.googleEvent.eventEmitter('cart_selection', 'cart_selection', this.userUnique + "-Selected Plan-" + price * 1, 0, this.userUnique, this.userUnique + "-Selected Plan-" + price * 1);

  //   // this.googleEvent.eventEmitter('Selected Plan - ' + price * 1, "Cart_Selection", this.userUnique + '-' + planCodeLabel, price * 1);
  // }
  onRentItemChange(value, pack: any, index) {

    const price = pack.PM_PRICE;
    console.log('packjjjj',pack);
    
    console.log(" Value is : ", value);
    console.log(" price is : ", price);
    this.rentContinuePressed = false;
    this.selectedRentChargeCode = value;
    console.log('this.selectedRentChargeCode',this.selectedRentChargeCode);
    
    this.selectedIndex = index;
    this.paymentArray = {};

    this.final_price = this.pm_price = price;

    this.coupon_discount = 0;
    this.is_applied_coupon = false;


    let planCodeLabel = 'Buying from my account';
    if (this.chargeCode) {
      planCodeLabel = this.chargeCode;
    }
    this.googleEvent.eventEmitter('cart_selection', 'cart_selection', this.userUnique + "-Selected Plan-" + price * 1, 0, this.userUnique, this.userUnique + "-Selected Plan-" + price * 1);
    this.coupon_list = [];
    this.promocode_error = '';
    pack.PAYMENT_MODES.forEach(e => {
      this.getCouponList(e.PM_CHARGECODE);
    });

    // this.googleEvent.eventEmitter('Selected Plan - ' + price * 1, "Cart_Selection", this.userUnique + '-' + planCodeLabel, price * 1);
  }
  onPaymentSelected(price, detail, payment_gateway) {
    this.details_error = "";

    this.paymentArray = {
      'amount': price,
      'ActualAmount': price,
      'charge_code': this.chargeCode,
      'pack_code': detail.PM_CHARGECODE,
      'validity': detail.PM_VALIDITY,
      'catlogue': CatlougeTypeConstants.catlogue,
      'plateform': 'web',
      'coupon_code':this.coupon_code ? this.coupon_code : '',
      'final_price':this.final_price ? this.final_price : ''
    }

    let planCodeLabel = '';
    if (this.chargeCode) {
      planCodeLabel = this.chargeCode;
    }
    this.selected_payment_gateway = payment_gateway;

    this.googleEvent.eventEmitter('payment_gateway_selection', 'payment_gateway_selection', this.userUnique + "-" + payment_gateway + "-" + planCodeLabel + "-" + price * 1, 0, this.userUnique, this.userUnique + "-" + payment_gateway + "-" + planCodeLabel + "-" + price * 1);
    // this.googleEvent.eventEmitter(payment_gateway, "Payment Gateway_Selection", this.userUnique + '-' + planCodeLabel + '-Amount-' + price * 1, price * 1);
    console.log(this.paymentArray);
   
    // this.getCouponList(detail.PM_CHARGECODE);
  }


  placeOrder() {

    if (Object.keys(this.paymentArray).length == 0) {
      this.details_error = "Please select a payment option.";
      return false;
    }

    this.payButtonText = 'Redirecting...';

    let planCodeLabel = '';
    if (this.chargeCode) {
      planCodeLabel = this.chargeCode;
    }
    this.googleEvent.eventEmitter('payment_gateway_checkout', 'payment_gateway_checkout', this.userUnique + "-" + this.selected_payment_gateway + "-" + planCodeLabel + "-" + this.paymentArray.amount +  this.paymentArray.coupon_code, 0, this.userUnique, this.userUnique + "-" + this.selected_payment_gateway + "-" + planCodeLabel + "-" + this.paymentArray.amount);
    // this.googleEvent.eventEmitter(this.userUnique + ' - ' + this.selected_payment_gateway + ' -Amount- ' + this.paymentArray.amount, "Payment_Checkout", planCodeLabel, this.paymentArray.amount);

    const searchParams = new URLSearchParams();
    Object.keys(this.paymentArray).forEach(key => searchParams.append(key, this.paymentArray[key]));
    console.log(searchParams.toString());

    this.apiService.getData('purchaseUrl?' + searchParams.toString()).subscribe((data: any) => {

      if (data.error_code == '200') {
        console.log('redirect url',data.result);
        window.location = data.result;


      } else {
        this.details_error = data.error_string;
        this.payButtonText = 'PAY NOW';
      }

    },
      error => {
        this.details_error = 'Something went wrong!! Please try again after some time.';
        this.payButtonText = 'PAY NOW';
      },
      () => {
      }
    );

  }


  applypromoCode() {

    if (this.addPromoForm.invalid) {
      return false;
    }

    let appliedCode = this.addPromoForm.controls.promocode.value;

    // this.googleEvent.eventEmitter(appliedCode, 'Redeem', this.userUnique + ' - Plan Details');

    this.promocode_error = '';
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded({ 'couponcode': this.addPromoForm.controls.promocode.value, 'charge_code': '', 'catlogue': this.catlogue, 'platform': 'web' });
    this.apiService.postData('activationcode', urlEncodedBodyData)
      .subscribe((data: any) => {
        console.log(data);
        if (data.error_code == '200') {
          this.add_promocodesuccess.nativeElement.click(); // show success
          this.addPromoForm.reset();
        } else {
          this.promocode_error = data.error_string;
        }

      },
        error => {
          this.promocode_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
    this.googleEvent.eventEmitter('payment_gateway_checkout', 'payment_gateway_checkout', this.userUnique + "-" + this.selected_payment_gateway + "-" + appliedCode + "-" + this.paymentArray.amount, 0, this.userUnique, this.userUnique + "-" + this.selected_payment_gateway + "-" + appliedCode + "-" + this.paymentArray.amount);
  }
  goToMyacoount() {
    this.closePromoButton.nativeElement.click(); // close the name field modal
    this.router.navigate(['myaccount']);
  }



  getCouponList(pm_chargecode) {
    console.log('pm_chargecode', pm_chargecode);

    let requestData = { catlogue: CatlougeTypeConstants.catlogue, pack_code: pm_chargecode, platform: 'web' };
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(requestData);
    this.apiService.postData('couponverification/couponlist', urlEncodedBodyData)
      .subscribe((data: any) => {
        console.log(data);
        data.result.forEach(element => {
          this.coupon_list.push(element);
        });
       

      },
        error => {
          this.promocode_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }


  applyNow(coupon) {
    console.log('coupon', coupon);
    if (coupon.is_visible) {
      // this.coupon_discount = coupon.discount_percent;
     
      this.couponVerification(coupon.coupon_code);
      this.coupon_code = coupon.coupon_code;
    }
  }

  applyCouponManual(coupon_code) {
    console.log('coupon', coupon_code);
   
      this.couponVerification(coupon_code);
      this.coupon_code = coupon_code;
  }

  couponVerification(coupon_code) {
    this.promocode_error = '';
    console.log('selectedRentChargeCode', this.selectedRentChargeCode);

    let requestData = { catlogue: CatlougeTypeConstants.catlogue, pack_code: this.selectedRentChargeCode, coupon: coupon_code, platform: 'web' };
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(requestData);
    this.apiService.postData('couponverification', urlEncodedBodyData)
      .subscribe((data: any) => {
        console.log(data);
        if (data.error_code == '200') {
          this.add_promocodesuccess.nativeElement.click(); // show success
          this.final_price= data.result.final_price;
          this.coupon_discount =data.result.discount;
          this.is_applied_coupon = true;
          // this.addPromoForm.reset();
        } else {
          this.promocode_error = data.error_string;
        }
      
      },
        error => {
          this.promocode_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }
  removeAppliedCode() {
    this.coupon_discount = 0;
    this.is_applied_coupon = false;
    this.final_price = this.pm_price;
  }

}
