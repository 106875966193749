<div class="maincontentarea mt20">
   <div class="container">
      <div class="contact-form-align">
         <div class="row">
            <div class="col-sm-12">
               <div class="_contact_content">
                  <h2>Contact Us</h2>
                  <!-- <div class="_contact_map">
                   <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.847363202616!2d77.0780792150484!3d28.514239696175917!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18691f0ea3bf%3A0xbc987bd64c947b88!2snexGTv-Digivive%20Services%20Pvt.%20Ltd.%7C%20India&#39;s%20Leading%20OTT%20Platform!5e0!3m2!1sen!2sin!4v1610702432059!5m2!1sen!2sin" width="100%" height="300" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                 </div> -->
                  <div class="_contact_split">
                     <div class="info_text">
                        <h3>We'd love to hear from you!</h3>
                        <P>For any information or queries please find contact detail.</P>
                     </div>
                     <div class="_nexgtv_detail text-right">
                        <h3>Digivive Services Pvt Ltd</h3>
                        <ul>
                           <li>
                              <i class="fa fa-envelope" aria-hidden="true"></i>
                              <a href="mailto:customercare@digivive.com">customercare@digivive.com</a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="help-center-form contact-form"
            *ngIf="!api_msg">
            <form [formGroup]="helpCenterForm" (submit)="submit()">
                <div class="row">
                    
                    <div class="col-md-6">
                        <div class="form-group mb-0">
                            <label>Mobile No <span class="asterisk_sign">*</span></label>
                            <input type="text" class="form-control" placeholder="Enter Mobile no." formControlName="mobile" />
                            <div class="popup-error-height">
                                <app-error
                                *ngIf="isSubmit && helpCenterFormControls.mobile.invalid && (helpCenterFormControls.mobile.hasError('required') || helpCenterFormControls.mobile.value =='' ||
                                helpCenterFormControls.mobile.hasError('minlength') || helpCenterFormControls.mobile.hasError('maxlength') || helpCenterFormControls.mobile.errors?.pattern)"
                                [message]="language.pleaseEnterValidMobile"></app-error>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                     <div class="form-group mb-0">
                         <label>Email Addrerss <span class="asterisk_sign">*</span></label>
                         <input type="text" class="form-control" placeholder="Enter Email" formControlName="email" />
                         <div class="popup-error-height">
                             <app-error
                             *ngIf="isSubmit && (helpCenterFormControls.email.invalid && helpCenterFormControls.email.hasError('required') || helpCenterFormControls.email.value ==''
                             || helpCenterFormControls.email.errors?.pattern)"
                             message="Please enter a valid Email"></app-error>
                         </div>
                     </div>
                     
                 </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Subject<span class="asterisk_sign">*</span></label>
                            <input type="text" class="form-control" placeholder="Subject" formControlName="query_type" />
                                <div class="popup-error-height">
                                    <app-error
                                    *ngIf="isSubmit && helpCenterFormControls.query_type.invalid && helpCenterFormControls.query_type.hasError('required') || isSubmit && helpCenterFormControls.query_type.value ==''"
                                    message="Please select an Issue Type"></app-error>
                                </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Description <span class="asterisk_sign">*</span></label>
                            <textarea class="form-control" placeholder="Describe your issue in details" formControlName="query_description"></textarea>
                            <div class="popup-error-height">
                                <app-error
                                *ngIf="isSubmit && helpCenterFormControls.query_description.invalid && (helpCenterFormControls.query_description.hasError('required') || helpCenterFormControls.query_description.value =='')"
                                message="Please enter a description"></app-error>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="help-center-btn">
                            <span>
                                <label for="file-upload" class="btn btn-default custom-file-upload">
                                    <img src="../../../assets/img/Icon/plush.svg" />Add Files
                                </label>
                            <input id="file-upload" type="file" multiple (change)="onFileSelect($event)" />
                            </span>

                            <button type="submit" class="btn btn-default btn_hover pl-4 pr-4">Submit</button>
                        </div>
                    </div>

                    <div class="popup-error-height">

                      
                        <!-- <app-error
                        *ngIf="isSubmit && helpCenterFormControls.attachment_path.invalid && helpCenterFormControls.attachment_path.hasError('required') || isSubmit && helpCenterFormControls.attachment_path.value ==''"
                        message="Please attatch a file"></app-error> -->
                        <app-error
                        [message]="api_error"
                        *ngIf="api_error"></app-error>
                    
                      
                    </div>
                </div>
            </form>
         </div>
         <div class="text-white text-center" *ngIf="api_msg">
            <h4>Form Submitted Successfully</h4>
            <app-success-message></app-success-message>
            <p> {{api_msg}}</p>
         </div>
      </div>
   </div>
  </div>