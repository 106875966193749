import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

interface Faq {
  question: string;
  answer: string;
}

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  faqs: Faq[] = [
    { question: 'What all can I watch on nexGTv?', answer: 'nexGTv is a one-stop entertainment destination...' },
    { question: 'Is there any content on nexGTv which is available free?', answer: 'nexGTv offers selected Live TV channels...' },
    { question: 'Where do I get to choose the different types of subscription packages?', answer: 'On clicking on any of the paid channel...' },
    { question: 'I am not able to stream any channel or video, what should I do?', answer: 'The problem might be because there is not enough network coverage...' },
    { question: 'How long will it take to activate the subscription?', answer: 'The subscription will be activated instantaneously...' },
    { question: 'Does nexGTv provide search functionality?', answer: 'Yes, nexGTv application provides an advanced search functionality...' },
    { question: 'Can I watch nexGTv on PC / Laptop as well?', answer: 'nexGTv now offers multi-screen viewing of your favourite content...' },
    { question: 'Can I watch nexGTv globally?', answer: 'Yes, nexGTv is available globally and can be watched outside of India as well...' },
    { question: 'How to contact nexGTv in case of any support?', answer: 'For any query, please mail us at customercare@digivive.com' }
  ];

  filteredFaqs: Faq[] = [];
  searchTerm: string = '';

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.filteredFaqs = this.faqs;  // Initialize with all FAQs
  }

  filterFaqs(): void {
    this.filteredFaqs = this.faqs.filter(faq =>
      faq.question.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      faq.answer.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  highlightSearchTerm(text: string): SafeHtml {
    if (!this.searchTerm) {
      return text; // Return original text if no search term is provided
    }
    const regex = new RegExp(`(${this.searchTerm})`, 'gi');
    const highlightedText = text.replace(regex, `<span class="search-text-bg">$1</span>`);
    return this.sanitizer.bypassSecurityTrustHtml(highlightedText); // Sanitize the highlighted HTML
  }
}