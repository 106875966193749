import { Component, Inject, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/core/services/user.service';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from "src/app/core/services/common.service";
import { AnimationOptions } from 'ngx-lottie';
import { successOptions } from 'src/app/shared/lottie-config';
import { DOCUMENT } from '@angular/common';
import { NgOtpInputComponent } from 'ng-otp-input';

declare var $: any;

@Component({
  selector: 'app-reset-pin',
  templateUrl: './reset-pin.component.html',
  styleUrls: ['./reset-pin.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ResetPinComponent implements OnInit {

  api_error: any;
  api_response: any;
  api_msg: any;
  setPinForm: FormGroup;
  otpForm: FormGroup;
  pin: any;
  isOtpSubmit: boolean = false;
  isSubmitOtpValid: boolean = false;
  timezone: any;
  locale: any;
  timezoneForGA: any;
  timeZoneName: any;
  username: any;
  isPinSubmit: boolean = false;
  confirmPinValid: boolean = false;
  isSubmitPinValid: any = false;
  isSubmitConfirmPinValid: any = false;
  otpData: any;
  timeLeft: number = 60;
  interval;
  userInfo : any;
  language: any;
  resetPinSuccess: boolean;
  successOptions: AnimationOptions = successOptions;
  profileName: any;
  isEnterOtpSubmitted: boolean;
  isOtpInvalid: boolean;
  isPinInvalid: boolean;
  isConfirmPinInvalid: boolean;
  otp_msg: string;
  sendOtpSuccess: boolean;
  resendOtpSuccess: boolean;
  countryDialCode: string;
  profileId: string;
  currentProfileId: string;

  @ViewChild('otpInput', { static: false }) otpInput: NgOtpInputComponent;
  @ViewChild('currentPinInput', { static: false }) currentPinInput: NgOtpInputComponent;
  @ViewChild('confirmPinInput', { static: false }) confirmPinInput: NgOtpInputComponent;

  constructor(private UserService: UserService, 
              private fb: FormBuilder, 
              private googleEvent: EventTrackingService, 
              private router: Router,
              private commonService: CommonService,
              @Inject(DOCUMENT) private _document,
              private route: ActivatedRoute,
              ) {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Kolkata' ? 'Asia/Calcutta' : Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.timezoneForGA = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.locale = Intl.DateTimeFormat().resolvedOptions().locale;
    this.timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZoneName;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.profileId = paramMap.get('id');
    });

    this.currentProfileId = localStorage.getItem('profile_id');
    localStorage.setItem('profile_id', this.profileId);

    this.getProfileData();

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.username = this.userInfo.result.user.username;
    this.countryDialCode = this.userInfo.result.user.country_dial_code;
    // this.profileName = localStorage.getItem('profile_UserName');
    this._document.body.classList.add('profile-bg');
    this.language = this.commonService.languageChange();
    this.setPinForm = this.fb.group({
      'profile_pin': ['', [Validators.required, Validators.minLength(4)]],
      'profile_lock': 'true',
      'confirm_profile_pin': ['', [Validators.required, Validators.minLength(4)]]
    });

    this.otpForm = this.fb.group({
      'username': [this.username,Validators.required],
      'otp': ['', [Validators.required,Validators.minLength(6)]],
      'device_id': localStorage.getItem('deviceId')
    });

    this.sendOtpForPin();
  }

  ngOnDestroy() {
    this._document.body.classList.remove('profile-bg');
    localStorage.setItem('profile_id', this.currentProfileId);
  }

  get otpFormControls() {
    return this.otpForm.controls;
  }

  get pinFormControls() {
    return this.setPinForm.controls;
  }

  getProfileData() {
    let url = 'profile/get';
    this.UserService.getPrfile(url).subscribe(data => {
      console.log(data);
      this.profileName = data.result.profile_name;
      // this.profile_data = data.result;
      // this.profileLockStatus = this.profile_data?.profile_lock;
      // this.is_kids = this.profile_data?.is_child;
      // this.viewingRestriction = this.profile_data?.is_child ? 'A' : this.profile_data?.viewing_restriction.slice(-1)[0]; // Get the last element of the viewing_restriction array
      // this.disablePinForm.get('profile_pin').setValue(this.profile_data?.profile_pin);
      // this.initEditForm();
      // if (this.profile_data?.is_child == true) {
      //   document.getElementById("lock").classList.add("switch-disable");
      // } else {
      //   if (document.getElementById("lock").hasAttribute('switch-disable')) {
      //     document.getElementById("lock").classList.remove("switch-disable")
      //   }
      // }
    }, (error) => {
      console.log('errorrrrr', error);
    });
  }

  sendOtpForPin() {
    let params = {
      username: this.username,
      otp_type: 'profile_pin_reset'
    };
    if (this.countryDialCode) {
      params['country_dial_code'] = this.countryDialCode;
    }
    let url = 'send/otp';
    this.UserService.resendOtp(url, params).subscribe(data => {
      if (data.response_code == 200) {
        this.startTimer();
        this.sendOtpSuccess = true;
        this.otpData = data;
        this.api_response = data.response_code;
        this.api_msg = data.message;
        this.googleEvent.eventEmitter('Profile', 'send otp', this.locale + ' - ' + this.timezoneForGA, 0, + '-' + this.username + '-' + this.locale + ' - ' + this.timezoneForGA + '-reset pin otp Button click');
      } else {
        this.api_error = data.message;
      }
    }, (error) => {
      this.isSubmitPinValid = false;
      this.api_error = error.message;
    });
  }

  startTimer() {
    this.timeLeft = 60;
    this.interval = setInterval(() => {
      if (this.timeLeft == 0) {
        clearInterval(this.interval);
      }
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }
    }, 1000)
  }

  submitOtp() {
    this.isOtpSubmit = true;
    this.api_error = '';
    this.isOtpInvalid = this.otpFormControls.otp.invalid;

    if (this.otpForm.invalid) {
      return;
    }
    let params = this.otpForm.value;
    params.otp_type = 'profile_pin_reset';
    let url = 'verify/otp';
    this.UserService.verifyOtp(url, params).subscribe(data => {
      if (data.response_code == 200) {
        this.isSubmitOtpValid = true;
        this.api_msg = data.message;
      } else {
        this.api_error = data.message;
      }
    }, (error) => {
      this.api_error = error.message;
    });
  }

  resendOtp() {
    this.isOtpInvalid = false;
    this.api_error = false;
    let params = {
      username: this.username,
      otp_type: 'profile_pin_reset'
    };
    if (this.countryDialCode) {
      params['country_dial_code'] = this.countryDialCode;
    }
    let url = 'send/otp';
    this.UserService.resendOtp(url, params).subscribe(data => {
      if(data.response_code === 200 || data.response_code === 201) {
        this.resendOtpSuccess = true;
        this.otp_msg = data.message;
      } else {
        this.api_error = data.message;
      }
    }, (error) => {
      this.api_error = error.message;
    });
  }

  setPin() {
    this.isPinSubmit = true;
    this.isPinInvalid = this.pinFormControls.profile_pin.invalid;
    this.isConfirmPinInvalid = this.pinFormControls.confirm_profile_pin.invalid;
    if (this.setPinForm.invalid) {
      return;
    } else {
      if (this.setPinForm.controls.profile_pin.value != this.setPinForm.controls.confirm_profile_pin.value) {
        this.confirmPinValid = true;
        return;
      } else {
        this.isSubmitPinValid = true;
        let params = this.setPinForm.value;
        let url = 'profile/set/pin';
        this.UserService.setPrfilePin(url, params).subscribe(data => {
          this.resetPinSuccess = true;
          this.api_msg = data.message;
          this.googleEvent.eventEmitter('Profile', 'Set', this.locale + ' - ' + this.timezoneForGA, 0, + '-' + this.username + '-' + this.locale + ' - ' + this.timezoneForGA + '-set Pin click');
          setTimeout(() => {
            this.router.navigateByUrl('/manage-profile');
          }, 2500)
        }, (error) => {
          this.api_error = error.message;
        });
      }
    }
  }

  onOtpChange(pin) {
    let currentPin = pin;
    this.otpForm.get("otp").setValue(currentPin);
  }

  onPinChange(pin) {
    let fpin = pin;
    this.setPinForm.get("profile_pin").setValue(fpin);
    if (pin.length === 4) {
      let eleId = this.confirmPinInput.getBoxId(0);
      this.confirmPinInput.focusTo(eleId);    }
  }

  onConfirmPinChange(pin) {
    let confirmPin = pin;
    this.setPinForm.get("confirm_profile_pin").setValue(confirmPin);
    // if (pin.length === 4) {
    //   this.setPin();
    // }
  }

  handleCancel() {
    this.router.navigateByUrl('/manage-profile');
  }

  handleEnterPinCancel() {
    this.isSubmitOtpValid = false;
  }

  handleConfirmPinCancel() {
    this.isSubmitConfirmPinValid = false;
  }

  handlePinProceed() {
    this.isEnterOtpSubmitted = true;
    if (this.pinFormControls.profile_pin.invalid) {
      return;
    }
    this.isSubmitConfirmPinValid = true;
  }
}
