import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/core';
import { AssetsService } from 'src/app/core';
import { CommonService } from 'src/app/core/services/common.service';
import { VideoPlayerService } from 'src/app/core/services/videoPlayerService';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { environment } from 'src/environments/environment';
declare var videojs: any;

@Component({
  selector: 'app-new-hover',
  templateUrl: './new-hover.component.html',
  styleUrls: ['./new-hover.component.css']
})
export class NewHoverComponent implements OnInit {
  @Input() item: any;
  @Input() page_view: string;
  @Input() isHovered: boolean;
  @Input() isContinueWatching: boolean;
  @Input() stopClickPropagation: boolean;

  userInfo: any;
  username: string;
  assets_hover: boolean;
  previewData: any;
  playermodal: any = [];
  checkPrview: any;
  hoverAndExpend: any;
  isPreviewVideoMuted: boolean = true;
  nameOutsideOverlay: any;
  isStarAnimation: boolean;
  @Output() addToWatchlist: EventEmitter<any> = new EventEmitter();
  @Output() removeFromWatchlist: EventEmitter<any> = new EventEmitter();
  @Output() deleteContinueWatching: EventEmitter<{ event: Event, item: any }> = new EventEmitter();
  @ViewChild('loginModal') loginModal: ElementRef;
  starAnimationInterval: any;
  freeIcon: string = environment.freeIcon;
  paidIcon: string = environment.paidIcon;

  constructor(
    private UserService: UserService,
    private AssetsService: AssetsService,
    private commonService: CommonService,
    private VideoPlayerService: VideoPlayerService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private googleEvent: EventTrackingService,
  ) { }

  ngAfterViewInit() {

    // setInterval(() => {
    //   this.isStarAnimation = false;
    //   setTimeout(() => {
    //     this.isStarAnimation = true;
    //   }, 100);
    // }, 9000);

    
  }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))?.result?.user;
    if (this.userInfo) {
      this.username = this.userInfo.username;
    } else {
      this.stopClickPropagation = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isHovered) {
      if (changes.isHovered.currentValue) {
        this.isStarAnimation = true;
        this.starAnimationInterval = setInterval(() => {
          this.isStarAnimation = false;
          setTimeout(() => {
            this.isStarAnimation = true;
          }, 100);
        }, 9000);
      } else {
        this.isStarAnimation = false;
        clearInterval(this.starAnimationInterval);
      }
    }
    // console.log('ngOnChanges from new hover for', this.item?.name);
    // console.log(this.item?.is_watchlist_added ? 'watchlist_added': 'watchlist_not_added');
  }

  strToLower(str: string) {
    return str?.toLowerCase();
  }

  openLoginModal() {
    this.modalService.open(this.loginModal, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  getAssetDetail() {
    let assetDetail = {
      "code": this.item.code,
      "type": this.item.type || ''
    }
    let urlEncodedDetail = this.UserService.convertoJSONToUrlEncoded(assetDetail);
    this.AssetsService.getAssetDetail(urlEncodedDetail).subscribe(assetDetailRes => {
      console.log('assetDetailRes from new hover: ', assetDetailRes);
      this.item['assetDetail'] = assetDetailRes.result;
    }, (error) => {
      console.error('error fetching asset detail: ', error);
    });
  }

  redirectUrl(data, event: Event,full_screen=false) {
    if (this.stopClickPropagation) event.stopPropagation();

    this.userInfo = localStorage.getItem('userInfo');

    console.log('data',data)
    if (this.userInfo) {
      
      data['full_screen'] = full_screen;
      let itemForDetailPage = {
        code: data.code,
        type: data.type,
      }
      let pagename = this.strToLower(''); //this.data.category_name
      this.page_view = this.page_view + '_' + pagename.split(/\s/).join('');

      if (!full_screen || (data.content_availability.toLowerCase() == 'free' || data.content_availability.toLowerCase() == 'paid' && data?.assetDetail?.is_pack_active == 1)) {
        this.commonService.goToDetail(data, this.page_view);
      } else {
        document.querySelectorAll('.modal-backdrop').forEach(backdrop => backdrop.remove());
        this.router.navigateByUrl('/subscribe');
      }
      // if (data.asset_cat_type == 'recentlywatched') {
      //   this.commonService.goToDetail(data, this.page_view);
      // } else {
      //   this.commonService.goToDetail(data, this.page_view);

      // }
    } else {
      const assetData = {
        redirect_to_detail: true,
        name: data.name,
        code: data.code,
        type: data.type,
      }
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: assetData,
        queryParamsHandling: 'merge',
      });
      this.openLoginModal();
    }
  }

  addRemoveFromWatchlist(item: any, button: HTMLButtonElement, event: Event) {
    event.stopPropagation();
    if (!localStorage.getItem('isAuthenticated')) {
      this.openLoginModal();
      return;
    }
    // button.disabled = true;


    if (!item?.is_watchlist_added || item?.is_watchlist_added == 0) {
      this.googleEvent.eventEmitter("Watchlist" , "Watchlist" , this.username+'-'+item.code+'-'+item.name,0,this.username , this.username+'-'+item.code+'-'+item.name);
      let params = {
        type: item.type,
        code: item.code,
      };
      this.AssetsService.addToWatchList(params).subscribe(
        (data) => {
          if (data.response_code === 200 || data.response_code === 201) {
            item.is_watchlist_added = 1;
            this.addToWatchlist.emit(item);
          }
          button.disabled = false;
        },
        (error) => {
          console.error('Error adding to watchlist:', error);
          button.disabled = false;
        }
      );
    } else {
      let params = {
        code: [item.code],
      };
      this.AssetsService.deleteFromWatchlist(params).subscribe(
        (data) => {
          if (data.response_code === 200 || data.response_code === 201) {
            item.is_watchlist_added = 0;
            this.removeFromWatchlist.emit(item);
          }
          button.disabled = false;
        },
        (error) => {
          console.error('Error deleting from watchlist:', error);
          button.disabled = false;
        }
      );
    }
  }

  togglePreviewVideoMute(asset, event) {
    event.stopPropagation();
    if (this.isPreviewVideoMuted) {
      videojs("hoverPreviewVideo" + asset.code).muted(false);
      this.isPreviewVideoMuted = false;
    } else {
      videojs("hoverPreviewVideo" + asset.code).muted(true);
      this.isPreviewVideoMuted = true;
    }
  }

  item_hover_enter(asset) {
    if (!this.item['assetDetail'] && this.userInfo) {
      this.getAssetDetail();
    }
    // Check if the player is already initialized
    if (asset?.code == this.checkPrview || videojs.getPlayers()[`hoverPreviewVideo${asset?.code}`]) {
      // console.log('return from item_hover_enter\n', 'item_hover_enter checkPrview:', this.checkPrview, '\nitem_hover_enter player:', videojs.getPlayers()[`hoverPreviewVideo${asset?.code}`]);
      return;
    }
    this.assets_hover = true;
    let assetDetail = {
      "code": asset.code,
    }

    let assetInfo = this.UserService.convertoJSONToUrlEncoded(assetDetail);

    this.VideoPlayerService.playPreview(assetInfo).subscribe(
      (playObj) => {
        this.previewData = playObj.result;
        // console.log("get preview data", playObj);

        let videoPlayUrl: any;
        if (
          this.previewData.cloud_front &&
          Object.keys(this.previewData.cloud_front).length > 0 &&
          this.previewData.cloud_front.cloudfront_key_pair_id != "" && this.previewData.cloud_front.video_url != "" && this.previewData.cloud_front.cloudfront_policy != "" && this.previewData.cloud_front.cloudfront_signature != ""
        ) {
          // console.log('inside if block');
          setTimeout(() => {
            this.checkPrview = asset.code;
          }, 5000);
          setTimeout(() => {
            videoPlayUrl = this.previewData.cloud_front.video_url; //this.previewData.cloud_front.cloud_front.video_url;
            this.setCookie(
              "CloudFront-Key-Pair-Id",
              this.previewData.cloud_front.cloudfront_key_pair_id.trim(),
              1
            );
            this.setCookie(
              "CloudFront-Policy",
              this.previewData.cloud_front.cloudfront_policy.trim(),
              1
            );
            this.setCookie(
              "CloudFront-Signature",
              this.previewData.cloud_front.cloudfront_signature.trim(),
              1
            );
            ("http://j-cdr.digivive.com/HLS_Aaj/Aaj.m3u8");

            let new_source = {
              // //sample source
              // sources: [
              //   {
              //     type: 'video/mp4',
              //     src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
              //   },
              // ],
              sources: [{ src: videoPlayUrl, type: "application/x-mpegURL" }],
              controls: false,
              autoplay: true,
              withCredentials: true,
              crossDomain: true,
              fluid: true,
              controlBar: {
                children: [
                  // "playToggle",
                  // "volumeMenuButton",
                  // "durationDisplay",
                  // "timeDivider",
                  // "currentTimeDisplay",
                  // "progressControl",
                  // "remainingTimeDisplay",
                  // "fullscreenToggle"
                ]
              },
            };

            // console.log('video tag: ', document.getElementById('hoverPreviewVideo'+asset.code));
            this.playermodal = videojs("hoverPreviewVideo" + asset.code, new_source);
            // console.log(this.playermodal);

            this.playermodal.userActive(false);
            this.playermodal.muted(true);
            this.playermodal.poster(asset?.image_landscape);
            this.playermodal.on("error", (event) => {
              console.error('Error playing preview video:', event);
              this.checkPrview = "no";
            });
            this.playermodal.on("ended", (event) => {
              videojs("hoverPreviewVideo" + asset.code).dispose();
              this.checkPrview = "no";
              this.isPreviewVideoMuted = true;
            });
          }, 5000);
        } else {
          this.checkPrview = "no";
        }
      });


  }

  item_hover_leave(asset) {
    this.assets_hover = false;
    if (asset.code == this.checkPrview || videojs.getPlayers()[`hoverPreviewVideo${asset?.code}`]) {
      videojs("hoverPreviewVideo" + asset.code).dispose();
      this.isPreviewVideoMuted = true;
      // console.log('video disposed from item_hover_leave: ', 'hoverPreviewVideo', asset.code);
      this.checkPrview = "no";
    }
  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  deleteFromContinueWatching(event:Event, item: any) {
    event.stopPropagation();
    this.deleteContinueWatching.emit(item);
  }

}
