import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { map } from 'rxjs/operators';
import { CatlougeTypeConstants, tempLiveData } from "src/app/core/constants";
import * as _ from 'lodash';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class AssetsService {
  userInfo: any = localStorage.getItem('userInfo');
  sessionId: any;
  catlouge: string;
  viewAllData: any = [];
  homePageAssetsData: any = [];
  constructor(
    private apiService: ApiService,
    private jwtService: JwtService
  ) {

  }

  getHomePageTabData(params) {

    return this.apiService.get(`tabdata/list?${params}`).pipe(map(data => {
      this.homePageAssetsData = data;
      return data;
    }));

  }


  getContinueWatchingData(params) {
    return this.apiService.get(`continuewatching/vr/get?${params}`).pipe(map(data => {
      this.homePageAssetsData = data;
      return data;
    }));
  }

  getBannerData(params) {
    return this.apiService.get(`tabdata/banner?${params}`).pipe(map(data => {
      this.homePageAssetsData = data;
      return data;
    }));

  }


  getHomePageAssets(params) {

    if (this.jwtService.getToken()) {
      if (typeof this.userInfo != 'object') {
        this.userInfo = JSON.parse(this.userInfo);
      }
      // this.userInfo = JSON.parse(this.userInfo)
      this.catlouge = localStorage.getItem('sessionId');
      this.catlouge = CatlougeTypeConstants.catlogue;
      if (this.userInfo) {
        this.sessionId = this.userInfo.result.sessionId;
      }
      return this.apiService.get('homecategoryasset?catlogue=' + this.catlouge).pipe(map(data => {
        this.homePageAssetsData = data;
        return data;
      }));

    }

  }

  get getHomePageAssetsData() {
    return this.homePageAssetsData;
  }

  addContineWatching(data) {
    return this.apiService.post(`continuewatching/add`, data);
  }


  deleteContinueWatching(data) {
    return this.apiService.delete(`continuewatching/vr/delete`, data).pipe(map(data => {
      return data;
    }));

  }


  detailEpisode(params) {
    return this.apiService.get(`assetdetailpage?${params}`).pipe(map(data => {
      return data;
    }));

  }

  getTrending() {
    return this.apiService.get(`trending`).pipe(map(data => {
      return data;
    }));
  }


  getRecommended(params) {
    return this.apiService.get(`recommendation?${params}`).pipe(map(data => {
      return data;
    }));

  }

  getFrequentlyPlayed(params) {
    return this.apiService.get(`frequentlyplayed/get?${params}`).pipe(map(data => {
      return data;
    }));

  }
  
  addFrequentlyPlayed(data) {
    return this.apiService.post(`frequentlyplayed/add`, data).pipe(map(data => {
      return data;
    }));

  }


  getAssetDetail(params) {
    return this.apiService.get(`assetdetail?${params}`).pipe(map(data => {
      return data;
    }));
  }

  getPlatformPages(params,langCode) {
    const setParams = new HttpParams().set('include', '');
    const header = { 'Lang-code': langCode }
    return this.apiService.get(`staticpages/get?${params}`,setParams,header).pipe(map(data => {
      return data;
    }));
  }

  getAssetRecentlyWatch(params) {
    return this.apiService.get(`assetdetail/recentlywatch?${params}`).pipe(map(data => {
      return data;
    }));
  }

  getViewAll(params) {

    return this.apiService.get(`tabdata/list?${params}`).pipe(map(data => {
      return data;
    }));

  }

  viewAllSavedData(gener) {
    return this.viewAllData.find(d => d[gener] != undefined)
  }

  getWatchlist() {
    return this.apiService.get(`watchlist/getall`).pipe(map(data => {
      return data;
    }));
  }

  addToWatchList(data) {
    return this.apiService.post(`watchlist/add`, data).pipe(map(data => {
      return data;
    }));
  }

  deleteFromWatchlist(data) {
    return this.apiService.delete(`watchlist/delete`, data).pipe(map(data => {
      return data;
    }));
  }


  getEpgData() {
    let programDateArr = [];
    let test = tempLiveData.egpData;
    let tempProgramByData = [];
    console.log(test);
    test.result.forEach(element => {
      programDateArr.push(element.progdate)
    });
    console.log(_.uniq(programDateArr));
    let programDateUniqArr = _.uniq(programDateArr)
    programDateUniqArr.forEach((element, index) => {
      console.log(index, programDateUniqArr.length - 1);
      tempProgramByData.push(test.result.filter(program => element == program.progdate));
      if (index == programDateUniqArr.length - 1) {
        console.log(tempProgramByData)
      }
    });
  }

  getContestDetail(params) {
    return this.apiService.get(`tabdata?${params}`).pipe(map(data => {
      return data;
    }));
  }

  postContestDetail(credentials){
    this.catlouge = CatlougeTypeConstants.catlogue;
    return this.apiService.post('tabdata?catlogue='+this.catlouge+'&call=votingpoll', credentials).pipe(map(data => {
      return data;
    }
    ));

  }
  

  getLiveEpgData(epgReqParams){
    return this.apiService.get(`assetdetail/channel/epg?${epgReqParams}`).pipe(map(data => {
      return data;
    }));

  }

  getEpgDate(params) {
    return this.apiService.get(`assetdetail/channel/category?${params}`).pipe(map(data => {
      return data;
    }));
  }

}

