<div *ngIf="!showForgotPassword" class="bodyarea mt-0">
    <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
            <button  type="button" class="close popup_close" (click)="closeModal()" #closebutton>&times;</button>
            <div class="form_bg" [ngClass]="{'register-scroll': !isOtpField && !isUserExist && isValidField}">
                <div class="text-center mb-4">
                    <div class="mb-4"><img class="profile-logo-width" src="../../../../assets/img/logo.png" /></div>
                </div>

                <div class="text-center mb-4"  *ngIf="(!isOtpField && !isValidField && !isUserExist && !authSuccess)">
                    <label class="login_title">{{language.loginRegister}}</label>
                </div>

                <div class="text-center mb-4" *ngIf="isUserExist && !isOtpField && !showForgotPassword && !authSuccess">
                    <label class="login_title">{{language.login}}</label>
                </div>
                
                <div class="text-center mb-4"  *ngIf="!isOtpField && !isUserExist && isValidField">
                    <label class="login_title">{{language.register}}</label>
                </div>

                <form *ngIf="!this.isValidField && !authSuccess" [formGroup]="loginRegisterForm" class="transparent_form"
                    autocomplete="off" (submit)="loginRegister()">
                    <label class="input_heading">{{ registrationType === 'both' ? 'Mobile Number / Email ID' : registrationType === 'email' ? 'Email ID' : 'Mobile number' }} <span class="asterisk_sign">*</span></label>
                    <div class="input_line">
                        <div class="phone_select">
                            <div class="dropdown" *ngIf="isMobile">
                                <button class="code_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span>{{ countryDialCode }}</span>
                                    <img src="../../../../assets/img/Icon/arrow_down_black.svg" />
                                </button>
                                <div class="dropdown-menu country_list_popup" aria-labelledby="dropdownMenuButton">
                                    <div class="additional_select">
                                        <ng-select dropdownPosition="bottom" formControlName="country_dial_code" [items]="countryList"
                                            bindLabel="name" bindValue="dial_code" placeholder="Search Country"
                                            (change)="setCountryDialCode($event)" [isOpen]="true">
                                            <ng-template ng-option-tmp let-item="item">
                                                <span>{{ item.name }}</span>
                                                <span>{{ item.dial_code }}</span>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                            
                            <input #usernameInput
                                id="username-input"
                                [type]="registrationType === 'mobile' ? 'number' : 'text'"
                                class="form-control"
                                required
                                [placeholder]="registrationType === 'both' ? language.enterMobileEmail : registrationType === 'email' ? 'Enter email ID' : 'Enter Mobile Number'"
                                formControlName="username"
                                (input)="validateUserName($event)">
                        </div>
                    </div>

                    <div class="popup-info-height error-toast-height">

                        <app-error
                            *ngIf="api_error"
                            [message]="api_error"></app-error>

                        <app-error
                        *ngIf="isSubmit && loginRegisterControls.username.invalid && loginRegisterControls.username.hasError('required') || isSubmit && loginRegisterControls.username.value ==''"
                        [message]="registrationType === 'both' ? 'Please enter a mobile number/ email ID' : registrationType === 'email' ? 'Please enter an email ID' : 'Please enter a mobile number'"></app-error>
                    
                        <span *ngIf="isSubmit && loginRegisterControls.username.invalid  && (loginRegisterControls.username.dirty || loginRegisterControls.username.touched) && loginRegisterControls.username.value !==''">
                            <app-error
                            *ngIf="isMobile"
                            [message]="selectedCountry.min_number_limit
                                        ? (language.pleaseEnter + selectedCountry.min_number_limit + language.digitsMobileNumber)
                                        : 'Please enter a valid mobile number'"></app-error>

                            <app-error
                            *ngIf="!isMobile"
                            [message]="language.pleaseEnterValidEmail"></app-error>

                        </span>
                    </div>

                    <button type="submit" class="btn btn-default btn_height btn_hover" [disabled]="loginRegisterControls.username.value==''" >{{language.proceed}}</button>

                    <span style="display: block;font-size: 12px;text-align: center;" class="mt-4 input_heading">By continuing, you agree to our 
                        <a (click)="closeModal()" routerLink="/terms" style="font-weight: 600;color: #fff;" href="javascript:void(0);">Terms of Use</a> and 
                        <a (click)="closeModal()" routerLink="/policy" style="font-weight: 600;color: #fff" href="javascript:void(0);">Privacy Policy</a></span>
                </form>

                <!------Login with Password Form------>
                <form *ngIf="isUserExist && isValidField && !isOtpField && !showForgotPassword && !authSuccess" [formGroup]="loginWithPasswordForm" (ngSubmit)="loginWithPassword()" class="transparent_form" autocomplete="off">
                    <div class="form-group">
                        <label class="input_heading">{{isMobile ? language.mobileNumber : language.emailId}}</label>
                        <div class="input_line">
                            <div class="d-flex align-items-center">
                                <span class="c_code_border" *ngIf="isMobile">{{this.countryDialCode}} </span>
                                <input type="text" class="form-control" formControlName="username" placeholder="Enter your register mobile number / email ID" readonly />
                                <a class="input-edit-icon" href="javascript:;" (click)="editUsername()"><img src="../../../../assets/img/Icon/edit-dark.svg" alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-0">
                        <label class="input_heading">{{language.password}}</label>
                        <div class="input_line">
                            <input id="login-password" autocomplete="new-password" [type]="loginPassHide ? 'password' : 'text'" class="form-control"
                                [placeholder]="language.password" formControlName="password">
                            <a class="input-edit-icon" href="javascript:;" (click)="hideRPass()">
                                <!-- <i *ngIf="loginPassHide==true" class="fa fa-eye-slash"></i>
                                <i *ngIf="loginPassHide==false" class="fa fa-eye"></i> -->
                                <img *ngIf="loginPassHide==true" src="../../../../assets/img/Icon/eye-off.svg" />
                                <img *ngIf="loginPassHide==false" src="../../../../assets/img/Icon/eye-on.svg" />
                            </a>
                        </div>
                    </div>


                        
                    <div class="popup-info-height text-right">
                        <div class="text-right" *ngIf="isUserExist && (selectedCountry.registration_type == 'mobile' || selectedCountry.registration_type == 'both')">
                            <small class="text-white cursor-pointer" (click)="openForgotPassword(forgotContent)">{{language.forgotPassword}}?</small>
                        </div>

                        <app-error
                            *ngIf="isLoginWithPassSubmit && passwordOtpControls.password.hasError('required') && passwordOtpControls.password.invalid"
                            [message]="language.pleaseEnterAPassword"></app-error>

                        <app-error
                            *ngIf="api_error"
                            [message]="api_error"></app-error>
                    </div>
                    

                    <button type="submit" class="btn btn-default btn_height btn_hover">{{language.login}}</button>

                    <ng-container *ngIf="selectedCountry.registration_type == 'mobile' || selectedCountry.registration_type == 'both'">
                        <div class="form-group" *ngIf="isUserExist">
                            <span class="or_line">
                                <p>{{language.or}}</p>
                            </span>
                        </div>
                        <div class="form-group text-center mb-0" *ngIf="isUserExist">
                            <a href="javascript:;" class="text-white" (click)="showOtpFiled()">
                                <span *ngIf="!isOtpField"> {{language.loginWithOTP}}</span>
                                <!-- <span *ngIf="isOtpField"> {{language.loginWithPassword}}</span> -->
                            </a>
                        </div>
                    </ng-container>
                    
                </form>
            
                <!------User Does Not Exist. Register User Form ------>
                <form *ngIf="!isOtpField && !isUserExist && isValidField" [formGroup]="registerForm" (ngSubmit)="register()" class="transparent_form" autocomplete="disabled">
                    <div class="form_scroll">
                        <div class="form-group mb-0">
                            <div *ngIf="!this.isMobile">
                                <label class="input_heading">{{language.emailId}}</label>
                                <div class="input_line" >
                                    <input type="text" formControlName="email" class="form-control" placeholder="Exist Email" readonly />
                                    <a class="input-edit-icon" href="javascript:;" (click)="editUsername()"><img src="../../../../assets/img/Icon/edit-dark.svg" alt="" /></a>
                                </div>
                            </div>
                            <div *ngIf="this.isMobile">
                                <label class="input_heading">{{language.mobileNumber}}</label>
                                <div class="input_line"  style="display: flex; justify-content: center; align-items: center;">
                                    <span class="c_code_border">{{countryDialCode}}&nbsp;</span>
                                    <input type="text" formControlName="mobile" class="form-control" placeholder="Exist Mob. Number" readonly />
                                    <a class="input-edit-icon" href="javascript:;" (click)="editUsername()"><img src="../../../../assets/img/Icon/edit-dark.svg" alt="" /></a>
                                </div>
                            </div>
                            <div class="popup-error-height"></div>
                        </div>
                        <div class="form-group mb-0">
                            <label class="input_heading">{{language.password}} <span class="asterisk_sign">*</span></label>
                            <div class="input_line">
                                <input autocomplete="new-password" [type]="loginPassHide ? 'password' : 'text'" placeholder="Enter Password" class="form-control" formControlName="password">
                                <a class="input-edit-icon" href="javascript:;" (click)="hideRPass()">
                                    <!-- <i *ngIf="loginPassHide==true" class="fa fa-eye-slash"></i>
                                    <i *ngIf="loginPassHide==false" class="fa fa-eye"></i> -->

                                    <img *ngIf="loginPassHide==true" src="../../../../assets/img/Icon/eye-off.svg" />
                                    <img *ngIf="loginPassHide==false" src="../../../../assets/img/Icon/eye-on.svg" />
                                </a>
                            </div>
                            <div class="popup-error-height">
                                <div *ngIf="registerFormControls.password.errors">
                                    <app-error
                                    *ngIf="isSubmit1 && registerFormControls.password.invalid && registerFormControls.password.hasError('required')"
                                    [message]="language.pleaseEnterAPassword"></app-error>
            
                                    <app-error
                                    *ngIf="isSubmit1 && (registerFormControls.password.hasError('maxlength') || registerFormControls.password.hasError('minlength'))"
                                    [message]="language.passwordShouldBe"></app-error>
                                </div>
                            </div>
                        </div>

                        <!-- Additional Fields based on API result -->
                        <ng-container *ngFor="let field of additionalFieldsArray; let indexOfelement=index;">
                            <ng-container [ngSwitch]="field.type">
                                <div *ngSwitchCase="'input'" class="form-group mb-0">
                                    <label class="input_heading">{{field.title}} <span class="asterisk_sign" *ngIf="field.is_required">*</span></label>
                                    <div class="input_line">
                                        <input type="text" class="form-control" [formControlName]="field.slug" placeholder="{{field.title}}">
                                    </div>
                                    <div class="popup-error-height">
                                        <app-error
                                        *ngIf='isSubmit1 && registerFormControls[field.slug].errors'
                                        [message]="field.error_message"></app-error>
                                    </div>
                                </div>
                            
                                <div *ngSwitchCase="'input_calender'" class="form-group mb-0">
                                    <label class="input_heading">{{field.title}} <span class="asterisk_sign" *ngIf="field.is_required">*</span></label>
                                    <div class="input_line d-flex">
                                        <input style="z-index: 99;" type="text" placeholder="DD/MM/YYYY" class="form-control" [formControlName]="field.slug" bsDatepicker [maxDate]="maxDate" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true }"> 
                                        <a href="javascript:void(0);" class="input-edit-icon" ><img src="../../../../assets/img/Icon/calender.svg" alt="" /></a>
                                    </div>
                                    <div class="popup-error-height">
                                        <app-error
                                        *ngIf="isSubmit1 && registerFormControls[field.slug].errors"
                                        [message]="field.error_message"></app-error>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'radio'" class="form-group mb-0">
                                    <label class="input_heading">{{field.title}} <span class="asterisk_sign" *ngIf="field.is_required">*</span></label>
                                    <ul class="radio_list">
                                        <li *ngFor="let rad of field.attributes">
                                            <label class="gander_radio">
                                            <input type="radio" name="{{field.slug}}" [formControlName]="field.slug" [value]="rad"><span></span>{{rad}}
                                            
                                            </label>
                                        </li>
                                    </ul>
                                    <div class="popup-error-height">
                                        <app-error
                                        *ngIf='isSubmit1 && registerFormControls[field.slug].errors'
                                        [message]="field.error_message"></app-error>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'checkbox'" class="form-group mb-0">    
                                    <label class="tellus_view_label">{{field.title}} <span class="asterisk_sign" *ngIf="field.is_required">*</span></label>
                                    <div class="">
                                        <ul class="additional_checkbox">
                                            <li *ngFor="let attribute of field.attributes">
                                                <label class="checkbox-img">
                                                    <input type="checkbox" name="{{field.slug}}" [value]="attribute" (change)="onCheckChange($event, field.slug)">{{attribute}}
                                                    <span class="checkmark-img"></span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="popup-error-height">
                                        <app-error
                                        *ngIf='isSubmit1 && registerFormControls[field.slug].errors'
                                        [message]="field.error_message"></app-error>
                                    </div>
                                </div>

                                <div *ngSwitchCase="'select'" class="form-group mb-0 ">
                                    <label class="tellus_view_label">{{field.title}} <span class="asterisk_sign" *ngIf="field.is_required">*</span></label>
                                    <div class="input_line additional_select">
                                        <!-- <select class="form-control" [formControlName]="field.slug">
                                            <option value="" disabled>{{field.title}}</option>
                                            <option *ngFor="let attribute of field.attributes" [value]="attribute">{{attribute}}</option>
                                        </select> -->

                                        <!-- <select2 [data]="demoSelect" [value]="'AK'"></select2> -->

                                        <ng-select [formControlName]="field.slug" [items]="field.attributes" placeholder="{{field.title}}"
                                        [searchable]="false" [clearable]="false" class="form-control">
                                            <ng-option *ngFor="let attribute of field.attributes" [value]="attribute">
                                                {{ attribute }}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="popup-error-height">
                                        <app-error
                                        *ngIf='isSubmit1 && registerFormControls[field.slug].errors'
                                        [message]="field.error_message"></app-error>
                                    </div>
                                </div>
                                
                                <div *ngSwitchCase="'textarea'" class="form-group mb-0">
                                    <label class="tellus_view_label">{{field.title}} <span class="asterisk_sign" *ngIf="field.is_required">*</span></label>
                                    <div class="scroll_height additional_textarea">
                                        <textarea class="form-control" [formControlName]="field.slug" placeholder="{{field.title}}"></textarea>
                                    </div>
                                    <div class="popup-error-height">
                                        <app-error
                                        *ngIf='isSubmit1 && registerFormControls[field.slug].errors'
                                        [message]="field.error_message"></app-error>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>

                        <div *ngIf="registerFormControls.email && registerFormControls.email.errors">
                            <span *ngIf="isSubmit1 && registerFormControls.email.hasError('required') && registerFormControls.email.invalid" class="submit-error">{{language.pleaseEnterEmail}}</span>
                            <span *ngIf="isSubmit1 && registerFormControls.email.errors.pattern" class="submit-error">{{language.pleaseEnterValidEmail}}</span>
                        </div>

                        <div *ngIf="registerFormControls.mobile && registerFormControls.mobile.errors">
                            <span *ngIf="isSubmit1 && (registerFormControls.mobile.hasError('required') || registerFormControls.mobile.invalid)" class="submit-error">{{language.pleaseEnterValidMobile}} </span>
                        </div>
                    </div>
                    
                    <div class="register-btn-pad">
                        <div class="text-right popup-error-height error-toast-height">
                            <app-error
                                *ngIf="api_error"
                                [message]="api_error"></app-error>
                        </div>
                        <button type="submit" class="btn btn-default btn_height btn_hover">{{language.register}}</button>
                    </div>
                </form>

                
                
                <!------OTP Form------>
                <label class="login_title" *ngIf="isOtpField">{{isUserExist ? language.login : (isMobile ? language.verifyMobile : language.verifyEmail)}}</label>
                    <div *ngIf="isOtpField" class="text-center mt-4">
                        <div *ngIf="sendOtpSuccess" class="login_white_text">
                            {{language.anOtpHasBeenSentTo}}
                            <span *ngIf="isMobile" class="login_white_text_bold">{{loginRegisterControls.country_dial_code.value}} </span>
                            <span class="login_white_text_bold">{{loginRegisterControls.username.value}}</span>
                            <a href="javascript:;" (click)="editUsername()">
                                <img class="edit-icon-width" src="../../../../assets/img/Icon/edit.svg" alt="" />
                            </a>
                        </div>

                        <div class="login_white_text login_white_text_bold mt-4">{{isUserExist ? language.enterOtpToLogin : language.enterOtpToRegister}}</div>
                    </div>
                    
                <form class="transparent_form" *ngIf="isOtpField" [formGroup]="otpForm" (ngSubmit)="verifyOtp()" autocomplete="off">
                    <div class="otp_line form-group" [ngClass]="(api_error || (isOtpInvalid && otpFormControls.otp.invalid)) && 'otp-invalid'">
                        <ng-otp-input (onInputChange)="onOtpChange($event)"
                            [config]="{length:6,allowNumbersOnly:true}" [formCtrl]="otp" #ngOtpInput>
                        </ng-otp-input>
                    </div>
                    

                    <div class="form-group popup-info-height">
                        <div class="row">
                            <div class="col-md-6 text-left">
                                <small class="text-white" *ngIf="timeLeft">{{ '00:' + (timeLeft < 10 ? '0' + timeLeft : timeLeft) }}</small>
                            </div>
                            <div class="col-md-6 text-right">
                                <small class="d-flex justify-end">
                                    <span class="text-white mr-2">{{language.didNotGetTheOtp}}</span>
                                    <a href="javascript:void(0);" *ngIf="!timeLeft" (click)="resendOtp()">{{language.resend}}</a>
                                    <a href="javascript:void(0);" class="resend_disable" *ngIf="timeLeft">{{language.resend}}</a>
                                </small>
                            </div>
                            <div class="col-md-12">
                                <small *ngIf="resendOtpSuccess" class="text-white">{{otp_msg}}</small>
                                <div *ngIf="otpFormControls.otp.errors">
                                    <app-error
                                    *ngIf="isOtpInvalid && (otpFormControls.otp.hasError('required') || otpFormControls.otp.invalid)"
                                    [message]="language.pleaseEnterValidOTP"></app-error>
                                </div>
                                <app-error
                                [message]="api_error"
                                *ngIf="api_error"></app-error>
                            </div>
                        </div>
                    </div>
                    
                    <button type="submit" class="btn btn-default mt-4 btn_height btn_hover">{{isUserExist ? language.login : language.register}}</button>

                    <!-- <div class="form-group" *ngIf="isUserExist">
                        <span class="or_line">
                            <p>{{language.or}}</p>
                        </span>
                    </div>

                    <div class="form-group text-center" *ngIf="isUserExist">
                        <a href="javascript:;" class="text-white" (click)="showOtpFiled()">
                            <span *ngIf="!isOtpField">{{language.loginWithOTP}}</span>
                            <span *ngIf="isOtpField">{{language.loginWithPassword}}</span>
                        </a>
                    </div> -->
                </form>

                <!------Success Message------>
                <div *ngIf="authSuccess">
                    <div class="success-add">
                        <h2 style="color:#fff;font-size: 20px;font-weight: 100;line-height: 35px;"
                        class="_verify_t">
                        {{api_msg}}
                        </h2>
                        <!-- <div class="lottie-icon text-center" style="width: 100%;">
                            <ng-lottie [options]="successOptions"></ng-lottie>
                        </div> -->

                        <div class="lottie-icon-block">
                            <div class="lottie-icon">
                                <ng-lottie [options]="successOptions"></ng-lottie>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showForgotPassword" #forgotContent>
    <app-forgotpassword [username]="userN" [isMobile]="isMobile" [countryDialCode]="countryDialCode"></app-forgotpassword>
</div>