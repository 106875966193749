<footer class="inner_f_i" id="footer">
    <div class="container">
        <!-- <div class="row">
            <div class="col-md-12">
                <h6 class="text-white text-center">Subscribe to our Newsletter</h6>
                <div class="subscribe-news">
                    <form>
                        <div class="subscribe-input">
                            <img src="../../../../assets/img/Icon/email.svg" />
                            <input type="text" placeholder="Enter your Email ID" />
                        </div>
                        <div class="subscribe-btn">
                            <input type="submit" value="Subscribe Now" />
                        </div>
                    </form>
                </div>
            </div>
        </div> -->

        <div class="row mt-5 fpad100">
            <div class="col-md-3 footer-nav">
                <label>Company</label>
                <ul>
                    <li><a href="javascript:;" routerLink="/aboutus">About Us</a></li>
                    <li><a href="javascript:;">News</a></li>
                </ul>
            </div>
            <div class="col-md-3 footer-nav">
                <label>Support</label>
                <ul>
                    <li><a href="javascript:;" routerLink="/contactus">Contact Us</a></li>
                    <li><a href="javascript:;" routerLink="/faq">FAQs</a></li>
                </ul>
            </div>
            <div class="col-md-3 footer-nav">
                <label>Legal</label>
                <ul>
                    <li><a href="javascript:;" routerLink="/terms">Terms of Use</a></li>
                    <li><a href="javascript:;" routerLink="/policy">Privacy Policy</a></li>
                </ul>
            </div>
            <div class="col-md-3 footer-social">
                <img class="f-logo" src="../../../../assets/img/logo.png" />
                <ul>
                    <li><a><img src="../../../../assets/img/Icon/facebook.svg" /></a></li>
                    <li><a><img src="../../../../assets/img/Icon/instagram.svg" /></a></li>
                    <li><a><img src="../../../../assets/img/Icon/linkedin.svg" /></a></li>
                    <li><a><img src="../../../../assets/img/Icon/youtube.svg" /></a></li>
                </ul>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-md-12 footer-bottom">
                <h6 class="text-white text-center">Available on many devices. Install the app now.</h6>
                <ul>
                    <li><a target="_blank" href="https://play.google.com/store/apps/details?id=com.digivive.offdeck"><img src="../../../../assets/img/Icon/google-play.svg" /></a></li>
                    <li><a target="_blank" href="https://apps.apple.com/in/app/nexgtv-live-tv-movies-videos/id495146199"><img src="../../../../assets/img/Icon/app-store.svg" /></a></li>
                    <li><a target="_blank" href="https://play.google.com/store/apps/details?id=com.digivive.androidtv"><img src="../../../../assets/img/Icon/android-tv.svg" /></a></li>
                    <li><a target="_blank" href="https://www.amazon.com/Digivive-Services-Pvt-Ltd-nexGTv/dp/B01N3UO97B/ref=sr_1_1?dib=eyJ2IjoiMSJ9.qPgdFxvkzN4JAWF3lO_EuQ.rLiZCN-4ESUyUELy6Hm8b4EeGTwNyC7HJe4b38DxHu4&dib_tag=se&keywords=nexgtv&qid=1727954533&s=mobile-apps&sr=1-1"><img src="../../../../assets/img/Icon/fire-tv.svg" /></a></li>
                </ul>
                <p>&copy; {{currentYear}}, OTT platform run by Digivive Services Pvt. Ltd. All Rights Reserved</p>
                <!-- <p>&copy; 2023, OTT platform run by Digivive Services Pvt. Ltd. All Rights Reserved</p> -->
            </div>
        </div>
<!--                 
                <div class="inner_footer_social">
                    <ul>
                        <li>
                            <a href="javascript:void(0);">
                                <i class="fa fa-facebook-square" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <i class="fa fa-twitter-square" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <i class="fa fa-youtube-play" aria-hidden="true"></i>
                            </a>
                        </li>
                    </ul>

                    <div class="inner_app">                 
                        <div class="app_list">
                            <a target="_blank" href="https://play.google.com/store/search?q=nexgtv&c=apps&hl=en-IN" class="w_play_store"></a>
                            <a target="_blank" href="https://apps.apple.com/in/app/nexgtv-live-tv-movies-videos/id495146199" class="w_app_store"></a>
                        </div>
                     </div>
                </div>
  
                <div class="inner_footer_page">
                    <ul>
                        <li>
                            <a href="javascript:void(0);"  routerLink="/advertise">
                                Advertise
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" routerLink="/aboutus">
                                {{this.langugae.aboutUs}}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" routerLink="/terms">
                                {{this.langugae.term}}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" routerLink="/policy">
                                {{this.langugae.policy}}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" routerLink="/faq">
                             FAQs
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" routerLink="/media">
                                Media
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" routerLink="/contactus">
                                {{this.langugae.contact}}
                            </a>
                        </li>
                        
                    </ul>
                </div>

                <span class="demo_c">This is demo website for testing purposes.</span>
  
                <p class="copy">&copy;  2023, OTT platform run by Digivive Services Pvt. Ltd.</p> -->
            
    </div>        
</footer>