<div class="asset-player-block">
  <div class="video-error-overlay"
    *ngIf="isPlaybackError">
    <div>
      <span>Oops! There was an error loading the video. Please try again later.</span>
      
      <a (click)="initData()">Retry</a>
    </div>
    <!-- <div class="re-play-btn"
      (click)="replayPreview()">
      <div class="re-play-btn">
        <div class="re-play-btn-align">
          <div class="re-play-btn-bg">
            <img src="../../../assets/img/Icon/re-play.svg" />
          </div>
          <label>Replay</label>
        </div>
      </div>
    </div> -->
  </div>
  <video id="player_one" class="video-js vjs-fluid" controls preload="auto" width="640" height="360" playsinline
    *ngIf="!isAssetImageVisible && !isDeviceNotAllowed && !isPlaybackError">
    <!--   autoplay -->
    <p class="vjs-no-js">
      To view this video please enable JavaScript, and consider upgrading to a
      web browser that
      <a href="https://videojs.com/html5-video-support/" target="_blank">
        supports HTML5 video
      </a>
    </p>
  </video>
  <div class="asset-paid-img"
    *ngIf="isAssetImageVisible || isPreviewFinished"
    >
    <div class="re-play-btn"
      *ngIf="isPreviewFinished"
      (click)="replayPreview()">
      <div class="re-play-btn">
        <div class="re-play-btn-align">
          <div class="re-play-btn-bg">
            <img src="../../../assets/img/Icon/re-play.svg" />
          </div>
          <label>Replay</label>
        </div>
      </div>
    </div>
    <img [src]="streamInfo?.image_landscape" alt="" />
  </div>
  <div class="device-not-allowed"
    *ngIf="isDeviceNotAllowed">
    <div class="device-not-allowed-info">
       <div class="device-not-allowed-text">
          <h2>{{ deviceRetrictionHeading }}</h2>
          <div class="device-not-allowed-icon">
            <app-transaction-failure></app-transaction-failure>
          </div>
          <p>{{ deviceRestrictionMessage }}</p>
       </div>
    </div>
    <img [src]="streamInfo?.image_landscape" alt="" />
 </div>
</div>
<a data-toggle="modal" data-target="#showError" #showErrorMsg></a>
<div class="modal auth _premium_msg" id="showError">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <button type="button" #closeShowErrorMsg (click)="removeErrorBox()" class="close"
          data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="success-add">
          <p>{{error}}</p>
        </div>

        <div class="action-btn">
          <div>
            <button type="button" class="btn btn-primary yes" (click)="removeErrorBox()">Ok</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- <span style="color: red;">{{nextVideoPaidData.image}}</span> -->
<div class="episode_hover" id="NextAsset" *ngIf="nextVideoPaidData!='' && nextVideoPaidData!=undefined">
   <h5>Next Episode</h5>
  <div class="asset_episode_hover">
    <div class="asset_episode_img">
      <image-manipulation [assetName]="nextVideoPaidData.name" 
      [img]="nextVideoPaidData.image || nextVideoPaidData.thumbnail"
      [params]="{'width':330,'height':180,'crop':'fill','gravity':'center'}"
      [notFound]="notFoundImg">
    </image-manipulation>

      <a class="hover_icon"><img src="../../../assets/img/audio_play.png" /></a>
    </div>
    <div class="asset_episode_info">
      <h6>{{nextVideoPaidData.name}}</h6>
      <p>{{nextVideoPaidData.synopsis}}</p>
      <label>{{nextVideoPaidData.hours!='00' ? nextVideoPaidData.hours+'h ' : '' }} {{nextVideoPaidData.min!='00' ? nextVideoPaidData.min+'m ' : '' }} {{nextVideoPaidData.sec!='' ? nextVideoPaidData.sec+'s' : ''}}</label>
    </div> 
  </div>

</div>
 
<div class="episode_hover" id="PrevAsset"  *ngIf="preVideoPaidData!='' && nextVideoPaidData!=undefined">
  <h5>Previous Episode</h5>
  <div class="asset_episode_hover">
    <div class="asset_episode_img">
      <image-manipulation [assetName]="preVideoPaidData.name" 
      [img]="isCloudFrontType == false ?  preVideoPaidData?.channel_image : preVideoPaidData?.image_cloudfront_url"
      [params]="{'width':330,'height':180,'crop':'fill','gravity':'center'}"
      [notFound]="notFoundImg">
    </image-manipulation>
    
      <a class="hover_icon"><img src="../../../assets/img/audio_play.png" /></a>
    </div>
    <div class="asset_episode_info">
      <h6>{{preVideoPaidData.name}}</h6>
      <p>{{preVideoPaidData.synopsis}}</p>
      <label>{{preVideoPaidData.hours!='00' ? preVideoPaidData.hours+'h ' : '' }} {{preVideoPaidData.min!='00' ? preVideoPaidData.min+'m ' : '' }} {{nextVideoPaidData.sec!='' ? nextVideoPaidData.sec+'s' : ''}}</label>
    </div>
  </div>
</div>

<div class="episode_hover" id="SimilarAsset" *ngIf="sessionData !=''">
  <div id="accordion">

    <div class="card" *ngFor="let item of sessionData">
      <div class="card-header" id="headingOne{{item.season_number}}">
          <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#collapseOne' + item.season_number"  aria-expanded="true" aria-controls="collapseOne">
            Season {{item.season_number}}
          </button>
      </div>
  
      <div id="collapseOne{{item.season_number}}" class="collapse show" aria-labelledby="headingOne{{item.season_number}}" data-parent="#accordion">
        <div class="card-body">
          <div class="asset_episode_hover asset_tag free_asset">
            <div class="asset_episode_img">
              <img src="https://stage-image.prideplex.com/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5IjoicHJpZGVwbGV4L2ltZy9tb3ZpZS9NT1Y0OTc5MDk0OTcvNjRmNmZlYTE2ZWVmNC01NDcxMi5wbmciLCJlZGl0cyI6eyJub3JtYWxpemUiOmZhbHNlLCJncmF5c2NhbGUiOmZhbHNlLCJ3ZWJwIjp0cnVlLCJzaGFycGVuIjpmYWxzZSwicmVzaXplIjp7IndpZHRoIjozMzAsImhlaWdodCI6MTgwLCJmaXQiOiJjb3ZlciJ9fX0=" />
              <span><img src="../../../assets/img/free_tag_76x35.png" /></span>
              <a class="hover_icon"><img src="../../../assets/img/audio_play.png" /></a>
              <div class="asset_play_bar"><span style="width: 10%;"></span></div>
            </div>
            <div class="asset_episode_info">
              <h6>Episode Name</h6>
              <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available</p>
              <label>30m 6s</label>
            </div>
          </div>
          <div class="asset_episode_hover asset_tag paid_asset">
            <div class="asset_episode_img">
              <img src="https://stage-image.prideplex.com/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5IjoicHJpZGVwbGV4L2ltZy9tb3ZpZS9NT1Y0OTc5MDk0OTcvNjRmNmZlYTE2ZWVmNC01NDcxMi5wbmciLCJlZGl0cyI6eyJub3JtYWxpemUiOmZhbHNlLCJncmF5c2NhbGUiOmZhbHNlLCJ3ZWJwIjp0cnVlLCJzaGFycGVuIjpmYWxzZSwicmVzaXplIjp7IndpZHRoIjozMzAsImhlaWdodCI6MTgwLCJmaXQiOiJjb3ZlciJ9fX0=" />
              <span><img src="../../../assets/img/crown.png" /></span>
              <a class="hover_icon"><img src="../../../assets/img/audio_play.png" /></a>
            </div>
            <div class="asset_episode_info">
              <h6>Episode Name</h6>
              <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available</p>
              <label>30m 6s</label>
            </div>
          </div>
          <div class="asset_episode_hover asset_tag playing_asset">
            <div class="asset_episode_img">
              <img src="https://stage-image.prideplex.com/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5IjoicHJpZGVwbGV4L2ltZy9tb3ZpZS9NT1Y0OTc5MDk0OTcvNjRmNmZlYTE2ZWVmNC01NDcxMi5wbmciLCJlZGl0cyI6eyJub3JtYWxpemUiOmZhbHNlLCJncmF5c2NhbGUiOmZhbHNlLCJ3ZWJwIjp0cnVlLCJzaGFycGVuIjpmYWxzZSwicmVzaXplIjp7IndpZHRoIjozMzAsImhlaWdodCI6MTgwLCJmaXQiOiJjb3ZlciJ9fX0=" />
              <label>Now Playing</label>
              <a class="hover_icon"><img src="../../../assets/img/audio_play.png" /></a>
            </div>
            <div class="asset_episode_info">
              <h6>Episode Name</h6>
              <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available</p>
              <label>30m 6s</label>
            </div>
          </div>
          <div class="asset_episode_hover">
            <div class="asset_episode_img">
              <img src="https://stage-image.prideplex.com/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5IjoicHJpZGVwbGV4L2ltZy9tb3ZpZS9NT1Y0OTc5MDk0OTcvNjRmNmZlYTE2ZWVmNC01NDcxMi5wbmciLCJlZGl0cyI6eyJub3JtYWxpemUiOmZhbHNlLCJncmF5c2NhbGUiOmZhbHNlLCJ3ZWJwIjp0cnVlLCJzaGFycGVuIjpmYWxzZSwicmVzaXplIjp7IndpZHRoIjozMzAsImhlaWdodCI6MTgwLCJmaXQiOiJjb3ZlciJ9fX0=" />
              <a class="hover_icon"><img src="../../../assets/img/audio_play.png" /></a>
            </div>
            <div class="asset_episode_info">
              <h6>Episode Name</h6>
              <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available</p>
              <label>30m 6s</label>
            </div>
          </div>
          <div class="asset_episode_hover">
            <div class="asset_episode_img">
              <img src="https://stage-image.prideplex.com/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5IjoicHJpZGVwbGV4L2ltZy9tb3ZpZS9NT1Y0OTc5MDk0OTcvNjRmNmZlYTE2ZWVmNC01NDcxMi5wbmciLCJlZGl0cyI6eyJub3JtYWxpemUiOmZhbHNlLCJncmF5c2NhbGUiOmZhbHNlLCJ3ZWJwIjp0cnVlLCJzaGFycGVuIjpmYWxzZSwicmVzaXplIjp7IndpZHRoIjozMzAsImhlaWdodCI6MTgwLCJmaXQiOiJjb3ZlciJ9fX0=" />
              <a class="hover_icon"><img src="../../../assets/img/audio_play.png" /></a>
            </div>
            <div class="asset_episode_info">
              <h6>Episode Name</h6>
              <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available</p>
              <label>30m 6s</label>
            </div>
          </div>
          <div class="asset_episode_hover">
            <div class="asset_episode_img">
              <img src="https://stage-image.prideplex.com/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5IjoicHJpZGVwbGV4L2ltZy9tb3ZpZS9NT1Y0OTc5MDk0OTcvNjRmNmZlYTE2ZWVmNC01NDcxMi5wbmciLCJlZGl0cyI6eyJub3JtYWxpemUiOmZhbHNlLCJncmF5c2NhbGUiOmZhbHNlLCJ3ZWJwIjp0cnVlLCJzaGFycGVuIjpmYWxzZSwicmVzaXplIjp7IndpZHRoIjozMzAsImhlaWdodCI6MTgwLCJmaXQiOiJjb3ZlciJ9fX0=" />
              <a class="hover_icon"><img src="../../../assets/img/audio_play.png" /></a>
            </div>
            <div class="asset_episode_info">
              <h6>Episode Name</h6>
              <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available</p>
              <label>30m 6s</label>
            </div>
          </div>
          <div class="asset_episode_hover">
            <div class="asset_episode_img">
              <img src="https://stage-image.prideplex.com/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5IjoicHJpZGVwbGV4L2ltZy9tb3ZpZS9NT1Y0OTc5MDk0OTcvNjRmNmZlYTE2ZWVmNC01NDcxMi5wbmciLCJlZGl0cyI6eyJub3JtYWxpemUiOmZhbHNlLCJncmF5c2NhbGUiOmZhbHNlLCJ3ZWJwIjp0cnVlLCJzaGFycGVuIjpmYWxzZSwicmVzaXplIjp7IndpZHRoIjozMzAsImhlaWdodCI6MTgwLCJmaXQiOiJjb3ZlciJ9fX0=" />
              <a class="hover_icon"><img src="../../../assets/img/audio_play.png" /></a>
            </div>
            <div class="asset_episode_info">
              <h6>Episode Name</h6>
              <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available</p>
              <label>30m 6s</label>
            </div>
          </div>
          <div class="asset_episode_hover">
            <div class="asset_episode_img">
              <img src="https://stage-image.prideplex.com/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5IjoicHJpZGVwbGV4L2ltZy9tb3ZpZS9NT1Y0OTc5MDk0OTcvNjRmNmZlYTE2ZWVmNC01NDcxMi5wbmciLCJlZGl0cyI6eyJub3JtYWxpemUiOmZhbHNlLCJncmF5c2NhbGUiOmZhbHNlLCJ3ZWJwIjp0cnVlLCJzaGFycGVuIjpmYWxzZSwicmVzaXplIjp7IndpZHRoIjozMzAsImhlaWdodCI6MTgwLCJmaXQiOiJjb3ZlciJ9fX0=" />
              <a class="hover_icon"><img src="../../../assets/img/audio_play.png" /></a>
            </div>
            <div class="asset_episode_info">
              <h6>Episode Name</h6>
              <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available</p>
              <label>30m 6s</label>
            </div>
          </div>
          <div class="asset_episode_hover">
            <div class="asset_episode_img">
              <img src="https://stage-image.prideplex.com/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5IjoicHJpZGVwbGV4L2ltZy9tb3ZpZS9NT1Y0OTc5MDk0OTcvNjRmNmZlYTE2ZWVmNC01NDcxMi5wbmciLCJlZGl0cyI6eyJub3JtYWxpemUiOmZhbHNlLCJncmF5c2NhbGUiOmZhbHNlLCJ3ZWJwIjp0cnVlLCJzaGFycGVuIjpmYWxzZSwicmVzaXplIjp7IndpZHRoIjozMzAsImhlaWdodCI6MTgwLCJmaXQiOiJjb3ZlciJ9fX0=" />
              <a class="hover_icon"><img src="../../../assets/img/audio_play.png" /></a>
            </div>
            <div class="asset_episode_info">
              <h6>Episode Name</h6>
              <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available</p>
              <label>30m 6s</label>
            </div>
          </div>
          <div class="asset_episode_hover">
            <div class="asset_episode_img">
              <img src="https://stage-image.prideplex.com/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5IjoicHJpZGVwbGV4L2ltZy9tb3ZpZS9NT1Y0OTc5MDk0OTcvNjRmNmZlYTE2ZWVmNC01NDcxMi5wbmciLCJlZGl0cyI6eyJub3JtYWxpemUiOmZhbHNlLCJncmF5c2NhbGUiOmZhbHNlLCJ3ZWJwIjp0cnVlLCJzaGFycGVuIjpmYWxzZSwicmVzaXplIjp7IndpZHRoIjozMzAsImhlaWdodCI6MTgwLCJmaXQiOiJjb3ZlciJ9fX0=" />
              <a class="hover_icon"><img src="../../../assets/img/audio_play.png" /></a>
            </div>
            <div class="asset_episode_info">
              <h6>Episode Name</h6>
              <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available</p>
              <label>30m 6s</label>
            </div>
          </div>
          <div class="asset_episode_hover">
            <div class="asset_episode_img">
              <img src="https://stage-image.prideplex.com/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5IjoicHJpZGVwbGV4L2ltZy9tb3ZpZS9NT1Y0OTc5MDk0OTcvNjRmNmZlYTE2ZWVmNC01NDcxMi5wbmciLCJlZGl0cyI6eyJub3JtYWxpemUiOmZhbHNlLCJncmF5c2NhbGUiOmZhbHNlLCJ3ZWJwIjp0cnVlLCJzaGFycGVuIjpmYWxzZSwicmVzaXplIjp7IndpZHRoIjozMzAsImhlaWdodCI6MTgwLCJmaXQiOiJjb3ZlciJ9fX0=" />
              <a class="hover_icon"><img src="../../../assets/img/audio_play.png" /></a>
            </div>
            <div class="asset_episode_info">
              <h6>Episode Name</h6>
              <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available</p>
              <label>30m 6s</label>
            </div>
          </div>
          <div class="asset_episode_hover">
            <div class="asset_episode_img">
              <img src="https://stage-image.prideplex.com/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5IjoicHJpZGVwbGV4L2ltZy9tb3ZpZS9NT1Y0OTc5MDk0OTcvNjRmNmZlYTE2ZWVmNC01NDcxMi5wbmciLCJlZGl0cyI6eyJub3JtYWxpemUiOmZhbHNlLCJncmF5c2NhbGUiOmZhbHNlLCJ3ZWJwIjp0cnVlLCJzaGFycGVuIjpmYWxzZSwicmVzaXplIjp7IndpZHRoIjozMzAsImhlaWdodCI6MTgwLCJmaXQiOiJjb3ZlciJ9fX0=" />
              <a class="hover_icon"><img src="../../../assets/img/audio_play.png" /></a>
            </div>
            <div class="asset_episode_info">
              <h6>Episode Name</h6>
              <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available</p>
              <label>30m 6s</label>
            </div>
          </div>
          <div class="asset_episode_hover">
            <div class="asset_episode_img">
              <img src="https://stage-image.prideplex.com/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5IjoicHJpZGVwbGV4L2ltZy9tb3ZpZS9NT1Y0OTc5MDk0OTcvNjRmNmZlYTE2ZWVmNC01NDcxMi5wbmciLCJlZGl0cyI6eyJub3JtYWxpemUiOmZhbHNlLCJncmF5c2NhbGUiOmZhbHNlLCJ3ZWJwIjp0cnVlLCJzaGFycGVuIjpmYWxzZSwicmVzaXplIjp7IndpZHRoIjozMzAsImhlaWdodCI6MTgwLCJmaXQiOiJjb3ZlciJ9fX0=" />
              <a class="hover_icon"><img src="../../../assets/img/audio_play.png" /></a>
            </div>
            <div class="asset_episode_info">
              <h6>Episode Name</h6>
              <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available</p>
              <label>30m 6s</label>
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
</div>

<div class="modal" id="frequently-modal"  *ngIf="!isAssetImageVisible && !isDeviceNotAllowed && !isPlaybackError">
  <div class="modal-dialog">
      <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
              <h6 class="text-white">Frequently Played</h6>
              <button type="button" id="closeFrequentlyPlayed" class="close" data-dismiss="modal">
                  <img alt="" src="./assets/img/new-player/close-icon.png" /></button>
          </div>

          <!-- Modal body -->
          <div class="modal-body frequently-scroll">
            <!-- *ngIf="moreLikeData.result && moreLikeData.result.length>0" -->
              <ul class="frequently_asset">
                <li class="frequently_current_play">
                  <a data-dismiss="modal"> 
                      <image-manipulation [assetName]="streamInfo.name" 
                          *ngIf="streamInfo"
                          [img]="streamInfo?.image_square"
                          [params]="{'width':200,'height':200,'crop':'fill','gravity':'center'}"
                          notFound="square">
                      </image-manipulation>
                      <span>Currently<br/>Playing</span>
                  </a>
                </li>
                <ng-container *ngIf="freqPlayedData"  >
                  <li *ngFor="let item of freqPlayedData">
                      <a (click)="PlayMoreLikeAsset(item)" data-dismiss="modal"> 
                          <image-manipulation [assetName]="item.name" 
                              [img]="item.image_square"
                              [params]="{'width':200,'height':200,'crop':'fill','gravity':'center'}"
                              notFound="square">
                          </image-manipulation>
                      </a>
                  </li>
                </ng-container>
              </ul>
          </div>
      </div>
  </div>
</div>

<div class="modal" id="more_like_modal" *ngIf="!isAssetImageVisible && !isDeviceNotAllowed && !isPlaybackError">
  <div class="modal-dialog">
      <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
              <button type="button" id="closeMoreLikeThis" class="close"data-dismiss="modal">
                  <img src="./assets/img/new-player/currently-playing-icon.png" /></button>
              <h6 class="text-white">More Like This</h6>
          </div>

        <!-- Modal body -->
          <div class="modal-body other_assests">
              <div class="row">
                  <div class="col-md-12">
                      <div class="view_more">
                        <!-- *ngIf="moreLikeData.result && moreLikeData.result.length>0" -->
                          <div *ngIf="moreLikeData" class="_v_all_parent new_asset_hover_type" >
                              
                              <div *ngFor="let item of moreLikeData" (click)="PlayMoreLikeAsset(item)" class="_view_img_list_p default_assests_view assets_hover" data-dismiss="modal">
                                <div 
                                style="position: relative;">
                                <div class="new_asset">
                                    <div  class="new_asset_img">
                                      <image-manipulation [assetName]="item.name" 
                                      [img]="item?.image_landscape" [params]="{ 'width': 304, 'height': 171 }"
                                      notFound="landscape"
                                      ></image-manipulation>

                                            <a class="_premium"
                                            *ngIf="strToLower(item.content_availability) == 'paid' && item.hide_tag == '0' ">
                                            <img class="crown" [src]="paidIcon">
                                            </a>

                                            <a class="_free_tag"
                                                *ngIf="strToLower(item.content_availability) == 'free' && item.hide_tag == '0' ">
                                                <img class="crown" [src]="freeIcon">
                                            </a>

                                            <a class="_free_tag"
                                                *ngIf="strToLower(item.content_availability) == 'no tag' && item.hide_tag == '0' ">
                                            </a>
                                        
                                        
                                    </div>
                                    
                                    
                                    <app-new-hover
                                      [item]="item"
                                      [page_view]="'Screen_Home'"
                                    ></app-new-hover>
                                </div>
                            </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>