import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AssetsService } from 'src/app/core';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-watchlist',
  templateUrl: './watchlist.component.html',
  styleUrls: ['./watchlist.component.css']
})
export class WatchlistComponent implements OnInit {
  watchlistEditButton : boolean;
  watchlistData: any[];
  selectedAssets: any[] = [];
  @ViewChildren('checkboxInput') checkboxInputs: QueryList<ElementRef>;
  @ViewChild('selectAllCheckbox') selectAllCheckbox: ElementRef;
  freeIcon: string = environment.freeIcon;
  paidIcon: string = environment.paidIcon;

  constructor(
    private assetsService: AssetsService,
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.getWatchlist();
  }

  getWatchlist() {
    this.assetsService.getWatchlist().subscribe(watchlistRes => {
      this.watchlistData = watchlistRes.result;
    }, (error) => {
      console.error('error fetching watchlist: ', error)
    });
  }

  goToDetailPage(assetData: any) {
    this.commonService.goToDetail(assetData);
  }

  addRemoveFromSelectedAssets(item) {
    // console.log('addRemoveFromSelectedAssets: ', item);
    let index = this.selectedAssets.findIndex(ele => ele === item.code);
    console.log(index);
    if (index === -1) {
      this.selectedAssets.push(item.code);
    } else {
      this.selectedAssets.splice(index, 1)
    }
    this.checkSelectedAssetsLength();
    // console.log('selected Assets in addRemove: ',this.selectedAssets);
    // console.log('------------------------------------------------------------------------------------');
  }

  handleSelectAllClick(event: Event) {
    if(this.selectAllCheckbox.nativeElement.checked) {
      this.selectedAssets = [];
      this.checkboxInputs.forEach((ele, index) => {
        ele.nativeElement.checked = true;
        this.addRemoveFromSelectedAssets(this.watchlistData[index]);
      });
    } else {
      this.checkboxInputs.forEach((ele, index) => {
        ele.nativeElement.checked = false;
        this.addRemoveFromSelectedAssets(this.watchlistData[index]);
      });
    }
  }
  
  clickAssetCheckbox(index: number) {
    if(!this.watchlistEditButton) {
      return
    }
    const checkbox = this.checkboxInputs.toArray()[index];
    if (checkbox) {
      checkbox.nativeElement.click();
    }
  }

  addToWatchlistArray(item) {
    this.watchlistData.push(item);
  }

  removeFromWatchlistArray(item) {
    let index = this.watchlistData.findIndex(ele => ele.code === item.code);
    if (index !== -1) {
      this.watchlistData.splice(index, 1);
    }
  }

  watchlistEdit(){
    this.selectedAssets = [];
    this.watchlistEditButton = !this.watchlistEditButton;
  }

  checkSelectedAssetsLength() {
    if(this.selectedAssets.length === this.watchlistData.length) {
      this.selectAllCheckbox.nativeElement.checked = true;
    } else {
      this.selectAllCheckbox.nativeElement.checked = false;
    }
  }

  deleteFromWatchlist() {
    let params = {
      code: [...this.selectedAssets]
    }

    this.assetsService.deleteFromWatchlist(params).subscribe(deleteRes => {
      if(deleteRes.response_code === 200) {
        let filteredWatchlistData = this.watchlistData.filter(item => {
          return !this.selectedAssets.includes(item.code);
        });
        this.watchlistData = [ ...filteredWatchlistData ];
        // console.log('watchlistData: ', this.watchlistData);
        this.watchlistEditButton = false;
        const modalMessageElement = document.querySelector("#removeWatchlistModal .after-login p");
        if(this.selectedAssets.length > 1) {
          modalMessageElement.textContent = ` ${this.selectedAssets.length} Assets have been removed from the list`;
        } else {
          modalMessageElement.textContent = 'Asset has been removed from the list';
        }
        document.getElementById('removeWatchlistModal').style.display = 'block';
        setTimeout(() => {
          document.getElementById('removeWatchlistModal').style.display = 'none';
        }, 3500);
        this.selectedAssets = [];
      }
    }, (error) => {
      console.error('error deleting watchlist items: ', error)
    })
  }

  strToLower(str: string) {
    return str.toLowerCase();
  }
  
}
