import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SlideInOutAnimation } from './../../../shared/animation';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationService, UserService } from 'src/app/core';
import { environment } from 'src/environments/environment';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { AnimationOptions } from 'ngx-lottie';
import { successOptions, errorOptions } from 'src/app/shared/lottie-config';
import { CommonService } from 'src/app/core/services/common.service';
import { customOptionsForAvatarSelector } from 'src/app/shared/owl-slider-config';
import { DOCUMENT } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css'],
  animations: [SlideInOutAnimation],
  encapsulation: ViewEncapsulation.Emulated
})
export class EditProfileComponent implements OnInit {
  is_kids: boolean;
  url: any = '../../../assets/img/user.png';
  avtar_array = [
    '../../../assets/img/profile/female.png',
    '../../../assets/img/profile/female2.png',
    '../../../assets/img/profile/male.png',
    '../../../assets/img/profile/male2.png',
    '../../../assets/img/profile/kids.png',
    '../../../assets/img/profile/kids2.png'
  ];
  message: any;
  viewing_restriction_arr = ['U', 'U/A 7+', 'U/A 13+', 'U/A 16+', 'A']
  animationState = 'in';
  profile_id: any;
  profile_data: any;
  editForm: FormGroup;
  is_submit: boolean;
  session_data: any;
  basePath: any;
  profileLockStatus: any;

  disablePinForm: FormGroup;
  verifyPinForm: FormGroup;
  isPinSubmit: boolean = false;
  isSubmitPinValid: boolean = false;
  api_error: any;
  api_msg: any;
  timezone: any;
  locale: any;
  timezoneForGA: any;
  timeZoneName: any;
  username: any;
  isShowPinForm: boolean = false;
  isDeleteLockedProfile: boolean = false;
  isVerifyPinSubmit: boolean = false;
  isVerifySubmitPinValid: boolean = false;
  isProfileNameEditable: boolean = false;
  editSuccess: boolean = false;
  language: any;
  successOptions: AnimationOptions = successOptions;
  errorOptions: AnimationOptions = errorOptions;
  isChooseAvatar: boolean = true;
  selectedAvatar: string = '/avatar/default.jpg';
  customOptions = customOptionsForAvatarSelector;
  @ViewChild('owlCarousel') owlCarousel: any;
  profileAvatars: any[];
  deleteSuccess: boolean;
  viewingRestriction: any;
  isSetPinSuccess: boolean;
  currentProfileId: string;

  constructor(private userService: UserService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public router: Router,
    private googleEvent: EventTrackingService,
    public commonService: CommonService,
    private modalService: NgbModal,
    private navigationService: NavigationService,
    @Inject(DOCUMENT) private _document) {

    this.route.paramMap.subscribe(paramMap => {
      this.profile_id = paramMap.get('id');
    })

    console.log('profile_id', this.profile_id);


    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Kolkata' ? 'Asia/Calcutta' : Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.timezoneForGA = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.locale = Intl.DateTimeFormat().resolvedOptions().locale;
    this.timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZoneName;
  }

  get f() {
    return this.editForm.controls;
  }

  ngOnInit(): void {
    this._document.body.classList.add('profile-bg');
    this.language = this.commonService.languageChange();
    this.basePath = environment.api_url.replace('api/v1/', '');
    let params = { profile_id: this.profile_id };
    this.currentProfileId = localStorage.getItem('profile_id');
    localStorage.setItem('profile_id', this.profile_id);
    
    this.getProfileData();

    this.disablePinForm = this.fb.group({
      'profile_pin': ['', [Validators.required, Validators.minLength(4)]],
      'profile_lock': 'false'
    });

    this.verifyPinForm = this.fb.group({
      'profile_pin': ['', [Validators.required, Validators.minLength(4)]]
    });

    this.editForm = this.fb.group({
      // 'profile_id':[this.profile_data._id],
      'profile_name': ['', [Validators.required]],
      'profile_image': [''],
      'is_child': [''],
      'viewing_restriction': [''],
    });
    // $('app-header').remove();
    // $('app-footer').remove();

  }

  ngAfterViewInit() {
    this.getProfileAvatars();
  }

  ngOnDestroy() {
    // remove the class form body tag
    this._document.body.classList.remove('profile-bg');
    // localStorage.setItem('profile_id', this.currentProfileId);
  }

  getProfileData() {
    let url = 'profile/get';
    this.userService.getPrfile(url).subscribe(data => {
      console.log(data);
      this.profile_data = data.result;
      this.profileLockStatus = this.profile_data?.profile_lock;
      this.is_kids = this.profile_data?.is_child;
      this.viewingRestriction = this.profile_data?.is_child ? 'A' : this.profile_data?.viewing_restriction.slice(-1)[0]; // Get the last element of the viewing_restriction array
      this.disablePinForm.get('profile_pin').setValue(this.profile_data?.profile_pin);
      this.initEditForm();
      if (this.profile_data?.is_child == true) {
        document.getElementById("lock").classList.add("switch-disable");
      } else {
        if (document.getElementById("lock").hasAttribute('switch-disable')) {
          document.getElementById("lock").classList.remove("switch-disable")
        }
      }
    }, (error) => {
      console.log('errorrrrr', error);
    });
  }

  setPinSuccess(msg: string) {
    console.log('SEt Pin Success : ', msg);
    this.getProfileData();
    this.isSetPinSuccess = true;
    this.api_msg = msg;
    setTimeout(() => {
      this.isSetPinSuccess = false;
    }, 2500);
  }

  changePinSuccess(msg: string) {
    console.log('MSG IN CHANGE PIN SUCCESS: ', msg);
    this.getProfileData();
    this.isSetPinSuccess = true;
    this.api_msg = msg;
    setTimeout(() => {
      this.isSetPinSuccess = false;
    }, 2500);
  }

  setProfileNameEditabe(flag: boolean) {
    this.isProfileNameEditable = flag;
  }

  setProfileName() {
    this.profile_data.profile_name = this.editForm.get('profile_name').value;
    this.isProfileNameEditable = false;
  }

  resetProfileName() {
    this.editForm.get('profile_name').setValue(this.profile_data?.profile_name);
    this.setProfileNameEditabe(false);
    // this.editForm.get('profile_name').setValue;
  }

  toggleChooseAvatar() {
    this.isChooseAvatar = !this.isChooseAvatar;
  }

  // saveProfileName() {
  //   this.editForm.get('profile_name').setValue('')
  // }

  getProfileAvatars() {
    let url = 'profile/avatar';
    this.userService.getProfileAvatar(url).subscribe(data => {
      // Below code is to get the user's selected avatar from the result and move it to the 0th index, so that the owl carousel displays it in the center.
      const tempResult = data.result;
      const coolAvatar = tempResult.find(item => item.avatar_image === this.profile_data?.profile_image);
      this.profileAvatars = coolAvatar ? [coolAvatar, ...tempResult.filter(item => item !== coolAvatar)] : tempResult;
    })
  }

  saveAvatarChange() {
    if(this.owlCarousel) {
      const centerSlide = this.owlCarousel.slidesData.find(element => element.isCentered === true);
      let centerSlideId = centerSlide.id.replace('cloned-', '');
      this.profile_data.profile_image = centerSlideId;
      this.editForm.controls['profile_image'].setValue(centerSlideId);
      this.toggleChooseAvatar();
    }
  }

  initEditForm() {
    this.editForm = this.fb.group({
      // 'profile_id':[this.profile_data?._id],
      'profile_name': [this.profile_data?.profile_name, [Validators.required, this.noWhitespaceValidator]],
      'profile_image': [this.profile_data?.profile_image],
      'is_child': [this.profile_data?.is_child],
      'viewing_restriction': [this.profile_data?.viewing_restriction[this.profile_data?.viewing_restriction.length - 1]],
      'profile_lock': [this.profile_data?.profile_lock]
    });

    if (!this.is_kids) {
      this.editForm.get('viewing_restriction').setValidators([Validators.required]);
      this.editForm.get('viewing_restriction').updateValueAndValidity();
    } else {
      this.animationState = 'out';
    }
  }

  // Custom validator to check for whitespace
  noWhitespaceValidator(control: any) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  isKids() {
    this.is_kids = !this.is_kids;
    if (this.animationState === 'out') {
      this.animationState = 'in';
      this.editForm.get('viewing_restriction').reset();
      this.editForm.get('viewing_restriction').setValidators([Validators.required]);
      this.editForm.get('viewing_restriction').setValue(this.viewingRestriction);
      this.editForm.get('viewing_restriction').updateValueAndValidity();
      this.editForm.get('is_child').setValue(false);
      if(this.profile_data?.profile_lock) {
        this.profileLockStatus = true;
        this.editForm.get('profile_lock').setValue(true);
      }
    } else {
      this.animationState = 'out';
      this.editForm.get('viewing_restriction').reset();
      this.editForm.get('viewing_restriction').clearValidators();
      this.editForm.get('viewing_restriction').setValue('U/A 7+');
      this.editForm.get('viewing_restriction').updateValueAndValidity();
      this.editForm.get('is_child').setValue(true);
      if(this.profile_data?.profile_lock) {
        this.profileLockStatus = false;
        this.editForm.get('profile_lock').setValue(false);
      }

      // if (this.profile_data?.profile_pin_status == true) {
      //   this.isShowPinForm = true;
      //   this.profileLockStatus = false;
      //   let params = this.disablePinForm.value;
      //   // params['profile_pin'] = this.profile_data?.profile_pin;
      //   let url = 'profile/disable/pin';
      //   console.log(params);
      //   this.userService.unsetPrfilePin(url, params).subscribe(data => {
      //   }, (error) => {
      //     console.log('errorrrrr', error);
      //   });
      // }
    }
  }
  // selectAvtar(avtar) {
  //   this.url = avtar;
  // }
  editSubmit() {
    this.is_submit = true;
    console.log(this.editForm);
    if (this.editForm.invalid) {
      console.log('Edit Form Invalid');
      return;
    }
    this.saveAvatarChange();
    let params = this.editForm.value;
    let url = 'profile/update';
    this.userService.editPrfile(url, params).subscribe(data => {
      if (data.response_code == 200) {
        this.editSuccess = true;
        this.api_msg = data.message;
        const username = JSON.parse(localStorage.getItem('userInfo'))?.result?.user?.username;
        this.googleEvent.eventEmitter('Edit Profile', 'Edit Profile', 'Edit Profile', 0, username, username + ' - ' + 'Edit Profile');
        setTimeout(() => {
          this.router.navigate(['manage-profile']);
          // this.navigationService.back();
        }, 2500);
      }
    }, (error) => {
      console.log('errorrrrr', error);
    });
  }

  checkLockStatus(content) {
    if (this.profile_data?.profile_lock == false) {
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: true }).result.then((result) => {
      }, (reason) => {
      });
    } else {
      this.profileLockStatus = !this.profileLockStatus;
      this.f.profile_lock.setValue(this.profileLockStatus);
    }
  }


  changePin(content) {
    // this.router.navigate(['manage-profile/change-pin'])
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: true }).result.then((result) => {
    }, (reason) => {
    });
  }

  closeModal() {
    try {
      document.querySelector("ngb-modal-window").classList.remove("show");
      document.querySelector("ngb-modal-backdrop").classList.remove("show");
      document.getElementById('nexgTV-app').click();
      document.querySelectorAll(".modal-backdrop").forEach(el => el.remove())
      document.querySelectorAll(".d-block").forEach(el => el.remove())
      document.body.style.overflow = "scroll";
    } catch (e) {

    }  
  }

  openDeleteProfileModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: true, windowClass:'delete_popup' }).result.then((result) => {
    }, (reason) => {
    });
  }

  deleteProfile() {
      let url = 'profile/delete';
      this.userService.deletePrfile(url).subscribe(data => {
        localStorage.setItem('profile_id', '');
        if (data.response_code == 200) {
          this.closeModal();
          this.deleteSuccess = true;
          this.api_msg = data.message;
          setTimeout(() => {
            this.router.navigate(['manage-profile'])
          }, 2500);
        }
      }, (error) => {
        console.log('errorrrrr', error);
      });


  }


  verifyPinSubmit() {
    this.isVerifyPinSubmit = true;
    if (this.verifyPinForm.invalid) {
      return;
    } else {
      this.isVerifySubmitPinValid = true;
      let params = this.verifyPinForm.value;
      let url = 'profile/verify/pin';
      console.log(params);
      this.userService.verifyPrfilePin(url, params).subscribe(data => {
        console.log("veryfied data", data);
        if (data.response_code == '200') {
          this.isDeleteLockedProfile = false;
          this.api_msg = data.message;
          let url = 'profile/delete';
          this.userService.deletePrfile(url).subscribe(data => {
            this.isVerifyPinSubmit = false;
            this.isVerifySubmitPinValid = false;
            localStorage.setItem('profile_id', '');
            this.router.navigate(['manage-profile'])
          }, (error) => {
            console.log('errorrrrr', error);
          });
        }
      }, (error) => {
        this.api_error = error.message;
      });
    }

  }


  onVerifyChange(pin) {
    let fpin = pin;
    this.verifyPinForm.get("profile_pin").setValue(fpin);
  }

  get pinVerifyFormControls() {
    return this.verifyPinForm.controls;
  }

  // disable pin unlock profile 
  // Below function is not used so that the pin is disabled only after clicking the save button. Which is handled by the profile_lock key in the editForm.
  disablePinSubmit() {
    this.isPinSubmit = true;
    // this.disablePinForm.get('profile_pin').setValue(this.profile_data?.profile_pin);

    if (this.disablePinForm.invalid) {
      return;
    } else {
      this.isSubmitPinValid = true;
      let params = this.disablePinForm.value;
      // params['profile_pin'] = this.profile_data?.profile_pin;
      let url = 'profile/disable/pin';
      console.log(params);
      this.userService.unsetPrfilePin(url, params).subscribe(data => {
        this.api_msg = data.message;
        this.isShowPinForm = false;
        this.profileLockStatus = false;
      }, (error) => {
        this.api_error = error.message;
      });
    }

  }

  onPinChange(pin) {
    let fpin = pin;
    this.disablePinForm.get("profile_pin").setValue(fpin);
  }

  get pinFormControls() {
    return this.disablePinForm.controls;
  }

  backButton(){
    this.router.navigate(['manage-profile']);
    // this.navigationService.back();
  }

}
