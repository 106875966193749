<div class="container mt-4">
    <div class="d-flex align-items-center justify-content-between">
        <img src="../../../assets/img/logo.png" class="profile-logo-width" />
        <a href="javascript:void(0);" (click)="backButton()">
            <img src="../../../assets/img/Icon/close.svg" />
       </a>
    </div>
    <div  class="profile-align" *ngIf="!addSuccess">
        <div class="row">
            <div class="col-md-12 text-center">
                <label class="login_title login-mb-40">{{language.addProfile}}</label>
            </div>
            <div class="col-md-12">
                <form [formGroup]="addForm" (submit)="addSubmit()">
                    <div class="add_profile_width text-center">
                        <div class="col-md-12">
                            <div class="profile_carousel" *ngIf="isChooseAvatar">
                                <owl-carousel-o  #owlCarousel [options]="customOptions" >
                                    <ng-template  *ngFor="let avatar of profileAvatars" [id]="avatar.avatar_image" carouselSlide>
                                        <div class="choose-avtar-slider">
                                            <img style="border-radius: 50%" [src]="avatar.avatar_image" alt="" title="" />
                                            <a class="select-avtar-btn" *ngIf="isChooseAvatar" href="javascript:;" (click)="setSelectedAvatar()">
                                                <!-- <small class="text-white">{{language.select}}</small> -->
                                                <img src="../../../../assets/img/Icon/profile-check.svg" />
                                            </a>
                                        </div>
                                    </ng-template>  

                                </owl-carousel-o>
                                <div class="text-center popup-error-height">
                                    <small
                                        *ngIf="is_submit && f.profile_image.invalid && f.profile_image.hasError('required')"
                                        class="submit-error">{{language.pleaseSelectProfileAvatar}}</small>
                                </div> 
                            </div>
                            <div class="add_profile_block" *ngIf="!isChooseAvatar">
                                <div class="add_profile">
                                    <img class="editProfileImage" [src]="selectedAvatar" alt="" title="" />
                                    <a class="change-avtar-btn" *ngIf="!isChooseAvatar" href="javascript:;" (click)="toggleChooseAvatar()">
                                        <!-- <small class="text-white">{{language.changeAvatar}}</small> -->
                                        <img src="../../../../assets/img/Icon/profile-edit.svg" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="transparent_form">
                            <div class="input_line">
                                <input type="text" class="form-control" formControlName="profile_name" placeholder="Enter profile name" />
                            </div>
                            <div class="text-right popup-error-height">
                                <app-error
                                *ngIf="is_submit && f.profile_name.invalid"
                                [message]="language.pleaseEnterValidProfileName"></app-error>
                            </div> <!-- && f.profile_name.hasError('required') -->
                            <div class="d-flex justify-content-between align-items-center switch-label mb-5">
                                <label class="text-white switch-label-tag">{{language.kidsProfile}}</label>
                                <label class="switch" (change)="isKids()" [class]="is_kids == true ? 'toggle-active' : '' ">
                                    <input type="checkbox" [checked]="true" formControlName="is_child">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                        
                        <div class="mb-4 mt-4">
                            <button type="submit" class="btn btn-default btn_height btn_hover profile-btn">
                                <span>{{language.save}}</span>
                            </button>
                            <!-- <button class="btn btn-default mt-4 btn_height btn_hover profile-btn" (click)="backButton()">
                                <span>{{language.cancel}}</span>
                            </button> -->
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div  class="profile-align" *ngIf="addSuccess">
        <div class="success-add text-center">
            <label class="login_title">{{language.hi}} {{addForm.value['profile_name']}}!</label>
            <h4 class="_verify_t text-white" style="font-weight: 400;">{{api_msg}}</h4>
            <div class="lottie-icon-block">
                <div class="lottie-icon text-center">
                    <ng-lottie [options]="successOptions"></ng-lottie>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-avtar-modal (avtarEvent)="selectAvtar($event)"></app-avtar-modal> -->
