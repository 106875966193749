import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { serverErrorOptions } from 'src/app/shared/lottie-config';
import { NavigationService } from 'src/app/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.css']
})
export class ServerErrorComponent implements OnInit {
  serverErrorOptions: AnimationOptions = serverErrorOptions;

  constructor(
    private location: Location,
    private navigationService: NavigationService
  ) { }

  ngOnInit(): void {
  }

  previousPage(){
    this.navigationService.back();
  }
}
