import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  langugae: any;
  currentYear: number = new Date().getFullYear();
  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
    this.langugae = this.commonService.languageChange();
    let Currenturl =window.location.href;
    if (Currenturl.includes("/manage-profile")) {
      let tagName = document.getElementById('footer');
      tagName.classList.add("no_click");
    }else{
      let tagName = document.getElementById('footer');
      tagName.classList.remove("no_click");
    }
  }

  flipLogoPlay(){
    console.log('Logo')
  }

  flipLogoApp(){

  }

}
