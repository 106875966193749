import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from "src/app/core/services/user.service";
import { CatlougeTypeConstants } from 'src/app/core/constants';
import { AssetsService } from "../../core/services/assets.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from "src/app/core/services/common.service";


@Component({
  selector: 'app-campaign-banner',
  templateUrl: './campaign-banner.component.html',
  styleUrls: ['./campaign-banner.component.css']
})
export class CampaignBannerComponent implements OnInit {

  catlouge = CatlougeTypeConstants.catlogue;
  @Input() data: any = {};
  landingPage: any;
  episodeDeatil: any;
  imgType: any;
  showImg: any;
  width: string;
  bannerWidth: boolean;
  public userInfo: any;
  isRedir: boolean = false;
  categoryImage: any;
  thumbnailBannerStretchedLandscape: any;
  thumbnail: any;
  assetImgParam: any;



  constructor(private router: Router,
    public UserService: UserService,
    public AssetsService: AssetsService,
    private modalService: NgbModal,
    public commonService: CommonService) { }

  ngOnInit(): void {
    this.data = this.data;
    this.landingPage = this.data.landing_page;
    this.imgType = this.data.thumbnail_type.slug;
    switch (this.imgType) {
      case 'stretched-landscape':
        this.showImg = this.data.image_stretched_landscape;
        this.bannerWidth = true;
        this.assetImgParam = { "crop": 'fill', 'width': 860, 'height': 376 };
        break;
      case 'banner':
        this.bannerWidth = false;
        this.showImg = this.data.image_landscape;
        this.assetImgParam = { "crop": 'fill' };
        break;
      case 'narrow-landscape':
          this.bannerWidth = false;
          this.showImg = this.data.image_narrow_landscape;
          this.assetImgParam = { "crop": 'fill' };
          break;
      default:
        this.showImg = this.data.image_landscape;
        this.assetImgParam = { "crop": 'fill' };
        break;
    }
  }


  redirectUrl(content) {
    this.userInfo = localStorage.getItem('userInfo');
    if (this.userInfo) {
      switch (this.landingPage) {
        case '__url':
          this.landingUrl();
          break;
        case '__app_page':
          this.landingAppPage();
          break;
        case '__sub_category':
          this.landingSubCategory();
          break;
        case '__asset':
          this.landingAsset();
          break;
        case '__webviewurl':
          this.landingVotingPage();
          break;
        default: this.router.navigateByUrl('/');
          break;

      }
    } else {
      this.commonService.setBeforeLoginState(this.data);
      // this.router.navigateByUrl('/login');
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => {
      });
    }
  }

  landingUrl() {
    let url = this.data.url;
    window.open(url, '_blank').focus();
  }
  landingAppPage() {
    let appPage = this.data.app_page;

    switch (appPage) {
      case 'about':
        this.router.navigateByUrl('/aboutus');
        break;
      case 'faq':
        this.router.navigateByUrl('/contactus');
        break;
      case 'help':
        this.router.navigateByUrl('/contactus');
        break;
      case 'my-profile':
        this.router.navigateByUrl('/myaccount');
        break;
      case 'theme-setting':
        this.router.navigateByUrl('/');
        break;
      case 'subscription':
        this.router.navigateByUrl('/subscribe');
        break;
      case 'activation-code':
        this.router.navigateByUrl('/subscribe');
        break;
      case 'watchlist':
        this.router.navigateByUrl('/playlist');
        break;

      default: this.router.navigateByUrl('/');
        break;

    }
  }
  landingSubCategory() {
    if (this.data.sub_category_id) {
      let input_id = this.data.category_id;
      let r_url = '/viewall';
      if (this.data.sub_category_id) {
        input_id = this.data.category_id;
        r_url = '/subcategory';
      }
      let viewAlldParams = {
        banner: "campaign_banner",
        sub_category_id: this.data.sub_category_id,
      }


      viewAlldParams['type'] = this.data.tab_slug;
      localStorage.setItem('banner', 'campaign_banner');
      this.router.navigate([r_url], { queryParams: viewAlldParams })

    } else {
      let categoryId = this.data.category_id;
      let viewalltabDataParams = {
        banner: "campaign_banner",
        "categoryId": categoryId,
        "type": 'home'
      }
      this.router.navigate(['/subcategory'], { queryParams: viewalltabDataParams })
    }
  }
  landingAsset() {
    let assetData = this.data.asset;
    this.router.navigate(['detail/' + this.data.asset.type + '/' + this.data.asset.code + '/' + this.data.asset.type])
  }

  landingVotingPage() {
    let url = this.data.webviewurl;
    const splitArr = url.split("cid=");
    let eventid = splitArr[1];
    let sessId = localStorage.getItem('sessionId');
    window.open('content/' + eventid + '/' + sessId, '_blank');
  }

}
