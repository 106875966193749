<div class="container mt-4">
    <div class="d-flex align-items-center justify-content-between">
        <img src="../../../assets/img/logo.png" class="profile-logo-width" />
        <a href="javascript:;" (click)="backButton()">
            <img src="../../.././../assets/img/Icon/close.svg" />
        </a>
    </div>
    <div class="profile-align" *ngIf="!editSuccess && !deleteSuccess && !isSetPinSuccess">
        <div class="row">
            <div class="col-md-12 text-center" *ngIf="!isDeleteLockedProfile">
                <div class="add_profile_width">
                <label class="login_title login-mb-40">{{language.editProfile}}</label>
                <form [formGroup]="editForm" (submit)="editSubmit()"> <!-- *ngIf="isShowPinForm==false" -->
                    
                        <owl-carousel-o *ngIf="isChooseAvatar" #owlCarousel [options]="customOptions" class="profile_carousel">
                            <ng-template  *ngFor="let avatar of profileAvatars" [id]="avatar.avatar_image" carouselSlide>
                                <div class="choose-avtar-slider">
                                    <img style="border-radius: 50%" [src]="avatar.avatar_image" alt="" title="" />
                                    <a class="select-avtar-btn" *ngIf="isChooseAvatar" href="javascript:;" (click)="saveAvatarChange()">
                                        <!-- <small class="text-white">{{language.select}}</small> -->
                                        <img src="../../../../assets/img/Icon/profile-check.svg" />
                                    </a>
                                </div>
                            </ng-template>   
                        </owl-carousel-o>
                        <div class="profile_carousel" *ngIf="!isChooseAvatar">
                            <div  class="add_profile ">
                                <a href="javascript:void(0);" data-backdrop="static" data-toggle="modal"
                                    data-target="#exampleModal"><img class="editProfileImage"
                                        [src]="profile_data?.profile_image || '../../assets/img/user.png'"
                                        alt="" title="" /></a>
                                        <a class="change-avtar-btn" *ngIf="!isChooseAvatar" href="javascript:;" (click)="toggleChooseAvatar()">
                                        <!-- <small class="text-white">{{language.changeAvatar}}</small> -->
                                        <img src="../../../../assets/img/Icon/profile-edit.svg" />
                                    </a>
                            </div>
                        </div>
                        <div class="mb-3">
                            <!-- <div *ngIf="!isProfileNameEditable">
                                <span class="text-white mr-2 edit-profile-name">{{profile_data.profile_name}}</span>
                                <a href="javascript:;" (click)="setProfileNameEditabe(true)">
                                    <img src="../../../../assets/img/Icon/edit.png" alt="" title="" />
                                </a>
                            </div> -->

                            <div class="transparent_form"> <!-- *ngIf="isProfileNameEditable" -->
                                <div class="input_line display-flex">
                                    <input formControlName="profile_name" type="text" class="form-control" formControlName="profile_name"
                                        placeholder="Enter profile name" />
                                        <!-- <div class="name-btn-block">
                                            <span class="mr-4" (click)="setProfileName()"><img src="../../../../assets/img/Icon/check.png" alt="" title="" /></span>
                                            <span (click)="resetProfileName()"><img src="../../../../assets/img/Icon/cross.png" alt="" title="" /></span>
                                        </div> -->
                                </div>
                                <app-error 
                                *ngIf="is_submit && f.profile_name.invalid"
                                [message]="language.pleaseEnterValidProfileName"></app-error> <!-- && f.profile_name.hasError('required') -->
                                    <!-- <small *ngIf="is_submit && f.viewing_restriction.invalid && f.viewing_restriction.hasError('required')" class="submit-error">{{language.pleaseChooseAgeRestriction}}</small> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 switch-label">
                                <div class="d-flex justify-content-between align-center">
                                    <label class="text-white switch-label-tag">{{language.kidsProfile}}</label>
                                    <label class="switch" (change)="isKids()" [class]="is_kids == true ? 'toggle-active' : '' ">
                                        <input type="checkbox" [checked]="profile_data?.is_child" formControlName="is_child">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>

                            <div class="col-md-6 switch-label" [ngClass]="{ 'disabled-for-kids': profile_data?.is_child || is_kids }" id="lock"> <!-- is_kids -->
                                <div class="d-flex justify-content-between align-center">
                                    <label class="text-white switch-label-tag">{{language.lockProfile}}</label>
                                    <label class="switch" [class]="profileLockStatus == true ? 'toggle-active' : '' " (change)="checkLockStatus(setPinModal)">
                                        <input type="checkbox" [checked]="profileLockStatus" #lockscreenData>
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <p class="text-right mb-0" *ngIf="profileLockStatus==true">
                                    <a class="change-pin-text" href="javascript:void(0);" (click)="changePin(changePinModal)">
                                        {{language.changePin}}?
                                    </a>
                                </p>
                            </div>
                        </div>
                        
                        <div class="row mt-4 mb-4">
                            <div class="col-md-12">
                                <h4 style="font-size: 14px;" class="text-left text-white">{{language.maturityRating}}</h4>
                            </div>
                            <div class="col-md-12">
                                <ul class="restriction_list">
                                    <li *ngFor="let restriction of viewing_restriction_arr; let index=index" [class]="(is_kids && index > 1) ? 'disabled-for-kids' : ''">
                                        <label class="gander_radio">
                                            <input type="radio" [value]="restriction" name="viewing_restriction"
                                                formControlName="viewing_restriction"/>
                                            <span>{{restriction}}</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-default btn_height btn_hover profile-btn mr-4">
                            <span>{{language.save}}</span>
                        </button>
                        <!-- <button type="button" class="btn btn-default btn_height btn_hover profile-btn" (click)="backButton()">
                            <span>{{language.cancel}}</span>
                        </button> -->

                        <div *ngIf="profile_data?.delete_status" class="mt-4">
                            <a href="javascript:void(0);" (click)="openDeleteProfileModal(deleteProfileModal)" class="delete_profile_btn">
                                <img src="../../../../assets/img/Icon/delete.svg" alt="" title="" />
                                <img src="../../../../assets/img/Icon/delete_hover.svg" alt="" title="" />
                                {{language.deleteProfile}}
                            </a>
                        </div>
                    
                </form>
                </div>
            </div>
        </div>
    </div>

    <!-- success message  -->
    <div class="profile-align" *ngIf="editSuccess || deleteSuccess || isSetPinSuccess">
        <div class="success-add text-center">
            <label class="login_title">{{language.hi}} {{editForm.value['profile_name']}}!</label>
            <h4 class="_verify_t text-white" style="font-weight: 400;">{{api_msg}}</h4>
            <div class="lottie-icon-block">
                <div class="lottie-icon">
                    <ng-lottie [options]="successOptions"></ng-lottie>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- delete profile modal -->
<ng-template #deleteProfileModal>
    <div class="popup_background auth">
        <div class="modal-content">
            <button  type="button" class="close popup_close" (click)="closeModal()" #closebutton>&times;</button>
            <p class="text-white">{{language.areYouSureYouWantToDeleteThisProfile}}</p>
            <p class="text-white" style="font-weight: 200;width: calc(100% - 100px);">{{language.allRecommendationsViewingHistory}}</p>

            <div class="text-right mt-2">
                <button class="btn btn-default btn_height btn_hover profile-btn mr-3" (click)="closeModal()">
                    <span>{{language.cancel}}</span>
                </button>
                <button class="btn btn-default btn_height btn_hover profile-btn delete_profile_btn" (click)="deleteProfile()">
                    <span  class="profile_btn">
                        <img src="../../../../assets/img/Icon/delete.svg" alt="" title="" />
                        <img src="../../../../assets/img/Icon/delete_hover.svg" alt="" title="" />
                        {{language.deleteProfile}}
                    </span>
                </button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #setPinModal>
    <app-setpin (setPinSuccess)="setPinSuccess($event)"></app-setpin>
</ng-template>

<ng-template #changePinModal>
    <app-changepin (changePinSuccess)="changePinSuccess($event)"></app-changepin>
</ng-template>