<header id="header" [ngClass]="{'topstrip': addForTopStrip}" class="header-bg">
  <nav class="navbar navbar-expand-md fixed-top ">
    <div class="container">
      <a (click)="gotToTab()" class="navbar-brand"><img
        src="assets/img/logo.png" /></a>

        <div class="hide-search">
          <button id="navbar-toggle-button" class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarsExample04"
            aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
              <i></i>
          </button>
        </div>

        <div class="collapse navbar-collapse" id="navbarsExample04">
          <ul class="navbar-nav mbl-menu">
            <li class="nav-item" *ngFor="let item of headerTabs let i=index"
              [ngClass]="{'active': slugName == item.slug}">
              <a class="nav-link" (click)="highLightMenu(item.slug,item.name)" routerLinkActive="active">{{item.name}}
              </a>
            </li>
          </ul>
          <ul class="navbar-nav desktop-menu">
            <li class="nav-item" *ngFor="let item of headerTabs | slice:0:6; let i=index"
              [ngClass]="{'active': slugName == item.slug}">
              <a class="nav-link" (click)="highLightMenu(item.slug,item.name)" routerLinkActive="active">{{item.name}}
              </a>
            </li>
  
            <li *ngIf="headerTabs.length>6" class="nav-item" style="position: relative;">
              <a class="nav-link" href="javascript:;">
                <span class="menu-hover-icon"></span>
              </a>
              <ul class="sub-menu">
                <li class="nav-item" *ngFor="let item of headerTabs | slice:6; let i=index"
                  [ngClass]="{'active': slugName == item.slug}">
                  <a class="nav-link" (click)="highLightMenu(item.slug,item.name)" routerLinkActive="active">{{item.name}}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          
        </div>
        <form class="form-inline">
          <ul class="form_right mobile-display-block">
            <li class="search-icon"><a (click)="openSearchModal()">
              <span></span>
            </a></li>
            <!-- <li><a  href="javascript:;"><img src="../../../../assets/img/Icon/notification.svg" /></a></li> -->
            <!-- <li class="subscribe-img " *ngIf="isAuthenticated == true">
              <a routerLink="/subscribe">
                <span class="head_btn btn_hover">
                  <img src="../../../../assets/img/Icon/subscribe.svg" />
                  <p>{{language.subscribe}}</p>
                </span>
              </a>
            </li> -->
            <li class="subscribe-img" > <!-- *ngIf="isAuthenticated == false" -->
              <a (click)="goToSubscribe(content)" #icl>
                <span class="head_btn btn_hover">
                  <img src="../../../../assets/img/Icon/subscribe.svg" />
                  <p>{{language.subscribe}}</p>
                </span>
              </a>
            </li>
  
            <li class="nav-item dropdown">
              <a id="login-button" (click)="openLoginModal(content)" #icl>
                  <span class="head_btn btn_hover" *ngIf="isAuthenticated==false">
                    <img src="../../../../assets/img/Icon/login.svg" />
                    <p>{{language.login}}</p>
                  </span>
              </a>
              <a class="nav-link dropdown-toggle" id="dropdown04" data-toggle="dropdown" aria-haspopup="true"> 
                  <div *ngIf="isAuthenticated">
                    <img [src]="selectedProfile?.profile_image || '../../../../assets/img/Icon/default-user.svg'" class="mr-2 p-select-img" /> 
                    <img src="../../../../assets/img/Icon/arrow_down.svg" />
                  </div>
              </a>
              <div class="dropdown-menu right-align-menu" aria-labelledby="dropdown04">
                <div *ngIf="isAuthenticated == true">
                  <div class="header-profile-list">
                    <ul>
                      <li *ngFor="let item of profileData" [ngClass]="{'profile_active no_click': item._id == selectedProfile._id}">
                        <a (click)="switchProfile(item._id, item.profile_name, item.profile_lock, item.profile_image)">
                          <div class="profile-list-border">
                            <img [src]="item?.profile_image || defaultImageUrl" alt="" title="" />
                            <span *ngIf="item.is_child">{{language.kids}}</span>
                          </div>
                          <span class="header-profile-name">
                            <img *ngIf="item.profile_lock" src="../../../../assets/img/Icon/lock.svg" alt="" title="" />
                            {{item.profile_name}}
                          </span>
                        </a>
                      </li>
                      <li *ngIf="addProfileCount" routerLink="manage-profile/add">
                        <a>
                          <div class="profile-list-border add_p_account">
                            <img src="../../../../assets/img/Icon/add_profile.svg" alt="" title="" />
                          </div>
                          <span class="header-profile-name">
                            {{language.addProfile}}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <span class="menu-line"></span>
                  <a class="dropdown-item" routerLink="/manage-profile" [queryParams]="{ is_manage: true }" routerLinkActive="active" aria-disabled="true"><img src="../../../../assets/img/Icon/manage_profile.svg" alt="" title="" /> {{this.language.manageProfile}}</a>
                  <a class="dropdown-item" routerLink="/myaccount" routerLinkActive="active" aria-disabled="true">
                    <img src="../../../../assets/img/Icon/my_account.svg" alt="" title="" />
                    <img src="../../../../assets/img/Icon/my_account-active.svg" alt="" title="" />
                    
                    {{this.language.myaccount}}</a> 
                  <a class="dropdown-item" routerLink="/myplans" routerLinkActive="active" aria-disabled="true">
                    <img src="../../../../assets/img/Icon/my_subscriptions.svg" alt="" title="" />
                    <img src="../../../../assets/img/Icon/my_subscriptions-active.svg" alt="" title="" />
                    
                    My Plans</a>
                  <!-- <a class="dropdown-item" routerLink="/change-password" routerLinkActive="active" aria-disabled="true">
                    <img src="../../../../assets/img/Icon/my_account.svg" alt="" title="" />
                    <img src="../../../../assets/img/Icon/my_account-active.svg" alt="" title="" />
                    {{this.language.changePassword}}</a> -->
                  <a class="dropdown-item" routerLink="/watchlist" routerLinkActive="active" aria-disabled="true">
                    <img src="../../../../assets/img/Icon/my_watchlist.svg" alt="" title="" />
                    <img src="../../../../assets/img/Icon/my_watchlist-active.svg" alt="" title="" />
                    {{this.language.watchlist}}</a>
                  <!-- <a class="dropdown-item" aria-disabled="true">
                    <img src="../../../../assets/img/Icon/my_vouchers.svg" alt="" title="" />
                    <img src="../../../../assets/img/Icon/my_vouchers-active.svg" alt="" title="" />
                    {{this.language.myVouchers}}</a> -->
                  <a class="dropdown-item" routerLink="/redeem-code" routerLinkActive="active" aria-disabled="true">
                    <img src="../../../../assets/img/Icon/redeem_code.svg" alt="" title="" />
                    <img src="../../../../assets/img/Icon/redeem_code-active.svg" alt="" title="" />
                    {{this.language.redeedmCode}}</a>
                  <a class="dropdown-item" routerLink="/help-center" routerLinkActive="active">
                    <img src="../../../../assets/img/Icon/help_center.svg" alt="" title="" />
                    <img src="../../../../assets/img/Icon/help_center-active.svg" alt="" title="" />
                    
                    {{this.language.helpCenter}}</a>
                  <span class="menu-line"></span>
                  <a id="header-logout-button" class="dropdown-item" (click)="openConfirmLogoutModal(confirmLogoutModal)"><img src="../../../../assets/img/Icon/logout.svg" alt="" title="" /> {{this.language.logOut}}</a>
                </div>
              </div>
  
  
            </li>
  
            
          </ul>
        </form>
        
      
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04"
        aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation"
        style="display:none !important;">
        <span class="navbar-toggler-icon">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </span>
      </button>
      
      
      
    </div>

  </nav>
</header>

<ng-template #confirmLogoutModal>
  <div class="popup_background auth">
      <div class="modal-content" *ngIf="!logoutSuccess">
        <p class="text-white">Are you sure you want to log out?</p>

        <div class="text-right mt-5">
            <button class="btn btn-default btn_hover profile-btn mr-3 btn_height30" (click)="closeModal()">
                <span>{{language.cancel}}</span>
            </button>
            <button class="btn btn-default btn_hover profile-btn btn_height30" (click)="logout()">
              <span>{{language.yesLogout}}</span>
            </button>
        </div>
      </div>
      <div *ngIf="logoutSuccess" class="modal-content">
        <div class="success-add">
            <h2 style="color:#fff;font-size: 20px;font-weight: 100;line-height: 35px;"
            class="_verify_t">
            {{api_msg}}
            </h2>
            <div class="lottie-icon-block">
              <div class="lottie-icon">
                  <ng-lottie [options]="successOptions"></ng-lottie>
              </div>
          </div>
        </div>
    </div>
  </div>
</ng-template>

<ng-template #content let-modal>
  <app-login-register></app-login-register>
</ng-template>

<ng-template #searchComponent>
  <app-search></app-search>
</ng-template>

<ng-template #tellUsContent let-modal>
  <app-tell-us></app-tell-us>
</ng-template>