import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';
import { AddProfileComponent } from './add-profile/add-profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { SetpinComponent } from './setpin/setpin.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { ChangepinComponent } from './changepin/changepin.component';
import { ResetPinComponent } from './reset-pin/reset-pin.component'; 
import { CarouselModule } from 'ngx-owl-carousel-o';

import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { EnterPinComponent } from './enter-pin/enter-pin.component';

export function playerFactory() {
  return player;
}


@NgModule({
  declarations: [
    ProfileComponent,
    AddProfileComponent,
    EditProfileComponent,
    SetpinComponent,
    ChangepinComponent,
    ResetPinComponent,
    EnterPinComponent,

  ],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    SharedModule,
    NgOtpInputModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    LottieModule.forRoot({ player: playerFactory })

  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    // SharedModule
  ]
})
export class ProfileModule { }
