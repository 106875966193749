import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { tempLiveData } from "src/app/core/constants";
import { pageNotFoundOptions } from 'src/app/shared/lottie-config';
import { AnimationOptions } from 'ngx-lottie';



@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.css']
})
export class PagenotfoundComponent implements OnInit {
  pageNotFoundOptions: AnimationOptions = pageNotFoundOptions;

  constructor(private location: Location) { }

  ngOnInit(): void {

    let programDateArr = [];
    let test = tempLiveData.egpData;
    let tempProgramByData = [];
   test.result.forEach(element => {
    programDateArr.push(element.progdate)
     // console.log(programDateArr);
   });
   console.log(_.uniq(programDateArr));
   let programDateUniqArr = _.uniq(programDateArr)
   programDateUniqArr.forEach(element => {
    tempProgramByData.push(test.result.filter(program => element == program.progdate));
   });
   console.log(tempProgramByData);
  }

  previousPage(){
    this.location.back(); 
  }

}