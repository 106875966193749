import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PageTitleService } from '../title/page-title.service';
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class EventTrackingService {

  detailRoute = '/';
  public searchAlpha: any;

  constructor(private pageTitleService: PageTitleService) { }

  public eventEmitter(
    event: string,
    eventCategory: string,
    eventLabel: string,
    eventValue?: number,
    eventUserName?: string,
    eventAction?: string) {

    //let label =  eventLabel == '' ? eventLabel : '';
    
    gtag('event', event, {
      'event_category': eventCategory,
      'event_label': eventLabel,
      'value': eventValue,
      'userName': eventUserName,
      'user_name': eventUserName,
      'action': eventAction
    });

    
  }

  public routeTrack(route: any) {


    let title = this.pageTitleService.getTitle();

    if(title == 'Detail' || title == 'NexGTV') {
      this.detailRoute = route;
    } else {
        this.trackRoute(title, route);
    }
  }
  trackRoute(title, path) {
    gtag('config', environment.googleTrackingCode,
      {
        'page_title': title,
        'screen_name': title,
        'page_path': path,
        'page_location': path
      }
    );

  }

  


}
