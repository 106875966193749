import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { CommonService } from 'src/app/core/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnimationOptions } from "ngx-lottie";
import { successOptions, errorOptions } from 'src/app/shared/lottie-config';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  loginPassHide: boolean = true;
  loginPassHide1: boolean = true;
  eyehideshow : any;
  resetPasswordForm: FormGroup;
  isValidField: boolean = false;
  isSubmit: boolean = false;
  confirmPass: boolean = false;
  @Input() username: string;
  @ViewChild('closebutton') closebutton: ElementRef;
  resetPassSuccess: boolean;
  api_msg: any;
  language: any;
  @ViewChild('loginRegisterContent') loginRegisterContent: ElementRef;
  successOptions: AnimationOptions = successOptions;
  errorOptions: AnimationOptions = errorOptions;

  
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private user: UserService,
    public commonService: CommonService,
    private modalService: NgbModal
  ) {
    console.log(router.getCurrentNavigation())
    // if(this.username = router.getCurrentNavigation().extras.state) {
    //   this.username = router.getCurrentNavigation().extras.state.username;
    // }
    // else {
    //   this.router.navigateByUrl('forgot-password');
    // }
    
;
    
    console.log('username',this.username); // get username by route
   }



   get resetPasswordControls() {
    return this.resetPasswordForm.controls;
  }


  ngOnInit(): void {
    let pwd_patren = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%$&^*]).{8,}';
    this.resetPasswordForm = this.fb.group({
      'username':[this.username],
      'password': ['', [Validators.required,Validators.minLength(8), Validators.maxLength(15)]],
      'confirm_password': ['', [Validators.required]]
    });

    this.language = this.commonService.languageChange();
  }

  closeModal() {
    try {
      // document.querySelector("ngb-modal-window").classList.remove("show");
      // document.querySelector("ngb-modal-backdrop").classList.remove("show");
      // document.getElementById('nexgTV-app').click();
      // document.querySelectorAll(".modal-backdrop").forEach(el => el.remove())
      // document.querySelectorAll(".d-block").forEach(el => el.remove())
      // document.body.style.overflow = "scroll";
      // this.clearPresistFormData();
      this.modalService.dismissAll();

    } catch (e) {

    }
  }

  openLoginRegisterModal() {
    this.modalService.open(this.loginRegisterContent, { ariaLabelledBy: 'modal-basic-title', backdrop: true, windowClass: 'tellu' }).result.then((result) => {
    }, (reason) => {
    });
  }

  resetPassword() {
    this.isSubmit = true;
    this.confirmPass = false;
    this.api_msg = '';
    console.log(this.resetPasswordForm);
    if (this.resetPasswordForm.invalid) {
      return;
    } else {
      if(this.resetPasswordForm.controls.password.value !=  this.resetPasswordForm.controls.confirm_password.value){
        this.confirmPass = true;
      }else{
        // this.isValidField = true;
        let params = this.resetPasswordForm.value;
        let url = 'password/forgot/newpassword';
        this.user.resetPassword(url,params).subscribe(data => {
          // console.log(data);
          this.api_msg = data.message;
          this.resetPassSuccess = true;
          setTimeout(() => {
            this.closebutton.nativeElement.click();
            this.openLoginRegisterModal();
            // this.router.navigateByUrl('');
          }, 2500)
         },(error)=>{
          console.log('errorrrrr',error); 
         });
      }
    }
  }



  hideRPass() {
    if(this.loginPassHide==true){
        this.loginPassHide = false;
    }else{
        this.loginPassHide = true;
    }
  }

  hideRPass1() {
    if(this.loginPassHide1==true){
        this.loginPassHide1 = false;
    }else{
        this.loginPassHide1 = true;
    }
  }



}
