import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/services/local-stroage.service';
import { UserService } from 'src/app/core/services/user.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  loginPassHide: boolean = true;
  loginPassHide1: boolean = true;
  eyehideshow : any;
  changePasswordForm: FormGroup;
  isValidField: boolean = false;
  isSubmit: boolean = false;
  confirmPass: boolean = false;
  username:any;
  user_data:any;
  api_error:any;
  api_msg:any;


  constructor(private fb: FormBuilder,private router: Router,private user: UserService,public LocalStorageService:LocalStorageService) {
    this.user_data = LocalStorageService.get('userInfo');
   }



   get f() {
    return this.changePasswordForm.controls;
  }


  ngOnInit(): void {
    console.log(this.user_data);
    console.log(this.user_data.result.username);
    let pwd_patren = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%$&^*]).{8,}';
    this.changePasswordForm = this.fb.group({
      'username':[this.user_data.result.username],
      'current_password': ['', [Validators.required]],
      'password': ['', [Validators.required,Validators.pattern(pwd_patren)]],
      'confirm_password': ['', [Validators.required]]
    });

  
  }

  changePassword() {
    this.isSubmit = true;
    this.confirmPass = false;
    console.log(this.changePasswordForm);
    this.api_error = '';
    this.api_msg = '';
    
    if (this.changePasswordForm.invalid) {
      return;
    } else {
      if(this.changePasswordForm.controls.password.value !=  this.changePasswordForm.controls.confirm_password.value){
        this.confirmPass = true;
      }else{
        // this.isValidField = true;
        let params = this.changePasswordForm.value;
        let url = 'password/change';
        this.user.changePassword(url,params).subscribe(data => {
          console.log(data);
          this.api_msg = data.message;
          this.isSubmit = false;
          this.changePasswordForm.reset();
          // this.router.navigateByUrl('');
         },(error)=>{
          console.log('errorrrrr',error); 
          this.api_error = error.message;
         });
      }
    }
  }



  hideRPass() {
    if(this.loginPassHide==true){
        this.loginPassHide = false;
    }else{
        this.loginPassHide = true;
    }
  }

  hideRPass1() {
    if(this.loginPassHide1==true){
        this.loginPassHide1 = false;
    }else{
        this.loginPassHide1 = true;
    }
  }

}
