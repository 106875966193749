import { Component, OnInit } from '@angular/core';
import { AccountService } from '../account/account.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/core/services/common.service';
import { CatlougeTypeConstants } from 'src/app/core/constants';
import { DeviceService, UserService } from 'src/app/core';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  helpCenterForm: FormGroup;
  isSubmit: boolean = false;
  api_error: any;
  api_msg: string;
  language: any;
  catlogue = CatlougeTypeConstants.catlogue;
  deviceInfo: any;
  deviceId: string;
  selectedFiles: File[] = []; // Array to hold selected files
  
  constructor(
    private accountService: AccountService,
    private fb: FormBuilder,
    public commonService: CommonService,
    private userService: UserService,
    private deviceService: DeviceService,
  ) { }

  ngOnInit(): void {
    let email_patren = "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
    let mobile_patren = '^[0-9]*$';
    this.language = this.commonService.languageChange();
    this.deviceId = localStorage.getItem('deviceId');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.helpCenterForm = this.fb.group({
      'email': ['', [Validators.required,Validators.email,Validators.pattern(email_patren)]],
      'mobile': ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10),Validators.pattern(mobile_patren)]],
      'query_type': [null, [Validators.required]],
      'query_description': ['', [Validators.required]],
      'device_detail': [{
        "device_id": this.deviceId,
        "device_model": this.deviceService.getDeviceType(),
        "os_version": this.deviceInfo.os_version,
        "build_version": "web",
        "device_token": localStorage.getItem('sessionId')
      }],
      // 'attachment_path': ['', [Validators.required]],
    });
  }


  get helpCenterFormControls() {
    return this.helpCenterForm.controls;
  }
  onFileSelect(event: any) {
    this.api_error = '';
    const files: FileList = event.target.files;

    // Ensure total files don't exceed the limit of 5
    if (files.length > 5) {
      // alert('You can upload a maximum of 5 files.');
      this.api_error = 'You can upload a maximum of 5 files.';
      (event.target as HTMLInputElement).value = '';
      return;
    }
    for (let i = 0; i < files.length; i++) {
      this.selectedFiles.push(files[i]); // Push each selected file into the array
    }
  }
  submit() {
    this.isSubmit = true;
    this.api_error = '';
    this.api_msg = '';
    console.log('this.helpCenterForm',this.helpCenterForm)
    console.log('this.helpCenterForm',this.helpCenterForm.invalid)
    if (this.helpCenterForm.invalid) {
      // this.isValidField = false;
      return;
    }else {
      const formData = new FormData();
      this.selectedFiles.forEach((file) => {
        formData.append('attachment_path', file); // Use the same key for all files
      });
      formData.append('email', this.helpCenterForm.get('email')?.value);
      formData.append('mobile', this.helpCenterForm.get('mobile')?.value);
      formData.append('query_type', this.helpCenterForm.get('query_type')?.value);
      formData.append('query_description', this.helpCenterForm.get('query_description')?.value);
      formData.append('device_detail', JSON.stringify(this.helpCenterForm.get('device_detail')?.value));
      // formData.append('catlogue', this.catlogue);
      // let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded({'catlogue':this.catlogue, ...this.helpCenterForm.value});
      this.accountService.postData('helpcenter/query', formData).subscribe(data => {
        this.api_msg = data.message;
        if(data.response_code === 200 || data.response_code === 201) {
          this.api_msg = data.message;
          this.helpCenterForm.reset();
          this.isSubmit = false;
          setTimeout(() => {
            this.api_msg = '';
          }, 5000);
        }
      }, (error) => {
        console.log('error submitting help center form: ', error);
        this.api_error = error && error.message ? error.message : 'Something went wrong';
      });
    }
  }

}
