import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router'; 
import { UserService } from 'src/app/core/services/user.service';
import { CommonService } from 'src/app/core/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnimationOptions } from 'ngx-lottie';
import { errorOptions, successOptions } from 'src/app/shared/lottie-config';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  isSubmit: boolean = false;
  // forgotPasswordForm: FormGroup;
  // isValidField: boolean = false;
  @Input() isMobile:any;
  verifyOtpForm: FormGroup; 
  resetPasswordForm: FormGroup;
  otp: any;
  isOtpSubmit: boolean = false;
  api_error:any;
  api_msg:any;
  timeLeft: number = 60;
  interval;
  language: any;
  public countryList: any;
  @Input() countryDialCode: string;
  @Input() username: string;
  verifyOtpSuccess: boolean;
  resetPassSuccess: boolean;
  confirmPass: boolean = false;
  isValidField: boolean = false;
  @ViewChild('closebutton') closebutton: ElementRef;
  @ViewChild('loginRegisterContent') loginRegisterContent: ElementRef;
  loginPassHide: boolean = true;
  loginPassHide1: boolean = true;
  successOptions: AnimationOptions = successOptions;
  errorOptions: AnimationOptions = errorOptions;
  isOtpInvalid: boolean;
  sendOtpSuccess: boolean;
  resendOtpSuccess: boolean;
  otp_msg: string;

  constructor(
    private fb: FormBuilder,
    private Router: Router,
    private user: UserService,
    public commonService: CommonService,
    private modalService: NgbModal
    ) { }


  // get forgotPasswordControls() {
  //   return this.forgotPasswordForm.controls;
  // }

  get verifyOtpFormControls() {
    return this.verifyOtpForm.controls;
  }

  get resetPasswordControls() {
    return this.resetPasswordForm.controls;
  }

  ngOnInit(): void {
    // this.forgotPasswordForm = this.fb.group({
    //   'country_dial_code': ['+91'],
    //   'username': [this.username, [Validators.required]]
    // });

    this.verifyOtpForm = this.fb.group({
      'username': [this.username, Validators.required],
      'otp': ['', [Validators.required,Validators.minLength(6)]],
      'device_id': [localStorage.getItem('deviceId')]
    });

    this.resetPasswordForm = this.fb.group({
      'username':[this.username],
      'password': ['', [Validators.required,Validators.minLength(8), Validators.maxLength(15)]],
      'confirm_password': ['', [Validators.required]]
    });

    this.language = this.commonService.languageChange();

    console.log(this.username);
    this.forgotPassword();
  }

  closeModal() {
    try {
      document.querySelector("ngb-modal-window").classList.remove("show");
      document.querySelector("ngb-modal-backdrop").classList.remove("show");
      document.getElementById('nexgTV-app').click();
      document.querySelectorAll(".modal-backdrop").forEach(el => el.remove())
      document.querySelectorAll(".d-block").forEach(el => el.remove())
      document.body.style.overflow = "scroll";
      // this.clearPresistFormData();

    } catch (e) {

    }
  }

  forgotPassword() {
    let params = {
      country_dial_code: this.countryDialCode,
      username: this.username,
      otp_type: 'password_reset'
    };
    if (!this.isMobile) {
      delete params['country_dial_code'];
    }
    let url = 'send/otp';
    this.user.forgotPassword(url,params).subscribe(data => {
      // this.isValidField = true;
      if(data.response_code === 200 || data.response_code === 201) {
        this.sendOtpSuccess = true;
        // this.api_msg = data.message;
        this.startTimer();
      }
    },(error)=>{
      console.log('error',error);
      this.api_error = error.message;
    })
  }
  onOtpChange(otp) {
    this.otp = otp;
    this.verifyOtpForm.get("otp").setValue(this.otp);
  }

  // otp expiry timer
  startTimer() {
    this.timeLeft = 60;
    this.interval = setInterval(() => {
      if(this.timeLeft == 0) {
        clearInterval(this.interval);
      }
      if(this.timeLeft > 0) {
        this.timeLeft--;
      }
    },1000)
  }
  

  verifyOtp() {
    this.isOtpSubmit = true;
    
    this.isOtpInvalid = this.verifyOtpFormControls.otp.invalid;
    let username = this.username;
    this.verifyOtpForm.patchValue({username:username});
    if (this.verifyOtpForm.invalid) {
      return;
    }
    // this.api_error = '';
    // this.api_msg = '';
      let params = this.verifyOtpForm.value;
      params.otp_type = 'password_reset';
      let url = 'verify/otp';
      this.user.verifyOtp(url,params).subscribe(data => {
      if (data.response_code==200){
        this.verifyOtpSuccess = true;
        this.api_msg = data.message;
      } else {
        this.api_error = data.message;
      }
      },(error)=>{
      this.api_error = error.message;
      });   

  }

  resendOtp() {
    this.api_error = '';
    this.api_msg = '';
    this.resendOtpSuccess = false;
    let otp_type = 'password_reset';
    let params = {
      username:this.verifyOtpForm.value.username,
      otp_type
    }
    if (this.isMobile) {
      params['country_dial_code'] = this.countryDialCode;
    }
    this.startTimer();
    let url = 'send/otp';
    this.user.resendOtp(url,params).subscribe(data => {
      if(data.response_code === 200 || data.response_code === 201) {
        this.resendOtpSuccess = true;
        this.otp_msg = data.message;
      }
     },(error)=>{
      this.api_error = error.message;
     });
  }

  openLoginRegisterModal() {
    this.modalService.open(this.loginRegisterContent, { ariaLabelledBy: 'modal-basic-title', backdrop: true, windowClass: 'tellu' }).result.then((result) => {
    }, (reason) => {
    });
  }

  editUsername() {
    this.closebutton.nativeElement.click();
    this.openLoginRegisterModal();
  }

  resetPassword() {
    this.isSubmit = true;
    this.confirmPass = false;
    this.api_msg = '';
    console.log(this.resetPasswordForm);
    if (this.resetPasswordForm.invalid) {
      return;
    } 
    // else {
    if(this.resetPasswordForm.controls.password.value !=  this.resetPasswordForm.controls.confirm_password.value){
      this.confirmPass = true;
    }
    else{
      // this.isValidField = true;
      let params = this.resetPasswordForm.value;
      let url = 'forgot/password';
      this.user.resetPassword(url,params).subscribe(data => {
        this.api_msg = data.message;
        this.resetPassSuccess = true;
        setTimeout(() => {
          this.closebutton.nativeElement.click();
          this.openLoginRegisterModal();
        }, 2500)
        },(error)=>{
        console.log('errorrrrr',error); 
        });
    }
    // }
  }

  handleSubmit() {
    this.isSubmit = true;
    this.isOtpInvalid = this.verifyOtpFormControls.otp.invalid;

    
    let username = this.username;
    this.verifyOtpForm.patchValue({username:username});
    if (this.verifyOtpForm.invalid) {
      return;
    }
    if(this.resetPasswordControls.confirm_password.value !== '' && (this.resetPasswordControls.password.value !=  this.resetPasswordControls.confirm_password.value)){
      this.confirmPass = true;
      return;
    }
    // this.api_error = '';
    // this.api_msg = '';
    let params = this.verifyOtpForm.value;
    params.otp_type = 'password_reset';
    let url = 'verify/otp';
    this.user.verifyOtp(url,params).subscribe(data => {
    if (data.response_code==200){
      // this.verifyOtpSuccess = true;
      // this.api_msg = data.message;
      this.resetPassword();
    } else {
      this.api_error = data.message;
    }
    },(error)=>{
    this.api_error = error.message;
    });
  }

  hideRPass() {
    this.loginPassHide = !this.loginPassHide;
  }

  hideRPass1() {
    this.loginPassHide1 = !this.loginPassHide1;
  }
 


}
