import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';

import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { User } from '../models';
import { map } from 'rxjs/operators';


@Injectable()
export class VideoPlayerService {

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService
  ) { }


  playUrl(credentials, is_trailer=0, is_live_evnet=0){
    if(is_trailer) {
      return this.apiService.playUrl(`assetdetail/play/trailer?${credentials}`).pipe(map(data => {
          return data;
          }
      ));
    }else{
      return this.apiService.playUrl(`assetdetail/play/asset?${credentials}`).pipe(map(data => {
        return data;
        }
    ));
    }
  }

  playPreview(credentials){
    return this.apiService.playUrl(`asset/play/preview?${credentials}`).pipe(map(data => {
      return data;
    }
    ));
  }

  wowzaAnalytics(body: any) {
    return this.apiService.post(`analytics/wowza`, body).pipe(map(data => {
      return data;
    }
    ));
  }

  postAnalytics(body: any) {
    return this.apiService.post('analytics/logs', body);
  }
  

}
