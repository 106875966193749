<div class="new_assets_hover" (mouseenter)="item_hover_enter(item)" (mouseleave)="item_hover_leave(item)" (click)="redirectUrl(item, $event)">
    <div class="hover_video" >
       <a class="hover-click">
        
        <!-- <img  class="asset_hover_img" *ngIf="item.code!=checkPrview" [src]="item.image_landscape || item.image"> -->

        <image-manipulation class="asset_hover_img"
            *ngIf="item.code!=checkPrview"
            [img]="item.image_landscape || item.image" [params]="{'width':448,'height':252}"
            [notFound]="'landscape'"></image-manipulation>
        
        <div class="hover_video_play" *ngIf="item.code==checkPrview">
            <div class="video_img_icon">
                
                <div class="video_mute_icon" (click)="togglePreviewVideoMute(item, $event)">
                    <img *ngIf="!isPreviewVideoMuted" src="../../../assets/img/Icon/unmute.svg" />
                    <img *ngIf="isPreviewVideoMuted" src="../../../assets/img/Icon/mute.svg" />
                </div>
                
            </div>

            <video style="z-index: -9;" [id]="'hoverPreviewVideo'+item.code"
                class="video-js vjs-fluid  vjs-16-9" controls autoplay
                preload="auto" playsinline>
                <p class="vjs-no-js">
                    To view this video please enable JavaScript, and
                    consider upgrading
                    to a
                    web browser that
                    <a href="https://videojs.com/html5-video-support/"
                        target="_blank">supports
                        HTML5 video</a>
                </p>
            </video>

            
        </div>
    </a>

            <a class="_premium"
                *ngIf="strToLower(item.content_availability) == 'paid' && item.hide_tag == '0' ">
                <img class="crown" [src]="paidIcon">
            </a>

            <a class="_free_tag"
                *ngIf="strToLower(item.content_availability) == 'free' && item.hide_tag == '0' ">
                <img class="crown" [src]="freeIcon">
            </a>

            <a class="_free_tag"
                *ngIf="strToLower(item.content_availability) == 'no tag' && item.hide_tag == '0' ">
            </a>

            <a class="_cross_icon"
                *ngIf="strToLower(item?.category_type) == 'continue-watching' || isContinueWatching">
                <img class="crown" (click)="deleteFromContinueWatching($event, item)"
                    src="assets/img/Icon/close.svg">
            </a>
        <div class="transparent_img_text">
            <!-- <div *ngIf="item.image_transparent" class="video_transpreant_img">
                <img [src]="item.image_transparent" />
            </div> -->
            <div *ngIf="item.image_transparent" class="video_transpreant_img">
                <!-- <img src="../../../assets/img/nexgtv.png" /> -->
                <img [src]="item.image_transparent" />
            </div>
            <span *ngIf="!item.image_transparent" >{{item.name}}</span>
        </div>
        
    </div>
    <div class="new_assets_hover_info">
        <div class="assets_button">
            <button type="button" class="play_btn btn_hover blur-btn" (click)="redirectUrl(item, $event,true)">
                <img src="../../../assets/img/Icon/play.svg" /> 
                {{item.type === 'livetv' ? 'Watch Live' : item?.category_type == 'continue-watching' ? 'Resume Now' : 'Watch Now'}}
            </button> <!--  *ngIf="strToLower(item.content_availability) == 'free'" -->

            <!-- <button *ngIf="strToLower(item.content_availability) == 'paid'" type="button" class="play_btn btn_hover subscribe-animation blur-btn"
                (click)="redirectUrl(item, $event, true)">
                <span class="top-star" [ngClass]="{'star-animation': isStarAnimation}"><img src="../../../assets/img/Icon/star.svg" /></span>
                <div class="subscribe-animation-line" [ngClass]="{'fade-line-animation': isStarAnimation}">
                    <img src="../../../assets/img/Icon/subscribe.svg" />
                    Subscribe to Watch
                </div>
                <span class="bottom-star" [ngClass]="{'star-animation': isStarAnimation}"><img src="../../../assets/img/Icon/star.svg" /></span>
            </button> -->

            <button #watchlistButton (click)="addRemoveFromWatchlist(item, watchlistButton, $event)" class="add_watch_btn add_watch_list_btn btn_hover blur-btn">
                <img *ngIf="item?.is_watchlist_added == undefined || item?.is_watchlist_added == 0"
                src="../../../assets/img/Icon/plush.svg" />
                <img *ngIf="item?.is_watchlist_added == 1" src="../../../assets/img/Icon/check.svg" />
            </button>
            <span *ngIf="item?.is_watchlist_added == 0" class="tooltip_hover home_tooltip">Watchlist</span>
            <span *ngIf="item?.is_watchlist_added == 1" class="tooltip_hover home_tooltip">Added to Watchlist</span>
        </div>
        <div class="assets_info_label">
            <ul>
                <!-- Corrected the conditions and terminology for genres and languages -->
                <li *ngIf="item.languages?.length > 1">
                    {{item.languages.length}} Languages
                    <!-- <span class="tooltip_hover">
                        <p *ngFor="let language of item.languages">{{language}}</p>
                    </span> -->
                </li>
                <li *ngIf="item.languages?.length === 1">
                    {{item.languages[0]}}
                </li>
                <li *ngIf="item.type !== 'livetv' && item.duration">{{item.formatted_duration}}</li>
                <li *ngIf="item.genre?.length > 1">
                    {{item.genre.length}} Genres
                    <!-- <span class="tooltip_hover">
                        <p *ngFor="let genre of item.genre">{{genre}}</p>
                    </span> -->
                </li>
                <li *ngIf="item.genre?.length === 1">
                    {{item.genre[0]}}
                </li>
                <li *ngIf="item.genre_text?.length > 1">
                    {{item.genre_text.length}} Genres
                    <!-- <span class="tooltip_hover">
                        <p *ngFor="let genre_text of item.genre_text">{{genre_text}}</p>
                    </span> -->
                </li>
                <li *ngIf="item.genre_text?.length === 1">
                    {{item.genre_text[0]}}
                </li>
            </ul>
            
            <span *ngIf="item.type !== 'livetv'" class="slider_rating">{{item.viewer_rating?.rating_name}}</span>
            <span *ngIf="item.type === 'livetv'" class="asset_live_tag">Live</span>
        </div>
        <div class="assets_info_des">
            <span class="synopsis_text" *ngIf="item.synopsis.length <= 200">{{item.synopsis}}</span>
            <span class="synopsis_text" *ngIf="item.synopsis.length > 200">{{item.synopsis | slice:0:200}}...</span>
        </div>
    </div>
</div>

<ng-template #loginModal let-modal>
    <app-login-register></app-login-register>
</ng-template>