<section class="trend_search">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
              <div class="search-form-position">
                <div class="search-form">
                    <form>
                      <div class="search-border">
                        <div class="search-input">
                            <img src="../../../../assets/img/Icon/search.svg" />
                            <input
                              id="search-input"
                              type="text"
                              placeholder="Search"
                              [(ngModel)]="searchQuery"
                              (ngModelChange)="onSearchInput($event)"
                              (blur)="onInputBlur()"
                              name="searchQuery"
                              autocomplete="off"
                              />
                        </div>
                      </div>
                        <div class="close-search">
                            <a (click)="closeModal(true)">
                                <img src="../../../assets/img/Icon/close.svg" />
                            </a>
                        </div>
                    </form>
                  </div>
                </div>
              </div>
        </div>
    </div>
                <div class="search-scroll">
                  <div class="container">
                    <div *ngIf="searchQuery?.length <= 1" class="trending-search-block">
                        <div class="recent-search"
                          *ngIf="recentSearches?.length > 0">
                            <label><img src="../../../assets/img/Icon/recent.svg" />Recent Searches :</label>
                            <ul>
                                <li *ngFor="let item of recentSearches | slice:0:6">
                                  <a
                                    (click)="searchWithField(item)">
                                    <span *ngIf="item.length <= 17">{{ item }}</span>
                                    <span *ngIf="item?.length > 17">{{ item | slice:0:15}}...</span>
                                    <img src="../../../assets/img/Icon/close.svg"
                                      (click)="openDeleteRecentItemModal(deleteRecentItemModal, $event, item)"/>
                                  </a>
                                </li>
                            </ul>

                            <div class="tooltip_view">
                                <a class="delete-recent-search"
                                  (click)="openDeleteAllRecentModal(deleteAllRecentModal, $event)"></a>
                                <span class="tooltip_text">
                                    Clear History
                                </span>
                            </div>
                        </div>

                        <ng-conatiner
                          *ngIf="trendingResult?.length > 0">
                          <div class="trending-search-list">
                              <label>Trending Searches :</label>
                          </div>
  
                          <div class="search-assets">
                              <ul>
                                  <li (click)="closeModal()" class="item assets_hover new_asset_hover_type" *ngFor="let item of trendingResult">
                                      <div class="new_asset" (click)="goto(item, loginPopup)">
                                          <div class="new_asset_img">
                                            <image-manipulation [assetName]="item.name" 
                                            [img]="item.image_portrait ? item.image_portrait : item.image_landscape"
                                            [params]="{ 'width': 220, 'height': 330 }" notFound="portrait"></image-manipulation>
  
                                              <a class="_premium"
                                                *ngIf="strToLower(item.content_availability) == 'paid' && item.hide_tag == '0' ">
                                                <img class="crown" [src]="paidIcon">
                                              </a>
  
                                              <a class="_free_tag"
                                                  *ngIf="strToLower(item.content_availability) == 'free' && item.hide_tag == '0' ">
                                                  <img class="crown" [src]="freeIcon">
                                              </a>
  
                                              <a class="_free_tag"
                                                  *ngIf="strToLower(item.content_availability) == 'no tag' && item.hide_tag == '0' ">
                                              </a>
  
                                          </div>
                                          
                                          <app-new-hover
                                            [item]="item"
                                            [page_view]="'Screen_Home'"
                                            (addToWatchlist)="addToWatchlistArray($event)"
                                            (removeFromWatchlist)="removeFromWatchlistArray($event)"></app-new-hover>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                        </ng-conatiner>
                    </div>
                    
                    <div *ngIf="searchQuery?.length > 1" class="enter-text-search">
                        <div class="text-keyword-search">
                            <!-- <div class="recent-search">
                                <ul>
                                    <li>
                                        <img src="../../../assets/img/Icon/search.svg" />
                                        <span>Defines the value of the credentials flag for CORS requests. 1</span>
                                    </li>
                                </ul>
                            </div> -->
                            <div class="recent-search">
                                <ais-instantsearch [config]="config1">
                                  <ais-configure [searchParameters]="allsearchParameters1"></ais-configure>
                
                                  <ais-hits>
                                    <ng-template let-hits="hits" let-results="results">
                                      <input type="hidden" [value]="results.nbHits" #totalSuggestions>
                                      <ul *ngIf="suggestionsCount && suggestionsCount > 1"> <!-- class="suggestion_search" -->
                                        <li *ngFor="let hit of hits | slice:0:6">
                                            <a>
                                                <img src="../../../assets/img/Icon/search.svg" />
                                                <span (click)="searchWithField(hit.name)">{{hit.name}}</span>
                                            </a>
                                        </li>
                                      </ul>
                                    </ng-template>
                                  </ais-hits>
                                </ais-instantsearch>
                            </div>
                            <label
                            *ngIf="allSearchResults?.length > 0">Showing Results for "{{searchQuery}}"</label>
                        </div>

                        <div class="search-nav" *ngIf="isSearchResultFetched">

                          <!-- remove display none to show search nav -->
                            <ul style="display: none;" class="nav nav-tabs" id="search_tab_1" role="tablist">
                              <li class="nav-item">
                                <a *ngIf="allSearchResults.length > 0"
                                  href="javascript:;"
                                  class="nav-link"
                                  [ngClass]="{'show active': activeTab === 'all-tab'}"
                                  (click)="updateActiveTab($event)"
                                  data-toggle="tab" data-target="#all-tab" role="tab" aria-selected="true">
                                  Show All
                                </a>
                              </li>
                              <li class="nav-item" *ngFor="let item of filteredSearchResults">
                                <a href="javascript:;"
                                  class="nav-link"
                                  [ngClass]="{'show active': activeTab === item.type + '-tab'}"
                                  (click)="updateActiveTab($event)"
                                  data-toggle="tab"
                                  [attr.data-target]="'#' + item.type + '-tab'" role="tab" aria-selected="false">
                                  {{ item.name }}
                                </a>
                              </li>
                            </ul>
                            <div class="tab-content">
                              <div class="tab-pane fade"
                                [ngClass]="{'show active': activeTab === 'all-tab'}"
                                id="all-tab"
                                role="tabpanel"
                                aria-labelledby="all-tab">
                                <div class="other_assests">
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <div class="search-assets">
                                        <ul *ngIf="allSearchResults?.length > 0">
                                        
                                          <li (click)="closeModal()" class="item new_asset_hover_type assets_hover search-assets-gap"
                                            *ngFor="let item of allSearchResults"
                                            (mouseenter)="assetHover(item.document)">
                                            <div class="new_asset" (click)="goto(item.document, loginPopup)">
                                              <div class="new_asset_img">
                                                <image-manipulation [assetName]="item.document.name" 
                                                  [img]="item.document.image_portrait ? item.document.image_portrait : item.document.image"
                                                  [params]="{ 'width': 220, 'height': 330 }" notFound="portrait"></image-manipulation>

                                                  <a class="_premium"
                                                    *ngIf="strToLower(item.document.content_availability) == 'paid' && item.document.hide_tag == '0' ">
                                                    <img class="crown" [src]="paidIcon">
                                                  </a>

                                                  <a class="_free_tag"
                                                      *ngIf="strToLower(item.document.content_availability) == 'free' && item.document.hide_tag == '0' ">
                                                      <img class="crown" [src]="freeIcon">
                                                  </a>

                                                  <a class="_free_tag"
                                                      *ngIf="strToLower(item.document.content_availability) == 'no tag' && item.document.hide_tag == '0' ">
                                                  </a>
                                              </div>
                                              <app-new-hover
                                                [item]="item.document"
                                                [page_view]="'Screen_Home'"
                                                (addToWatchlist)="addToWatchlistArray($event)"
                                                (removeFromWatchlist)="removeFromWatchlistArray($event)"></app-new-hover>
                                            </div>
                                          </li>
                                        </ul>
                                        <div *ngIf="allSearchResults.length == 0">
                                        <div class="_not_find">
                                          <div class="search-not-found" >
                                              <img src="../../../assets/img/Icon/search-result.svg" />
                                              <p>Sorry, we couldn't find any results matching</p>
                                              <span>'{{searchQuery}}'</span>
                                          </div>
                                        </div>

                                        <div class="trending-search-block"
                                          *ngIf="trendingResult?.length > 0">
                                          
                  
                                          <div class="trending-search-list text-left">
                                              <label>Trending Searches :</label>
                                          </div>
                  
                                          <div class="search-assets">
                                            <ul>
                                                <li (click)="closeModal()" class="item assets_hover new_asset_hover_type" *ngFor="let item of trendingResult">
                                                    <div class="new_asset" (click)="goto(item, loginPopup)">
                                                        <div class="new_asset_img">
                                                          <image-manipulation [assetName]="item.name" 
                                                          [img]="item.image_portrait ? item.image_portrait : item.image_landscape"
                                                          [params]="{ 'width': 220, 'height': 330 }" notFound="portrait"></image-manipulation>
                                                          
                                                          <a class="_premium"
                                                            *ngIf="strToLower(item.content_availability) == 'paid' && item.hide_tag == '0' ">
                                                            <img class="crown" [src]="paidIcon">
                                                          </a>

                                                          <a class="_free_tag"
                                                              *ngIf="strToLower(item.content_availability) == 'free' && item.hide_tag == '0' ">
                                                              <img class="crown" [src]="freeIcon">
                                                          </a>

                                                          <a class="_free_tag"
                                                              *ngIf="strToLower(item.content_availability) == 'no tag' && item.hide_tag == '0' ">
                                                          </a>

                                                        </div>
                                                        <app-new-hover
                                                          [item]="item"
                                                          [page_view]="'Screen_Home'"
                                                          (addToWatchlist)="addToWatchlistArray($event)"
                                                          (removeFromWatchlist)="removeFromWatchlistArray($event)"></app-new-hover>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                      </div>
                                    </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div *ngFor="let item of filteredSearchResults"
                                class="tab-pane fade"
                                [ngClass]="{'show active': activeTab === item.type + '-tab'}"
                                id="{{item.type}}-tab"
                                role="tabpanel"
                                aria-labelledby="{{item.type}}-tab">
                                <div class="other_assests">
                                  <div class="container-fluid">
                                    <div class="row">
                                      <div class="col-sm-12">
                                        <div class="search-assets">
                                          <ul>
                                            <li class="item assets_hover new_asset_hover_type search-assets-gap"
                                              *ngFor="let hit of item.results"
                                              (mouseenter)="assetHover(hit.document)">
                                              <div class="new_asset">
                                                <div class="new_asset_img">
                                                  <image-manipulation [assetName]="hit.document.name" 
                                                    [img]="hit.document.image_portrait ? hit.document.image_portrait : hit.document.image"
                                                    [params]="{ 'width': 220, 'height': 330 }" notFound="portrait"></image-manipulation>

                                                    <a class="_premium"
                                                      *ngIf="strToLower(item.document.content_availability) == 'paid' && item.document.hide_tag == '0' ">
                                                      <img class="crown" [src]="paidIcon">
                                                    </a>

                                                    <a class="_free_tag"
                                                        *ngIf="strToLower(item.document.content_availability) == 'free' && item.document.hide_tag == '0' ">
                                                        <img class="crown" [src]="freeIcon">
                                                    </a>

                                                    <a class="_free_tag"
                                                        *ngIf="strToLower(item.document.content_availability) == 'no tag' && item.document.hide_tag == '0' ">
                                                    </a>

                                                </div>
                                                <app-new-hover
                                                  [item]="hit.document"
                                                  [page_view]="'Screen_Home'"
                                                  (addToWatchlist)="addToWatchlistArray($event)"
                                                  (removeFromWatchlist)="removeFromWatchlistArray($event)"></app-new-hover>
                                              </div>
                                            </li>
                                            </ul>
                                          <div class="_not_find">
                                            <h4 class="no-result" *ngIf="item.results.length == 0">Sorry, we couldn't find any results matching
                                              <span> <b>"{{searchQuery}}" </b></span>
                                            </h4>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                          
                    </div>
                    </div>
                </div>
            
</section>
<ng-template #deleteAllRecentModal>

  
    
      <!-- <button type="button" class="close popup_close" data-dismiss="modal">&times;</button> -->
      <p class="text-white" style="font-weight: 200;width: calc(100% - 100px);">
        Are you sure?
      </p>

            <div class="text-right mt-2">
                <button class="btn btn-default popup_btn btn_hover  mr-3"
                  (click)="closeDeleteAllRecentModal()">
                    <span>{{language.cancel}}</span>
                </button>
                <button class="btn btn-default popup_btn btn_hover"
                  (click)="clearAllRecentSearches()"
                  data-dismiss="modal">
                    <span>
                        Clear History
                    </span>
                </button>
            </div>

</ng-template>
<ng-template #deleteRecentItemModal>
<!-- <div class="modal auth popup_background" id="deleteRecentItemModal"> -->

  
  
    
      <!-- <button type="button" class="close popup_close" data-dismiss="modal">&times;</button> -->
      <p class="text-white" style="font-weight: 200;width: calc(100% - 100px);">
        Are you sure you want to delete "{{currentDeleteItem}}"?
      </p>

            <div class="text-right mt-2">
                <button class="btn btn-default popup_btn btn_hover  mr-3"
                  (click)="closeDeleteRecentItemModal()">
                    <span>{{language.cancel}}</span>
                </button>
                <button class="btn btn-default popup_btn btn_hover"
                  (click)="removeFromRecentSearches($event, currentDeleteItem)">
                    <span>
                        Clear History
                    </span>
                </button>
            </div>

</ng-template>
<ng-template #loginPopup>
  <app-login-register></app-login-register>
</ng-template>