import { Component, OnInit, Inject, Input, Output, EventEmitter, ViewChild, HostListener, SimpleChanges, ElementRef } from '@angular/core';
import { OwlOptions, SlidesOutputData, CarouselComponent } from 'ngx-owl-carousel-o';
import { AssetsService } from "../../core/services/assets.service";
import { UserService } from '../../core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlayListService } from "src/app/views/playlist/playlist/playlist.service";
import { CommonService } from "src/app/core/services/common.service";
import { DOCUMENT } from '@angular/common';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { ActivatedRoute, Router } from '@angular/router';
declare var videojs;
declare var $;
import { VideoPlayerService } from "src/app/core/services/videoPlayerService";

@Component({
  selector: 'app-banner-slider',
  templateUrl: './banner-slider.component.html',
  styleUrls: ['./banner-slider.component.css'],
  providers: [UserService, PlayListService]
})
export class BannerSliderComponent implements OnInit {

  @Input() owlOption: OwlOptions;
  @Input() data: any = {};
  @Input() page_view;
  @Output() deleteRecentWatch: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closeAddtoWatchListModal') closeAddtoWatchListModal;
  @ViewChild('addToWatchListSuccessFromBanner') addToWatchListSuccessFromBanner;
  @ViewChild('owlCarousel') owlCarousel: CarouselComponent;
  @ViewChild('thumbnailCarousel') thumbnailCarousel: CarouselComponent;
  @ViewChild('loginContent') loginContent: ElementRef;
  public bannerResult: any = null;
  streamInfo: any;
  public userInfo: any;
  username: string;
  playListId: string;
  notFoundImg: any;
  language: any;

  playermodal: any = [];
  isPreviewVideoMuted: boolean = true;
  timeDuration: any;
  isVideoPlaying: any[] = [];
  activeSlideIndex: number = 2;
  currentPlayedIndex: number;
  addBefourVideo1Second: any = [];
  thumbnailCarouselOptions: OwlOptions;
  anumaitionDurtion:any;
  currentSlideIndex: number = 0;
  changeTimeout: any;
  isAuthenticated: boolean;
  isCarouselInitialized: boolean = false;
  isThumbnailCarouselInitialized: boolean = false;
  currentThumbnailIndex: number = 0;
  isVideoPlayingTimeout: any;
  isSubscribeAnimation: boolean = true;
  subscribeAnimationTimeout: any;
  assetDetail: any;

  constructor(
    public AssetsService: AssetsService,
    public UserService: UserService,
    private modalService: NgbModal,
    public playListService: PlayListService,
    public commonService: CommonService,
    @Inject(DOCUMENT) private document: Document,
    private googleEvent: EventTrackingService,
    private router: Router,
    public VideoPlayerService: VideoPlayerService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('data inside banner slider ngOnChanges: ', changes?.data.currentValue);
    if (changes?.data.currentValue?.length > 0) {
      this.bannerResult = changes?.data.currentValue;
      if (this.userInfo) {
        this.getAssetDetail(0);
      }

      if (this.bannerResult?.length === 1)
        this.owlOption = { ...this.owlOption, loop: false }
      
      if (this.bannerResult?.length <= 4)
        this.thumbnailCarouselOptions = { ...this.thumbnailCarouselOptions, loop: false }

      setTimeout(() => {
        const allBannerSlides = document.querySelectorAll('.home_slide .owl-item');
        for (let i = 0; i < allBannerSlides.length; i++) {
          if (allBannerSlides[i].classList.contains('cloned') == true && allBannerSlides[i].querySelector('.trailer_video .video-js')) {
            allBannerSlides[i].querySelector('.trailer_video .video-js').removeAttribute('id');
            const gId = allBannerSlides[i].querySelector('.trailer_video .video-js');
          }
        }
  
        this.playPreviewVideo(0);
      }, 0);
    }
    // this.playPreviewVideo(0);
  }

  ngOnInit() {
    this.isAuthenticated = !!localStorage.getItem('isAuthenticated');
    this.thumbnailCarouselOptions = {
      loop: true,
      dots: false,
      margin: 10,
      nav: true,
      autoplay: false,
      autoplayHoverPause: true,
      navText: [
        "<img src='assets/img/Icon/left-arrow.svg'>",
        "<img src='assets/img/Icon/right-arrow.svg'>",
      ],
      items: 4
    };
    $(".dark").addClass("homeAndDetailPage");
    this.language = this.commonService.languageChange();
    this.notFoundImg = "landscape";
    if (localStorage.getItem('userInfo')) {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.username = this.userInfo.result.user.username;
      this.playListId = localStorage.getItem('playListId');
    }
    // this.bannerResult = this.data;
    //let isIos = /iPhone/i.test(window.navigator.userAgent)
    if (this.userInfo) {
      // this.getWatchList();
    }
    // if (isIos) {
    //   this.owlOption.autoplay = false;
    //   delete this.owlOption.responsive['0'].autoplayTimeout;
    //   delete this.owlOption.responsive['600'].autoplayTimeout;
    //   delete this.owlOption.responsive['1000'].autoplayTimeout;
    //   delete this.owlOption.responsive['1300'].autoplayTimeout;
    // }

    this.owlOption = { ...this.owlOption, autoplay: false }

  }

  ngOnDestroy() {
    // console.log('ngOnDestroy banner slider');
    if (this.changeTimeout)
      clearTimeout(this.changeTimeout);
    this.playermodal.forEach(item => item?.dispose());
  }

  onCarouselInitialized(event: SlidesOutputData) {
    // console.log('carousel initialized');
    if (this.isCarouselInitialized)
      return;
    
    this.isCarouselInitialized = true;

    // setTimeout(() => {
    //   const allBannerSlides = document.querySelectorAll('.home_slide .owl-item');
    //   console.log('allBannerSlides: ', allBannerSlides);
    //   for (let i = 0; i < allBannerSlides.length; i++) {
    //     if (allBannerSlides[i].classList.contains('cloned') == true && allBannerSlides[i].querySelector('.trailer_video .video-js')) {
    //       allBannerSlides[i].querySelector('.trailer_video .video-js').removeAttribute('id');
    //       const gId = allBannerSlides[i].querySelector('.trailer_video .video-js');
    //     }
    //   }

    //   this.playPreviewVideo(0);
    // }, 0);
  }

  onCarouselTranslated(event: SlidesOutputData) {
    if (event.startPosition === this.currentSlideIndex) {
      return;
    }
    if (this.changeTimeout) {
      clearTimeout(this.changeTimeout);
    }
    if (this.subscribeAnimationTimeout) {
      clearTimeout(this.subscribeAnimationTimeout);
    }
    this.isSubscribeAnimation = false;
    this.isSubscribeAnimation = true;
    this.subscribeAnimationTimeout = setInterval(() => {
      console.log('setInterval');
      this.isSubscribeAnimation = false;
      setTimeout(() => {
        this.isSubscribeAnimation = true;
      }, 100);
    }, 7000);

    this.isVideoPlaying[this.currentSlideIndex] = false;
    if(this.playermodal[this.currentSlideIndex]) {
      this.playermodal[this.currentSlideIndex].pause();
      this.playermodal[this.currentSlideIndex].muted(true);
    }

    this.currentSlideIndex = event.startPosition;
    this.thumbnailCarousel.to(this.currentSlideIndex.toString());
    this.playPreviewVideo(this.currentSlideIndex);
    if(this.bannerResult?.length <= 4){
      this.document.querySelectorAll('.slider_thumbnail .owl-item').forEach(item => {
        item.classList.remove('thumbnail-active');
      });
      
      let activeThumbnailSlides = this.document.querySelectorAll('.slider_thumbnail .owl-item.active');
      activeThumbnailSlides[this.currentSlideIndex].classList.add('thumbnail-active');
    }
    if (!this.bannerResult[this.currentSlideIndex]?.['assetDetail'] && this.userInfo) {
      this.getAssetDetail();
    }
  }

  onThumbnailCarouselInitialized(event: SlidesOutputData) {
    if (this.isThumbnailCarouselInitialized)
      return;
    
    this.isThumbnailCarouselInitialized = true;
    setTimeout(() => {
      let activeThumbnailSlides = this.document.querySelectorAll('.slider_thumbnail .owl-item.active');
      activeThumbnailSlides[0].classList?.add('thumbnail-active');
    }, 0);
  }

  onThumbnailCarouselTranslated(event: SlidesOutputData) {
    if (event.startPosition === this.currentThumbnailIndex) {
      console.log('carousel start position: ', event.startPosition);
      console.log('currentThumbnailIndex: ', this.currentThumbnailIndex);
      return;
    }
    this.owlCarousel.to(event.startPosition.toString());
    this.currentThumbnailIndex = event.startPosition;
    this.document.querySelectorAll('.slider_thumbnail .owl-item').forEach(item => {
      item.classList.remove('thumbnail-active');
    });
    let activeThumbnailSlides = this.document.querySelectorAll('.slider_thumbnail .owl-item.active');
    activeThumbnailSlides[0].classList.add('thumbnail-active');
  }

  goToNextSlide() {
    this.owlCarousel.next();
    this.thumbnailCarousel.next();
  }

  getAssetDetail(bannerIndex?: number) {
    let index = bannerIndex || this.currentSlideIndex
    let assetDetail = {
      "code": this.bannerResult[index].code,
      "type": this.bannerResult[index].type || ''
    }
    let urlEncodedDetail = this.UserService.convertoJSONToUrlEncoded(assetDetail);
    this.AssetsService.getAssetDetail(urlEncodedDetail).subscribe(assetDetailRes => {
      console.log('assetDetailRes: ', assetDetailRes);
      this.bannerResult[this.currentSlideIndex]['assetDetail'] = assetDetailRes.result;
    }, (error) => {
      console.error('error fetching asset detail: ', error);
    });
  }

  playPreviewVideo(index: number) {
    if (this.bannerResult[index]?.preview && this.bannerResult[index].preview[0]?.preview_url) {
      // console.log(`playPreviewVideo if block for index ${index} name ${this.bannerResult[index]?.name}`);
      let hrs = this.bannerResult[index]?.preview[0]?.preview_hrs ? parseInt(this.bannerResult[index]?.preview[0]?.preview_hrs) * 3600 : 0;
      let min = this.bannerResult[index]?.preview[0]?.preview_min ? parseInt(this.bannerResult[index]?.preview[0]?.preview_min) * 60 : 0;
      let sec = this.bannerResult[index]?.preview[0]?.preview_sec ? this.bannerResult[index]?.preview[0]?.preview_sec : 0;
      this.timeDuration = (parseInt(sec) + min + hrs) * 1000;
      this.anumaitionDurtion = (this.timeDuration / 1000).toFixed(0);
      // this.owlOption = { ...this.owlOption, autoplayTimeout: this.timeDuration, autoplay: false }
    } else {
      // console.log('playPreviewVideo else block for: ', index);
      this.timeDuration = 5000;
      this.changeTimeout = setTimeout(() => {
        // this.owlCarousel.next();
        this.goToNextSlide();
        // console.log('go to next slide after no video found');
      }, 5000);
      return;
      // this.owlOption = { ...this.owlOption, autoplayTimeout: this.timeDuration, autoplay: true }
    }
    let assetDetail = {
      "code": this.bannerResult[index].code,
    }

    let assetInfo = this.UserService.convertoJSONToUrlEncoded(assetDetail);

    this.VideoPlayerService.playPreview(assetInfo).subscribe(
      (playObj) => {
        let previewData = playObj.result;
        // console.log("preview url for: " + index + ': ', previewData.cloud_front?.video_url);

        let videoPlayUrl: any;
        clearTimeout(this.changeTimeout);
        this.changeTimeout = setTimeout(() => {
          // console.log('changeTimeout started');
            if (
              index === this.currentSlideIndex &&
              previewData.cloud_front?.cloudfront_key_pair_id &&
              previewData.cloud_front?.video_url &&
              previewData.cloud_front?.cloudfront_policy &&
              previewData.cloud_front?.cloudfront_signature
            ) {
            videoPlayUrl = previewData.cloud_front?.video_url; 
            this.setCookie(
              "CloudFront-Key-Pair-Id",
              previewData.cloud_front?.cloudfront_key_pair_id.trim(),
              1
            );
            this.setCookie(
              "CloudFront-Policy",
              previewData.cloud_front?.cloudfront_policy.trim(),
              1
            );
            this.setCookie(
              "CloudFront-Signature",
              previewData.cloud_front?.cloudfront_signature.trim(),
              1
            );
            ("http://j-cdr.digivive.com/HLS_Aaj/Aaj.m3u8");

            let new_source = {
              //sample source
              // sources: [
              //   {
              //     type: 'video/mp4',
              //     src: 'https://www.w3schools.com/html/mov_bbb.mp4',
              //   },
              // ],
              sources: [{ src: videoPlayUrl, type: "application/x-mpegURL" }],
              controls: false,
              autoplay: true,
              withCredentials: true,
              crossDomain: true,
              fluid: true,
              muted: true,
              controlBar: {
                children: [
                ]
              },
            };

            if (!this.playermodal[index]) {
              this.playermodal[index] = videojs("bannervideo" + index, new_source);
              this.playermodal[index].poster(this.bannerResult[index].image_landscape);
  
              // Add error event listener
              this.playermodal[index].on("error", (event) => {
                console.error('Error playing preview video:', event);
                if (this.isVideoPlayingTimeout)
                  clearTimeout(this.isVideoPlayingTimeout);
                this.isVideoPlaying[index] = false;
                // this.owlCarousel.next();
                this.goToNextSlide();
              });
            }
  
            this.playermodal[index].ready(() => {
              if (this.playermodal[index].error()) {
                console.error('Error detected in playermodal before playing:', this.playermodal[index].error());
                if (this.isVideoPlayingTimeout)
                  clearTimeout(this.isVideoPlayingTimeout);
                this.isVideoPlaying[index] = false;
                // this.owlCarousel.next();
                this.goToNextSlide();
                return;
              }
              this.playermodal[index].currentTime(0);
              this.playermodal[index].play();
              this.isPreviewVideoMuted = true;
              this.isVideoPlayingTimeout = setTimeout(() => {
                this.isVideoPlaying[index] = true;
              }, 1000);
              this.playermodal[index].muted(true);
  
              this.playermodal[index].on("ended", (event) => {
                // this.owlCarousel.next();
                this.goToNextSlide();
                this.playermodal[index].pause();
              });
            });
  
          } 
          else {
            // setTimeout(() => {
              // this.owlCarousel.next();
              this.goToNextSlide();
            // }, 5000);
          }
        }, 5000);
      });
  }

  handleThumbnailClick(thumbnailIndex: number) {
    // console.log('thumbnailClicked');
    this.owlCarousel.to(thumbnailIndex.toString());
    this.thumbnailCarousel.to(thumbnailIndex.toString());
  }

  addRemoveFromWatchlist(item: any, event: Event) {
    // button.disabled = true;
    event.stopPropagation();

    if (!this.isAuthenticated) {
      this.openLoginModal();
      return;
    }

    if (!item?.is_watchlist_added || item?.is_watchlist_added == 0) {
      this.googleEvent.eventEmitter("Watchlist" , "Watchlist" , this.username+'-'+item.code+'-'+item.name,0,this.username , this.username+'-'+item.code+'-'+item.name);
      let params = {
        type: item.type,
        code: item.code,
      };
      this.AssetsService.addToWatchList(params).subscribe(
        (data) => {
          if (data.response_code === 200 || data.response_code === 201) {
            item.is_watchlist_added = 1;
            // this.addToWatchlist.emit(item);
          }
          // button.disabled = false;
        },
        (error) => {
          console.error('Error adding to watchlist:', error);
          // button.disabled = false;
        }
      );
    } else {
      let params = {
        code: [item.code],
      };
      this.AssetsService.deleteFromWatchlist(params).subscribe(
        (data) => {
          if (data.response_code === 200 || data.response_code === 201) {
            item.is_watchlist_added = 0;
            // this.removeFromWatchlist.emit(item);
          }
          // button.disabled = false;
        },
        (error) => {
          console.error('Error deleting from watchlist:', error);
          // button.disabled = false;
        }
      );
    }
  }

  openLoginModal() {
    this.modalService.open(this.loginContent, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  muteUnmutePreview(isMuted: boolean, index, event: Event) {
    event.stopPropagation();
    if (isMuted) {
      this.isPreviewVideoMuted = false;
      var muted = this.playermodal[index].muted(false);
    } else {
      this.isPreviewVideoMuted = true;
      var muted = this.playermodal[index].muted(true);
    }
  }

  // playVideo(item, content, loginContent) {
  //   if (this.userInfo) {
  //     this.streamInfo = item;
  //     this.streamInfo['stop'] = this.streamInfo.stop ? this.streamInfo : 0;
  //     this.streamInfo['genre'] = this.streamInfo?.asset_detail?.genre;
  //     this.commonService.goToDetail(this.streamInfo, this.page_view);
  //   }
  //   else {
  //     this.commonService.setBeforeLoginState(item);
  //     // this.router.navigateByUrl('/login');
  //     this.modalService.open(loginContent, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
  //     }, (reason) => {
  //     });
  //   }
  // }

  playVideo(event: MouseEvent,item, content, loginContent,full_screen=false) {
    event.stopPropagation();  // Prevent the event from bubbling up
    if (this.userInfo) {
      this.streamInfo = item;
      this.streamInfo['stop'] = this.streamInfo.stop ? this.streamInfo : 0;
      this.streamInfo['genre'] = this.streamInfo?.asset_detail?.genre;
      this.streamInfo['full_screen'] = full_screen;
      console.log('is_pack_active for asset ', item.name, ': ', this.streamInfo?.assetDetail?.is_pack_active);
      if (!full_screen || (item.content_availability.toLowerCase() == 'free' || item.content_availability.toLowerCase() == 'paid' && this.streamInfo?.assetDetail?.is_pack_active == 1)) {
        this.commonService.goToDetail(this.streamInfo, this.page_view);
      } else {
        this.router.navigateByUrl('/subscribe');
      }
    }
    else {
      // this.commonService.setBeforeLoginState(item);
      this.modalService.open(loginContent, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => {
      });
      this.commonService.setDetailRedirectParams(item);
    }
  }
  
  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  strToLower(str: string) {
    return str?.toLowerCase();
  }

  strToString(str) {
    return str?.toString();
  }

}
