<div class="container mt-4">
    <div class="d-flex align-items-center justify-content-between">
        <img src="../../../assets/img/logo.png" class="profile-logo-width" />
        <a href="javascript:void(0);" (click)="handleCancel()">
            <img src="../../../assets/img/Icon/close.svg" />
       </a>
    </div>
    <div class="profile-align">
        <div class="row"  *ngIf="!verifyPinSuccess">
            <div class="col-md-12">
                <div class="col-md-12 text-center">
                    <div class="add_profile_width set_pin">
                        <div class="col-md-12 text-center">
                            <label class="login_title">{{language.hi}} {{profileName}}!</label> <!---->
                            <label class="text-white mb-0">{{language.enterYourPinToAccess}}</label>
                        </div>
                        <form [formGroup]="verifyPinForm" (submit)="verifyPinSubmit()">
                            <div class="add_profile_width1 ">
                                <div class="otp_line form-group text-center mt-5">
                                    <div class="four_digit_pin" [ngClass]="(api_error || showValidationErrors) && 'otp-invalid'">
                                        <ng-otp-input (onInputChange)="onPinChange($event)"
                                            [config]="{length:4,allowNumbersOnly:true}">
                                        </ng-otp-input>
                                        <div class="popup-error-height text-right">
                                            <div  *ngIf="showValidationErrors && pinFormControls.profile_pin.errors">
                                                <app-error
                                                    *ngIf="pinFormControls.profile_pin.hasError('required') || pinFormControls.profile_pin.invalid"
                                                    [message]="language.pleaseEnterValidPIN"></app-error>
                                            </div>
                                            <div *ngIf="api_error">
                                                <app-error [message]="api_error"></app-error>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                

                                
                                <button type="submit" class="btn btn-default mt-3 btn_height btn_hover profile-btn">
                                    <span>{{language.proceed}}</span>
                                </button>
                                <div class="text-center mt-5">
                                    <a href="javascript:;" [routerLink]="'../../reset-pin/' + profileId">
                                        <span>{{language.forgotPin}}?</span>
                                    </a>
                                    <!-- <button (click)="handleCancel()" type="button" class="btn btn-default mt-4 btn_height btn_hover profile-btn">
                                        <span>{{language.cancel}}</span>
                                    </button> -->
                                </div>
                            </div>
                        </form>

                    </div>
                </div>

            </div>
        </div>
        <div  class="profile-align" *ngIf="verifyPinSuccess">
            <div class="success-add text-center">
                <label class="login_title">{{language.hi}} {{profileName}}!</label>
                <h4 class="_verify_t text-white" style="font-weight: 400;">{{api_msg}}</h4>
                <div class="lottie-icon-block">
                    <div class="lottie-icon text-center">
                        <ng-lottie [options]="successOptions"></ng-lottie>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>