<div class="bodyarea">
    <div class="row">
        <div class="col-md-12">
            <button  type="button" class="close popup_close" (click)="closeModal()" #closebutton>&times;</button>
            <div class="form_bg">
                <div class="text-center mb-4">
                    <div class="mb-4"><img class="profile-logo-width" src="../../../../assets/img/logo.png" /></div>
                </div>
                <ng-container *ngIf="!resetPassSuccess">
                    <div class="text-center">
                        <label class="login_title">{{language.resetPassword}}</label>
                    </div>
                    <form *ngIf="!isValidField && !resetPassSuccess" [formGroup]="resetPasswordForm" class="transparent_form" autocomplete="off">
                        <div class="form-group mb-0">
                            <label class="input_heading">{{language.createNewPassword}}</label>
                            <div class="input_line">
                                <input [type]="loginPassHide ? 'password' : 'text'" class="form-control" [placeholder]="language.enterNewPassword" formControlName="password">
                                <a class="input-edit-icon" href="javascript:;" (click)="hideRPass()">
                                    <!-- <i *ngIf="loginPassHide==true" class="fa fa-eye-slash"></i>
                                    <i *ngIf="loginPassHide==false" class="fa fa-eye"></i> -->

                                    <img *ngIf="loginPassHide==true" src="../../../../assets/img/Icon/eye-off.svg" />
                                    <img *ngIf="loginPassHide==false" src="../../../../assets/img/Icon/eye-on.svg" />
                                </a>
                            </div>
                            <div class="text-right" style="min-height: 30px;">
                                <app-error
                                *ngIf="isSubmit && resetPasswordControls.password.invalid && resetPasswordControls.password.hasError('required')" class="error-toast animate__animated animate__fadeInUp"
                                [message]="language.pleaseEnterYourPassword"></app-error>
                                    <!-- <span style="width: 25px">
                                        <ng-lottie [options]="errorOptions"></ng-lottie>
                                    </span>
                                    <span>{{language.pleaseEnterYourPassword}}</span> -->

                                <app-error
                                *ngIf="resetPasswordControls.password.invalid  && (resetPasswordControls.password.dirty || resetPasswordControls.password.touched)"
                                [message]="language.passwordShouldBe"></app-error>
                                    <!-- <span style="width: 25px">
                                        <ng-lottie [options]="errorOptions"></ng-lottie>
                                    </span>
                                    <span *ngIf="resetPasswordControls.password.hasError('minlength')">{{language.passwordShouldHaveMinimum}}</span>
                                    <span *ngIf="resetPasswordControls.password.hasError('maxlength')">{{language.passwordShouldHaveMaximum}}</span> -->
                            </div>
                        </div>
                        

                        <div class="form-group mb-0">
                            <label class="input_heading">{{language.confirmNewPassword}}</label>
                            <div class="input_line">
                                <input [type]="loginPassHide1 ? 'password' : 'text'" class="form-control"
                                    formControlName="confirm_password" [placeholder]="language.confirmNewPassword" />
                                <a class="input-edit-icon" href="javascript:;" (click)="hideRPass1()">
                                    <!-- <i *ngIf="loginPassHide1==true" class="fa fa-eye-slash"></i>
                                    <i *ngIf="loginPassHide1==false" class="fa fa-eye"></i> -->

                                    <img *ngIf="loginPassHide1==true" src="../../../../assets/img/Icon/eye-off.svg" />
                                    <img *ngIf="loginPassHide1==false" src="../../../../assets/img/Icon/eye-on.svg" />
                                </a>
                            </div>
                            <div class="text-right">
                                <app-error
                                *ngIf="isSubmit && resetPasswordControls.confirm_password.invalid && resetPasswordControls.confirm_password.hasError('required')"
                                [message]="language.pleaseEnterConfirmPassword"></app-error>
                                    <!-- <span style="width: 25px">
                                        <ng-lottie [options]="errorOptions"></ng-lottie>
                                    </span>
                                    <span>{{language.pleaseEnterConfirmPassword}}</span> -->
                                <app-error
                                *ngIf="isSubmit && this.resetPasswordControls.confirm_password.value !== '' && (this.resetPasswordControls.password.value !=  this.resetPasswordControls.confirm_password.value)"
                                [message]="language.passwordAndConfirmPasswordDoNotMatch"></app-error>
                                    <!-- <span style="width: 25px">
                                        <ng-lottie [options]="errorOptions"></ng-lottie>
                                    </span>
                                    <span>{{language.passwordAndConfirmPasswordDoNotMatch}}</span> -->
                            </div>
                        </div>
                        <!-- <button (click)="resetPassword()" type="submit" class="btn btn-default mt-4 btn_height btn_hover">{{language.setPassword}}</button> -->
                    </form>
                    <div class="text-center mt-4">
                        <!-- <small class="text-white">
                            {{api_msg}}
                            <b>{{verifyOtpFormControls.username.value}}</b>
                            <a href="javascript:;" (click)="editUsername()">
                                <img src="../../../../assets/img/Icon/edit.svg" alt="" />
                            </a>
                        </small> -->

                        <div *ngIf="sendOtpSuccess" class="login_white_text">
                            {{language.anOtpHasBeenSentTo}}
                            <span class="login_white_text_bold">{{verifyOtpFormControls.username.value}}</span>
                            <a href="javascript:;" (click)="editUsername()">
                                <img class="edit-icon-width" src="../../../../assets/img/Icon/edit.svg" alt="" />
                            </a>
                        </div>
                        <label class="login_white_text mt-2"><b>{{language.enterOtpToConfirm}}</b></label>
                    </div>
                    <form [formGroup]="verifyOtpForm"
                    (ngSubmit)="handleSubmit()" class="transparent_form" autocomplete="off">
                    <div class="otp_line form-group" [ngClass]="((isOtpInvalid && verifyOtpFormControls.otp.errors) || api_error) && 'otp-invalid'">
                        <ng-otp-input (onInputChange)="onOtpChange($event)"
                            [config]="{length:6, allowNumbersOnly:true}" [formCtrl]="otp" #ngOtpInput>
                        </ng-otp-input>
                    </div>
                    <div class="popup-info-height">
                        <div class="row">
                            <div class="col-md-6 text-left">
                                <small class="text-white" *ngIf="timeLeft">{{ '00:' + (timeLeft < 10 ? '0' + timeLeft : timeLeft) }}</small>
                            </div>
                            <div class="col-md-6 text-right">
                                <small class="d-flex justify-end">
                                    <span class="text-white mr-2">{{language.didNotGetTheOtp}}</span>
                                    <a href="javascript:void(0);" (click)="resendOtp()" *ngIf="!timeLeft">{{language.resend}}</a>
                                    <a href="javascript:void(0);" *ngIf="timeLeft" class="resend_disable" style="opacity: 0.5;cursor: no-drop;">{{language.resend}}</a>
                                </small>
                            </div>
                        </div>
                        <div class="text-right">
                            <small *ngIf="resendOtpSuccess" class="text-white">{{otp_msg}}</small>
                            <div *ngIf="verifyOtpFormControls.otp.errors">
                                <app-error
                                *ngIf="isOtpInvalid && (verifyOtpFormControls.otp.hasError('required') || verifyOtpFormControls.otp.invalid)"
                                [message]="language.pleaseEnterValidOTP"></app-error>
                                    <!-- <span style="width: 25px">
                                        <ng-lottie [options]="errorOptions"></ng-lottie>
                                    </span>
                                    <span>
                                        {{language.pleaseEnterValidOTP}}
                                    </span> -->
                            </div>
                            <app-error
                            *ngIf="api_error"
                            [message]="api_error"></app-error>
                                <!-- <span style="width: 25px">
                                    <ng-lottie [options]="errorOptions"></ng-lottie>
                                </span>
                                <span>{{api_error}}</span> -->
                        </div>
                    </div>
                    
                    <button type="submit" class="btn btn-default btn_height btn_hover">{{language.setNewPassword}}</button>
                    </form>
                </ng-container>
                <div *ngIf="resetPassSuccess">
                    <div class="success-add">
                        <h2 style="color:#fff;font-size: 20px;font-weight: 100;line-height: 35px;"
                            class="_verify_t">
                            {{api_msg}}
                        </h2>
                        <!-- <div class="lottie-icon text-center" style="width: 100%;">
                            <ng-lottie [options]="successOptions"></ng-lottie>
                        </div> -->
                        <div class="lottie-icon-block">
                            <div class="lottie-icon">
                                <ng-lottie [options]="successOptions"></ng-lottie>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #resetPassword>
    <app-reset-password [username]="username"></app-reset-password>
</ng-template>

<ng-template #loginRegisterContent>
    <app-login-register></app-login-register>
</ng-template>