import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core';

@Component({
  selector: 'app-myaccount-menu',
  templateUrl: './myaccount-menu.component.html',
  styleUrls: ['./myaccount-menu.component.css']
})
export class MyaccountMenuComponent implements OnInit {
  userInfo: any;
  userName: string;
  isUsernameMobile: boolean;
  countryDialCode: string;

  constructor(
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.userService.getUserDetails().subscribe(userDetailsRes => {
      console.log('userDetailsRes: ', userDetailsRes);
      this.userInfo = userDetailsRes.result;
      this.userName = userDetailsRes.result.username;
      this.countryDialCode = userDetailsRes.result.country_dial_code;
      this.isUsernameMobile = this.userInfo?.type === 'mobile';
    }, (error: any) => {
      console.error('error fetching user details: ', error);
    });
  }

  handleLogout() {
    document.getElementById('header-logout-button').click();
  }

}
