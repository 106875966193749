import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Errors, UserService, ApiService } from '../../../core';
import { ActivatedRoute, Router, NavigationStart, Event, NavigationEnd } from '@angular/router';
import { NgbModal, ModalDismissReasons, } from '@ng-bootstrap/ng-bootstrap';
import { SearchService } from '../../../shared/search/search.service';
import { AccountService } from '../../../views/account/account.service';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { LocalStorageService } from '../../../core/services/local-stroage.service'
import { CatlougeTypeConstants } from 'src/app/core/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';
import { AnimationOptions } from "ngx-lottie";
import { successOptions } from 'src/app/shared/lottie-config';
declare var $: any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userInfo: any;
  loginStatus: any;
  public isAuthenticated: any = false;
  @ViewChild('loginwindow') loginwindow;
  activeComponent: any;
  activeMenu: any = '';
  @ViewChild('searchTerm') searchTerm;
  @ViewChild('icl') icl: ElementRef;
  public loginModalContent: any;
  searchSubscriber: any;
  searchQuery = '';
  tempQueryStr: any;
  timezone: any;
  locale: any;
  timeZoneName: any;
  mobileSearchTerm: string = '';

  registeredBy: any;
  first_name_text: any;
  registredEmail: any;
  registredMobileNumber: any;
  parental_control: any;
  name_error: string;
  packDetail: any;
  canBuy: any;
  headerTabs: any = [];
  currentSlugName: any;
  getCurrentSlug: any;
  fristSlug: any;
  slugName: string;
  header: any;
  element: any;
  modalPopupStatus: boolean = true;
  errorCode: any;
  @ViewChild('openTellUsModel') openTellUsModel: ElementRef;
  errors1: { errors: {}; };
  public dynamicName;
  tellUsAllF: any = [];
  Currenturl: any = "";
  api_error: any;
  language: any;
  profileData: any;
  selectedProfile: any;
  addProfileCount: number;
  basePath: string;
  addForTopStrip: boolean = false;
  logoutSuccess: boolean;
  api_msg: string;
  successOptions: AnimationOptions = successOptions;
  defaultImageUrl: string = "https://stage-image.nexgtv.com/nexgtv/img/avatar/AVTR949964/66e804fa6ee35-06928.png";
  @ViewChild('searchComponent') searchComponent: ElementRef;
  username: string;
  isSearchOpen: boolean;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserService,
    private modalService: NgbModal,
    private serachSerarvice: SearchService,
    private apiService: AccountService,
    public activatedRoute: ActivatedRoute,
    private googleEvent: EventTrackingService,
    public localStorageService: LocalStorageService,
    public commonService: CommonService
  ) {
    if (window.location.href.includes("/app-pages")) {
      return;
    }
    this.slugName = localStorage.getItem('slugName');

    this.dynamicTab();
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.locale = Intl.DateTimeFormat().resolvedOptions().locale;
    this.timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZoneName;
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.language = this.commonService.languageChange();


    // this.router.events.subscribe((event: Event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.Currenturl = window.location.href;
    //     if (this.Currenturl.includes("/home") || this.Currenturl.includes("/detail")) {
    //       $(".dark").addClass("homeAndDetailPage");
    //     } else {
    //       $(".dark").removeClass("homeAndDetailPage");
    //     }
    //   }
    // });

  }



  ngOnInit(): void {
    if (window.location.href.includes("/app-pages")) {
      return;
    }
    this.basePath = environment.api_url.replace('api/v1/', '');
    
    this.Currenturl = window.location.href;

    document.body.scrollTop = 0;
    this.userService.activeMenu.subscribe((activeMenu) => {
      this.activeMenu = activeMenu;
      document.getElementById('navbarsExample04').classList.remove("show");
    });

    this.getCurrentSlug = this.userService.getCurrentSlugName;
   

    if (this.activeMenu == undefined || this.activeMenu == null || this.activeMenu == '') {
      this.activeMenu = this.getCurrentSlug;
    }

    this.searchSubscriber = this.route.queryParams.subscribe(params => {
      this.searchQuery = params['q'] == undefined ? '' : params['q'];

      //this.userService.updateSelectedMenu('Search');
    });

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.username = this.userInfo.result.user.username;
    this.isAuthenticated = localStorage.getItem('isAuthenticated');
    // this.userService.loginStatus(true);
    // this.loginwindow.nativeElement.click();
    this.userService.currentUser.subscribe((loginStatus) => {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.loginStatus = loginStatus;
      if (this.loginStatus == true || localStorage.getItem('isAuthenticated') == 'true') {
        localStorage.setItem('isAuthenticated', 'true')
        this.isAuthenticated = true;
        if (localStorage.getItem('profile_UserName') != "" && localStorage.getItem('profile_UserName') != undefined) {
          this.dynamicName = localStorage.getItem('profile_UserName');
        } else {
          this.dynamicName = "Hello";
        }

        this.getAllProfiles();

      } else {
        this.isAuthenticated = false;

      }
    });
    
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Send Google Analytics event after navigation is complete
        if (this.logoutSuccess) {
          console.log('logout block');
          this.googleEvent.eventEmitter('logout', 'logout', this.username + ' logged out final', 0, this.username, this.username + ' user logged out final');
          this.logoutSuccess = false;
        }
      }
    });

    // this.dynamicTab();

  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe(params => {
      if (params['search'] && !this.isSearchOpen) {
        this.isSearchOpen = true
        this.openSearchModal();
      } else if (!params['search']) {
        this.isSearchOpen = false;
      }
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.scrollY > 50) {
      this.addForTopStrip = true;
    } else {
      this.addForTopStrip = false;
    }
  }

  logout() {
    let url = 'logout';
    
    this.userService.logout(url).subscribe(data => {
      this.api_msg = data.message;
      this.userService.purgeAuth();
      // window.onbeforeunload = function() {
      //   this.googleEvent.eventEmitter('logout', 'logout', this.username + ' logged out', 0, this.username, this.username + ' user logged out');
      // }

      setTimeout(() => {
        this.closeModal();
        window.location.href = '/';
        // this.router.navigateByUrl('/');
      }, 2500);
      this.logoutSuccess = true;
    }, (error) => {
      this.api_error = error.message;
    });
  }

  addItem(newItem: boolean) {
    this.modalPopupStatus = newItem;
    this.openLoginModal(this.loginModalContent);
  }

  openLoginModal(content) {
    this.loginModalContent = content;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: this.modalPopupStatus }).result.then((result) => {
    }, (reason) => {
    });

  }

  openConfirmLogoutModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }
  
  goToSubscribe(content) {
    if (localStorage.getItem('isAuthenticated')) {
      this.router.navigate(['/subscribe']);
      return;
    } 
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        redirect_to: 'subscribe'
      }
    });
    this.openLoginModal(content);
  }

  openSearchModal() {
    this.modalService.open(this.searchComponent, {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      windowClass: 'search-popup',
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  openTellUs(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false, windowClass: 'tellu' }).result.then((result) => {
    }, (reason) => {
    });

  }

  closeModal() {
    try {
      document.querySelector("ngb-modal-window").classList.remove("show");
      document.querySelector("ngb-modal-backdrop").classList.remove("show");
      document.getElementById('nexgTV-app').click();
      document.querySelectorAll(".modal-backdrop").forEach(el => el.remove())
      document.querySelectorAll(".d-block").forEach(el => el.remove())
      document.body.style.overflow = "scroll";
    } catch (e) {

    } 
  }

  searchResult(e: any) {
    let term = e.target.value;
    term = term.trim();
    if (term.length === 0 || !term.trim()) {
      return false;
    }

    if (term.length > 2 && localStorage.getItem('isAuthenticated') == undefined) {
      this.router.navigate(['search'], { queryParams: { 'q': term } })
    } else if (term.length > 2 && localStorage.getItem('isAuthenticated') == "true") {

      let isParentalControlEnabled = this.serachSerarvice.parentalControlStatus();

      if (isParentalControlEnabled == null) {
        // then hit the profile api for parental status
        this.apiService.getData('signupnexg/profile')
          .subscribe((data: any) => {

            if (data.result) {
              this.serachSerarvice.setParentalControl(data.result.parental_control);
              this.router.navigate(['search'], { queryParams: { 'q': term } });
            } else {
              this.serachSerarvice.setParentalControl(null);
            }

          },
            error => {
              this.serachSerarvice.setParentalControl(null);
            },
            () => {
            }
          );

      } else {
        this.serachSerarvice.setParentalControl(isParentalControlEnabled);
        this.router.navigate(['search'], { queryParams: { 'q': term } });
      }

    }
  }

  submitSearch(mobileTerm?: string) {

    let term = this.searchTerm.nativeElement.value;
    term = term.trim();

    if (mobileTerm != undefined && (mobileTerm.length || !term.trim())) {
      term = mobileTerm.trim();
    }

    if (term.length === 0 || !term.trim()) {
      return false;
    } else if (localStorage.getItem('isAuthenticated') == undefined) {
      //this.serachSerarvice.setParentalControl(null);
      this.router.navigate(['search'], { queryParams: { 'q': term } });
    }

    else {

      let isParentalControlEnabled = this.serachSerarvice.parentalControlStatus();


      if (isParentalControlEnabled == null) {
        // then hit the profile api for parental status
        this.apiService.getData('signupnexg/profile')
          .subscribe((data: any) => {

            if (data.result) {
              this.serachSerarvice.setParentalControl(data.result.parental_control);
              this.router.navigate(['search'], { queryParams: { 'q': term } });
            } else {
              this.serachSerarvice.setParentalControl(null);
            }

          },
            error => {
              this.serachSerarvice.setParentalControl(null);
            },
            () => {
            }
          );

      } else {
        this.serachSerarvice.setParentalControl(isParentalControlEnabled);
        this.router.navigate(['search'], { queryParams: { 'q': term } });
      }

    }
  }

  submitMobileSearch() {
    let term = this.mobileSearchTerm;
    term = term.trim();
    this.submitSearch(term);
  }

  profileDetails() {
    let tabDataParams = {
      "profile_id": CatlougeTypeConstants.catlogue,
    }
    let getProfileData = this.userService.convertoJSONToUrlEncoded(tabDataParams);

    this.apiService.getData('user/profile/id', getProfileData)
      .subscribe((data: any) => {
        if (Object.keys(data.result).length > 0) {
          this.registeredBy = data.result.register_by;
          this.first_name_text = data.result.first_name;
          this.registredEmail = data.result.emailaddress;
          this.registredMobileNumber = data.result.mobile;
          this.parental_control = data.result.parental_control;
          // this.searchSerarvice.setParentalControl(data.result.parental_control);

        }

      },
        error => {
          this.name_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }

  gotToTab() {
    this.slugName = this.fristSlug;
    localStorage.setItem('slugName', this.fristSlug);
    this.userService.updateSelectedMenu(this.fristSlug);
    this.userService.setCurrentSlugName(this.fristSlug);
    this.router.navigateByUrl('/' + this.fristSlug);
    window.scroll(0, 0);
    // window.location.reload();


  }

  highLightMenu(slug: any, name: any) {
    this.slugName = slug;
    this.activeMenu = slug;
    localStorage.setItem('slugName', slug);
    this.userService.updateSelectedMenu(slug);
    this.userService.setCurrentSlugName(slug);
    this.router.navigateByUrl('/' + slug);
    const navbarToggleButton = document.getElementById('navbar-toggle-button');
    if (navbarToggleButton && !navbarToggleButton.classList.contains('collapsed')) {
      navbarToggleButton.classList.add('collapsed');
    }
    window.scroll(0, 0);
    // if(slug == 'home'){
    //   this.router.navigateByUrl('/');
    // }else{
    //   this.router.navigateByUrl('/'+slug);
    // }

  }

  dynamicTab() {

    // let tabDataParams = {
    //   "catlogue": CatlougeTypeConstants.catlogue,
    //   "call": "gettabs"
    // }

    // let dynamicTabsRes = this.userService.convertoJSONToUrlEncoded(tabDataParams);

    this.userService.dynamicTab().subscribe((tabRes) => {
      this.fristSlug = tabRes.result[0].slug;
      if (tabRes.result && tabRes.result.length > 0) {
        this.headerTabs = tabRes.result;
        if (this.slugName == undefined || this.slugName == null || this.slugName == "") {
          this.slugName = tabRes.result[0].slug;
        }
        // for (let index = 0; index < tabRes.result.length; index++) {
        //   this.element = tabRes.result[index].slug;
        // }

        // localStorage.setItem('tabsLength', this.headerTabs.length);
        // this.fristSlug = tabRes.result[0].slug;
        // this.currentSlugName = this.userService.currentSlugName;
        // if (this.currentSlugName) {
        //   this.activeMenu = this.currentSlugName
        //   this.slugName = this.currentSlugName;
        //   // localStorage.setItem('currentSlugName',this.currentSlugName);
        //   this.userService.updateSelectedMenu(this.currentSlugName);
        //   this.userService.setCurrentSlugName(this.currentSlugName);
        // } else {
        //   this.activeMenu = tabRes.result[0].slug;
        //   this.slugName = tabRes.result[0].slug;
        //   // localStorage.setItem('currentSlugName',tabRes.result[0].slug);
        //   this.userService.updateSelectedMenu(tabRes.result[0].slug);
        //   this.userService.setCurrentSlugName(tabRes.result[0].slug);
        // }

        // this.userService.updateSelectedMenu(tabRes.result[0].slug);
        // this.userService.setCurrentSlugName(tabRes.result[0].slug);
        // this.router.navigateByUrl('/'+tabRes.result[0].slug);

        // console.log(this.headerTabs);
      }

    })

  }

  getAllProfiles() {
    let params = { _userId: JSON.parse(localStorage.getItem('userInfo')).result.user._id };
    let url = 'profile/getall';
    this.userService.getPrfiles(url, params).subscribe(data => {
      this.profileData = data.result;
      this.selectedProfile = this.profileData.find(profile => profile._id === localStorage.getItem('profile_id'));
      if (!this.selectedProfile) {
        localStorage.setItem('profile_id', '');
        this.router.navigateByUrl('/manage-profile');
      }
      const profileCreationLimit = parseInt(localStorage.getItem('profileCreationLimit')) || 3;
      this.addProfileCount = profileCreationLimit - this.profileData.length;
    }, (error) => {
      console.error('error fetching profiles: ', error);
    });
  }

  switchProfile(profileId, profileName, isLockedProfile, profileImageUrl) {
    
    if (isLockedProfile){
      this.router.navigateByUrl(`/manage-profile/enter-pin/${profileId}`);
    } else {
      localStorage.setItem('profile_id', profileId);
      localStorage.setItem('profile_UserName', profileName);
      localStorage.setItem('profileImageUrl', profileImageUrl);
      localStorage.setItem('profileSelected', 'true'); // To display welcome card in Dynamic Tab component
      window.location.reload();
    }
  }
}
