import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'image-manipulation',
  templateUrl: './image-manipulation.component.html',
  styleUrls: ['./image-manipulation.component.css'],
})
export class ImageManipulationComponent implements OnInit, OnChanges {

  BASE_ASSETS_PATH: string = environment.BASE_ASSETS_PATH;
  APP_CLOUD_NAME: string = environment.APP_CLOUD_NAME;
  EXT: string = environment.SRC_EXT;

  @Input() public params: any = {};
  @Input() img: string;
  @Input() notFound: string;
  @Input() assetName: string;

  assetsImage: string = '';
  isImageInvalid: boolean;

  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
    this.updateImage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.img || changes.params || changes.notFound) {
      this.updateImage(); // Update image if any input changes
    }
  }

  private updateImage(): void {
    this.isImageInvalid = false;
    if (!this.img) {
      this.isImageInvalid = true;
    }
    this.assetsImage = this.commonService.compressImgUrl(this.img, this.params, this.notFound);
  }

  onImageError() {
    this.assetsImage = "./assets/img/not-found/" + this.notFound + ".png";
    this.isImageInvalid = true;
  }
}
