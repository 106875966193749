import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/core/services/user.service';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from "src/app/core/services/common.service";
import { AnimationOptions } from 'ngx-lottie';
import { successOptions } from 'src/app/shared/lottie-config';
import { DOCUMENT } from '@angular/common';
import { NgOtpInputComponent } from 'ng-otp-input';

declare var $: any;

@Component({
  selector: 'app-setpin',
  templateUrl: './setpin.component.html',
  styleUrls: ['./setpin.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SetpinComponent implements OnInit {
  api_error: any;
  api_msg: any;
  setPinForm: FormGroup;
  pin: any;
  isPinSubmit: boolean = false;
  timezone: any;
  locale: any;
  timezoneForGA: any;
  timeZoneName: any;
  username: any;
  language: any;
  isSetPinSuccess: boolean;
  successOptions: AnimationOptions = successOptions;
  isSetPinVisible: boolean = true;
  isConfirmPinVisible: boolean;
  @Output("setPinSuccess") setPinSuccess: EventEmitter<any> = new EventEmitter();
  isPinInvalid: boolean;
  isConfirmPinInvalid: boolean;

  @ViewChild('currentPinInput', { static: false }) currentPinInput: NgOtpInputComponent;
  @ViewChild('confirmPinInput', { static: false }) confirmPinInput: NgOtpInputComponent;

  constructor(private UserService: UserService,
    private fb: FormBuilder,
    private googleEvent: EventTrackingService,
    private router: Router,
    private commonService: CommonService,
    @Inject(DOCUMENT) private _document
  ) {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Kolkata' ? 'Asia/Calcutta' : Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.timezoneForGA = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.locale = Intl.DateTimeFormat().resolvedOptions().locale;
    this.timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZoneName;
  }

  ngOnInit(): void {
    this._document.body.classList.add('profile-bg');
    this.language = this.commonService.languageChange();
    this.setPinForm = this.fb.group({
      'profile_pin': ['', [Validators.required, Validators.minLength(4)]],
      'profile_lock': 'true',
      'confirm_profile_pin': ['', [Validators.required, Validators.minLength(4)]]
    });

    let userData = localStorage.getItem('userInfo');
  }

  ngOnDestroy() {
    this._document.body.classList.remove('profile-bg');
  }

  get pinFormControls() {
    return this.setPinForm.controls;
  }

  setPin() {
    this.isPinSubmit = true;
    this.isPinInvalid = this.pinFormControls.profile_pin.invalid;
    this.isConfirmPinInvalid = this.pinFormControls.confirm_profile_pin.invalid;

    if (this.setPinForm.invalid) {
      return;
    }

    if (this.setPinForm.controls.profile_pin.value != this.setPinForm.controls.confirm_profile_pin.value) {
      this.isConfirmPinInvalid = true;
      return;
    }

    let params = this.setPinForm.value;
    let url = 'profile/set/pin';
    this.UserService.setPrfilePin(url, params).subscribe(data => {
      this.api_msg = data.message;
      this.googleEvent.eventEmitter('Profile', 'Set', this.locale + ' - ' + this.timezoneForGA, 0, + '-' + this.username + '-' + this.locale + ' - ' + this.timezoneForGA + '-set Pin click');
      this.closeModal();
      this.setPinSuccess.emit(this.api_msg);
    }, (error) => {
      this.api_error = error.message;
    });
  }

  closeModal() {
    try {
      document.querySelector("ngb-modal-window").classList.remove("show");
      document.querySelector("ngb-modal-backdrop").classList.remove("show");
      document.getElementById('nexgTV-app').click();
      document.querySelectorAll(".modal-backdrop").forEach(el => el.remove());
      document.querySelectorAll(".d-block").forEach(el => el.remove());
      document.body.style.overflow = "scroll";
    } catch (e) {

    }
  }

  onPinChange(pin) {
    let fpin = pin;
    this.setPinForm.get("profile_pin").setValue(fpin);
    if (fpin.length === 4) {
      let eleId = this.confirmPinInput.getBoxId(0);
      this.confirmPinInput.focusTo(eleId);
    }
  }

  onConfirmPinChange(pin) {
    let confirmPin = pin;
    this.setPinForm.get("confirm_profile_pin").setValue(confirmPin);
    // if (confirmPin.length === 4) {
    //   this.setPin();
    // }
  }
}
