<div class="maincontentarea mt40">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <app-myaccount-menu></app-myaccount-menu>
            </div>
            <div class="col-md-9">
                <div class="account_page">
                    <div class="account_head_btn watchlist-block">
                        <h4 class="font-bold text-white">Watchlist</h4>
                        <div class="watchlist-btn"
                            *ngIf="watchlistData?.length > 0">
                            <span *ngIf = "watchlistEditButton">
                                <a class="text-white" href="javascript:;" (click)="watchlistEdit();">Cancel</a>
                                <label class="checkbox">
                                    <input type="checkbox" #selectAllCheckbox
                                        (change)="handleSelectAllClick($event)"/>
                                    <span>&nbsp;Select All</span>
                                </label>
                                <button class="btn btn-default btn_hover"
                                    (click)="deleteFromWatchlist()">Remove</button>
                            </span>
                            <button type="button" class="btn btn-default btn_hover"
                                *ngIf="!watchlistEditButton"
                                (click)="watchlistEdit();">Edit</button>
                        </div>
                    </div>
                </div>

                <div class="watchlist-assets"
                    [ngClass]="{'watchlist-edit' : watchlistEditButton }"
                    >
                    <ul *ngIf="watchlistData?.length >= 0">
                        <li *ngFor="let item of watchlistData; let index=index" class="item assets_hover new_asset_hover_type">
                            <div class="new_asset">
                                <div class="new_asset_img"
                                    (click)="goToDetailPage(item)">
                                    <div class="watchlist-btn"
                                        *ngIf="watchlistEditButton">
                                        <label class="checkbox">
                                            <input #checkboxInput type="checkbox"
                                                (change)="addRemoveFromSelectedAssets(item)"/>
                                        </label>
                                    </div>
                                    <!-- <img (click)="clickAssetCheckbox(index)" [src]="item.image_portrait || item.image_landscape" /> -->
                                    <image-manipulation
                                        (click)="clickAssetCheckbox(index)"
                                        [assetName]="item.name"
                                        [img]="item.image_portrait"
                                        [params]="{ 'width': 220, 'height': 330 }" notFound="portrait"></image-manipulation>

                                    <a class="_premium"
                                    *ngIf="strToLower(item.content_availability) == 'paid' && item.hide_tag == '0' ">
                                    <img class="crown" [src]="paidIcon">
                                    </a>

                                    <a class="_free_tag"
                                        *ngIf="strToLower(item.content_availability) == 'free' && item.hide_tag == '0' ">
                                        <img class="crown" [src]="freeIcon">
                                    </a>

                                    <a class="_free_tag"
                                        *ngIf="strToLower(item.content_availability) == 'no tag' && item.hide_tag == '0' ">
                                    </a>
                                </div>
                                
                                <app-new-hover
                                    [item]="item"
                                    [page_view]="'Screen_Home'"
                                    (addToWatchlist)="addToWatchlistArray($event)"
                                    (removeFromWatchlist)="removeFromWatchlistArray($event)"></app-new-hover>
                            </div>
                        </li>
                    </ul>
                    <div style="height: 100%;" class="d-flex flex-column align-items-center justify-content-center"
                        *ngIf="watchlistData?.length <= 0">
                        <img src="../../../assets/img/Icon/shippingbox.svg" style="width: 100px"/>
                        <span class="text-white">Nothing In Here</span>
                        <small class="text-white">You haven't added anything to watchlist yet.</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="" id="removeWatchlistModal" style="display: none;" #removeWatchlistModal>
    <div class="after-login">
        <img src="../../../assets/img/Icon/continue-watching.svg" />
        <div>
          <label>Watchlist Updated</label>
          <p>Asset has been removed from the list</p>
        </div>
      </div>
</div>