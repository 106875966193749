import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { routes } from 'src/app/app.routing';
import { DynamicTabComponent } from 'src/app/views/dynamic-tab/dynamic-tab.component';
import { CatlougeTypeConstants } from '../constants';
import { UserService } from './user.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  currentRoute: any;
  currentSlugName: any;
  activeMenu: any;
  slugName: any;
  headerTabs: string;

  constructor(public location: Location, public router: Router, public userService: UserService) {
    if(location.path()==''){
      localStorage.removeItem('slugName');
    }

    if (localStorage) {
      this.slugName = localStorage.getItem('slugName');
      this.currentSlugName = this.userService.currentSlugName;
    }

    router.events.subscribe(val => {
      if (location.path() != "") {
        this.currentRoute = location.path();
      } else {
        this.currentRoute = "/";
      }
    })

  }

  Init() {

    return new Promise<void>((resolve, reject) => {

      // Check if the current route is /app-pages
      if (this.location.path().startsWith('/app-pages')) {
        console.log('Skipping API calls for /app-pages route');
        resolve();
        return;
      }

      if(!localStorage.getItem('deviceId')) {
        localStorage.clear();
        localStorage.setItem('deviceId', uuidv4());
      }

      let dynamicRoute = [];

      let tabDataParams = {
        "catlogue": CatlougeTypeConstants.catlogue,
        // "tab_id":"",
        // "category_id":"",
        // "sub_category_id":"",
        // "sub_sub_category_id":"6110dafdaa47a5710906460c",
        "call": "gettabs"
      }

      let dynamicTabRes = this.userService.convertoJSONToUrlEncoded(tabDataParams);

      this.userService.dynamicTab().subscribe((tabRes) => {

        if(tabRes.filter_type!=undefined && tabRes.filter_type!=''){
          var base64decodeData =  atob(tabRes.filter_type);
          localStorage.setItem('filterTypeData', base64decodeData);
          }else{
            var base64decodeData = '{"is_cloudfront_enabled":"0","bucket":"digi-file-img"}';
            localStorage.setItem('filterTypeData', base64decodeData);
          }
        
     
        if (tabRes.result && tabRes.result.length > 0) {
          localStorage.setItem('tabs', JSON.stringify(tabRes.result));
          tabRes.result.forEach((tabResData, index) => {
            dynamicRoute.push({
              path: `${tabResData.slug}`,
              component: DynamicTabComponent
            })
            if (index >= tabRes.result.length - 1) {
              let finalRoute = [...dynamicRoute, ...routes];
              this.userService.setCurrentSlugName(tabRes.result[0].slug);
              localStorage.setItem('continuewatching', tabRes.result[0].slug);

              if (this.slugName) {
                this.userService.updateSelectedMenu(this.slugName);
                this.userService.setCurrentSlugName(this.slugName);
              } else {
                this.slugName = tabRes.result[0].slug;
                this.userService.updateSelectedMenu(tabRes.result[0].slug);
                this.userService.setCurrentSlugName(tabRes.result[0].slug);
              }

    
              this.router.resetConfig(finalRoute);
              this.router.navigateByUrl(this.currentRoute);
              resolve();

            }

          });
        }
      }, (error) => {
        console.error('error fetching tabs from app init: ', error);
        if(error.response_code === 401) {
          this.userService.purgeAuth();
          window.location.href = '/';
          return;
        }
        if (!this.currentRoute.contains('app-pages')) {
          this.router.navigate(['/500']);
        }
        resolve();
      })

    })
  }
}
