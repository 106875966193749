<section class="_custom_paren_img mini_slider"
    [ngClass]="{'assets_hover': assets_hover }" *ngIf="data && data.assets && data.assets.length > 0">
    <div class="_custom_cont" *ngIf="data.assets.length>0">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="i_head_c">
                        <div class="view_all_animation">
                            <h4>{{data.category_name}}</h4>
                            <span class="view_all_animation_hidden">
                                <a class="new_view_all"
                                    *ngIf="data.assets && data.assets[0].type != 'livetv' && data.assets[0].type != 'event' && data.assets.length > showViewAll"
                                    (click)="viewall(data)"><span>View all </span> <i class="fa fa-angle-right"></i></a>


                                    
                                <a class="new_view_all"
                                *ngIf="data.assets && data.assets[0].type == 'livetv' || data.assets[0].type == 'event' && data.assets.length > showViewAll"
                                (click)="viewall(data)"><span>View all </span> <i class="fa fa-angle-right"></i></a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Four slider -->

    <div class="_custom_grid_img mb40" [ngClass]="{'assets_hover': assets_hover }">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <!--for hover class (new_asset_hover_type) and expend class (new_asset_expend_type)-->
                    <div class="owl-carousel four_slides owl-theme {{data.category_type == 'continue-watching' ? 'new_asset_hover_type' : ''}} {{hoverAndExpend!='' ? hoverAndExpend : ''}} {{thumbnailClass?thumbnailClass : 'landscape_assets'}}"
                        [ngClass]="{'_less_four':data.assets.length <= 4, 'assets_hover': assets_hover }">
                        <owl-carousel-o [options]="owlOption"  #owlCarousel
                            (initialized)="onCarouselInitialized($event)"
                            (translated)="onCarouselTranslated($event)">
                            

                            <ng-container *ngFor="let item of data.assets; let i=index">
                                <ng-template carouselSlide [id]="strToString(i)">
                                    <div class="item" (click)="redirectUrl(item, content, jwplaydom, $event)"
                                        *ngIf="hoverAndExpend==''" >
                                        <div class="_watching_img1" [ngClass]="{'live_tv': item.type == 'livetv'}"> <!-- (mouseenter)="item_hover_enter(item)" (mouseleave)="item_hover_leave(item)" -->
                                            
                                            <div class="p_g_i" id="cont_thombImage{{i}}">
                                                <image-manipulation [assetName]="item.name"  *ngIf="data.category_type == 'continue-watching'"
                                                    [img]="item.image" [params]="{'width':330,'height':180}"
                                                    [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'portrait'"
                                                    [img]="item.image_portrait || item.image_landscape"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'landscape'"
                                                    [img]="item.image_landscape" [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation [assetName]="item.name"  [assetName]="item.name"  *ngIf="thumbnailType == 'circular'"
                                                    [img]=" item.image_square || item.image_landscape"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'stretched-landscape'"
                                                    [img]="item.image_stretched_landscape || item.image_landscape"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation [assetName]="item.name"  [assetName]="item.name"  *ngIf="thumbnailType == 'square'"
                                                    [img]="item.image_square || item.image_landscape"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'enlarged-landscape'"
                                                    [img]="item.image_landscape" [params]="assetImgParam"
                                                    [notFound]="notFoundImg"
                                                    [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'enlarged-square'"
                                                    [img]="item.image_square || item.image_landscape"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>
                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'enlarged-circle'"
                                                    [img]="item.image_square || item.image_landscape"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>

                                                <a class="_premium"
                                                    *ngIf="strToLower(item.content_availability) == 'paid'">
                                                    <img class="crown" [src]="paidIcon">
                                                </a>

                                                <a class="_free_tag"
                                                    *ngIf="strToLower(item.content_availability) == 'free'">
                                                    <img class="crown" [src]="freeIcon">
                                                </a>

                                                <a class="_free_tag"
                                                    *ngIf="strToLower(item.content_availability) == 'no tag'">
                                                </a>

                                                <a class="_cross_icon"
                                                    *ngIf="strToLower(data.category_type) == 'continue-watching'">
                                                    <img class="crown" (click)="deleteWatch($event, item)"
                                                        src="assets/img/asset_cross_icon.png">
                                                </a>

                                            </div>
                                            <div class="_visible_hover1" id="cont_thombMore{{i}}">
                                                <div class="_v_c_l">
                                                    <a href="javascript:;">
                                                        <div class="_c_img">
                                                            <img id="{{item.name}}" src="assets/img/hover_Play.png">
                                                        </div>
                                                        <div class="_c_progress"
                                                            *ngIf="strToLower(data.category_type) == 'continue-watching'">
                                                            <span *ngIf="item.stop > 0">Continue Watching <br />
                                                                <span style="text-transform: capitalize;"
                                                                    *ngIf="item.type == 'episode'">
                                                                    S{{item.season?.season_number}} Episode {{item.no}}
                                                                </span>
                                                            </span>
                                                            <span *ngIf="item.stop <= 0">Watch now
                                                                <span *ngIf="item.type == 'episode'">
                                                                    S{{item.season?.season_number}} E{{item.no}} </span>
                                                            </span>
                                                            <div *ngIf="strToLower(data.category_type) == 'continue-watching'  && item.stop > 0 "
                                                                class="progress">
                                                                <div class="progress-bar" role="progressbar"
                                                                    aria-valuenow="70" aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                    [ngStyle]="{'width': item.stop*100/item.duration+'%'}">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="_c_progress">
                                                            <span
                                                                *ngIf="strToLower(data.category_type) != 'continue-watching'">watch
                                                                now</span>
                                                        </div>
                                                    </a>
                                                    <div class="_right_c_t">
                                                        <span class="rating_s"
                                                            *ngIf="(item?.viewer_rating) && item?.viewer_rating?.rating_name">
                                                            {{item?.viewer_rating?.rating_name}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="_inner_text_l">
                                                    <h4>{{item.name | slice:0:40}}
                                                        <span *ngIf="item.name && item.name.length > 40">...</span>
                                                    </h4>

                                                    <p class="type_film">
                                                    </p>

                                                    <p>{{item.synopsis | slice:0:100}}
                                                        <span
                                                            *ngIf="item.synopsis && item.synopsis.length > 100">...</span>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div *ngIf="hoverAndExpend!=''" class="item"
                                        [ngClass]="{'assets_hover': assets_hover }"
                                        (mouseenter)="item_hover_enter(item, i)" (mouseleave)="item_hover_leave(item, i)"
                                        style="position: relative;">
                                        <div class="new_asset">
                                            <div (click)="redirectUrl(item, content, $event)" class="new_asset_img">
                                                <!-- <image-manipulation *ngIf="data.category_type == 'continue-watching'"
                                                    [img]="item.image_landscape" [params]="assetImgParam" [notFound]="notFoundImg"
                                                    [params]="{'width':330,'height':180}"></image-manipulation> -->


                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'portrait'"
                                                    [img]="item.image_portrait || item.image_landscape"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>

                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'landscape'"
                                                    [assetName]="item.name"
                                                    [img]="item.image_landscape" [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>

                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'circular'"
                                                    [img]="item.image_square || item.image_landscape"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>

                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'stretched-landscape'"
                                                    [img]="item.image_stretched_landscape || item.image_landscape"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>

                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'square'"
                                                    [img]="item.image_square || item.image_landscape"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>


                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'enlarged-landscape'"
                                                    [img]="item.image_landscape" [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>


                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'enlarged-square'"
                                                    [img]="item.image_square || item.image_landscape"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>

                                                <image-manipulation [assetName]="item.name"  *ngIf="thumbnailType == 'enlarged-circle'"
                                                    [img]="item.image_square || item.image_landscape"
                                                    [params]="assetImgParam"
                                                    [notFound]="notFoundImg"></image-manipulation>

                                                    <a class="_premium"
                                                    *ngIf="strToLower(item.content_availability) == 'paid' && item.hide_tag == '0' ">
                                                    <img class="crown" [src]="paidIcon">
                                                    </a>

                                                    <a class="_free_tag"
                                                        *ngIf="strToLower(item.content_availability) == 'free' && item.hide_tag == '0' ">
                                                        <img class="crown" [src]="freeIcon">
                                                    </a>

                                                    <a class="_free_tag"
                                                        *ngIf="strToLower(item.content_availability) == 'no tag' && item.hide_tag == '0' ">
                                                    </a>
                                                <span class="p_btn" *ngIf="data.category_type == 'continue-watching'">
                                                    <img src="../../../assets/img/Icon/play.svg" />
                                                </span>
                                                <div class="new_asset_seekbar"
                                                    *ngIf="data.category_type == 'continue-watching' && item.type !== 'livetv'">
                                                    <!-- <img src="../../../assets/img/Watch_Now.png" /> -->
                                                    <div class="new_asset_seekbar_info">
                                                        <span
                                                            [ngStyle]="{'width': item.stop*100/item.duration+'%'}"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="data.category_type != 'continue-watching' && data.he_displaytext == 'yes' " [class]="nameOutsideOverlay!='' ? nameOutsideOverlay : nameOutsideOverlay">
                                                {{item.name}}
                                            </div>
                                            <div class="new_asset_info" *ngIf="data.category_type == 'continue-watching'">
                                                <span>{{item.name}}</span>
                                                <label class="asset_live_tag" *ngIf="item.type == 'livetv'">Live</label>
                                                <label *ngIf="item.type !== 'livetv' && item.remaining_time">{{item.remaining_time}}</label>
                                            </div>
                                            <app-new-hover
                                                [item]="item"
                                                [page_view]="'Screen_Home'"
                                                [isHovered]="assets_hover"
                                                (deleteContinueWatching)="deleteFromContinueWatching($event)"
                                                [isContinueWatching]="data.category_type == 'continue-watching'"></app-new-hover>
                                        </div>
                                    </div>

                                    
                                </ng-template>
                            </ng-container>
                            
                            <!-- Blank Slides to maintain owl-item width -->
                            <ng-container *ngIf="owlOption.items > data.assets.length">
                                <ng-container *ngFor="let i of counter(blankSliderCount)">
                                    <ng-template carouselSlide>
                                        <p style="cursor: auto;width: 100%;height: 100%;"></p>
                                    </ng-template>
                                </ng-container>
                            </ng-container>
                            
                            
                            
                            
                            
                            
                            
                           
                            


                            <!-- <ng-container *ngFor="let item of data.assets; let i=index" >
                                <ng-template carouselSlide >
                                 

                                </ng-template>
                            </ng-container> -->
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
    </div>



</section>


<a data-toggle="modal" data-target="#addToWatchList" #addToWatchListSuccess></a>

<div class="modal auth" id="addToWatchList">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header" style="visibility: hidden;">
                <button type="button" id="closeAddToWatchList" class="close" data-dismiss="modal"
                    #removeToWatchListSuccessModal>&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="success-add">
                    <img src="../../../assets/img/success.png">
                    <p id="itemAddSuccessMsg" #itemAddedIntoWach></p>

                </div>
            </div>
        </div>
    </div>
</div>


<!--Cancel subscription -->
<div class="modal auth" id="add_watch_list_from_slider" *ngIf="userInfo">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Are you sure ?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeAddtoWatchListModal>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="AddToPlayListItemFromSlider()">Yes</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                <a href="" data-toggle="modal" data-target="#pack_removed_success" #showPackSuccess></a>
            </div>
        </div>
    </div>
</div>
<!-- Cancel subscription -->

<!--Cancel subscription -->
<div class="modal auth" id="removeWatchListSlider" *ngIf="userInfo">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Are you sure ?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="deleteWatch()">Yes</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                <a href="" data-toggle="modal" data-target="#pack_removed_success" #showPackSuccess></a>
            </div>
        </div>
    </div>
</div>


<!-- <a data-toggle="modal" data-target="#removeContinueModal" #closeContinueModal></a> -->

<div class="" id="removeContinueModal" style="display: none;" *ngIf="userInfo" #closeContinueModal>
    <div class="after-login">
        <img src="../../../assets/img/Icon/continue-watching.svg" />
        <div>
          <label>Continue Watching</label>
          <p>Asset has been removed from the list</p>
        </div>
      </div>
</div>


<!-- JW Player  -->
<ng-template #jwplaydom let-modal>
    <app-videojs-player [streamInfo]="streamInfo"></app-videojs-player>
</ng-template>

<!-- Cancel subscription -->
<ng-template #content let-modal>
    <app-login-register></app-login-register>
</ng-template>