<div class="watchlist">
    <div class="watchlist-login">
        <img src="../../../assets/img/Icon/default-user.svg" />
        <label>
            <ng-container *ngIf="isUsernameMobile">{{ countryDialCode }} - </ng-container>
            <ng-container *ngIf="userName">{{ userName }}</ng-container>
        </label>
        <button class="btn btn-default"
            (click)="handleLogout()">
            <img src="../../../assets/img/Icon/logout.svg" /> Logout
        </button>
    </div>
    <ul class="watchlist-nav">
        <li routerLink="/myaccount" routerLinkActive="active">
            <a href="javascript:;">
                <img src="../../../assets/img/Icon/my_account.svg" />
                <img routerLinkActive="active"  src="../../../assets/img/Icon/my_account-active.svg" />
                
                My Account
            </a>
        </li>
        <li routerLink="/myplans" routerLinkActive="active">
            <a href="javascript:;">
                <img src="../../../assets/img/Icon/my_subscriptions.svg" />
                <img routerLinkActive="active"  src="../../../assets/img/Icon/my_subscriptions-active.svg" />
                My Plans
            </a>
        </li>
        <li routerLink="/watchlist" routerLinkActive="active">
            <a href="javascript:;">
                <img routerLinkActive="active" src="../../../assets/img/Icon/my_watchlist.svg" />
                <img routerLinkActive="active" src="../../../assets/img/Icon/my_watchlist-active.svg" />
                 Watchlist
            </a>
        </li>
        <li routerLink="/redeem-code" routerLinkActive="active">
            <a href="javascript:;">
                <img src="../../../assets/img/Icon/redeem_code.svg" />
                <img src="../../../assets/img/Icon/redeem_code-active.svg" />
                 Redeem Code
            </a>
        </li>
        <!-- <li routerLinkActive="active">
            <a routerLink="/watchlist" href="javascript:;">
                <img src="../../../assets/img/Icon/my_vouchers.svg" />
                <img src="../../../assets/img/Icon/my_vouchers-active.svg" />
                 My Vouchers
            </a>
        </li> -->
        <li routerLink="/help-center" routerLinkActive="active">
            <a href="javascript:;">
                <img src="../../../assets/img/Icon/help_center.svg" />
                <img src="../../../assets/img/Icon/help_center-active.svg" />
                 Help Center
            </a>
        </li>
    </ul>
</div>
