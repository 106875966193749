import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { redeemCodeOptions, redeemCodeAppliedOptions } from 'src/app/shared/lottie-config';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-redeem-code',
  templateUrl: './redeem-code.component.html',
  styleUrls: ['./redeem-code.component.css']
})
export class RedeemCodeComponent implements OnInit {

  redeemCodeOptions: AnimationOptions = redeemCodeOptions;
  redeemCodeAppliedOptions: AnimationOptions = redeemCodeAppliedOptions;
  redeemText: string;
  showPopupText: boolean;
  errorText: string;
  appliedCode: any;
  successText: string;
  @ViewChild('redeemCodeModal') redeemCodeModal: ElementRef;

  constructor(
    private modalService: NgbModal,
    private subscriptionService: SubscriptionService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  openRedeemCodeAppliedModal() {
    this.showPopupText = false;
    setTimeout(() => {
      this.showPopupText = true;
      this.cdr.detectChanges();
      setTimeout(() => {
        this.modalService.dismissAll();
        this.router.navigateByUrl('/myaccount');
      }, 2000);
    }, 1850);
    this.modalService.open(this.redeemCodeModal, { ariaLabelledBy: 'modal-basic-title', backdrop: true, windowClass:'delete_popup' }).result.then((result) => {
    }, (reason) => {
    });
  }

  closeModal() {
    try {
      document.querySelector("ngb-modal-window").classList.remove("show");
      document.querySelector("ngb-modal-backdrop").classList.remove("show");
      document.getElementById('nexgTV-app').click();
      document.querySelectorAll(".modal-backdrop").forEach(el => el.remove())
      document.querySelectorAll(".d-block").forEach(el => el.remove())
      document.body.style.overflow = "scroll";
    } catch (e) {

    }  
  }

  activationCode() {
    this.errorText = "";
    if (!this.redeemText) {
      this.errorText = 'Please enter a code';
      return
    }
    const body = {
      activation_code: this.redeemText
    };
    this.subscriptionService.activationCode(body).subscribe(activationCodeRes => {
      if (activationCodeRes.response_code == 200) {
        this.successText = activationCodeRes.message;
        this.appliedCode = activationCodeRes.result;
        this.openRedeemCodeAppliedModal();
      } else {
        this.errorText = activationCodeRes.message;
      }
    }, (error) => {
      this.errorText = error.message;
      console.error('error redeeming code: ', error);
    });
  }

}
