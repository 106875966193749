
<div class="container">
    <button type="button" style="color: blueviolet;" [hidden]="true" class="dropdown-item" id="openLogin"
    (click)="openLoginModal(content)" #openLoginllModel>Open Login Modal</button>

<ng-template #content let-modal>
    <app-login></app-login>
</ng-template>

    <div class="main_content" *ngIf="contestResult && contestResult.length > 0">
        <header>
            <div class="row">
                <div class="col-md-12 text-center mt-4 mb-4">
                    <img src="./assets/img/voting-img/logo.png" alt="" title="" />
                </div>
                <div class="col-md-12 text-center">
                    <img src="./assets/img/voting-img/vote_text.png" alt="" title="" class="img-fluid" />
                </div>
            </div>
        </header>
        <div class="row">
           
            <div class="col-md-12" >
                <p>Click on your favourite contestant to vote</p>
                <ul class="vote">
                    <li *ngFor="let res of contestResult" id="contest{{res.contetant_id}}"
                        (click)="choseContestant(res.contetant_id)">
                        <!-- <img src="{{res.image}}" alt="" title="" /> -->
                        <image-manipulation [assetName]="res.name" [img]="res.image_cloudfront_url" [params]="{'width':250,'height':250}" [notFound]="notFoundImg">
                        </image-manipulation>
                        <label>{{res.contestant_name}}</label>
                    </li>
                </ul>
                <div class="submit_btn mb-5" style="display: none">
                    <button type="button" class="btn btn-color" (click)="clickforVote()">SUBMIT</button>
                    <button type="button" class="btn btn-color" data-toggle="modal" data-target="#VoteModal"
                        data-backdrop="static" [hidden]="true" id="msgbutton">Open Message</button>
                </div>
            </div>
        </div>
    </div>

    <div class="vote_close" *ngIf="msgShow">
        
        <div class="row">
            <div class="col-md-12">
                <label>Voting unavailable</label>
                <a href="javascript:void(0);" class="btn btn-danger mt-4" (click)="closeWin()">BACK</a>
            </div>
        </div>
    </div>
</div>



<div class="modal fade modal_center" id="VoteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <label>{{msg}}</label>
                <button type="button" class="btn btn-color float-right mt-4" data-dismiss="modal">CLOSE</button>
            </div>

        </div>
    </div>
</div>



<script type="text/javascript">
    $(document).ready(function () {
        $("ul.vote li").on('click', function () {
            //alert($(this).attr("data-id"));
            $("ul.vote li").removeClass("active")
            $(this).addClass("active");
            $(".submit_btn").show();
        });
    });
</script>