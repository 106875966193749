import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddProfileComponent } from './add-profile/add-profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ProfileComponent } from './profile.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SetpinComponent } from './setpin/setpin.component';
import { ChangepinComponent } from './changepin/changepin.component';
import { ResetPinComponent } from './reset-pin/reset-pin.component';
import { EnterPinComponent } from './enter-pin/enter-pin.component';

const routes: Routes = [
  { 
    path: '', component: ProfileComponent 
  },
  { 
    path: 'add', component: AddProfileComponent 
  },
  { 
    path: 'edit/:id', component: EditProfileComponent 
  },
  // { 
  //   path: 'setpin', component: SetpinComponent
  // },
  // { 
  //   path: 'change-pin', component: ChangepinComponent
  // },
  { 
    path: 'reset-pin/:id', component: ResetPinComponent
  },
  { 
    path: 'enter-pin/:id', component: EnterPinComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes),SharedModule],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
