<div class="maincontentarea mt20">
    <div class="container">    
        <div class="page-text">
            <h2>About us</h2>
            <div>
            <img src="./assets/img/nexgtv.png">
            <p>nexGTv is Digivive's flagship and award-winning application offers users with wholesome
                entertainment across multi-screen devices, be it mobile, tablets, laptop/PCs or smart
                TV.</p>
            <p>Besides ranking amongst the top ten applications in its category across app stores,
                nexGTv has also been awarded 'The Best Digital Experience' at the prestigious World
                Communication Awards 2014. nexGTv is available on Android, iOS, Fire TV, Android TV and
                Web.</p>
            <p>The app streams 100+ Live TV channels with movies, TV Shows and videos. NexGtv keeps you
                entertained to suit all your moods and what makes it more interesting is that it has a
                host of unique features, one of them being pause and resume, besides a host of others
            </p>
            <p>One-stop destination for all entertainment needs, nexGTv is committed to provide its
                users with the best-in-class mobile TV experience through its world-class
                infrastructure. nexGTv will continue innovating on the content front and will remain the
                preferred choice for users.</p>
            <img src="./assets/img/digivive-logo.png" alt="" title="" />
            <p>nexGTv is the flagship entertainment app of Digivive, a group company of Media Matrix
                Worldwide Limited, established in 2010, with the vision of becoming a leading Data VAS
                provider in the fast emerging infotainment and data MVAS segment. Set up by a core team
                of professionals and backed by its strong technology lineage, it provides expertise
                across Direct to Consumer and Managed Value Added Services.</p>
            <p>Our expertise in delivering unique products to the end consumers derives from a strong
                lineage in technology and deep consumer insights. All products are developed keeping in
                mind changing trends, best in class content, ease of use, mass availability &
                affordability.</p>
            <p>Digivive specializes in end to end enabling of solutions with managed service model for
                delivery, system integration programs and best in class technologies. By bringing
                together unique content and superior technology, we are able to drive operational
                efficiencies, enabling faster time to market and delivering competitive timelines and
                pricing. We provide innovative platforms for evolving next-generation technologies. The
                company has long term business plans to provide, users and providers, a wider selection
                and more alternatives.</p>
            <p>Our partners are major telecom operators and OEMs including Airtel, Vodafone, Idea,
                Aircel, BSNL, MTNL, Reliance, MTS and Karbonn Mobiles</p>
            <p>Our flagship mobile TV offering, nexGTv ever since its launch, has been fulfilling the
                entertainments needs of today's generation by providing Live TV, Movies, TV Shows and
                Videos. Over a million viewers were generated in less than 3 months of its launch and
                today the number has moved up to over 24 million. Our in-house R&D has created
                applications that support a long tail of handsets in the market on a variety of
                platforms including Android, Symbian, Blackberry, Tizen, iOS and many others. Our VOD
                library has generated an overwhelming response and created a buzz across our users.</p>
            <p>With constant innovation, we aim to foresee tomorrow's demand today and meet it now. We
                are committed to our belief in FUTURE FORWARD.</p>
            <p>For more details visit <a routerLink="/">Digivive site</a></p>
            <!-- <p>Platform8 is the flagship entertainment OTT App of Channel Eight Virtual Estudios Limited. Channel Eight Virtual Estudios Limited is a Public Company incorporated in 1998. It is classified as a Non-govt company limited by Shares and is registered at Registrar of Companies, RoC-Kolkata. Channel Eight Virtual Estudios Limited had pledged to fulfil Bengal's fancy towards art, culture, literature, and music. Now they are coming up with Bengal's exclusive OTT platform "Platform 8". Our expertise in delivering unique products to end consumers derives from a strong lineage technology and deep consumer insights. All product are developed keeping in mind changing trends, best in class content, ease of use, mass availability & affordability. For more details visit <a href="https://platform8.tv/">Platform8</a></p> -->
        </div>
        </div>
    </div>
</div>