import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SafeUrlPipe,SortByPipe,BlockCopyPasteDirective, ordinalDate } from '../core';
import { MaterialComponents } from '../core/material-ui';
import { OwlSliderComponent } from './owl-slider/owl-slider.component';  
import { OwlBannerSliderComponent } from './owl-banner-slider/owl-banner-slider.component';  
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ResendOtpComponent } from 'src/app/views/resend-otp/resend-otp.component';
import { VideojsPlayerComponent } from './videojs-player/videojs-player.component';
import { ImageManipulationComponent } from '../core/components/image-manipulation/image-manipulation.component';
import { EpgsliderComponent } from 'src/app/views/epgslider/epgslider.component';
import { LiveComponent } from './videojs-player/live/live.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AssetRowComponent } from '../core/components/asset-row/asset-row.component';
import { CategoryComponent } from './category/category.component';
import { CampaignBannerComponent } from './campaign-banner/campaign-banner.component';
import { AssetThumbnailComponent } from '../core/components/asset-thumbnail/asset-thumbnail.component';


import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { AvtarModalComponent } from './avtar-modal/avtar-modal.component';
import { LoginRegisterComponent } from '../views/auth/login-register/login-register.component';
import { NgOtpInputModule } from 'ng-otp-input'; 
import { NgSelectModule } from '@ng-select/ng-select';
import { ResetPasswordComponent } from '../views/auth/reset-password/reset-password.component';
import { ForgotpasswordComponent } from '../views/auth/forgotpassword/forgotpassword.component';

import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { ErrorComponent } from './lottie/error-message/error/error.component';
import { UpcomingProgramsComponent } from '../views/upcoming-programs/upcoming-programs.component';
import { NewHoverComponent } from './new-hover/new-hover.component';
import { MyaccountMenuComponent } from './myaccount-menu/myaccount-menu.component';
import { RouterModule } from '@angular/router';
import { NoActivePlanComponent } from './lottie/no-active-plan/no-active-plan.component';
import { SuccessMessageComponent } from './lottie/success-message/success-message.component';
import { BannerSliderComponent } from './banner-slider/banner-slider.component';
import { SearchComponent } from './search/search.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgAisModule } from 'angular-instantsearch';
import { RedeemCodeSuccessComponent } from './lottie/redeem-code-success/redeem-code-success.component';
import { TransactionFailureComponent } from './lottie/transaction-failure/transaction-failure.component';

export function playerFactory() {
  return player;
}
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialComponents,
    MatTooltipModule,
    CarouselModule,
    BsDatepickerModule.forRoot(),
    NgOtpInputModule,
    NgSelectModule,
    LottieModule.forRoot({ player: playerFactory }),
    RouterModule,
    NgxPaginationModule,
    NgbModule,
    NgAisModule.forRoot(),
  ],
  declarations: [
    SortByPipe,
    BlockCopyPasteDirective,
    SafeUrlPipe, 
    ordinalDate,
    OwlSliderComponent,
    OwlBannerSliderComponent,
    ResendOtpComponent,
    VideojsPlayerComponent,
    ImageManipulationComponent,
    AssetRowComponent,
    EpgsliderComponent,
    LiveComponent,
    CategoryComponent,
    CampaignBannerComponent,
    AssetThumbnailComponent,
    AvtarModalComponent,
    LoginRegisterComponent,
    ResetPasswordComponent,
    ForgotpasswordComponent,
    ErrorComponent,
    UpcomingProgramsComponent,
    NewHoverComponent,
    MyaccountMenuComponent,
    NoActivePlanComponent,
    SuccessMessageComponent,
    BannerSliderComponent,
    SearchComponent,
    RedeemCodeSuccessComponent,
    TransactionFailureComponent,
  ],
  exports: [
    CommonModule,
    BsDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SortByPipe,
    BlockCopyPasteDirective,
    SafeUrlPipe,
    OwlSliderComponent,
    OwlBannerSliderComponent,
    VideojsPlayerComponent,
    LiveComponent,
    ImageManipulationComponent,
    AssetRowComponent,
    EpgsliderComponent,
    CategoryComponent,
    CampaignBannerComponent,
    AssetThumbnailComponent,
    MaterialComponents,
    AvtarModalComponent,
    LoginRegisterComponent,
    ResetPasswordComponent,
    ForgotpasswordComponent,
    ErrorComponent,
    UpcomingProgramsComponent,
    NewHoverComponent,
    MyaccountMenuComponent,
    NoActivePlanComponent,
    SuccessMessageComponent,
    BannerSliderComponent,
    SearchComponent,
    RedeemCodeSuccessComponent,
    TransactionFailureComponent,
  ],
  providers:[BsDatepickerConfig],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }