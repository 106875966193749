import { HttpHeaders, HttpClient, HttpParams, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private httpClient: HttpClient;
  private device_base_url = environment.device_base_url;


  constructor(
    private httpBackend: HttpBackend,
    private deviceService: DeviceDetectorService,
  ) {
    this.httpClient = new HttpClient(httpBackend);
  }

  getDeviceInfo() {
    return this.deviceService.getDeviceInfo();
  }

  getDeviceType() {
    return this.deviceService.isDesktop() ? 'desktop' : (this.deviceService.isTablet() ? 'tablet' : this.deviceService.isMobile() ? 'mobile' : 'others');
  }

  getDeviceOsVersion() {
    return this.deviceService.getDeviceInfo().os_version;
  } 

  checkAllowedDevices(path: string, body: Object = {}){
    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/x-www-form-urlencoded',
    //   'Access-Control-Allow-Origin' : '*',
    //   'Origin' : 'https://api.razorpay.com',
    //   'Accept': 'application/json',
    //   'appinventiv':'digivive'
    // });

    // let options = { headers: headers };
    return this.httpClient.post(
      `${this.device_base_url}${path}`,
      body
    ).pipe(catchError(this.formatErrors));

    return this.httpClient.post(`${environment.api_base_url}${path}`,{responseType:'text', body, observe: 'response'}).pipe(map((data:any) => {
      
      console.log("Here will be return response code Ex :200", data.status)
      return data.status
        }));
  }

  private formatErrors(error: any) {
    console.log(error);
    
    if(error.status === 400) {
    
    }
    return throwError(error);
  }

}
