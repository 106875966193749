
<div class="maincontentarea" style="min-height: unset;">
    <div class="container">
        <div class="_page_not_found">
                <div class="row">
                    <div class="col-md-7 col-sm-12">
                        <div class="_404_content text-left mt40">
                            <p class="_404_t1">Uh-oh! We can't seem to find the page you're looking for!</p>
                            <p class="_404_t2">Error Code: 404</p>
                            <p class="_404_t3">This page isn't available. Seems like the link you followed in broken or the page has been removed.</p>
                            <button class="btn btn-default  backhome" routerLink="/">Home</button>
                            <!-- <a href="javascript:;" (click)="previousPage()">Retry</a> -->
                        </div>
                   </div>

                    <div class="col-md-5 col-sm-12">
                        <div class="_404_img">
                            <ng-lottie [options]="pageNotFoundOptions"></ng-lottie>
                            <!-- <img class="404"  src="../../../assets/img/Icon/404.svg"> -->
                            <!-- <h2>Oops!</h2>
                            <P>This page isn't available</P> -->
                        </div>
                    </div>
                    
                </div>
        </div>
    </div>
</div>
