<div class="maincontentarea mt20">
  <div class="container">
    <div class="page-text">
      <h2 class="text-center mb-2">FAQs</h2>
      <h2 class="text-center mb-2">Frequently asked questions</h2>
      <p class="text-center">Have questions? We're here to help.</p>
      <div class="search-form faq-border">
        <div class="search-input">
          <img src="../../../../assets/img/Icon/search.svg">
          <input type="text" placeholder="Search" [(ngModel)]="searchTerm" (input)="filterFaqs()" />
        </div>
      </div>

      <div class="container-txt">
        <div class="expend-box">
          <mat-accordion *ngIf="filteredFaqs.length > 0">
            <mat-expansion-panel *ngFor="let faq of filteredFaqs">
              <mat-expansion-panel-header><p [innerHTML]="highlightSearchTerm(faq.question)"></p> <span class="faq-icon"></span></mat-expansion-panel-header>
              <p [innerHTML]="highlightSearchTerm(faq.answer)"></p>
            </mat-expansion-panel>
          </mat-accordion>
          <p style="margin-top: 40px;font-size: 16px;text-align: center;" *ngIf="filteredFaqs.length === 0">No FAQs found matching your search.</p>
        </div>
      </div>
    </div>
  </div>
</div>
