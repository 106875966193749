
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Event } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { environment, } from '../environments/environment.prod';
import { EventTrackingService } from './services/google/event-tracking.service';
import { PageTitleService } from './services/title/page-title.service';
import { filter, map } from 'rxjs/operators';
declare let gtag: Function;
import { LocalStorageService } from './core/services/local-stroage.service'
import { UserTypeConstants, authKey } from "src/app/core/constants/userType.constant";
import * as e from 'express';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {


  public spinkit = Spinkit;
  skChasingDots = 'sk-chasing-dots'
  skCubeGrid = 'sk-cube-grid'
  skDoubleBounce = 'sk-double-bounce'
  skRotatingPlane = 'sk-rotationg-plane'
  skSpinnerPulse = 'sk-spinner-pulse'
  skThreeBounce = 'sk-three-bounce'
  skWanderingCubes = 'sk-wandering-cubes'
  skWave = 'sk-wave'
  activeComponent: string;
  hideHeaderFooter: boolean;
  delayFooter: boolean;

  constructor(
    private router: Router,
    private googleEventTracking: EventTrackingService,
    private pageTitleService: PageTitleService,
    private activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService
  ) {

    // if (window.performance.navigation.type == 1) {
    //   return;
    // }else{
    //   localStorage.removeItem('slugName');
    //   // window.onbeforeunload = () => {
    //   //   localStorage.removeItem('slugName');
    //   // }
    // }
    
    window.onbeforeunload = () => {
      // localStorage.removeItem('slugName');
    }
    // location.onPopState(() => {
    //   console.log(`pressed back in add!!!!!`);
    //   //this.router.navigateByUrl(‘/multicomponent’);
    //   //history.forward();
    //   });
    if (localStorage.getItem('debug') == 'false') {
      window.console.log = function () { };   // disable any console.log debugging statements in production mode
      window.console.error = function () { };
    } else {

    }
    // if (!localStorage.getItem('isAuthenticated')) {
    //   localStorage.setItem('sessionId', authKey.defaultAuthKey);
    // }

    // if(environment.production ) {
    // } else {
    //   // window.console.log = function () { };   // disable any console.log debugging statements in production mode
    //   window.console.error = function () { };
    // }

  }

  ngOnInit() {
    const appTitle = this.pageTitleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }

          this.activeComponent = child.snapshot.data['componentName'];
          console.log(this.activeComponent);
          console.log(child);

          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        console.log(ttl);
        this.pageTitleService.setTitle(ttl);
      });



    this.router.events.subscribe((evt) => {

      if (evt instanceof NavigationEnd) {
        const urlPath = evt.urlAfterRedirects.split('?')[0];
        this.googleEventTracking.routeTrack(urlPath);
      }


      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    if (localStorage.getItem('isAuthenticated') && !localStorage.getItem('profile_id') && !window.location.href.includes("/manage-profile")) {
      this.router.navigate(["manage-profile"], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'preserve',
      });  
      // window.location.href = '/manage-profile';
      }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let currenturl = window.location.href;
        console.log(currenturl);
        if (currenturl.includes("/manage-profile") || currenturl.includes("/search") || currenturl.includes("/app-pages")) {
          this.hideHeaderFooter = true;
          // let HeaderTag = document.getElementById('header');
          // HeaderTag.classList.add("no_click");

          // let footerTag = document.getElementById('footer');
          // footerTag.classList.add("no_click");
        } else {
          this.hideHeaderFooter = false;
          // let HeaderTag = document.getElementById('header');
          // HeaderTag.classList.remove("no_click");

          // let footerTag = document.getElementById('footer');
          // footerTag.classList.remove("no_click");
        }
      }
    });

    setTimeout(() => {
      this.delayFooter = true;
    }, 1000);
  }

}
