import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationService, UserService } from 'src/app/core';
import { LocalStorageService } from 'src/app/core/services/local-stroage.service';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient, HttpParams, } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from "src/app/core/services/common.service";
import { DOCUMENT, Location } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ProfileComponent implements OnInit {

  user_data: any;
  profile: any;
  add_profile_count: number;
  basePath: any;
  profile_data: any;
  showVerifyPinForm: boolean = false;
  isPinSubmit: boolean = false;
  verifyPinForm: FormGroup;
  isSubmitPinValid: boolean = false;
  api_error: any;
  api_msg: any;
  profileData: any;
  isManageProfile: any = false;
  language: any;
  isProfileEdit: boolean = false;
  currentLockedProfile: any;
  showValidationErrors: boolean;

  constructor(
    private userService: UserService,
    public localStorageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public commonService: CommonService,
    private location: Location,
    private navigationService: NavigationService,
    @Inject(DOCUMENT) private _document,
  ) {
    this.user_data = this.localStorageService.get('userInfo');
  }

  ngOnInit(): void {
    this._document.body.classList.add('profile-bg');
    this.language = this.commonService.languageChange();
    this.basePath = environment.api_url.replace('api/v1/', '');
    let params = { _userId: this.user_data.result._id };
    console.log(params);
    let url = 'profile/getall';
    const profileCreationLimit = parseInt(localStorage.getItem('profileCreationLimit')) || 3;
    this.userService.getPrfiles(url, params).subscribe(data => {
      this.profile = data.result;
      this.add_profile_count = profileCreationLimit - this.profile.length
      console.log(this.add_profile_count);
    }, (error) => {
      console.log('errorrrrr', error);
    });


    this.verifyPinForm = this.fb.group({
      'profile_id': ['', Validators.required],
      'profile_pin': ['', [Validators.required, Validators.minLength(4)]]
    });
    // $('app-header').remove();
    // $('app-footer').remove();
    this.route.queryParams.subscribe(params => {
      this.isManageProfile = params['is_manage'];
    });
    
    if (!!localStorage.getItem('profile_id')) {
      this.isManageProfile = true;
    }
  }

  ngOnDestroy() {
    // remove the class form body tag
    this._document.body.classList.remove('profile-bg');
  }

  toggleShowVerifyPinForm() {
    this.showVerifyPinForm = !this.showVerifyPinForm;
  }

  verifyPinBack() {
    this.toggleShowVerifyPinForm();
    this.api_error = '';
    this.showValidationErrors = false;
  }


  SwitchUser(userId, profileName, isLockedProfile, profileImageUrl) {
    if (isLockedProfile){
      this.currentLockedProfile = { userId, profileName, profileImageUrl };
      this.verifyPinForm.get('profile_id').setValue(userId);
      this.toggleShowVerifyPinForm();
    } else {
      localStorage.setItem('profile_id', userId);
      localStorage.setItem('profile_UserName', profileName);
      localStorage.setItem('profileImageUrl', profileImageUrl);
      localStorage.setItem('profileSelected', 'true'); // To display welcome card in Dynamic Tab component
      this.route.queryParams.subscribe(params => {
        if (!params.redirect_to_detail && !params.redirect_to) {
          this.router.navigate(['/']);
        }
        if (params.redirect_to_detail) {
          this.commonService.goToDetail(params);
        }
        if (params.redirect_to) {
          this.router.navigate([params.redirect_to]);
        }
      });
      // window.location.href = '/';
    }
  }

  editProfile(userId, profileName, isLockedProfile) {
    // localStorage.setItem('profile_id', userId);
    let url = 'profile/get';
    if (isLockedProfile) {
      this.currentLockedProfile = { userId, profileName };
      this.verifyPinForm.get('profile_id').setValue(userId);
      this.isProfileEdit = true;
      this.showVerifyPinForm = true;
    } else {
      this.router.navigateByUrl('/manage-profile/edit/' + userId);
    }
  }


  // verify pin profile 
  verifyPinSubmit() {
    this.isPinSubmit = true;
    this.api_error = '';
    if (this.verifyPinForm.invalid) {
      this.showValidationErrors = true;
      return;
    }
    this.showValidationErrors = false;
    // this.isSubmitPinValid = true;
    let params = this.verifyPinForm.value;
    let url = 'profile/verify/pin';
    this.userService.verifyPrfilePin(url, params).subscribe(data => {
      if(data.response_code=='200'){
        this.api_msg = data.message;
        if (this.isProfileEdit) {
          this.router.navigateByUrl('manage-profile/edit/'+this.currentLockedProfile.userId);
        } else {
          localStorage.setItem('profile_id', this.currentLockedProfile.userId);
          localStorage.setItem('profile_UserName', this.currentLockedProfile.profileName);
          localStorage.setItem('profileImageUrl', this.currentLockedProfile.profileImageUrl);
          localStorage.setItem('profileSelected', 'true'); // To display welcome card in Dynamic Tab component
          const queryParams = { ...this.route.snapshot.queryParams };
          if (!queryParams.redirect_to_detail && !queryParams.redirect_to) {
            this.router.navigate(['/']);
          }
          if (queryParams.redirect_to_detail) {
            this.commonService.goToDetail(queryParams);
          }
          if (queryParams.redirect_to) {
            this.router.navigate([queryParams.redirect_to]);
          }
          // window.location.href = "/";
        }
      // this.googleEvent.eventEmitter('Profile', 'disable Pin', this.locale + ' - ' + this.timezoneForGA, 0, + '-' + this.username + '-' + this.locale + ' - ' + this.timezoneForGA + '-disable Pin click');
      // setTimeout(() => {
      //   this.router.navigateByUrl('manage-profile/edit/'+this.profile_data._id)
      // }, 500)
      // this.showVerifyPinForm = false;
      }
    }, (error) => {
      this.api_error = error.message;
    });
    

  }

  onPinChange(pin) {
    let fpin = pin;
    this.verifyPinForm.get("profile_pin").setValue(fpin);
  }

  get pinFormControls() {
    return this.verifyPinForm.controls;
  }

  manageProfile(manageShowType){
    if(localStorage.getItem('profile_id')) {
      this.navigationService.back();
    } else {
      this.isManageProfile = manageShowType;
    }
  }


}
