import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CatlougeTypeConstants } from 'src/app/core/constants';
import { customOptionsForBigSlider, customOptionsForEightMiniSlider, customOptionsForFourMiniSlider, customOptionsForSevenMiniSlider, customOptionsForSixMiniSlider, customOptionsForThreeMiniSlider, customOptionsForFiveMiniSlider } from 'src/app/shared/owl-slider-config';
import { CommonService } from "src/app/core/services/common.service";
import * as moment from 'moment';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {


  @Input() owlOption: OwlOptions;
  @Input() data: any = {};

  customOptionsForBigSlider = customOptionsForBigSlider;
  customOptionsForFourMiniSlider = customOptionsForFourMiniSlider;
  customOptionsForFiveMiniSlider = customOptionsForFiveMiniSlider;
  customOptionsForSevenMiniSlider = customOptionsForSevenMiniSlider;
  customOptionsForSixMiniSlider = customOptionsForSixMiniSlider;
  customOptionsForThreeMiniSlider = customOptionsForThreeMiniSlider;
  customOptionsForEightMiniSlider = customOptionsForEightMiniSlider;


  assets_hover: boolean;
  userInfo: any;
  page_view: string;
  routeName: string;
  playListId: string;
  setPlayListItem: any;
  addedAssetIndex: any;
  error_code: any;
  deletedItem: any;
  deleteAsset_id: any;

  thumbnailType: any;
  thumbnailClass: string;
  notFoundImg: string = 'image-not-found';
  assetImgParam: any;
  categoryId: any;
  viewAllType: any;
  limit: number = 20;

  isCircle: any;
  enlargedCircle: any;
  blankSliderCount: number;
  owlSlides: boolean = false;

  constructor(
    public router: Router,
    public commonService: CommonService,

  ) { }

  ngOnInit(): void {


    this.data = this.data;

    this.thumbnailType = this.data.thumbnail_type.slug;

    switch (this.thumbnailType) {
      case 'landscape':
        this.owlOption = customOptionsForFiveMiniSlider;
        this.assetImgParam = { "crop": 'fill', 'width': 304, 'height': 171 };
        this.thumbnailClass = 'landscape_assets';
        this.notFoundImg = 'landscape';
        break;
      case 'enlarged-landscape':
        this.owlOption = customOptionsForFourMiniSlider;
        this.assetImgParam = { "crop": 'fill', 'width': 400, 'height': 225 };
        this.thumbnailClass = 'enlarged_landscape_assets';
        this.notFoundImg = 'enlarged_landscape';
        break;
      case 'stretched-landscape':
        this.owlOption = customOptionsForFourMiniSlider;
        this.assetImgParam = { "crop": 'scale', 'width': 368, 'height': 161 };
        this.thumbnailClass = 'stretched_landscape_assets';
        this.notFoundImg = 'stretched_landscape';

        break;
      case 'portrait':
        this.owlOption = customOptionsForEightMiniSlider;
        this.assetImgParam = { "crop": 'fill', 'ar_2': 3, 'width': 220, 'height': 330 };
        this.thumbnailClass = 'portrait_assets';
        this.notFoundImg = 'portrait';
        break;
      case 'square':
        this.owlOption = customOptionsForSevenMiniSlider;
        this.assetImgParam = { "crop": 'scale', 'width': 220, 'height': 220 };
        this.thumbnailClass = 'square_assets';
        this.notFoundImg = 'square';
        break;
      case 'enlarged-square':
        this.owlOption = customOptionsForSixMiniSlider;
        this.assetImgParam = { "crop": 'scale', 'width': 320, 'height': 320 };
        this.thumbnailClass = 'enlarged_square_assets';
        this.notFoundImg = 'enlarged_square';
        break;
      case 'circular':
        this.owlOption = customOptionsForSevenMiniSlider;
        this.assetImgParam = { "roundCrop": true, 'width': 220, 'height': 220 };
        this.thumbnailClass = 'circular_assets';
        this.notFoundImg = 'circle';
        break;
      case 'enlarged-circle':
        this.owlOption = customOptionsForSixMiniSlider;
        this.assetImgParam = { "roundCrop": true, 'width': 320, 'height': 320 };
        this.thumbnailClass = 'enlarged-circular_assets';
        this.notFoundImg = 'circle';
        break;

      default: this.thumbnailClass = 'landscape_assets';
        this.assetImgParam = { 'width': 304, 'height': 171 };
        this.owlOption = customOptionsForFourMiniSlider;
        this.notFoundImg = 'landscape';
        break;
    }

    this.blankSliderCount = this.owlOption.items - this.data.sub_categories.length;

    // this.owlSlides = this.data.sub_categories && this.data.sub_categories.length >= 4 ? true : false;


  }

  counter(count: number): any[] {
    return Array(count).fill(0).map((x, i) => i);
  }

  redirectUrl(data, content, jwPlayerDom, event) {
    let viewAlldParams = {
      categoryId: data._id,
    }

    viewAlldParams['type'] = this.data.tab_slug;
    let viewalltabDataParams = {
      "plateform": "web",
      "catlogue": CatlougeTypeConstants.catlogue,
      "slug": this.data.tab_slug,
      "call": 'index',
      "page": 1,
      "limit": this.limit
    };
    this.router.navigate(['/subcategory'], { queryParams: viewAlldParams })

    
  }
  
  viewall(data) {
    console.log(data);
    let viewAlldParams = {
      categoryId: data.category_mongo_id,
      categoryType: data.category_type,
      subCategoryCount: data.sub_category_count
    }

    viewAlldParams['type'] = data.tab_slug;
    this.router.navigate(['/viewall'], { queryParams: viewAlldParams })
  }

  item_hover_enter() {
    this.assets_hover = true;
  }

  item_hover_leave() {
    this.assets_hover = false;
  }


 

}
