<div class="container mt-4">
    <div class="d-flex align-items-center justify-content-between">
        <img src="../../../assets/img/logo.png" class="profile-logo-width" />
        <a href="javascript:void(0);" (click)="verifyPinBack()" *ngIf="showVerifyPinForm">
             <img src="../../../assets/img/Icon/close.svg" />
        </a>
        <a href="javascript:void(0);" (click)="manageProfile(false)" *ngIf="isManageProfile && !showVerifyPinForm">
            <img src="../../../assets/img/Icon/close.svg" />
       </a>
    </div>
    <div class="profile-align">
        <div class="row">
            <div class="col-md-12" *ngIf="!showVerifyPinForm">
                <div class="col-md-12 text-center">
                    <label *ngIf="!isManageProfile" class="login_title login-mb-40">{{language.whoIsWatching}}</label>
                    <label *ngIf="isManageProfile" class="login_title login-mb-40">{{language.editProfile}}</label>
                </div>
                <div class="col-md-12" *ngIf="!isManageProfile">
                    <ul class="profile_type">

                        <li class="wow animate__animated animate__fadeInUp" *ngFor="let item of profile; let index=index;" [attr.data-wow-delay]="index/8 + 's'" title="Switch profile">
                            
                            <a href="javascript:void(0);" (click)="SwitchUser(item._id, item.profile_name, item.profile_lock, item.profile_image)">
                                <div class="img_border">
                                    <img [src]="item.profile_image" alt="" title="" />
                                    <span class="kids_tag" *ngIf="item.is_child">
                                        <label>{{language.kids}}</label>
                                    </span>
                                </div>
                                <span>
                                    <!-- <i *ngIf="item.profile_lock" class="fa fa-lock user_profile_name mr-2" style="font-weight: 400;"></i> -->
                                    <img *ngIf="item.profile_lock" class="user_profile_name mr-2 profile-lock-img" src="../../../assets/img/Icon/lock.svg" />
                                    <a class="user_profile_name" title="Edit Profile">{{item.profile_name}}</a>
                                </span>
                            </a>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" [attr.data-wow-delay]="profile.length/8 + 's'" *ngIf="add_profile_count" routerLink="add">
                            <a href="javascript:;">
                                <div class="img_border add_new_profile">
                                    <img style="z-index: 9999;" src="../../../assets/img/Icon/add_profile.svg" alt="" title="" />
                                </div>
                                <span class="user_profile_name">{{language.addNew}}</span>
                            </a>
                        </li>
                    </ul>
                    <div class="col-md-12 text-center mt-4 mb-4">
                        <button class="btn btn-default mt-4 btn_height btn_hover profile-btn" (click)="manageProfile(true)">
                            <span>{{language.manageProfiles}}</span>
                        </button>
                    </div>
                </div>
                <div *ngIf="isManageProfile">
                    <ul class="profile_type manage_profile">
                        <li class="wow animate__animated animate__fadeInUp" [attr.data-wow-delay]="index/8 + 's'" *ngFor="let item of profile; let index = index" title="Switch profile">
                            <a href="javascript:void(0);" (click)="editProfile(item._id, item.profile_name, item.profile_lock)">
                                <div class="img_border">
                                    <div class="manage_profile_overlay">
                                        <img [src]="item.profile_image" alt="" title="" />
                                        <span class="kids_tag" *ngIf="item.is_child">
                                            <label>{{language.kids}}</label>
                                        </span>
                                    </div>
                                    <i class="profile_edit">
                                        <img src="../../../assets/img/Icon/edit.svg" />
                                    </i>
                                </div>
                                <span>
                                    <img *ngIf="item.profile_lock" class="user_profile_name mr-2 profile-lock-img" src="../../../assets/img/Icon/lock.svg" />
                                    <a class="user_profile_name" title="Edit Profile">{{item.profile_name}}</a>
                                </span>
                            </a>
                        </li>
                        <li class="wow animate__animated animate__fadeInUp" [attr.data-wow-delay]="profile.length/8 + 's'" *ngIf="add_profile_count" routerLink="add">
                            <a href="javascript:;">
                                <div class="img_border add_new_profile">
                                    <img style="z-index: 9999;" src="../../../assets/img/Icon/add_profile.svg" alt="" title="" />
                                </div>
                                <span class="user_profile_name">{{language.addNew}}</span>
                            </a>
                        </li>

                    </ul>
                    
                </div>
            </div>

            <div class="col-md-12">
                <div class="col-md-12 text-center" *ngIf="showVerifyPinForm">
                    <div class="add_profile_width set_pin">
                        <!-- <label class="login_title">{{language.enterPinForProfile}}</label> -->
                        <div class="col-md-12 text-center">
                            <label class="login_title">{{language.hi}} {{currentLockedProfile.profileName}}!</label>
                            <label class="text-white mb-0">{{language.enterYourPinToAccess}}</label>
                        </div>
                        <form [formGroup]="verifyPinForm" (submit)="verifyPinSubmit()">
                            <div class="add_profile_width">
                                <div class="otp_line form-group text-center mt-5">
                                    <div class="four_digit_pin" [ngClass]="(api_error || (showValidationErrors && pinFormControls.profile_pin.invalid)) && 'otp-invalid'">
                                        <ng-otp-input (onInputChange)="onPinChange($event)"
                                            [config]="{length:4,allowNumbersOnly:true}">
                                        </ng-otp-input>
                                        <div class="popup-error-height text-right">
                                            <div  *ngIf="showValidationErrors && pinFormControls.profile_pin.errors">
                                                <app-error
                                                    *ngIf="pinFormControls.profile_pin.hasError('required') || pinFormControls.profile_pin.invalid"
                                                    [message]="language.pleaseEnterValidPIN"></app-error>
                                            </div>
                                            <div *ngIf="api_error">
                                                <app-error [message]="api_error"></app-error>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                <!-- <div>
                                    <a routerLink="reset-pin">{{language.forgotPin}}</a>
                                </div> -->

                                
                                <button type="submit" class="btn btn-default mt-3 btn_height btn_hover profile-btn">
                                    <span>{{language.proceed}}</span>
                                </button>
                                <div class="text-center mt-5">
                                    <a [routerLink]="'reset-pin/' + currentLockedProfile.userId" class="">
                                        <span>{{language.forgotPin}}?</span>
                                    </a>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>