import { Injectable } from '@angular/core';
import { Observable, throwError} from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/core';

@Injectable()
export class PlayListService {
  END_POINT = environment.api_url;
  constructor(
    private apiService: ApiService,
    ) { }

  addToPlayList(params){
    return this.apiService.post('watchlist/add', params).pipe(map(data => {
      return data;
    }));

  }


  getPlaylist(){
    return this.apiService.get(`watchlist/getall`).pipe(map(data => {
      return data;
    }));
  
  }


  deleteFromPlayList(params){
    return this.apiService.delete('watchlist/delete?'+params).pipe(map(data => {
      return data;
    }));
  }
  
}
