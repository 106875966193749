import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';

import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { User } from '../models';
import { map } from 'rxjs/operators';
import { LoginResponse } from '../models'


@Injectable()
export class UserService {
  private currentUserSubject = new BehaviorSubject(false);
  public currentUser:any;

  private currentMenuSubject = new BehaviorSubject(false);
  public activeMenu = this.currentMenuSubject.asObservable();
  currentSlugName: any;
  auth_path= 'auth/';
  user_path= 'user/';

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService
  ) { 
   this.currentUser = this.currentUserSubject.asObservable();
  }
  
  populate() {
    if (this.jwtService.getToken()) {
      this.apiService.get('/user')
        .subscribe(
          data => this.setAuth(data.user),
          err => this.purgeAuth()
        );
    } else {
      this.purgeAuth();
    }
  }

  setAuth(user: any) {
    this.jwtService.saveToken(user.data);
    this.currentUserSubject.next(user);
    // localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('id', user.user_id);
    localStorage.setItem('type', user.role);
    localStorage.setItem('user_name', user.user_name);
    localStorage.setItem('uname', user.uname);
    localStorage.setItem('uemail', user.uemail);
    localStorage.setItem('menu', JSON.stringify(user.menu));

  }

  attemptAuth(credentials): Observable<User> {
    return this.apiService.post('signupnexg',  credentials).pipe(map(data => {
      if (data['code'] != 404) this.setAuth(data);
      return data;
    }
    ));
  }

  getHistory(credentials): Observable<User> {
    return this.apiService.post('auth/getHistory', credentials).pipe(map(data => {
      return data;
    }));
  }

  getMenuListForNexGtv(credentials):Observable<User> {
    return this.apiService.post('auth/getMenuListForNexgtv', credentials).pipe(map(data => {
      return data;
    }
    ));
  }

  update(user): Observable<User> {
    return this.apiService
      .put('/update/user', { user })
      .pipe(map(data => {
        // Update the currentUser observable
        this.currentUserSubject.next(data.user);
        return data.user;
      }));
  }

  emailExist(cond) {
    return this.apiService.post('auth/emailExist', cond).pipe(map(data => {
      return data;
    }));
  }


  convertoJSONToUrlEncoded(params){
      var queryString = Object.keys(params).map(function(key) {
          return key + '=' + params[key]
      }).join('&');
      queryString.replace('"', '');
      return queryString;
  }

  loginStatus(loginStatus:boolean){
    this.currentUserSubject.next(loginStatus)
  }

  updateSelectedMenu(menuName){
    this.currentMenuSubject.next(menuName)
  }

  requestOtp(credentials){
    return this.apiService.post('signupnexg/sendpotp', credentials).pipe(map(data => {
      return data;
    }));

  }

  setCurrentSlugName(name) {
    localStorage.setItem('slug',name);
  }

  get getCurrentSlugName() {
    return this.currentSlugName = localStorage.getItem('slug');
  }

  
  dynamicTab() {
    return this.apiService.get(`tabdata/tabs`).pipe(map(data => {
      return data;
    }));
  }

///////////////////////// New With Node API

  checkUser(url,data): Observable<any>{
    return this.apiService.post('auth/'+url, data)
  }
  register(url,data): Observable<any>{
    return this.apiService.post('auth/'+url, data)
  }
  login(url,data): Observable<any>{
    return this.apiService.post('auth/'+url, data)
  }
  verifyOtp(url,data): Observable<any>{
    return this.apiService.post('auth/'+url, data)
  }
  sendOtp(data): Observable<any> {
    return this.apiService.post('auth/send/otp', data);
  }
  resendOtp(url,data): Observable<any>{
    return this.apiService.post('auth/'+url, data)
  }
  loginWithOtp(url,data): Observable<any>{
    return this.apiService.post('auth/'+url, data)
  }
  forgotPassword(url,data): Observable<any>{
    return this.apiService.post('auth/'+url, data)
  }
  logout(url): Observable<any>{
    return this.apiService.post('user/'+url)
  }
  changePassword(url,data): Observable<any>{
    return this.apiService.put('user/'+url, data)
  }
  updateUserDetails(data): Observable<any> {
    return this.apiService.put('user/details/update', data)
  }
  resetPassword(url,data): Observable<any>{
    return this.apiService.put('auth/'+url, data)
  }
  checkDevice(url, data): Observable<any>{
    return this.apiService.post('user/'+url, data)
  }
  changeUsername(data): Observable<any> {
    return this.apiService.post('user/change/username', data);
  }
  verifyChangeUsername(data): Observable<any> {
    return this.apiService.post('user/change/username/verify', data);
  }
  deleteAccount(): Observable<any> {
    return this.apiService.delete('user/delete');
  }
  getPrfiles(url,data): Observable<any>{
    return this.apiService.get(this.user_path+url, data)
  }

  getPrfile(url): Observable<any>{
    return this.apiService.get(this.user_path+url)
  }

  addPrfile(url,data): Observable<any>{
    return this.apiService.post(this.user_path+url, data)
  }

  editPrfile(url,data): Observable<any>{
    return this.apiService.put(this.user_path+url, data)
  }

  deletePrfile(url): Observable<any>{
    return this.apiService.delete(this.user_path+url)
  }

  setPrfilePin(url,data): Observable<any>{
    return this.apiService.post(this.user_path+url, data)
  }

  changePrfilePin(url,data): Observable<any>{
    return this.apiService.put(this.user_path+url, data)
  }

  unsetPrfilePin(url,data): Observable<any>{
    return this.apiService.post(this.user_path+url, data)
  }

  verifyPrfilePin(url,data): Observable<any>{
    return this.apiService.post(this.user_path+url, data)
  }

  resetPrfilePin(url): Observable<any>{
    return this.apiService.post(this.user_path+url)
  }

  getCountryList(url): Observable<any>{
    return this.apiService.get('auth/'+url)
  }

  getAdditionalFieldList(url): Observable<any>{
    return this.apiService.get('auth/'+url)
  }

  updatedAdditionalFieldList(url, data): Observable<any>{
    return this.apiService.put('user/'+url, data)
  }

  getProfileAvatar(url): Observable<any> {
    return this.apiService.get(this.user_path+url)
  }

  getUserDetails(): Observable<any> {
    return this.apiService.get('user/details');
  }

  purgeAuth() {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('playListId');
    localStorage.removeItem('parentControl');
    localStorage.removeItem('debug');
    localStorage.removeItem('slugName');
    localStorage.removeItem('profile_id');
    localStorage.removeItem('profile_UserName');
    localStorage.removeItem('profileImageUrl');
    localStorage.removeItem('prevProfileId');
    localStorage.removeItem('prevProfileName');
    localStorage.removeItem('recentSearches');
  }

  getBuildSummary(): Observable<any> {
    return this.apiService.get('buildsummary');
  }

}
