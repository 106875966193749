import { Component, EventEmitter, Input, OnInit, ViewChild, ViewEncapsulation, Output, SimpleChanges } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LocationStrategy } from "@angular/common";
import { VideoPlayerService } from "src/app/core/services/videoPlayerService";
import { ApiService, DeviceService, UserService, AssetsService } from "src/app/core";
import { EventTrackingService } from "src/app/services/google/event-tracking.service";
import { jwPlayerService } from "src/app/core/services/jwplayer.service";
import { Analyticsstreaminglog, CatlougeTypeConstants } from "src/app/core/constants/index";
import { SpinnerVisibilityService } from "ng-http-loader";
import { ToastrService } from "ngx-toastr";
import { DfpVideoDirective } from "ngx-dfp/video";
import { ActivatedRoute, Router } from "@angular/router";
import * as CryptoJS from 'crypto-js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from "@angular/common/http";
import { CommonService } from '../../core/services/common.service';
import * as moment from 'moment-timezone';
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";


declare var videojs;
declare var google;
declare var $: any;
declare var window: any;
declare function invokeSpriteScript(url, interval, width, height): any;
@Component({
  selector: "app-videojs-player",
  templateUrl: "./videojs-player.component.html",
  styleUrls: ["./videojs-player.component.css"],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbActiveModal],
})
export class VideojsPlayerComponent implements OnInit {
  @ViewChild(DfpVideoDirective) dfpVideo: DfpVideoDirective;
  @Output() onModalClose = new EventEmitter<any>();
  @Input() playResult: any;
  streamInfo: any;

  adInput = new EventEmitter<any>();


  playermodal: any;
  totalDuration: any;
  seekTime: any;
  userInfo: any;
  sessionId: any;
  title: string = "";
  title1: string = "";
  videoPlayUrl: any = "";

  username: string = "NA";
  timerOfPlayer: any;
  timerForPlayer: number;
  videoType: string = "video";
  nextVideoCode: string = "";
  preVideoCode: string = "";
  nextVideoName: string = "";
  preVideoName: string = "";
  nextEpisodeNumber: string = "";
  preEpisodeNumber: string = "";
  nextSeasonNumber: string = "";
  preSeasonNumber: string = "";
  prePlayingThumb: string = "";

  preVideoDuration: string = "";
  is_trailer: number = 0;
  public is_live_evnet: number = 0;

  currentPlayingCode: string = "";
  currentPlayingCharegCode: string = "";
  currentPlayingMongoId: string = "";
  currentPlayingContentAvailability: string = "";
  currentPlayingThumb: string = "";
  currentPlayingSec: string = "";
  currentPlayingMin: string = "";
  currentPlayingHour: string = "";
  currentPlayingDuration: string = "";
  currentPlayingGenreText: string = "";
  currentPlayingGenre: any[] = [];
  currentPlayingLanguages: any[] = [];
  currentPlayingSys: string = "";
  currentPlayingShowID: string = "";
  currentPlayingSeasonId: string = "";
  currentPlayingName: string = "";
  currentLastPlayingSeasonId: string = "";

  nextPlayingMongoId = "";
  nextPlayingCharegCode: string = "";
  nextPlayingContentAvailability: string = "";
  nextPlayingThumb: string = "";
  nextPlayingSec: string = "";
  nextPlayingMin: string = "";
  nextPlayingHour: string = "";
  nextPlayingGenreText: string = "";
  nextPlayingGenre: string = "";
  nextPlayingLanguages: string = "";
  nextPlayingSys: string = "";
  nextPlayingStop: string = "";
  nextPlayingDuration: string = "";
  nextVideoDuration: string = "";
  nextPlayingStartTime: any = 0;
  prePlayingStartTime: any = 0;

  nextPlayingShowID: string = "";
  nextPlayingSeasonId: string = "";
  public action1: any;

  trickPlayUrl: string = "";
  trickPlaySize: string = "";
  nextPlayingStreamDetails = {
    charge_code: "",
    asset_mongo_id: "",
    release_date: "",
    name: "",
    thumbnail: "",
    content_availability: "",
    sec: "",
    min: "",
    hours: "",
    genre_name: "",
    languages: "",
    synopsis: "",
    season_id: "",
    show_id: "",
  };
  prePlayingStreamDetails = {
    charge_code: "",
    asset_mongo_id: "",
    release_date: "",
    name: "",
    thumbnail: "",
    content_availability: "",
    sec: "",
    min: "",
    hours: "",
    genre_name: "",
    languages: "",
    genre: "",
    synopsis: "",
    season_id: "",
    show_id: "",
  };

  playType: string = "";
  assetsCode: any;
  rmContApiCalledStatus = false;
  lastEpisode: any;

  error: string = "";
  startTime: any = 0;
  nextVideObj: any = { start: 0, stop: 0, duration: 0 };

  seasonarray: any = [];
  isHudredPersent: boolean = true;

  @ViewChild("showErrorMsg") showErrorMsg;
  @ViewChild("closeShowErrorMsg") closeShowErrorMsg;
  playUrlResObj: any;
  videoLabelObj: any;
  watch_credit_status: any = 1;
  currentPlayingTime: any;
  shouldCloseAfterPersent: boolean = true;

  currentPlayingData: any;
  interval_ID: any;

  data = "Example1";//Message to Encrypt
  iv = CryptoJS.enc.Base64.parse("");//giving empty initialization vector
  key = CryptoJS.SHA256("Message");//hashing the key using SHA256
  encrypted_UUID: any;
  deviceInfo = null;
  errmsg: any;
  ipAddress: any;

  nextVideoPaidData: any;
  nextEpisodeIsBlank: boolean = true;
  preVideoPaidData: any;
  notFoundImg: any;
  isCloudFrontType: any;
  sessionData: any;
  moreLikeData: any;
  freqPlayedData: any;
  routeParams: any;
  isFullScreen: boolean;
  isSearchOpen: boolean;
  isAssetImageVisible: boolean;
  isPlaybackError: boolean;
  previewResult: any;
  isPreviewVideoMuted: boolean = true;
  isPreviewFinished: boolean;
  deviceId: string;
  deviceType: string;
  freeIcon: string = environment.freeIcon;
  paidIcon: string = environment.paidIcon;
  playStartTime: number;
  isDeviceNotAllowed: boolean;
  deviceRestrictionMessage: string;
  deviceRetrictionHeading: string;
  planObj: any;
  playbackErrorTimeout: any;
  playbackErrorRetries: number = 20;
  // @Input() currentProgram: any;


  constructor(
    public activeModal: NgbActiveModal,
    private location: LocationStrategy,
    public VideoPlayerService: VideoPlayerService,
    private googleEvent: EventTrackingService,
    public UserService: UserService,
    public jwPlayerService: jwPlayerService,
    public spinner: SpinnerVisibilityService,
    private toastr: ToastrService,
    public router: Router,
    public apiService: ApiService,
    private userService: UserService,
    private deviceService: DeviceService,
    private http: HttpClient,
    // private deviceService: DeviceDetectorService,
    private assetsService: AssetsService,
    public CommonService: CommonService,
    private activatedRoute: ActivatedRoute,
  ) {
    // history.pushState(null, null, window.location.href);
    // this.location.onPopState(() => {
    //   this.mobileBrowserBackbutton();
    // });
    // window.stop();

  }

  adEvent(event) {

    if (event.type === "complete") {
      this.dfpVideo.adContainer.style.zIndex = "-1";
      this.playermodal.play();
    }

  }

  mobileBrowserBackbutton() {
    this.disableModal();
    if (this.playermodal) this.playermodal.dispose();
    this.playermodal = "";
    return false;
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.playResult) {
      this.initData();
    }
  }

  ngOnInit(): void {
    this.notFoundImg = "landscape";
    if (this.CommonService.filterType == 1) {
      this.isCloudFrontType = true;
    } else {
      this.isCloudFrontType = false;
    }

    this.watch_credit_status = 1;
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"))?.result?.user;
    this.sessionId = localStorage.getItem("sessionId");
    this.deviceId = localStorage.getItem('deviceId');
    this.getDeviceInfo();

    if (this.userInfo) {
      this.username = this.userInfo.username;
    }
    if (this.playResult) {
    }
    this.initData();
    
  }

  ngOnDestroy(): void {
    clearInterval(this.interval_ID);
    if(this.playermodal) {
      this.playermodal.dispose();
    }
  }

  initData() {
    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.isFullScreen = params['full_screen'];
    // });
    this.activatedRoute.queryParams.subscribe(params => {
      // this.isFullScreen = params['is_fullscreen'];
      this.isFullScreen = params['full_screen'];
      this.isSearchOpen = params['search'];
      if (this.playermodal && this.isSearchOpen) 
        this.playermodal.pause();

      if (this.playermodal && !this.isSearchOpen)
        this.playermodal.play();
    });

    this.activatedRoute.params.subscribe(params => {
      if (this.streamInfo) {
        // post the analytics data for the previous asset
        this.postAnalyticsData();
      }
      this.routeParams = params;
      this.isAssetImageVisible = false;
      this.isPreviewFinished = false;
      this.isPlaybackError = false;
      this.isDeviceNotAllowed = false;
      this.playbackErrorRetries = 20;
      if (this.interval_ID) {
        clearInterval(this.interval_ID);
      }
      if (this.playbackErrorTimeout) {
        clearTimeout(this.playbackErrorTimeout);
      }

      let videoStreamObj: any = {
        nmode: "WIFI",
        pushId: "",
        ct: "1",
        build: 10000,
        code: this.routeParams.code,
        appv: "1.0.00",
        push_date: "",
        air: "airip",
        ip: "172.30.0.109"
      };
  
      let urlEncodedStreamInfo = this.UserService.convertoJSONToUrlEncoded(videoStreamObj);
      this.VideoPlayerService.playUrl(urlEncodedStreamInfo).subscribe(
        (playRes) => {
          this.playResult = playRes.result;

          console.log('playResult from video player: ', this.playResult);
          setTimeout(() => {
            document.getElementById('maincontentarea').classList.remove("_beforeLoad");
            // document.getElementById('beforeload_spinner').style.display = 'none';
          }, 400);
  
          this.streamInfo = this.playResult.assetData;
          this.planObj = playRes.plan;
          this.videoType = this.routeParams.type;
          console.log("this.streamInfoooooooooooooooooo",this.streamInfo);

          this.getRecommAndFreq(this.routeParams.code);
      
          if(this.streamInfo.recently_watched) {
      
            if (this.streamInfo.recently_watched.asset != undefined && this.videoType == "episode") {
              this.streamInfo.recently_watched.charge_code = this.streamInfo.recently_watched.asset.charge_code;
            }
      
            if (this.streamInfo.recently_watched.sec == undefined && this.videoType == "episode") {
              if (this.streamInfo.recently_watched.asset != undefined)
                this.streamInfo.recently_watched.sec = this.streamInfo.recently_watched.asset.sec;
            }
      
            if (this.streamInfo.recently_watched.min == undefined && this.videoType == "episode") {
              if (this.streamInfo.recently_watched.asset != undefined)
                this.streamInfo.recently_watched.min = this.streamInfo.recently_watched.asset.min;
            }
      
            if (this.streamInfo.recently_watched.hours == undefined && this.videoType == "episode") {
              if (this.streamInfo.recently_watched.asset != undefined)
                this.streamInfo.recently_watched.hours = this.streamInfo.recently_watched.asset.hours;
            }
      
            if (this.streamInfo.recently_watched.genre_text == undefined && this.videoType == "episode") {
              if (this.streamInfo.recently_watched.asset != undefined)
                this.streamInfo.recently_watched.genre_text = this.streamInfo.recently_watched.asset.genre_text != undefined
                  ? this.streamInfo.recently_watched.asset.genre_text
                  : this.streamInfo.genre_text;
              else
                this.streamInfo.recently_watched.genre_text = this.streamInfo.genre_text == undefined ? "" : this.streamInfo.genre_text;
            } else {
              this.streamInfo.recently_watched.genre_text = this.streamInfo.genre_text == undefined ? "" : this.streamInfo.genre_text;
            }
            if (this.streamInfo.recently_watched.synopsis == undefined) {
              this.streamInfo.recently_watched.synopsis = this.streamInfo.synopsis;
            }
            if (this.streamInfo.recently_watched.season_id == undefined) {
              this.streamInfo.recently_watched.season_id = this.streamInfo.season_id;
            }
            if (this.streamInfo.recently_watched.show_id == undefined) {
              this.streamInfo.recently_watched.show_id = this.streamInfo.show_id;
            }
      
          }
      
      
          try {
            this.currentPlayingCode = this.streamInfo?.code;
            this.currentPlayingCharegCode = this.streamInfo?.charge_code;
            this.currentPlayingName = this.streamInfo?.name;
            this.currentPlayingGenre = this.streamInfo?.genre;
            this.currentPlayingLanguages = this.streamInfo?.languages;
            // this.currentPlayingCode = this.streamInfo.recently_watched?.code == undefined
            //   ? (this.streamInfo.seek_bar_data ? this.streamInfo.seek_bar_data.code : '')
            //   : this.streamInfo.recently_watched.code;
            // this.currentPlayingName = this.streamInfo.name;
            this.currentPlayingMongoId = this.streamInfo.recently_watched.asset_id;
            this.currentPlayingCharegCode = this.streamInfo.recently_watched.charge_code;
            this.currentPlayingContentAvailability = this.streamInfo.recently_watched.content_availability;
            this.currentPlayingThumb = this.streamInfo.recently_watched.thumbnail;
            this.currentPlayingSec = this.streamInfo.recently_watched.sec;
            this.currentPlayingMin = this.streamInfo.recently_watched.min;
            this.currentPlayingHour = this.streamInfo.recently_watched.hours;
            this.currentPlayingDuration = this.streamInfo.recently_watched.duration;
            this.currentPlayingGenreText = this.streamInfo.recently_watched.genre_text;
            // this.currentPlayingGenre = this.streamInfo.recently_watched.genre
            // this.currentPlayingLanguages = this.streamInfo.recently_watched.languages;
            this.currentPlayingSys = this.streamInfo.recently_watched.synopsis;
            this.currentPlayingShowID = this.streamInfo.recently_watched.show_id;
            this.currentPlayingSeasonId = this.streamInfo.recently_watched.season_id;
      
          } catch (e) {
            console.log(e);
          }
      
          if (this.streamInfo.seasons != undefined && !this.streamInfo.is_trailer) {
            this.preNextVideo();
          }
          if (this.streamInfo.is_trailer && this.streamInfo.is_trailer_list_index) {
            this.trailerPreviewList();
          }
      
      
          let videoStreamObj: any = {
            nmode: "WIFI",
            pushId: "",
            ct: "1",
            build: 10000,
            code: this.streamInfo.code, // this.streamInfo.type == 'event' ? this.streamInfo.code : this.currentPlayingCode,
            appv: "1.0.00",
            push_date: "",
            air: "airip",
            ip: "172.30.0.109"
          };
          // if trailer exist
          
      
          if (this.streamInfo.is_trailer && this.streamInfo.trailer.length > 0) {
            videoStreamObj.code = this.streamInfo.code;
            this.is_trailer = this.streamInfo.is_trailer;
            const trailer_index = this.streamInfo.is_trailer_list_index ? this.streamInfo.is_trailer_list_index : 0;
            videoStreamObj.trailer_id = this.streamInfo.trailer[trailer_index].trailer_id;
          }
      
      
          if (this.streamInfo.is_liveEvenet && this.streamInfo.type == "event") {
      
            this.is_live_evnet = this.streamInfo.is_liveEvenet;
          }
      
          if (this.streamInfo?.content_availability === "FREE" || (this.streamInfo?.is_pack_active == 1 && this.streamInfo?.content_availability === "PAID")) {
            // Call the device api before playing the video
            if (this.planObj != undefined && this.planObj != '' && Object.keys(this.planObj).length > 0) {
              this.callDeviceApi();
              this.interval_ID = setInterval(() => {
                this.callDeviceApi();
              }, this.planObj.api_duration);
            }
            this.playVideoWithAPI();
          } else if (this.streamInfo?.preview?.length > 0) {
            this.playAssetPreview();
          } else {
            this.isAssetImageVisible = true;
            if (this.playermodal) {
              this.playermodal.dispose();
              this.playermodal = "";
            }
          }
          let is_check_device = JSON.parse(localStorage.getItem('is_check_device'));
      
          // if(this.streamInfo.type === 'livetv') {
          //   this.getCurrentProgram(this.streamInfo.code);
          // }
      
      
          // this.getDeviceInfo();
      
          //////////// for uuid ////////////////
          // this.getDeviceInfo();
          // console.log('is_check_device: ', localStorage.getItem('is_check_device'));
          // if (is_check_device) {
            
          // }
      
      
          document.addEventListener('click', function () {
            //#frequently-modal[aria-hidden="true"]
            
            var FrequentlyElement = $("#frequently-modal.modal");
            setTimeout(() => {
              FrequentlyElement.attr('aria-hidden', 'false')
            },2000)
              
            var MoreElement = $("#more_like_modal.modal");
            setTimeout(() => {
              MoreElement.attr('aria-hidden', 'false')
            },1000)
      
          });
        });
    });
    
  }

  getDeviceInfo() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.deviceType = this.deviceService.getDeviceType();
  }

  callDeviceApi() {
    let username = JSON.parse(localStorage.getItem('userInfo')).result.user.username;
    let alloweddevice = this.planObj.allowed_device;
    let allowedOs = this.planObj.allowed_os;
    let apiDuration = this.planObj.api_duration;
    let obj: any = {
      catalogue: CatlougeTypeConstants.catlogue,
      // session_id: this.sessionId,
      username: username,
      code: this.streamInfo?.code,
      device_id: localStorage.getItem('deviceId'),
      device_type: 'computer',
      date: Date.now(),
      plan: {
        allowed_device: alloweddevice,
        allowed_os: allowedOs,
        api_duration: apiDuration
      }
    }

    let urlEncodedBodyData = this.UserService.convertoJSONToUrlEncoded(obj)
    urlEncodedBodyData = JSON.stringify(obj);
    // console.log(urlEncodedBodyData);
    // console.log(obj);

    let allowsArr = this.planObj.allowed_os;
    if (allowsArr != "" && allowsArr != undefined && allowsArr.length > 0) {
      if (allowsArr.includes('computer')) {
        this.deviceService.checkAllowedDevices('device', obj).subscribe(
          (res) => {
            this.errmsg = res.msg
          },
          (err) => {
            console.error('error checking device restriction: ', err);
            if (err.status == 400) {
              clearInterval(this.interval_ID);
              this.isDeviceNotAllowed = true;
              this.deviceRetrictionHeading = 'Screen Limit Reached';
              this.deviceRestrictionMessage = err?.error?.body?.msg;
            if (this.playermodal) {
              this.playermodal.dispose();
              this.playermodal = "";
            }
              // this.shouldCloseAfterPersent = false;
              // this.onModalClose.emit(err.error.msg);
            }

          })

      } else {
        clearInterval(this.interval_ID);
        this.disableModal();
        this.isDeviceNotAllowed = true;
        this.deviceRestrictionMessage = 'This device is not allowed for streaming in your existing plan. Kindly upgrade your plan in case you wish to watch on this device.';
        this.deviceRetrictionHeading = 'Screen Limit Reached'
        if (this.playermodal) {
          this.playermodal.dispose();
          this.playermodal = "";
        }
        this.shouldCloseAfterPersent = false;
        this.onModalClose.emit("This device is not allowed for streaming in your existing plan. Kindly upgrade your plan in case you wish to watch on this device.");
      }
    }

  }

  replayPreview() {
    this.playermodal.currentTime(0);
    this.playermodal.play();
    this.isPreviewFinished = false;
  }

  playAssetPreview() {
    console.log('playAssetPreview');
    let assetDetail = {
      code: this.routeParams.code,
    }

    let assetInfo = this.UserService.convertoJSONToUrlEncoded(assetDetail);
    this.VideoPlayerService.playPreview(assetInfo).subscribe(
      (previewRes) => {
        this.previewResult = previewRes.result;
        if (
          this.previewResult.cloud_front &&
          Object.keys(this.previewResult.cloud_front).length > 0 &&
          this.previewResult.cloud_front.cloudfront_key_pair_id != "" && this.previewResult.cloud_front.video_url != "" && this.previewResult.cloud_front.cloudfront_policy != "" && this.previewResult.cloud_front.cloudfront_signature != ""
        ) {
          this.videoPlayUrl = this.previewResult.cloud_front.video_url;
          this.playVideoWithAPI(true);
        } else {
          this.isAssetImageVisible = true;
          if (this.playermodal) {
            this.playermodal.dispose();
            this.playermodal = "";
          }
        }
      }, (error) => {
        console.error('error fetching preview: ', error);
        this.isAssetImageVisible = true;
        if (this.playermodal) {
          this.playermodal.dispose();
          this.playermodal = "";
        }
      });
  }

  muteUnmutePreview(isMuted: boolean, index, event: Event) {
    event.stopPropagation();
    if (isMuted) {
      this.isPreviewVideoMuted = false;
      var muted = this.playermodal.muted(false);
    } else {
      this.isPreviewVideoMuted = true;
      var muted = this.playermodal.muted(true);
    }
  }

  addSoFastParams() {
    const soFastParams = {
      ads: {
        cb: '3524931673',
        coppa: this.streamInfo?.coppa || 0,
        device_id: this.deviceId,
        device_type_name: this.deviceType.toLowerCase(),
        distribution_platform_name: CatlougeTypeConstants.platformName,
        inapp_browser: 'browser',
        os_name: this.deviceInfo.os.toLowerCase(),
        output: 'vast',
        site_domain: new URL(window.location.href).hostname,
        site_name: CatlougeTypeConstants.platformName,
        us_privacy: '1NYN',
      }
    };
    const adsParams = soFastParams.ads;
    const urlEncodedParams = Object.keys(adsParams)
      .map(key => `ads.${encodeURIComponent(key)}=${encodeURIComponent(adsParams[key])}`)
      .join('&');
    
    this.videoPlayUrl = this.videoPlayUrl + '?' + urlEncodedParams;
    console.log('changed url: ', this.videoPlayUrl);
  }

  playVideoWithAPI(isPreview: boolean = false) {
    // this.VideoPlayerService.playUrl(urlEncodedStreamInfo, this.is_trailer, this.is_live_evnet).subscribe(
    //   (playObj) => {
        let playObj = this.playResult;
        let noerror = 1;
        this.watch_credit_status = 1;
        if (this.videoType == "episode") {
          this.currentLastPlayingSeasonId = playObj.last_video_play_id;
          this.nextVideoCode = playObj.next_episode.code;
          this.currentPlayingData = playObj.assetData;
          this.nextVideoPaidData = Object.keys(playObj.next_episode).length > 0 ? playObj.next_episode : "";
          this.preVideoPaidData = Object.keys(playObj.prev_episode).length > 0 ? playObj.prev_episode : ""; 
          this.sessionData = this.streamInfo.sessionData.result.season.length > 0 ? this.streamInfo.sessionData.result.season : "";
        }


        if (playObj.assetData.trick_play && playObj.assetData.trick_play.image_url) {
          let img_interval = playObj.assetData.trick_play.size;
          let img_width = playObj.assetData.trick_play.width && playObj.assetData.trick_play.width != "" ? playObj.assetData.trick_play.width : 150;
          let img_height = playObj.assetData.trick_play.height && playObj.assetData.trick_play.height != "" ? playObj.assetData.trick_play.height : 84;
          invokeSpriteScript(playObj.assetData.trick_play.image_url, img_interval, img_width, img_height);
        }

        this.playUrlResObj = playObj;

        if (playObj.cloud_front && Object.keys(playObj.cloud_front).length > 0) {
          $('.live_asset_block').removeClass('asset-preview');
          this.videoPlayUrl = playObj.cloud_front.video_url;
          this.setCookie("CloudFront-Key-Pair-Id", playObj.cloud_front.cloudfront_key_pair_id.trim(), 1);
          this.setCookie("CloudFront-Policy", playObj.cloud_front.cloudfront_policy.trim(), 1);
          this.setCookie("CloudFront-Signature", playObj.cloud_front.cloudfront_signature.trim(), 1);
        } else if (!playObj.result) {
          // this.previewResult = previewRes.result;
          $('.live_asset_block').addClass('asset-preview');
          this.videoPlayUrl = this.previewResult.cloud_front.video_url;
          this.setCookie(
            "CloudFront-Key-Pair-Id",
            this.previewResult.cloud_front.cloudfront_key_pair_id.trim(),
            1
          );
          this.setCookie(
            "CloudFront-Policy",
            this.previewResult.cloud_front.cloudfront_policy.trim(),
            1
          );
          this.setCookie(
            "CloudFront-Signature",
            this.previewResult.cloud_front.cloudfront_signature.trim(),
            1
          );
        } else {
          $('.live_asset_block').removeClass('asset-preview');
          this.videoPlayUrl = playObj.result;
        }

        ("http://j-cdr.digivive.com/HLS_Aaj/Aaj.m3u8");

        if (!this.is_trailer && (playObj.assetData.type == 'episode' || playObj.assetData.type == 'tvshow')) {
          let seasonN = this.streamInfo.totalSeason > 1 ? 'S' + playObj.assetData.show_details.season_number : '';
          this.title = '<div id="show_temp">' +
            '<div class="temp-title">' + playObj.assetData.show_details.show_title + '</div>' +
            '<span class="temp-seasnon">' + seasonN + ' E' + playObj.assetData.show_details.episode_number + ' | ' + '</span>' +
            '<span class="temp-seasnon">' + playObj.assetData.show_details.episode_title + '</span>' + '</div>';

          this.title1 = playObj.assetData.show_details.show_title + 'S' + playObj.assetData.show_details.season_number + ' E' + playObj.assetData.show_details.episode_number + ' | ' +
            + playObj.assetData.show_details.episode_title;

        } else if(playObj.assetData.type == 'livetv') {
          if (playObj.result) {
            // If the asset is free or the user has active pack
            this.getCurrentProgram(playObj.assetData.code).subscribe(currentProg => {
              let currentProgram = currentProg?.name ? currentProg.name : '';
              this.title = '<div id="show_temp">' +
                '<div class="temp-title">' + playObj.assetData.name + '</div>' +
                '<span class="temp-seasnon">' + currentProgram + '</span>' + '</div>';
                if ($('.vjs-ttl').length) {
                  $(".vjs-ttl").html(this.title);
                }
            });
          } else {
            // for preview video
            this.title = '<div id="show_temp">' +
              '<div class="temp-title">Preview</div>' +
              '<span class="temp-seasnon"></span></div>';
              if ($('.vjs-ttl').length) {
                $(".vjs-ttl").html(this.title);
              }
          }
        } else {
          this.title = '<div id="show_temp">' +
            '<span class="temp-seasnon movie_title">' + playObj.assetData.name + '</span>' + '</div>';
          this.title1 = (playObj.assetData.name || '');
        }

        let advertisement_url = playObj.assetData.advertisement_url;

        if (playObj.assetData.partner.toLowerCase() === 'so_fast') {
          this.addSoFastParams();
        }

        let new_source = {
          // preview video
          // sources: playObj.result ? [{ src: this.videoPlayUrl, type: "application/x-mpegURL" }] : [{ type: 'video/mp4', src: 'https://www.w3schools.com/html/mov_bbb.mp4', },],
          sources: [{ src: this.videoPlayUrl, type: "application/x-mpegURL" }],
          controls: true,
          autoplay: this.isSearchOpen ? false : true,
          withCredentials: true,
          crossDomain: true,
          // userActions: {
          //   hotkeys: function(event) {
          //     if (event.which === 88) {
          //       this.pause();
          //     }
          //     if (event.which === 89) {
          //       this.play();
          //     }
          //   }
          // }
        };

        // if (!playObj.result) {
        //   console.log('if if if fi');
        //   new_source['muted'] = true;
        // } 
        if (this.playermodal) {
          if(playObj.assetData.type == 'livetv' && this.isFullScreen) {
            // Add event listener to request fullscreen on play
             this.requestForFullScreen();
             
          }
          console.log('playermodal: ', this.playermodal);
          setTimeout(() => {
            if ($(".topcontrols .language .with-two-div .vjs-audio-button.vjs-control").hasClass("vjs-hidden") && $(".topcontrols .language .with-two-div .vjs-subs-caps-button.vjs-control").hasClass("vjs-hidden")) {
                $(".topcontrols").addClass("audioAndSubTitleNotAvailable");
                console.log('top controls if block');
            }else{
              console.log('top controls else block');
              $(".topcontrols").removeClass("audioAndSubTitleNotAvailable");
            }

            if ($(".topcontrols .language .with-two-div .vjs-audio-button.vjs-control").hasClass("vjs-hidden")) {
              $(".language").addClass("audioNotAvailable");
            }
            else {
              $(".language").removeClass("audioNotAvailable");
            }

            if ($(".topcontrols .language .with-two-div .vjs-subs-caps-button.vjs-control").hasClass("vjs-hidden")) {
              $(".language").addClass("audioNotAvailable");
            }
            else {
              $(".language").removeClass("audioNotAvailable");
            }
          }, 200);
          this.playermodal.changeSource(new_source);
        } else {
          this.playermodal = videojs("player_one", new_source);
          if(playObj.assetData.type == 'livetv' && this.isFullScreen) {
            // Add event listener to request fullscreen on play
             this.requestForFullScreen();
             
         }
        }

        // this.playermodal.hotkeys({
        //   volumeStep: 0.1,
        //   seekStep: 5,
        //   enableVolumeScroll:false,
        // });
        
        // var skipBehindButton = this.playermodal.controlBar.addChild("button");
        // var skipBehindButtonDom = skipBehindButton.el();
        // skipBehindButtonDom.innerHTML = "<span aria-hidden='true' class='vjs-icon-placeholder'></span><span class='vjs-control-text' aria-live='polite'>Rewind</span>";
        // skipBehindButton.addClass("buttonClass");
        // skipBehindButton.addClass("vjs-rewind-control");
        // $(".vjs-play-control").before(skipBehindButtonDom);

        // skipBehindButtonDom.onclick = function () {
        //   skipS3MV(-10);
        // }

        // var skipAheadButton = this.playermodal.controlBar.addChild("button");
        // var skipAheadButtonDom = skipAheadButton.el();
        // skipAheadButtonDom.innerHTML = "<span aria-hidden='true' class='vjs-icon-placeholder'></span><span class='vjs-control-text' aria-live='polite'>Rewind</span>";
        // skipAheadButton.addClass("buttonClass");
        // skipAheadButton.addClass("vjs-forward-control");
        // $(".vjs-play-control").after(skipAheadButtonDom);

        // skipAheadButtonDom.onclick = function () {
        //   skipS3MV(10);
        // }

        // function skipS3MV(skipBy) {
        //   videojs("player_one").currentTime(videojs("player_one").currentTime() + skipBy);
        // }
        if (!this.isSearchOpen) {
          console.log('search not open')
          this.playermodal.play();
        }

        videojs.options.hls.overrideNative = true;
        videojs.options.html5.nativeAudioTracks = false;
        videojs.options.html5.nativeTextTracks = false;

        let imaOptions = {
          adTagUrl: advertisement_url,
          disableAdControls: true,
          id: "videoAds",
        };

        this.playermodal.ima(imaOptions);
        google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);
        var startEvent = "click";

        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/Android/i)) {
          startEvent = "touchend";
        }

        let _this = this;
        this.playermodal.one(startEvent, function () {
          _this.playermodal.ima.initializeAdDisplayContainer();
        });

        this.playermodal.nuevo({
          videoInfo: true,
          // infoTitle: this.title,
          infoIcon: "",
          qualityMenu: false,
          pipButton: false,
          settingButton: true,
          buttonRewind: true,
          buttonForward: true,
          resume: true,
          // poster: playObj.assetData.image,
          errordisplay: false,
          shareMenu: false,
          contextMenu: false,
          // slideImage: "https://p8tst.s3.ap-south-1.amazonaws.com/thumb/rra/150/RRA_sprite.jpg",
          // slideType: 'vertical',
          // //slideWidth: 160,  //optional
          // //slideHeight: 90  //optional
        });

        if (playObj.assetData.trick_play && playObj.assetData.trick_play.image_url) {
          this.playermodal.spriteThumbnails({
            interval: playObj.assetData.trick_play.size,
            url: playObj.assetData.trick_play.image_url,
            width: playObj.assetData.trick_play.width && playObj.assetData.trick_play.width != '' ? playObj.assetData.trick_play.width : 150,
            height: playObj.assetData.trick_play.height && playObj.assetData.trick_play.height != '' ? playObj.assetData.trick_play.height : 84
          });
        }

        $(".player_one-dimensions.vjs-fluid").css(
          "height",
          `${window.innerHeight}px`
        );

        $(window).bind("orientationchange", function (event) {
          let height = window.innerHeight;
          $(".player_one-dimensions.vjs-fluid").css("height", `${height}px`);
        });

        $(window).resize(function () {
          let height = window.innerHeight;
          $(".player_one-dimensions.vjs-fluid").css("height", `${height}px`);
        });

        this.playermodal.on("fullscreenchange", (event) => {
          $('#closeFrequentlyPlayed').click();
          $('#closeMoreLikeThis').click();
        });
        let newSelf = this;
        this.playermodal.on('nuevoReady',(event) => {
          event.preventDefault();

          let playingCode =
            this.streamInfo.recently_watched?.code == undefined
              ? (this.streamInfo.seek_bar_data ? this.streamInfo.seek_bar_data.code : '')
              : this.streamInfo.recently_watched.code;

          if (this.streamInfo.recently_watched?.stop != undefined || this.streamInfo.is_trailer) {

            let preTime = this.streamInfo.is_trailer ? 0 : Math.round(this.streamInfo.recently_watched.stop);
            var _this = this;
            if (advertisement_url == "") {
              this.playermodal.one("loadedmetadata", function () {
                _this.playermodal.currentTime(preTime);
              });
            }
            this.playermodal.on("adend", () => {
              if (playingCode == _this.currentPlayingCode) {
                _this.playermodal.currentTime(preTime);
              } else {
                _this.playermodal.play();
              }
            });
            this.playermodal.on("complete", function (e) {
              console.log(e);
            });
            this.playermodal.on("adserror", function (e) {
              console.log(e);
            });
          }

          $(".ima-ad-container").after("<div class='topcontrols'></div>");

          setTimeout(() => {}, 100);
          $(".topcontrols").append("<div class='afterTopDiv'></div>");
          $(".topcontrols").after("<div class='frequently'><div data-toggle='modal' data-target='#frequently-modal' class='frequently-icon'><span><img src='./assets/img/new-player/recently-played-icon.png' /> <p>Frequently Played</p></span</div></div>");
          $(".afterTopDiv").append("<div class='languageandsetting'></div>");
          $(".languageandsetting").append("<div class='language'></div>");


          $("#frequently-modal").appendTo(".video-js.player_one-dimensions");
          $("#more_like_modal").appendTo(".video-js.player_one-dimensions");
          $(".vjs-control-bar").append("<a data-toggle='modal' data-target='#more_like_modal' class='more_like'>More Like This <img src='./assets/img/new-player/up-arrow.png' /></a>");
          $(".vjs-big-play-button").append("<div id='play-pause'><div id='left'><div id='triangle1' class='triangle'></div></div><div id='right'><div id='triangle2' class='triangle'></div></div></div>");
          setTimeout(() => {
            setTimeout(() => {
              $(".vjs-big-play-button").css("visibility", "visible");
            }, 1000);
            
            $(".vjs-big-play-button").before($(".vjs-rewind-control.vjs-rewind-first.vjs-control"));
            $(".vjs-big-play-button").after($(".vjs-forward-control.vjs-control"));

            // to play/pause on touch
            this.playermodal.on('touchend', (e) => {
              e.stopPropagation();
              console.log('touch event: ', e);
              if (e.target.matches('button.vjs-fullscreen-control.vjs-control.vjs-button')
                  || e.target.matches('button.vjs-mute-control.vjs-control.vjs-button')
                  || e.target.matches('div.frequently-icon')
                  || e.target.matches('i#vjs-ttl_btn.fa.fa-arrow-left.vjs-ttl_btn')
                  || e.target.matches('div.vjs-quality-button.vjs-menu-button.vjs-control.vjs-button.q-setting')) {
                return;
              }
              console.log('toucheddddddddddddddddddddddddddddd');
              if (this.playermodal.paused()) {
                this.playermodal.play();
              } else {
                this.playermodal.pause();
              }
            });

            

            this.playermodal.on('userinactive', () => {
              console.log('User is inactive - user-inactive class added');
              const qualityMenu = $('.languageandsetting .vjs-quality-button .vjs-menu')
              if (qualityMenu.hasClass('show_quality') && !this.playermodal.paused()) {
                qualityMenu.removeClass('show_quality');
              }
            });

            var settingButtonIcons = $(".vjs-control-bar .vjs-quality-button");
            var titlesDiv = $(".vjs-info-top");
            var Language = $(".vjs-audio-button.vjs-control");
            var LanguageCaption = $(".vjs-subs-caps-button.vjs-control");
            //settingButtonIcons.appendTo(".topcontrols");
            settingButtonIcons.appendTo(".languageandsetting");
            settingButtonIcons.addClass("q-setting");
            // settingButtonIcons.hide();

            this.playermodal.on('resolutionchange', (event) => {
              const qualityMenu = $('.languageandsetting .vjs-quality-button .vjs-menu')
              if (qualityMenu.hasClass('show_quality')) {
                setTimeout(() => {
                  const touchEvent = new TouchEvent('touchend', {
                    bubbles: true,
                    cancelable: true,
                    touches: [],
                    targetTouches: [],
                    changedTouches: [],
                });
                settingButtonIcons[0].dispatchEvent(touchEvent);
                  qualityMenu.removeClass('show_quality');
                  this.playermodal.play();
                }, 400);
              }
            });


            settingButtonIcons.on('click', (e) => {
              e.stopPropagation();
              console.log('settings clicked');
              const qualityMenu = $('.languageandsetting .vjs-quality-button .vjs-menu')
              if (qualityMenu.hasClass('show_quality')) {
                qualityMenu.removeClass('show_quality');
              } else {
                qualityMenu.addClass('show_quality');
              }
            });
            settingButtonIcons.on('touchend', (e) => {
              console.log('settings touched');
              console.log(e);
              e.stopPropagation();
              e.preventDefault();
              const qualityMenu = $('.languageandsetting .vjs-quality-button .vjs-menu')
              if (qualityMenu.hasClass('show_quality')) {
                qualityMenu.removeClass('show_quality');
              } else {
                qualityMenu.addClass('show_quality');
              }
            });

            //titlesDiv.appendTo(".topcontrols");
            $(".afterTopDiv").prepend(titlesDiv);

            $(".language").append("<div class='audio_subtitles_icon'></div>");
            $(".language").append("<div class='with-two-div'></div>");
            $(".audio_subtitles_icon").append("<img src='./assets/img/new-player/audio_subtitles.png'>");
            Language.appendTo(".with-two-div");
            LanguageCaption.appendTo(".with-two-div");
            $(".vjs-text").prepend(
              '<i class="fa fa-arrow-left vjs-ttl_btn" id="vjs-ttl_btn"></i>'
            );
            $(".vjs-quality-button").append(
              "<img class='setting-icon' src='./assets/img/new-player/setting.png' />"
            );

            var progressBar = $(".vjs-progress-control.vjs-control");
            var currentTime = $(".vjs-current-time.vjs-time-control");
            var timeDivider = $(".vjs-time-control.vjs-time-divider");
            var timeDuration = $(".vjs-duration.vjs-time-control");
            $(".vjs-control-bar").prepend(progressBar); 
            $(".vjs-control-bar").addClass("vjs-visibility");; 
            $(".vjs-progress-control.vjs-control").after("<div class='bottomControlDiv'></div>");
            // $(".bottomControlDiv1").after("<div class='bottomControlDiv2'></div>");
            $(".bottomControlDiv").append("<div class='bottomControlleft'></div>");
            $(".bottomControlDiv").append("<div class='bottomControlRight'></div>");
            $(".bottomControlleft").append("<div class='playTime'></div>");
            currentTime.appendTo(".playTime");
            $(".vjs-current-time.vjs-time-control").after(timeDivider);
            $(".vjs-time-control.vjs-time-divider").after(timeDuration);
           // $(".playTime").after($(".vjs-volume-panel.vjs-control"));
            $(".bottomControlRight").append("<div class='player-previous-button'><img src='./assets/img/new-player/previous-button.png'></div>");
            $("#PrevAsset").appendTo($(".player-previous-button"));
            $(".bottomControlRight").append("<div class='player-next-button'><img src='./assets/img/new-player/next-button.png'></div>");
            $("#NextAsset").appendTo($(".player-next-button"));
            $(".bottomControlRight").append("<div class='player-similar-button'><img src='./assets/img/new-player/episodes.png'></div>");
            $("#SimilarAsset").appendTo($(".player-similar-button"));
            // $(".player-similar-button").after($(".vjs-fullscreen-control.vjs-control"));
            $(".vjs-live-control").after($(".vjs-fullscreen-control.vjs-control"));
            $(".playTime").after("<div class='speed_button'><div class='speed_button_click'><img src='./assets/img/new-player/speed.png'></div></div>");
            $(".speed_button").append($(".vjs-control-bar .vjs-menu-settings .vjs-submenu.vjs-menu-speed"));
            $(".speed_button").after($(".vjs-volume-panel.vjs-control"));

            $(".language .audio_subtitles_icon").click(function () {
              // if ($('.language .with-two-div').hasClass('audio_subtitles_show')) {
              //   $('.language .with-two-div').removeClass('audio_subtitles_show')
              // } else {
              //   $('.language .with-two-div').addClass('audio_subtitles_show');
              // }
            });

            // if ($(".topcontrols .language .with-two-div .vjs-audio-button.vjs-control").hasClass("vjs-hidden") && $(".topcontrols .language .with-two-div .vjs-subs-caps-button.vjs-control").hasClass("vjs-hidden")) {
            //   $(".topcontrols").addClass("audioAndSubTitleNotAvailable");
            // }

            if ($(".topcontrols .language .with-two-div .vjs-audio-button.vjs-control").hasClass("vjs-hidden") && $(".topcontrols .language .with-two-div .vjs-subs-caps-button.vjs-control").hasClass("vjs-hidden")) {
              $(".topcontrols").addClass("audioAndSubTitleNotAvailable");
              console.log('top controls if block');
            }else{
              console.log('top controls else block');
              $(".topcontrols").removeClass("audioAndSubTitleNotAvailable");
            }

            if ($(".topcontrols .language .with-two-div .vjs-audio-button.vjs-control").hasClass("vjs-hidden")) {
              $(".language").addClass("audioNotAvailable");
            }
            else {
              $(".language").removeClass("audioNotAvailable");
            }

            if ($(".topcontrols .language .with-two-div .vjs-subs-caps-button.vjs-control").hasClass("vjs-hidden")) {
              $(".language").addClass("audioNotAvailable");
            }
            else {
              $(".language").removeClass("audioNotAvailable");
            }


            let buttonfwd = document.getElementById("vjs-ttl_btn");

            buttonfwd.ontouchstart = (event) => {
              event.preventDefault();
              event.stopPropagation();

              if(playObj.assetData.type == 'livetv') {
                $('.vjs-fullscreen-control.vjs-control.vjs-button').click();

              } else {
                clearInterval(this.interval_ID);
                // this.stopVideo();
  
  
                this.disableModal();
                if (this.playermodal) {
                  this.playermodal.dispose();
                  this.playermodal = "";
                }
  
                this.playermodal = "";
  
                return false;
              }
            };

            buttonfwd.onclick = (event) => {
              event.preventDefault();
              event.stopPropagation();

              if(playObj.assetData.type == 'livetv') {
                $('.vjs-fullscreen-control.vjs-control.vjs-button').click();
              } else {
                clearInterval(this.interval_ID);
                console.log("buttonfwd.onclick");
                this.action1 = "stop";
                // this.stopVideo();
  
  
                this.disableModal();
                if (this.playermodal) {
                  this.playermodal.dispose();
                  this.playermodal = "";
                }
                // alert('this.playermodal' +this.playermodal)
                //$('body').removeClass('vjs-full-window');
                this.playermodal = "";
  
                return false;
              }
            };

          }, 500)
          

          let next_template =
            '<span class="next_text">Next: </span>' +
            '<div class="_progress_play"><img class="next_thumb" src="' +
            this.nextPlayingThumb +
            '"><span><i class="fa fa-play" aria-hidden="true"></i></span></div><div class="_serial_parent"><span class="serial_number">' +
            this.nextSeasonNumber +
            this.nextEpisodeNumber +
            ': </span><span class="episode_name">' +
            this.nextVideoName +
            '</span><span class="left_miun">' +
            this.nextVideoDuration +
            "</span></div>";
          let next_button =
            '<button class="next_button btn btn-primary" id="next_button" type="button" title="Next Video" aria-disabled="false"> ' +
            next_template +
            "</button>";
          $(".vjs-big-play-button").after(next_button);

          $("#next_button").css({
            background: "rgb(2 2 2 / 100%)",
            width: "130px",

            top: "50%",
            right: "1%",
            cursor: "pointer",
            "z-index": "3",
            position: "absolute",
            display: "none",
            padding: "0",
            border: "0",
            outline: "0 !important",
            "border-radius": "4px",

          });

          let nextbutton = document.getElementById("next_button");

          nextbutton.onclick = (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.nextButtonAction(event);
          };

          nextbutton.ontouchstart = (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.nextButtonAction(event);
          };

          let pre_template =
            '<span class="previous_text">Previous: </span>' +
            '<div class="_progress_play"><img class="pre_thumb" src="' +
            this.prePlayingThumb +
            '"><span><i class="fa fa-play" aria-hidden="true"></i></span></div><div class="_serial_parent"><span class="serial_number">' +
            this.preSeasonNumber +
            this.preEpisodeNumber +
            ': </span><span class="episode_name">' +
            this.preVideoName +
            '</span><span class="left_miun">' +
            this.preVideoDuration +
            "</span> </div>";
          let back_button =
            '<button class="back_button btn btn-primary" id="back_button" type="button" title="Previous Video" aria-disabled="false">' +
            pre_template +
            "</button>";

          $(".vjs-big-play-button").after(back_button);

          $("#back_button").css({
            background: "rgb(35 36 37 / 64%)",
            width: "18%",
            top: "50%",
            "margin-top": "-2%",
            "margin-left": "1%",
            cursor: "pointer",
            "z-index": "3",
            position: "absolute",
            display: "none",
            padding: "0",
            border: "0",
            outline: "0 !important",
            "border-radius": "10px",
            transform: "translateY(-50%)",
          });

          let backbutton = document.getElementById("back_button");

          backbutton.onclick = (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.backButtonAction(event);
          };

          backbutton.ontouchstart = (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.backButtonAction(event);
          };

          // Skip intro button & Click Functionlity
          console.log(this.streamInfo);
          let skip_intro_button = `<button class="skip_intro_button btn btn-primary" id="skip_intro_button" type="button" title="Skip Intro" aria-disabled="false">
            ${this.playUrlResObj.assetData.skip_intro_text
              ? this.playUrlResObj.assetData.skip_intro_text
              : "Skip Intro"
            }</button>`;

          $(".vjs-big-play-button").after(skip_intro_button);

          $("#skip_intro_button").css({
            background: "rgb(2 2 2 / 100%)",
            width: "130px",
            top: "50%",
            "margin-top": "-2%",
            right: "10%",
            cursor: "pointer",
            "z-index": "3",
            position: "absolute",
            display: "none",
          });

          let skipIntroButton = document.getElementById("skip_intro_button");

          skipIntroButton.onclick = (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.skipIntroButtonAction();
          };

          skipIntroButton.ontouchstart = (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.skipIntroButtonAction();
          };

          // Skip intro button & Click Functionlity show_temp
          this.videoLableButtonRander();


          let skipCastButton = document.getElementById("skip_cast_button");

          skipCastButton.onclick = (event) => {
            event.stopPropagation();
            event.preventDefault();
            console.log("skipCastButton");
            this.videoLabelButtonAction(this.videoLabelObj);
          };

          skipCastButton.ontouchstart = (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.videoLabelButtonAction(this.videoLabelObj);
          };
        });

        // let self = this;
        let isPlaced: boolean = false;
        this.playermodal.on("timeupdate", () => {
          this.currentPlayingTime = this.playermodal.currentTime();

          if (
            this.playermodal.remainingTime() <= 31 &&
            this.watch_credit_status == "1" &&
            this.videoType == "episode" &&
            this.nextVideoCode != "" && this.nextVideoCode != undefined
          ) {

            if ($("#next_button").css("display") == "none") {
              let next_template =
                '<div class="_progress_play"><div class="progress_move_background">Next Episode</div></div>' +
                "</div>";
              $("#next_button").html(next_template);
              $("#next_button").css({ display: "block" });
              $("#skip_cast_button").css({ right: "12%" });
            }

          } else {
            $("#next_button").css({ display: "none" });
          }

          let totalDuration = Math.round(this.playermodal.duration());
          let playedDuration = Math.round(this.playermodal.currentTime());


          let playingDuration = (playedDuration * 100) / totalDuration;
          if (playingDuration >= 99.98 && this.shouldCloseAfterPersent) {
            if (this.videoType == "episode") {
              this.shouldCloseAfterPersent = false;
              console.log(`episode Next Vide Code ${this.nextVideoCode}`)
              if (this.nextVideoCode == "" || this.nextVideoCode == undefined) {
                console.log(`it's last episode so player should be closed`)
                this.shouldCloseAfterPersent = false;
                this.disableModal();
                // if (this.playermodal) {
                //   this.playermodal.dispose();
                //   this.playermodal = "";
                // }

                return;
              } else {
                if (this.nextEpisodeIsBlank) {
                  this.nextButtonAction("");
                  setInterval(() => {
                    this.shouldCloseAfterPersent = true;
                  }, 10000);

                  $("#uaRationOnPLayer").remove();
                }

              }
            } else {
              if (isPreview) return;
              
              this.removeFromContinueWatch(this)
              this.disableModal();
              // if (this.playermodal) {
              //   this.playermodal.dispose();
              //   this.playermodal = "";
              // }
              this.shouldCloseAfterPersent = false;
              return;
            }

          }

          if (playingDuration >= 98 && this.isHudredPersent) {
            this.removeFromContinueWatch(this)
            this.isHudredPersent = false;
          }
        });

        this.playermodal.on("seeking", (event) => {

          if (this.playermodal.remainingTime() <= 31) {
            let remainingTime = Math.round(this.playermodal.remainingTime()) + 3 + 's ';
            $('.progress_move_background').css('animation-duration', remainingTime);
          }
        });
        let name = this.title1;
        let type = this.streamInfo.type;
        let code = this.streamInfo?.code;
        let action = 'Playing';
        this.playermodal.on("play", (event) => {
          // check for uncaught playback error
          // setTimeout(() => {
          //   if (this.playermodal.error()) {
          //     console.error('Playback error detected:', this.playermodal.error());
          //     this.isPlaybackError = true;
          //     document.querySelector('.modal-backdrop')?.remove();
          //     document.body?.classList.remove('modal-open');
          //     this.playermodal.dispose();
          //     this.playermodal = null;
          //   }
          // }, 1000);
          $('.progress_move_background').removeClass("animation-paused");
          this.action1 = 'start';
          action = 'Playing';
          this.googleEvent.eventEmitter('video_play', 'video_play', this.username + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.username, this.username + "-" + action + "-" + name + "-" + type + "-" + code);
          this.googleEvent.eventEmitter('users', 'users', this.username + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.username, this.username + "-" + action + "-" + name + "-" + type + "-" + code);
          // this.stopVideo();
          const qualityMenu = $('.languageandsetting .vjs-quality-button .vjs-menu')
          if (qualityMenu.hasClass('show_quality')) {
            qualityMenu.removeClass('show_quality');
          }
          this.playStartTime = Date.now();
          this.postAnalyticsData();
        });

        if (this.streamInfo.recently_watched?.duration > 0) {
          this.playermodal.on("seeked", (event) => {
            this.action1 = 'seek';
            // this.stopVideo();
          });
        }

        this.playermodal.on("pause", (event) => {
          $('.progress_move_background').addClass("animation-paused");
          action = 'Paused';
          this.action1 = 'paused';

          this.googleEvent.eventEmitter('users_pause', 'users_pause', this.username + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.username, this.username + "-" + action + "-" + name + "-" + type + "-" + code);
          this.googleEvent.eventEmitter('users', 'users', this.username + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.username, this.username + "-" + action + "-" + name + "-" + type + "-" + code);

          // this.stopVideo();
          const qualityMenu = $('.languageandsetting .vjs-quality-button .vjs-menu')
          if (qualityMenu.hasClass('show_quality')) {
            qualityMenu.removeClass('show_quality');
          }
          this.postAnalyticsData();
        });
        this.playermodal.on("fullWindowOnEscKey", (event) => { });

        this.playermodal.on("ended", (event) => {

          action = 'Stopped';
          this.action1 = 'stop';
          if (isPreview)
            this.isPreviewFinished = true;

          this.googleEvent.eventEmitter('users_completed', 'users_completed', this.username + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.username, this.username + "-" + action + "-" + name + "-" + type + "-" + code);
          this.googleEvent.eventEmitter('users', 'users', this.username + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.username, this.username + "-" + action + "-" + name + "-" + type + "-" + code);
          // this.stopVideo();
        });
        this.playermodal.on("dispose", (event) => {
          this.postAnalyticsData();
          this.jwPlayerService.closePlayer.next(true);
          clearInterval(this.interval_ID);
        });
        this.playermodal.on("error", (event) => {
          console.error('error playing video: ', event);
          const error = this.playermodal.error();
          console.error('player error: ', error);
          // if (!this.isInitialPlaybackError) {
          //   return;
          // }
          // this.isInitialPlaybackError = false;
          console.log('playbackErrorRetries: ', this.playbackErrorRetries);
          if (this.playbackErrorRetries > 0 && (error?.code === 4 || error?.status === 403) && this.streamInfo.type == 'livetv') { //  && this.isInitialPlaybackError
            this.playbackErrorRetries--;
            if (this.playbackErrorTimeout) {
              clearTimeout(this.playbackErrorTimeout);
            }
            console.log('4XX player error caught: ', error);
            let videoStreamObj: any = {
              nmode: "WIFI",
              pushId: "",
              ct: "1",
              build: 10000,
              code: this.routeParams.code,
              appv: "1.0.00",
              push_date: "",
              air: "airip",
              ip: "172.30.0.109"
            };
            let urlEncodedStreamInfo = this.UserService.convertoJSONToUrlEncoded(videoStreamObj);
            this.VideoPlayerService.playUrl(urlEncodedStreamInfo).subscribe(
              (playRes) => {
                let playObj = playRes.result;
                if (playObj.cloud_front && Object.keys(playObj.cloud_front).length > 0) {
                  this.videoPlayUrl = playObj.cloud_front.video_url;
                  this.setCookie("CloudFront-Key-Pair-Id", playObj.cloud_front.cloudfront_key_pair_id.trim(), 1);
                  this.setCookie("CloudFront-Policy", playObj.cloud_front.cloudfront_policy.trim(), 1);
                  this.setCookie("CloudFront-Signature", playObj.cloud_front.cloudfront_signature.trim(), 1);
                } else {
                  this.videoPlayUrl = playObj.result;
                }
                // this.playVideoWithAPI();
                let newSource = {
                  sources: [{ src: this.videoPlayUrl, type: "application/x-mpegURL" }],
                };
                this.playermodal.changeSource(newSource);
            });
            return;
          }
          this.playbackErrorTimeout = setTimeout(() => {
            this.isPlaybackError = true;
            document.querySelector('.modal-backdrop')?.remove();
            document.body?.classList.remove('modal-open');
            this.playermodal.dispose();
            this.playermodal = null;
          }, 500);
        });
    //   }
    // );
  }


  postAnalyticsData() {
    const playEndTime = Date.now();

    const playStartDate = moment(this.playStartTime).format('YYYY-MM-DD HH:mm:ss');
    const playEndDate = moment(playEndTime).format('YYYY-MM-DD HH:mm:ss');

    const duration = Math.floor((playEndTime - this.playStartTime) / 1000);
    const streamingObj = {
      device_details: {
        device_id: localStorage.getItem('deviceId'),
        device_name: this.deviceService.getDeviceType(),
        make: this.deviceInfo.browser,
        model: this.deviceInfo.browser_version,
        os: 'web',
        os_version: this.deviceInfo.os_version
      },
      bodyData: [
        {
          profileId: localStorage.getItem('profile_id'),
          is_pack_active: this.streamInfo?.is_pack_active,
          query: {
            code: this.streamInfo?.code,
            type: this.streamInfo?.type
          },
          play: {
            start: playStartDate,
            stop: playEndDate,
            duration: duration
          }
        },
      ]
    };

    this.VideoPlayerService.postAnalytics(streamingObj).subscribe(
      (streamingRes: any) => {
        console.log('streamingRes: ', streamingRes);
      }, (error) => {
        console.error('error posting analytics data: ', error);
      }
    );
  }

  requestForFullScreen() {
    if (this.playermodal.requestFullscreen) {
      this.playermodal.requestFullscreen();
    } else if (this.playermodal.mozRequestFullScreen) { // Firefox
        this.playermodal.mozRequestFullScreen();
    } else if (this.playermodal.webkitRequestFullscreen) { // Chrome, Safari, Opera
        this.playermodal.webkitRequestFullscreen();
    } else if (this.playermodal.msRequestFullscreen) { // IE/Edge
        this.playermodal.msRequestFullscreen();
    }
  }

  stopVideo() {
    console.log('stopVideo');
    let cs_uri_query = this.playUrlResObj.result.split("?")[1];
    let cs_uri_stem = this.playUrlResObj.result.split("?")[0];

    let videoLogData = {
      "x-app": this.streamInfo.type == "livetv" ? "livetv" : "vod",
      "x_event": "paused", // this.action1,
      "sc-bytes": Math.round(this.playermodal.currentTime()),
      "c-user-agent": "platform/web/PlayerVersion-2.7.3",
      "x-duration": this.streamInfo.recently_watched?.duration,
      "x-sname": this.currentPlayingName,
      "cs-uri-query": cs_uri_query
        ? encodeURIComponent(cs_uri_query.toString())
        : "",
      "cs-uri-stem": cs_uri_stem,
    };

    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 10);

    // let urlEncodedEncodeParams =
    //   this.UserService.convertoJSONToUrlEncoded(videoLogData);
    // this.jwPlayerService.analyticsLog(urlEncodedEncodeParams).subscribe((res) => { });

    if (this.streamInfo.is_trailer) {
      return
    }

    if (this.streamInfo.is_liveEvenet) {
      return
    }

    let totalDuration = Math.round(this.playermodal.duration());
    let playedDuration = Math.round(this.playermodal.currentTime());
    let playingDuration = (playedDuration * 100) / totalDuration;
    if (playingDuration <= 98 && playedDuration > 1) {
      //let playLogReqdata = Analyticsstreaminglog.logData;
      let playLogReqdata = { code: '', stop: 91, start: 91, type: '' };
      playLogReqdata.code = this.currentPlayingCode;
      playLogReqdata.stop = Math.round(this.playermodal.currentTime());
      if (playLogReqdata.stop > playLogReqdata["duration"]) {
        playLogReqdata.stop = playLogReqdata["duration"] - 1;
      }
      playLogReqdata.start = 0;
      playLogReqdata["type"] = this.streamInfo.type;

      setTimeout(() => {
        this.spinner.hide();
      }, 10);
      //let urlEncodedPlayData = this.UserService.convertoJSONToUrlEncoded(playLogReqdata);

      console.log(playLogReqdata);
      if (!this.is_trailer && !this.is_live_evnet && (playedDuration * 100) / totalDuration <= 100) {
        this.jwPlayerService.pause(playLogReqdata).subscribe((res) => { });
      }
    }

  }

  strToLower(str) {
    if (str)
      return str.toLowerCase();
    else
      return 'na';
  }

  disableModal() {
    try {
      document.querySelector("ngb-modal-window").classList.remove("show");
      document.querySelector("ngb-modal-backdrop").classList.remove("show");
      document.getElementById("nexgTV-app").click();
      document.querySelectorAll(".modal-backdrop").forEach((el) => el.remove());
      document.querySelectorAll(".d-block").forEach((el) => el.remove());
      document.body.style.overflow = "scroll";
    } catch (e) {
      console.log(e);
    }
  }

  videoLableButtonRander() {
    let skip_cast_button = `<button class="skip_cast_button btn btn-primary" id="skip_cast_button" type="button" title="Skip Intro" aria-disabled="false">
    </button>`;

    let content_advisory = this.playUrlResObj?.assetData?.content_rating?.content_advisory || '';

    let viewer_rating = this.playUrlResObj?.assetData?.content_rating?.viewer_rating || '';

    let uARating_button = `<button class="uaRationOnPLayer btn btn-primary" id="uaRationOnPLayer" type="button" title="Skip Cast" aria-disabled="false">

    ${'<span class="rating_underline">' + 'RATED ' + viewer_rating + '</span>'
      + '<div>' + content_advisory + '<div>'}
    </button>`;

    $(".vjs-big-play-button").after(skip_cast_button);

    $(".vjs-user-inactive").toggleClass('uaRationOnPLayer');


    if (this.playUrlResObj.assetData.content_rating) {
      $(".vjs-big-play-button").after(uARating_button);
      let uiRatingTimeout = this.playUrlResObj.assetData.content_rating.show_duration * 1000;
      setTimeout(() => {
        console.log(uiRatingTimeout);
        $("#uaRationOnPLayer").remove();
      }, uiRatingTimeout);
    }


    $("#skip_cast_button").css({
      background: "rgb(2 2 2 / 100%)",
      width: "130px",
      top: "50%",
      right: "12%",
      cursor: "pointer",
      "z-index": "3",
      position: "absolute",
      display: "none",
    });
  }

  preNextVideo() {
    let totalSeasons = Object.keys(this.streamInfo.seasons).length;

    let seasonArray = [];
    if (totalSeasons > 0) {
      seasonArray = [];
      for (let i = 0; i < totalSeasons; i++) {
        // add episode number
        if (Object.keys(this.streamInfo.seasons[i].assets).length > 0) {
          for (
            let k = 0;
            k < Object.keys(this.streamInfo.seasons[i].assets).length;
            k++
          ) {
            this.streamInfo.seasons[i].assets[k].episode_number = k + 1;
          }
        }
        seasonArray = [...seasonArray, ...this.streamInfo.seasons[i].assets];
      }
      this.seasonarray = seasonArray;

      // find code in array index
      let index = seasonArray.findIndex(
        (seasonArray) => seasonArray.code == this.currentPlayingCode
      ); //

      if (seasonArray[index + 1] == undefined) {
        this.nextVideoCode = "";
        this.nextVideoName = "";
        this.nextEpisodeNumber = "";
        this.nextSeasonNumber = "";
        this.nextPlayingStartTime = 0;
        this.nextPlayingMongoId = "";
        this.nextPlayingCharegCode = "";
        this.nextPlayingContentAvailability = "";
        this.nextPlayingThumb = "";
        this.nextPlayingSec = "";
        this.nextPlayingMin = "";
        this.nextPlayingHour = "";
        this.nextPlayingGenreText = "";
        this.nextPlayingGenre = "";
        this.nextPlayingSys = "";
        this.nextPlayingLanguages = "";
        this.nextPlayingStop = "";
        this.nextPlayingDuration = "";
        this.nextPlayingShowID = "";
        this.nextPlayingSeasonId = "";
        this.nextVideoDuration = "";
      } else {
        this.nextVideoCode = seasonArray[index + 1].code;
        this.nextVideoName = seasonArray[index + 1].name;
        this.nextEpisodeNumber = " E" + seasonArray[index + 1].episode_number;
        this.nextSeasonNumber = " S" + seasonArray[index + 1].season_number;
        this.nextPlayingStartTime = seasonArray[index + 1].seek_bar_data.stop;
        this.nextPlayingMongoId = seasonArray[index + 1].asset_mongo_id;
        this.nextPlayingCharegCode = seasonArray[index + 1].charge_code;
        this.nextPlayingContentAvailability = seasonArray[index + 1].content_availability;
        this.nextPlayingThumb = seasonArray[index + 1].thumbnail;
        this.nextPlayingSec = seasonArray[index + 1].sec;
        this.nextPlayingMin = seasonArray[index + 1].min;
        this.nextPlayingHour = seasonArray[index + 1].hour;
        this.nextPlayingGenreText = seasonArray[index + 1].genre_text;
        this.nextPlayingLanguages = seasonArray[index + 1].languages;
        this.nextPlayingSys = seasonArray[index + 1].synopsis;
        this.nextPlayingStop = seasonArray[index + 1].seek_bar_data.stop;
        this.nextPlayingDuration = seasonArray[index + 1].seek_bar_data.duration;
        this.nextPlayingShowID = seasonArray[index + 1].show_id;
        this.nextPlayingSeasonId = seasonArray[index + 1].season_id;

        if (seasonArray[index + 1].hours > 0) {
          if (seasonArray[index + 1].hours < 10) {
            let h = seasonArray[index + 1].min;
            this.nextVideoDuration = h.replace(/^0+/, "") + " h";
          } else {
            this.nextVideoDuration = seasonArray[index + 1].hours + "h";
          }

          if (seasonArray[index + 1].min > 0) {
            this.nextVideoDuration =
              this.nextVideoDuration +
              " " +
              seasonArray[index + 1].min +
              "m";
          }
        } else if (
          seasonArray[index + 1].hours <= 0 &&
          seasonArray[index + 1].min > 0
        ) {
          if (seasonArray[index + 1].min < 10) {
            let t = seasonArray[index + 1].min;
            this.nextVideoDuration = t.replace(/^0+/, "") + "m";
          } else {
            this.nextVideoDuration = seasonArray[index + 1].min + "m";
          }

          if (seasonArray[index + 1].sec > 0) {
            this.nextVideoDuration =
              this.nextVideoDuration +
              " " +
              seasonArray[index + 1].sec +
              "s";
          }
        } else {
          this.nextVideoDuration =
            this.nextVideoDuration + " " + seasonArray[index + 1].sec + "s";
        }

      }
      if (seasonArray[index - 1] == undefined) {
        this.preVideoCode = "";
        this.preVideoName = "";
        this.preEpisodeNumber = "";
        this.preSeasonNumber = "";
        this.prePlayingStartTime = 0;
        this.prePlayingThumb = "";
        this.preVideoDuration = "";
      } else {
        this.preVideoCode = seasonArray[index - 1].code;
        this.preVideoName = seasonArray[index - 1].name;
        this.preEpisodeNumber = " E" + seasonArray[index - 1].episode_number;
        this.preSeasonNumber = " S" + seasonArray[index - 1].season_number;
        this.prePlayingStartTime = seasonArray[index - 1].seek_bar_data.stop;
        this.prePlayingThumb = seasonArray[index - 1].thumbnail;

        if (seasonArray[index - 1].hours > 0) {
          if (seasonArray[index - 1].hours < 10) {
            let h = seasonArray[index - 1].hours;
            this.preVideoDuration = h.replace(/^0+/, "") + "h";
          } else {
            this.preVideoDuration = seasonArray[index - 1].hours + "h";
          }

          if (seasonArray[index - 1].min > 0) {
            this.preVideoDuration =
              this.preVideoDuration + " " + seasonArray[index - 1].min + "m";
          }
        } else if (
          seasonArray[index - 1].hours <= 0 &&
          seasonArray[index - 1].min > 0
        ) {
          if (seasonArray[index - 1].min < 10) {
            let m = seasonArray[index - 1].min;
            this.preVideoDuration = m.replace(/^0+/, "") + "m";
          } else {
            this.preVideoDuration = seasonArray[index - 1].min + "m";
          }

          if (seasonArray[index - 1].sec > 0) {
            this.preVideoDuration =
              this.preVideoDuration + " " + seasonArray[index - 1].sec + "s";
          }
        } else {
          this.preVideoDuration =
            this.preVideoDuration + " " + seasonArray[index - 1].sec + "s";
        }
      }
    }
    this.lastEpisode = seasonArray[seasonArray.length - 1];
  }


  trailerPreviewList() {
    this.streamInfo.trailer = [];
    let object_detail = this.streamInfo;
    this.streamInfo.new_trailer.forEach((element, i) => {
      this.streamInfo.trailer.push(this.streamInfo.new_trailer[i]);
      this.streamInfo.trailer[i].recently_watched = this.streamInfo.recently_watched;
      this.streamInfo.trailer[i].asset_mongo_id = this.streamInfo.asset_mongo_id;
      this.streamInfo.trailer[i].charge_code = this.streamInfo.charge_code;
      this.streamInfo.trailer[i].code = this.streamInfo.code;
      this.streamInfo.trailer[i].content_availability = this.streamInfo.content_availability;
      this.streamInfo.trailer[i].genre_text = this.streamInfo.genre_text;
      this.streamInfo.trailer[i].sec = this.streamInfo.sec;
      this.streamInfo.trailer[i].min = this.streamInfo.min;
      this.streamInfo.trailer[i].hours = this.streamInfo.hours;
      this.streamInfo.trailer[i].synopsis = this.streamInfo.synopsis;
      this.streamInfo.trailer[i].languages = this.streamInfo.languages;

    });


    let totalSeasons = Object.keys(this.streamInfo.trailer).length;

    let seasonArray = [];
    if (totalSeasons > 0) {
      seasonArray = [];
      for (let i = 0; i < totalSeasons; i++) {
        // add episode number
        if (this.streamInfo.trailer && Object.keys(this.streamInfo.trailer).length > 0) {
          for (
            let k = 0;
            k < Object.keys(this.streamInfo.trailer).length;
            k++
          ) {
            this.streamInfo.trailer[k].episode_number = k + 1;
          }
        }
        seasonArray = [...seasonArray, ...this.streamInfo.trailer];
      }
      this.seasonarray = seasonArray;

      // find code in array index
      let index = seasonArray.findIndex(
        (seasonArray) => seasonArray.code == this.currentPlayingCode
      ); //

      console.log('index', index);
      console.log('this.currentPlayingCode', this.currentPlayingCode);
      console.log('seasonArray', seasonArray);


      // debugger;
      if (seasonArray[index + 1] == undefined) {
        this.nextVideoCode = "";
        this.nextVideoName = "";
        this.nextEpisodeNumber = "";
        this.nextSeasonNumber = "";
        this.nextPlayingStartTime = 0;
        this.nextPlayingMongoId = "";
        this.nextPlayingCharegCode = "";
        this.nextPlayingContentAvailability = "";
        this.nextPlayingThumb = "";
        this.nextPlayingSec = "";
        this.nextPlayingMin = "";
        this.nextPlayingHour = "";
        this.nextPlayingGenreText = "";
        this.nextPlayingGenre = "";
        this.nextPlayingSys = "";
        this.nextPlayingLanguages = "";
        this.nextPlayingStop = "";
        this.nextPlayingDuration = "";
        this.nextPlayingShowID = "";
        this.nextPlayingSeasonId = "";
        this.nextVideoDuration = "";
      } else {
        this.nextVideoCode = seasonArray[index + 1].code;
        this.nextVideoName = seasonArray[index + 1].name;
        this.nextEpisodeNumber = " E" + seasonArray[index + 1].episode_number;
        this.nextSeasonNumber = " S" + seasonArray[index + 1].season_number;
        this.nextPlayingStartTime = seasonArray[index + 1].recently_watched.stop;
        this.nextPlayingMongoId = seasonArray[index + 1].asset_mongo_id;
        this.nextPlayingCharegCode = seasonArray[index + 1].charge_code;
        this.nextPlayingContentAvailability = seasonArray[index + 1].content_availability;
        this.nextPlayingThumb = seasonArray[index + 1].thumbnail;
        this.nextPlayingSec = seasonArray[index + 1].sec;
        this.nextPlayingMin = seasonArray[index + 1].min;
        this.nextPlayingHour = seasonArray[index + 1].hour;
        this.nextPlayingGenreText = seasonArray[index + 1].genre_text;
        this.nextPlayingLanguages = seasonArray[index + 1].languages;
        this.nextPlayingSys = seasonArray[index + 1].synopsis;
        this.nextPlayingStop = seasonArray[index + 1].recently_watched.stop;
        this.nextPlayingDuration = seasonArray[index + 1].recently_watched.duration;
        this.nextPlayingShowID = seasonArray[index + 1].show_id;
        this.nextPlayingSeasonId = seasonArray[index + 1].season_id;

        if (seasonArray[index + 1].hours > 0) {
          if (seasonArray[index + 1].hours < 10) {
            let h = seasonArray[index + 1].min;
            this.nextVideoDuration = h.replace(/^0+/, "") + " h";
          } else {
            this.nextVideoDuration = seasonArray[index + 1].hours + "h";
          }

          if (seasonArray[index + 1].min > 0) {
            this.nextVideoDuration =
              this.nextVideoDuration +
              " " +
              seasonArray[index + 1].min +
              "m";
          }
        } else if (
          seasonArray[index + 1].hours <= 0 &&
          seasonArray[index + 1].min > 0
        ) {
          if (seasonArray[index + 1].min < 10) {
            let t = seasonArray[index + 1].min;
            this.nextVideoDuration = t.replace(/^0+/, "") + "m";
          } else {
            this.nextVideoDuration = seasonArray[index + 1].min + "m";
          }

          if (seasonArray[index + 1].sec > 0) {
            this.nextVideoDuration =
              this.nextVideoDuration +
              " " +
              seasonArray[index + 1].sec +
              "s";
          }
        } else {
          this.nextVideoDuration =
            this.nextVideoDuration + " " + seasonArray[index + 1].sec + "s";
        }
      }

      if (seasonArray[index - 1] == undefined) {
        this.preVideoCode = "";
        this.preVideoName = "";
        this.preEpisodeNumber = "";
        this.preSeasonNumber = "";
        this.prePlayingStartTime = 0;
        this.prePlayingThumb = "";
        this.preVideoDuration = "";
      } else {
        this.preVideoCode = seasonArray[index - 1].code;
        this.preVideoName = seasonArray[index - 1].name;
        this.preEpisodeNumber = " E" + seasonArray[index - 1].episode_number;
        this.preSeasonNumber = " S" + seasonArray[index - 1].season_number;
        this.prePlayingStartTime = seasonArray[index - 1].recently_watched.stop;
        this.prePlayingThumb = seasonArray[index - 1].thumbnail;

        if (seasonArray[index - 1].hours > 0) {
          if (seasonArray[index - 1].hours < 10) {
            let h = seasonArray[index - 1].hours;
            this.preVideoDuration = h.replace(/^0+/, "") + "h";
          } else {
            this.preVideoDuration = seasonArray[index - 1].hours + "h";
          }

          if (seasonArray[index - 1].min > 0) {
            this.preVideoDuration =
              this.preVideoDuration + " " + seasonArray[index - 1].min + "m";
          }
        } else if (
          seasonArray[index - 1].hours <= 0 &&
          seasonArray[index - 1].min > 0
        ) {
          if (seasonArray[index - 1].min < 10) {
            let m = seasonArray[index - 1].min;
            this.preVideoDuration = m.replace(/^0+/, "") + "m";
          } else {
            this.preVideoDuration = seasonArray[index - 1].min + "m";
          }

          if (seasonArray[index - 1].sec > 0) {
            this.preVideoDuration =
              this.preVideoDuration + " " + seasonArray[index - 1].sec + "s";
          }
        } else {
          this.preVideoDuration =
            this.preVideoDuration + " " + seasonArray[index - 1].sec + "s";
        }
      }
    }
    this.lastEpisode = seasonArray[seasonArray.length - 1];
  }

  startTimerFor10Second() {
    this.timerForPlayer = 0;
    clearInterval(this.timerOfPlayer);
  }

  stopTimerfor10Second() {
    this.timerForPlayer = 0;
    clearInterval(this.timerOfPlayer);
  }

  sendNextEpisode(nextVideObj) { }

  removeFromContinueWatch(episodeObj) {
    if (this.streamInfo.is_trailer) {
      return
    }

    let recentWatchedDelete = {
      asset_id: this.streamInfo.show_id ? this.streamInfo.show_id : this.currentPlayingMongoId,
      code: this.streamInfo?.code
    }


    let urlEncodedeleteObj = this.UserService.convertoJSONToUrlEncoded(recentWatchedDelete);

    if (this.videoType == "episode") {
      if (this.nextVideoCode == undefined) {
        setTimeout(() => {
          this.spinner.hide();
        }, 50);
        this.assetsService
          .deleteContinueWatching(urlEncodedeleteObj)
          .subscribe((res) => {
            console.log(`current playing series cleared`);
          });
      } else {
        let playLogReqdata = Analyticsstreaminglog.logData;
        playLogReqdata["duration"] = this.currentPlayingDuration;
        playLogReqdata["last_video_play_id"] = this.currentPlayingMongoId;
        playLogReqdata["asset_mongo_id"] = this.currentPlayingMongoId;
        playLogReqdata["uid"] = this.sessionId;
        playLogReqdata.type = this.videoType == "episode" ? "episode" : "vod";
        playLogReqdata.catlogue = CatlougeTypeConstants.catlogue;
        playLogReqdata.asset_code = this.currentPlayingCode;
        playLogReqdata.charge_code = this.currentPlayingCharegCode;
        playLogReqdata.paid_status = this.currentPlayingContentAvailability;
        playLogReqdata["content_availability"] = this.currentPlayingContentAvailability;
        playLogReqdata.code = this.currentPlayingCode;
        playLogReqdata.play_url = this.videoPlayUrl;
        playLogReqdata.category = this.currentPlayingCode;
        playLogReqdata.release_date = "";
        playLogReqdata.name = this.title;
        playLogReqdata["cloud_image"] = this.currentPlayingThumb;
        playLogReqdata["sec"] = this.currentPlayingSec;
        playLogReqdata["min"] = this.currentPlayingMin;
        playLogReqdata["hours"] = this.currentPlayingHour;
        playLogReqdata["genre_text"] = this.currentPlayingGenreText;
        playLogReqdata["languages"] = this.currentPlayingLanguages;
        // playLogReqdata.genre = this.currentPlayingGenre;
        playLogReqdata["synopsis"] = this.currentPlayingSys;
        playLogReqdata.stop = parseInt(this.currentPlayingDuration);
        playLogReqdata["show_id"] = this.currentPlayingShowID;
        playLogReqdata["season_id"] = this.currentPlayingSeasonId;

        let urlEncodedNextObj = this.UserService.convertoJSONToUrlEncoded(playLogReqdata);
        setTimeout(() => {
          this.spinner.hide();
        }, 50);
        this.jwPlayerService.pause(urlEncodedNextObj).subscribe((res) => {
        });
      }
    } else {
      setTimeout(() => {
        this.spinner.hide();
      }, 50);
      this.jwPlayerService
        .removeFromContinueWatching(urlEncodedeleteObj)
        .subscribe((res) => {
          console.log("content remove");
        });
    }
  }

  nextButtonAction(param: any) {
    let currentTime = this.nextPlayingStartTime;
    this.nextVideoDuration = '';
    this.preVideoDuration = '';
    console.log(this.nextVideoCode)
    this.getPlayUrl(this.nextVideoCode)
      .then((playObj) => {
        this.playType = "next";
        this.playUrlResObj = playObj.result[0];
        var sources = [
          { type: "application/x-mpegURL", src: this.videoPlayUrl },
        ];


        let advertisement_url = playObj.assetData.advertisement_url;
        this.playermodal.ima.setContentWithAdTag(sources, advertisement_url);
        this.playermodal.ima.requestAds();

        this.isHudredPersent = true;
        this.watch_credit_status = 1;
      })
      .catch((error) => {
        console.log("something went wrong to access the next video");
        console.log(error);
      });
  }
  backButtonAction(param: any) {
    //this.stopVideo();

    let preTime = this.prePlayingStartTime;
    this.getPlayUrl(this.preVideoCode)
      .then((playObj) => {
        this.playType = "pre";
        var sources = [
          { type: "application/x-mpegURL", src: this.videoPlayUrl },
        ];

        let advertisement_url = playObj.assetData.advertisement_url;
        this.playermodal.ima.setContentWithAdTag(sources, advertisement_url);
        this.playermodal.ima.requestAds();
        let name = this.title1;
        let type = this.streamInfo.type;
        let code = this.streamInfo.recently_watched.code;
        let timeInSeconds = this.playermodal.currentTime();

        this.googleEvent.eventEmitter('video_viewtime', 'video_viewtime', this.username + "-" + timeInSeconds + "s-" + name + "-" + type + "-" + code, 0, this.username, this.username + "-" + timeInSeconds + "s-" + name + "-" + type + "-" + code);
      })
      .catch((error) => {
        console.log("something went wrong to access the next video");
      });
  }
  skipIntroButtonAction() {
    this.playermodal.currentTime(this.playUrlResObj.assetData.skip_intro.end);
  }

  videoLabelButtonAction(videoLabelObj) {
    if (videoLabelObj[0].is_watch_credit == "1") {
      this.watch_credit_status = 0;
      $(".skip_cast_button").css({ display: "none" });
      console.log(videoLabelObj);
    } else {
      this.playermodal.currentTime(videoLabelObj[0].end);
    }
  }

  getRecommAndFreq(code) {
    console.log('getRecommAndFreq for code: ', code);
    var recommededParams: any = {
      code: code,
      // search_text: '',
      // catlogue: CatlougeTypeConstants.catlogue
    }
    let urlEncodedRecomdedParams = this.UserService.convertoJSONToUrlEncoded(recommededParams);
    this.assetsService.getRecommended(urlEncodedRecomdedParams).subscribe(data => {
      this.moreLikeData = data.result;
    });

    this.assetsService.getFrequentlyPlayed(urlEncodedRecomdedParams).subscribe(data => {
      this.freqPlayedData = data.result;
    });
  }

  getCurrentProgram(code: string): Observable<any> {
    const currentDate = moment().format('YYYY-MM-DD');
    let epgPrograms = [];
    let dataParams = {
      from_date: currentDate,
      code: code
    }
    let urlEncodedDataParams = this.UserService.convertoJSONToUrlEncoded(dataParams);
    return this.assetsService.getLiveEpgData(urlEncodedDataParams).pipe(
      map(data => {
        epgPrograms = data.result.epg;
        const current_timestamp = moment().format('YYYY-MM-DD HH:mm A');
        return epgPrograms.find(prog => {
          const progStartTime = moment(`${prog.progdate} ${prog.startTime}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm A');
          const progEndTime = moment(`${prog.progdate} ${prog.endTime}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm A');
          return moment(current_timestamp, 'YYYY-MM-DD HH:mm A').isBetween(
            moment(progStartTime, 'YYYY-MM-DD HH:mm A'),
            moment(progEndTime, 'YYYY-MM-DD HH:mm A'),
            null,
            '[]'
          );
        });
      }),
      catchError(error => {
        console.error('Error fetching current program:', error);
        // Handle error, e.g., return a default value or re-throw the error
        return throwError('Failed to fetch current program');
      })
    );
  }

  PlayMoreLikeAsset(asset) {

    // $('#frequently-modal').hide();
    // $('#more_like_modal').hide();
    // $('closeMoreLikeThis').click();

    // this.getRecommAndFreq(asset.code);

    this.CommonService.goToDetail(asset, 'Screen_Detail_Similar');
    

    // this.getPlayUrl(code)
    //   .then((playObj) => {
    //     this.CommonService.goToDetail(playObj.assetData, 'Screen_Detail_Similar');
    //     var sources = [
    //       { type: "application/x-mpegURL", src: this.videoPlayUrl },
    //     ];

    //     let advertisement_url = playObj.assetData.advertisement_url;
    //     this.playermodal.ima.setContentWithAdTag(sources, advertisement_url);
    //     this.playermodal.ima.requestAds();
    //     let name = this.title;
    //     let type = this.streamInfo.type;
    //     let code = this.streamInfo.recently_watched.code;
    //     let timeInSeconds = this.playermodal.currentTime();
    //     this.googleEvent.eventEmitter('video_viewtime', 'video_viewtime', this.username + "-" + timeInSeconds + "s-" + name + "-" + type + "-" + code, 0, this.username, this.username + "-" + timeInSeconds + "s-" + name + "-" + type + "-" + code);
    //   })
    //   .catch((error) => {
    //     console.log("Error Accessing the requested video: ", error);
    //   });
  }

  getPlayUrl(code): Promise<any> {
    return new Promise((resolve, reject) => {
      this.rmContApiCalledStatus = false;
      let videoStreamObj = {
        nmode: "WIFI",
        pushId: "",
        ct: "1",
        build: 10000,
        code: code,
        appv: "1.0.00",
        push_date: "",
        air: "airip",
      };

      let urlEncodedStreamInfo =
        this.UserService.convertoJSONToUrlEncoded(videoStreamObj);
      // console.log(urlEncodedStreamInfo);
      this.VideoPlayerService.playUrl(urlEncodedStreamInfo, 0, 0).subscribe(
        (playObj) => {
          // console.log('playObj from getPlayUrl: ', playObj);
          this.error = playObj.message;
          playObj = playObj.result;
          if (playObj == undefined || playObj == "") {
            this.playermodal.pause();
            this.nextEpisodeIsBlank = false;
            this.showErrorMsg.nativeElement.click();
            reject();
          } else {
            
            this.currentPlayingData = playObj.assetData;
            if(Object.keys(playObj.next_episode).length > 0){
              this.nextVideoPaidData = playObj.next_episode;
              this.playUrlResObj = playObj;
              this.nextVideoCode = playObj.next_episode.code;
            }
            if (playObj.cloud_front && Object.keys(playObj.cloud_front).length > 0) {

              this.videoPlayUrl = playObj.cloud_front.video_url;
              this.setCookie("CloudFront-Key-Pair-Id", playObj.cloud_front.cloudfront_key_pair_id.trim(), 1);
              this.setCookie("CloudFront-Policy", playObj.cloud_front.cloudfront_policy.trim(), 1);
              this.setCookie("CloudFront-Signature", playObj.cloud_front.cloudfront_signature.trim(), 1);

            } else if (!playObj.result) {
              // this.previewResult = previewRes.result;
              $('.live_asset_block').addClass('asset-preview');
              this.videoPlayUrl = this.previewResult.cloud_front.video_url;
              this.setCookie(
                "CloudFront-Key-Pair-Id",
                this.previewResult.cloud_front.cloudfront_key_pair_id.trim(),
                1
              );
              this.setCookie(
                "CloudFront-Policy",
                this.previewResult.cloud_front.cloudfront_policy.trim(),
                1
              );
              this.setCookie(
                "CloudFront-Signature",
                this.previewResult.cloud_front.cloudfront_signature.trim(),
                1
              );
            } else {
              this.videoPlayUrl = playObj.result;
            }



            if (playObj.assetData.type == 'episode' || playObj.assetData.type == 'tvshow') {
              let seasonN = this.streamInfo.totalSeason > 1 ? 'S' + playObj.assetData.show_details.season_number : '';
              this.title = '<div id="show_temp">' +
                '<div class="temp-title">' + playObj.assetData.show_details.show_title + '</div>' +
                '<span class="temp-seasnon">' + seasonN + ' E' + playObj.assetData.show_details.episode_number + ' | ' + '</span>' +
                '<span class="temp-seasnon">' + playObj.assetData.show_details.episode_title + '</span>' + '</div>';

              this.title1 = playObj.assetData.show_details.show_title + 'S' + playObj.assetData.show_details.season_number + ' E' + playObj.assetData.show_details.episode_number + ' | ' +
                + playObj.assetData.show_details.episode_title;

            } else if(playObj.assetData.type == 'livetv') {
              this.getCurrentProgram(code).subscribe(currentProg => {
                let currentProgram = (currentProg?.name && playObj.result) ? currentProg.name : '';
                this.title = '<div id="show_temp">' +
                  '<div class="temp-title">' + playObj.assetData.name + '</div>' +
                  '<span class="temp-seasnon">' + currentProgram + '</span>' + '</div>';
                  $(".vjs-ttl").html(this.title);
              });
            } else {
              this.title = '<div id="show_temp">' +
                '<span class="temp-seasnon movie_title">' + playObj.assetData.name + '</span>' + '</div>';

              this.title1 = (playObj.assetData.name || '');
            }


            $(".vjs-ttl").html(this.title);

            this.currentPlayingCode = code;
            this.playermodal.pause();
            this.playermodal.poster(playObj.assetData.image);

            if (playObj.assetData.trick_play && playObj.assetData.trick_play.image_url) {
              let img_interval = playObj.assetData.trick_play.size;
              let img_width = playObj.assetData.trick_play.width && playObj.assetData.trick_play.width != "" ? playObj.assetData.trick_play.width : 150;
              let img_height = playObj.assetData.trick_play.height && playObj.assetData.trick_play.height != "" ? playObj.assetData.trick_play.height : 84;
              invokeSpriteScript(playObj.assetData.trick_play.image_url, img_interval, img_width, img_height);
            }
            // console.log('currentPlayingData: ', this.currentPlayingData);
            // console.log('playObj: ', playObj.assetData);
            this.currentPlayingMongoId = this.currentPlayingData.asset_id;
            this.currentPlayingCharegCode = this.currentPlayingData.charge_code;
            this.currentPlayingContentAvailability = this.currentPlayingData.content_availability;
            this.currentPlayingThumb = this.currentPlayingData.image;
            this.currentPlayingName = this.currentPlayingData.name;

            // this.currentPlayingSec = this.currentPlayingData.sec;
            // this.currentPlayingMin = this.currentPlayingData.min;
            // this.currentPlayingHour = this.currentPlayingData.hours;
            // this.currentPlayingDuration = this.currentPlayingData.duration;
            this.currentPlayingGenreText = this.currentPlayingData.genre_text;
            this.currentPlayingLanguages = this.currentPlayingData.languages;
            this.currentPlayingSys = this.currentPlayingData.synopsis;
            this.currentPlayingShowID = this.currentPlayingData.show_id;
            this.currentPlayingSeasonId = this.currentPlayingData.season_id;
            // this.currentLastPlayingSeasonId = this.playUrlResObj.last_video_play_id;
            // this.preNextVideo();
            resolve(playObj);
          }
        }
      );
    });
  }

  removeErrorBox() {
    if (this.playermodal != "") {
      console.log("removeErrorBox");
      this.playermodal.currentTime(0);
      console.log(this.playermodal.currentTime());
      // this.stopVideo();
    }

    this.closeShowErrorMsg.nativeElement.click();
    setTimeout(() => {
      this.disableModal();
      if (this.playermodal) this.playermodal.dispose();

      //$('body').removeClass('vjs-full-window');
      this.playermodal = "";
    }, 50);
  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }


  deleteCookie(cname, cvalue) {
    var expires = "expires=" + "Thu, 01 Jan 1970 00:00:00 UTC";
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

}
