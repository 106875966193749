import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/core/services/common.service';
import { UserService } from 'src/app/core';
import { successOptions, errorOptions } from 'src/app/shared/lottie-config';
import { AnimationOptions } from 'ngx-lottie';
import { ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-enter-pin',
  templateUrl: './enter-pin.component.html',
  styleUrls: ['./enter-pin.component.css']
})
export class EnterPinComponent implements OnInit {
  verifyPinForm: FormGroup;
  language: any;
  api_error: string;
  api_msg: string;
  isSubmitPinValid: boolean;
  isPinSubmit: boolean;
  profileData: any;
  profileName: string;
  verifyPinSuccess: boolean;
  successOptions: AnimationOptions = successOptions;
  errorOptions: AnimationOptions = errorOptions;
  showValidationErrors: boolean;
  profileId: string;
  profileImageUrl: string;
  prevProfileId: string;
  prevProfileName: string;


  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    public commonService: CommonService,
    private location: Location,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private _document
    ) { }

  ngOnInit(): void {
    this._document.body.classList.add('profile-bg');
    this.language = this.commonService.languageChange();
    this.profileId = this.route.snapshot.paramMap.get('id');
    this.verifyPinForm = this.fb.group({
      'profile_id': [this.profileId, [Validators.required]],
      'profile_pin': ['', [Validators.required, Validators.minLength(4)]]
    });

    // if(!localStorage.getItem('prevProfileId') || !localStorage.getItem('prevProfileName')) {
    //   localStorage.setItem('prevProfileId', localStorage.getItem('profile_id'));
    //   localStorage.setItem('prevProfileName', localStorage.getItem('profile_UserName'));
    // }
    // this.prevProfileId = localStorage.getItem('prevProfileId');
    // this.prevProfileName = localStorage.getItem('prevProfileName');
    // localStorage.setItem('profile_id', this.profileId);

    this.getProfileData();

    // this.profileName = localStorage.getItem('profile_UserName');

  }

  ngOnDestroy() {
    // remove the class form body tag
    this._document.body.classList.remove('profile-bg');
    // localStorage.removeItem('prevProfileId');
    // localStorage.removeItem('prevProfileName');
    // if(!this.verifyPinSuccess) {
    //   localStorage.setItem('profile_id', this.prevProfileId);
    //   localStorage.setItem('profile_UserName', this.prevProfileName);
    // }
  }

  get pinFormControls() {
    return this.verifyPinForm.controls;
  }

  getProfileData() {
    let prevProfileId = localStorage.getItem('profile_id');
    localStorage.setItem('profile_id', this.profileId)

    let url = 'profile/get';
    this.userService.getPrfile(url).subscribe(data => {
      this.profileData = data.result;
      this.profileName = this.profileData.profile_name;
      this.profileImageUrl = this.profileData.profile_image;

      localStorage.setItem('profile_id', prevProfileId);
    }, (error) => {
      console.log('error fetching profile data: ', error);
      localStorage.setItem('profile_id', prevProfileId);
    });
  }

  verifyPinSubmit() {
    this.api_error = '';
    if (this.verifyPinForm.invalid) {
      this.showValidationErrors = true;
      return;
    }
    this.showValidationErrors = false;
    this.isPinSubmit = true;
    this.isSubmitPinValid = true;
    let params = this.verifyPinForm.value;
    let url = 'profile/verify/pin';
    this.userService.verifyPrfilePin(url, params).subscribe(data => {
      if(data.response_code=='200'){
        this.api_msg = data.message;
        this.verifyPinSuccess = true;
        localStorage.setItem('profile_id', this.profileId)
        localStorage.setItem('profile_UserName', this.profileName);
        localStorage.setItem('profileImageUrl', this.profileImageUrl);
        localStorage.setItem('profileSelected', 'true'); // To display welcome card in Dynamic Tab component
        // localStorage.setItem('profile_id', this.currentLockedProfile.userId);
        // localStorage.setItem('profile_UserName', this.currentLockedProfile.profileName);
        setTimeout(() => {
          window.location.href = "/";
        }, 2500)
      }
    }, (error) => {
      this.api_error = error.message;
    });
    

  }

  onPinChange(pin) {
    let fpin = pin;
    this.verifyPinForm.get("profile_pin").setValue(fpin);
  }

  handleCancel() {
    this.location.back();
  }

}
