<div class="container mt-4">
    <div class="d-flex align-items-center justify-content-between">
        <img src="../../../assets/img/logo.png" class="profile-logo-width" />
        <a href="javascript:void(0);" (click)="handleCancel()">
             <img src="../../../assets/img/Icon/close.svg" />
        </a>
    </div>

    <div class="profile-align">
        <div class="row">
            <div class="col-md-12">
                <div class="add_profile_width" *ngIf="!resetPinSuccess">
                    <form class="transparent_form" [formGroup]="otpForm" autocomplete="off" (ngSubmit)="submitOtp()"
                        *ngIf="isSubmitOtpValid==false">

                        <label class="login_title">{{language.hi}} {{profileName}}!</label>

                        <div class="otp_line form-group otp_6_input" [ngClass]="(api_error || (isOtpInvalid && otpFormControls.otp.errors)) && 'otp-invalid'">
                            <div *ngIf="sendOtpSuccess" class="login_white_text text-center mb-3">
                                {{language.anOtpHasBeenSentTo}}
                                <span *ngIf="countryDialCode" class="login_white_text_bold">{{countryDialCode}} </span>
                                <span class="login_white_text_bold">{{username}}</span>
                            </div>
                            
                            <ng-otp-input #otpInput (onInputChange)="onOtpChange($event)"
                                [config]="{length:6,allowNumbersOnly:true}">
                            </ng-otp-input>
                            <div class="popup-info-height">
                                
                                    <div class="text-left d-flex align-center justify-content-between mb-1">
                                        <span class="text-white" *ngIf="timeLeft">{{ '00:' + (timeLeft < 10 ? '0' + timeLeft : timeLeft) }}</span>
                                        <span class="text-right" >
                                            <small class="text-white mr-2">{{language.didNotGetTheOtp}}</small>
                                            <a href="javascript:void(0);" *ngIf="!timeLeft" (click)="resendOtp()">{{language.resend}}</a>
                                            <a href="javascript:void(0);" class="resend_disable" *ngIf="timeLeft">{{language.resend}}</a>
                                        </span>
                                    </div>
                                    
                                
                                <div class="popup-error-height text-right">
                                    <small *ngIf="resendOtpSuccess" class="text-white">{{otp_msg}}</small>
                                    <app-error
                                    *ngIf="isOtpInvalid && (otpFormControls.otp.hasError('required') || otpFormControls.otp.invalid)"
                                    [message]="language.pleaseEnterValidOTP"></app-error>
                                
                                    <app-error
                                    *ngIf="api_error"
                                    [message]="api_error"></app-error>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <button type="submit" class="btn btn-default btn_height btn_hover profile-btn mr-5">
                                <span>{{language.submit}}</span>
                            </button>
                        </div>
                    </form>

                    <form class="transparent_form" [formGroup]="setPinForm" autocomplete="off" (ngSubmit)="setPin()"
                        *ngIf="isSubmitOtpValid==true">
                        <label class="login_title mb-5">{{language.resetPin}}</label>

                            <div class="four_digit_pin">
                                <div class="otp_line form-group" [ngClass]="(isPinInvalid && pinFormControls.profile_pin.invalid) && 'otp-invalid'">
                                    <div for="pin" class="text-white text-center">
                                        {{language.enterPin}}
                                    </div>
                                    <ng-otp-input #currentPinInput (onInputChange)="onPinChange($event)" [config]="{length:4,allowNumbersOnly:true}">
                                    </ng-otp-input>
                                    <div class="popup-error-height text-right">
                                        <app-error
                                        *ngIf="isPinSubmit && (pinFormControls.profile_pin.hasError('required') || pinFormControls.profile_pin.invalid)"
                                        [message]="language.pleaseEnterPin"></app-error>
                                    </div>
                                </div>
                            </div>

                            <div class="four_digit_pin">
                                <div class="otp_line form-group" [ngClass]="(isConfirmPinInvalid && pinFormControls.confirm_profile_pin.invalid) && 'otp-invalid'">
                                    <div for="confirmProfilePin" class="text-white text-center">
                                        {{language.confirmPin}}
                                    </div>
                                    <ng-otp-input #confirmPinInput (onInputChange)="onConfirmPinChange($event)"
                                        [config]="{length:4,allowNumbersOnly:true,disableAutoFocus:true}">
                                    </ng-otp-input>

                                    <div class="popup-error-height text-right">
                                        <app-error
                                        *ngIf="isPinSubmit && (pinFormControls.confirm_profile_pin.hasError('required') || pinFormControls.confirm_profile_pin.invalid)"
                                        [message]="language.pleaseEnterConfirmPin"></app-error>

                                        <app-error
                                        *ngIf="(pinFormControls.confirm_profile_pin.valid && pinFormControls.profile_pin.valid) && (pinFormControls.confirm_profile_pin.value != pinFormControls.profile_pin.value)"
                                        [message]="language.pinAndConfirmPinDoNotMatch"></app-error>

                                        <app-error
                                        *ngIf="api_error"
                                        [message]="api_error"></app-error>
                                    </div>
                                </div>
                            </div>

                            <div class="text-center">
                                <button type="submit" class="btn btn-default btn_height btn_hover profile-btn mr-5">
                                    <span>{{language.changePin}}</span>
                                </button>
                            </div>
                    </form>

                </div>
                <div *ngIf="resetPinSuccess">
                    <div class="success-add">
                        <label class="login_title">{{language.hi}} {{profileName}}!</label>
                        <h4 class="_verify_t text-white" style="font-weight: 400;">{{api_msg}}</h4>
                        <div class="lottie-icon-block">
                            <div class="lottie-icon text-center">
                                <ng-lottie [options]="successOptions"></ng-lottie>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
