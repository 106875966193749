import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagenotfoundComponent } from './views/pagenotfound/pagenotfound.component';
import { ServerErrorComponent } from './views/server-error/server-error.component';
import { ContactusComponent } from './views/contactus/contactus.component';
import { PolicyComponent } from './views/policy/policy.component';
import { TermsComponent } from './views/terms/terms.component';
import { AdvertiseComponent } from './views/advertise/advertise.component';
import { AboutusComponent } from './views/aboutus/aboutus.component';
import { DynamicTabComponent } from './views/dynamic-tab/dynamic-tab.component';
import { FaqComponent } from './views/faq/faq.component';
import { ContestComponent } from './views/contest/contest.component';
import { RedeemCodeComponent } from './views/redeem-code/redeem-code.component';

const hostname = 'NexGTv ';
export const routes: Routes = [
  {
    path: '', component: DynamicTabComponent,data: {title:`Home` }
   
  },
  // { 
  //   path: 'login', 
  //   loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule)
  // },
  { 
    path: 'change-password', 
    loadChildren: () => import('./views/change-password/change-password.module').then(m => m.ChangePasswordModule)
  },
  { 
    path: 'manage-profile', 
    loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
    data: {title: `Manage Profile`}
  },
  { 
    path: 'detail/:name/:code/:type', 
    loadChildren: () => import('./views/detail-episode/detail-episode.module').then(m => m.DetailModule),
    data: {
      componentName:"DetailEpisodeComponent"
    }
  },
  { 
    path: 'viewall', 
    loadChildren: () => import('./views/viewall/viewall.module').then(m => m.ViewallModule),
    data: {title: `View All`}
  },
  {
    path: 'playlist',
    loadChildren: () => import('./views/playlist/playlist.module').then(m => m.PlaylistModule),
    data: {title: `Watchlist`}
    
  },
  // {
  //   path: 'myaccount',
  //   loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule),
  //   data: {title: `My Account`}
  // },
  {
    path: 'myaccount',
    loadChildren: () => import('./views/myaccount/myaccount.module').then(m => m.MyaccountModule),
    data: {title: `My Account`}
  },
  {
    path: 'myplans',
    loadChildren: () => import('./views/myplans/myplans.module').then(m => m.MyplansModule),
    data: {title: `My Plans`}
  },
  // {
  //   path: 'search',
  //   loadChildren: () => import('./shared/search/search.module').then(m => m.SearchModule),
  //   data: {title: `Search`}
  // },
  {
    path: 'redeem-code',
    // loadChildren: () => import('./views/redeem-code/redeem-code.module').then(m => m.RedeemCodeModule),
    component: RedeemCodeComponent,
    data: {title: `Redeem Code`}
  },
  {
    path: 'help-center',
    loadChildren: () => import('./views/help-center/help-center.module').then(m => m.HelpCenterModule),
    data: {title: `Help Center`}
  },
  {
    path: 'subscribe/payment',
    loadChildren: () => import('./views/subscribe/payment/payment.module').then(m => m.PaymentModule),
    data: {title: `Payment`}
  },
  {
    path: 'subscribe/choseplan',
    loadChildren: () => import('./views/subscribe/chose-plan/chose-plan.module').then(m => m.ChosePlanModule),
    data: {title: `Plan Details`}
  },
  {
    path: 'subscribe/choseplan-v2',
    loadChildren: () => import('./views/subscribe/choose-plan-v2/choose-plan-v2.module').then(m => m.ChoosePlanV2Module),
    data: {title: `Plan Details`}
  },
  {
    path: 'subscribe',
    loadChildren: () => import('./views/subscribe/subscribe.module').then(m => m.SubscribeModule),
    data: {title: `View Plans`}
  },
  {
    path: 'subcategory',
    loadChildren: () => import('./views/sub-category-list/sub-category-list.module').then(m => m.SubCategoryListModule),
  },
  {
    path: 'subsubcategory',
    loadChildren: () => import('./views/sub-sub-category-list/sub-sub-category-list.module').then(m => m.SubSubCategoryListModule),
  },
  {
    path: 'watchlist',
    loadChildren: () => import('./views/watchlist/watchlist.module').then(m => m.WatchlistModule),
    data: {title: `Watchlist`}
  },
  { 
    path: 'app-pages/:langCode/:platform/:page', 
    loadChildren: () => import('./views/platform-pages/platform-pages.module').then(m => m.PlatformPagesModule),
    data: {
      componentName:"PlatformPagesComponent"
    }
  },
  {
    path: '404',
    component:PagenotfoundComponent,data: {title: '404'}
  },
  {
    path: '500',
    component:ServerErrorComponent,data: {title: '500'}
  },

  {
    path: 'contactus',
    component:ContactusComponent,data: {title: 'Contact us'}
  },
  {
    path: 'aboutus',
    component:AboutusComponent,data: {title: 'About us'}
  },
  {
    path: 'policy',
    component:PolicyComponent,data: {title: 'Privacy Policy'}
  },
  {
    path: 'terms',
    component:TermsComponent,data: {title: 'Term & Condition'}
  },
  {
    path: 'faq',
    component:FaqComponent,data: {title: 'FAQs'}
  },
  {
    path: 'advertise',
    component:AdvertiseComponent,data: {title: 'Advertise'}
  },
  {
    path: 'content/:id',
    component: ContestComponent,data: {title: `${hostname} | Contest`}
  },
  {
    path: 'content/:id/:sessionId',
    component: ContestComponent,data: {title: `${hostname} | Contest`}
  },
 

  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [];
