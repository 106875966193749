<div class="campaign-banner" (click)="redirectUrl(content)">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <image-manipulation [assetName]="data.name" [img]="showImg" [params]="assetImgParam"></image-manipulation>
            </div>
        </div>
    </div>
</div>

<!-- Cancel subscription -->
<ng-template #content let-modal>
    <app-login-register></app-login-register>
</ng-template>