<div class="container mt-4">
    <div class="row">
        <div class="col-md-12">
            <button  type="button" class="close popup_close" (click)="closeModal()" #closebutton>&times;</button>
            <div class="form_bg">
                <div class="set_pin" *ngIf="!isChangePinSuccess">
                    <label class="login_title login-mb-40">{{language.changePin}}</label>

                    <form class="transparent_form" [formGroup]="changePinForm" autocomplete="off" (ngSubmit)="setPin()">

                        <div class="otp_line form-group text-center" [ngClass]="(api_error || (isCurrentPinInvalid && pinFormControls.current_profile_pin.invalid)) && 'otp-invalid'">
                            <label for="pin" class="text-white mb-0">{{language.enterCurrentPin}}</label>
                            <ng-otp-input #currentPinInput (onInputChange)="onCurrentPinChange($event)"
                                [config]="{length:4,allowNumbersOnly:true}">
                            </ng-otp-input>
                            <div class="popup-error-height text-right">
                                <app-error 
                                *ngIf="isCurrentPinInvalid && (pinFormControls.current_profile_pin.hasError('required') || pinFormControls.current_profile_pin.invalid)"
                                [message]="language.pleaseEnterCurrentPIN"></app-error>

                                <div *ngIf="api_error">
                                    <app-error [message]="api_error"></app-error>
                                </div>
                            </div>
                        </div>

                        


                        <div class="otp_line form-group text-center" [ngClass]="(isNewPinInvalid && pinFormControls.new_profile_pin.invalid) && 'otp-invalid'">
                            <label for="pin" class="text-white mb-0">{{language.enterNewPin}}</label>
                            <ng-otp-input #newPinInput (onInputChange)="onPinChange($event)" [config]="{length:4,allowNumbersOnly:true,disableAutoFocus:true}">
                            </ng-otp-input>
                            <div class="popup-error-height text-right">
                                <!-- <div *ngIf="isPinSubmit && (pinFormControls.new_profile_pin.hasError('required') || pinFormControls.new_profile_pin.invalid)"> -->
                                    <app-error 
                                    *ngIf="isNewPinInvalid && (pinFormControls.new_profile_pin.hasError('required') || pinFormControls.new_profile_pin.invalid)"
                                    [message]="language.pleaseEnterNewPin"></app-error>
                                <!-- </div> -->
                            </div>
                        </div>

                        

                        <div class="otp_line form-group text-center" [ngClass]="(isConfirmPinInvalid && pinFormControls.confirm_new_profile_pin.invalid) && 'otp-invalid'">
                            <label for="confirmProfilePin" class="text-white mb-0">{{language.confirmNewPin}}</label>
                            <ng-otp-input #confirmPinInput (onInputChange)="onConfirmPinChange($event)"
                                [config]="{length:4,allowNumbersOnly:true,disableAutoFocus:true}">
                            </ng-otp-input>
                            <div class="popup-error-height text-right">
                                <app-error 
                                *ngIf="isConfirmPinInvalid && (pinFormControls.confirm_new_profile_pin.hasError('required') || pinFormControls.confirm_new_profile_pin.invalid)"
                                [message]="language.pleaseEnterConfirmPin"></app-error>

                                <app-error 
                                *ngIf="(pinFormControls.confirm_new_profile_pin.valid && pinFormControls.new_profile_pin.valid) && (pinFormControls.confirm_new_profile_pin.value != pinFormControls.new_profile_pin.value)"
                                [message]="language.pinAndConfirmPinDoNotMatch"></app-error>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-default mt-4 btn_height btn_hover profile-btn">
                            <span>{{language.proceed}}</span>
                        </button>
                    </form>
                </div>
                <div *ngIf="isChangePinSuccess">
                    <div class="success-add">
                        <h4 class="_verify_t text-white" style="font-weight: 400;">
                        {{api_error}}
                        </h4>
                        <div class="lottie-icon-block">
                            <div class="lottie-icon text-center">
                                <ng-lottie [options]="successOptions"></ng-lottie>
                            </div>
                        </div>
                    </div>
                </div>
                <!--*ngIf="isSubmitPinValid==true && api_response=='200'"   {{api_error}}-->
            </div>
        </div>
    </div>
</div>