import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/core/services/user.service';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from "src/app/core/services/common.service";
import { AnimationOptions } from 'ngx-lottie';
import { successOptions, errorOptions } from 'src/app/shared/lottie-config';
import { DOCUMENT } from '@angular/common';
import { NgOtpInputComponent } from 'ng-otp-input';

@Component({
  selector: 'app-changepin',
  templateUrl: './changepin.component.html',
  styleUrls: ['./changepin.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChangepinComponent implements OnInit {

  api_error: any;
  api_response: any;
  changePinForm: FormGroup;
  pin: any;
  isPinSubmit: boolean = false;

  timezone: any;
  locale: any;
  timezoneForGA: any;
  timeZoneName: any;
  username: any;
  isConfirmPinSubmit: boolean = false;
  confirmPinValid: boolean = false;
  isSubmitPinValid: any = false;
  isSubmitConfirmPinValid: any = false;
  language: any;
  isChangePinSuccess: boolean;
  successOptions: AnimationOptions = successOptions;
  errorOptions: AnimationOptions = errorOptions;
  showValidationErrors: boolean;
  isCurrentPinInvalid: boolean;
  isNewPinInvalid: boolean;
  isConfirmPinInvalid: boolean;
  @Output("changePinSuccess") changePinSuccess: EventEmitter<any> = new EventEmitter();
  @ViewChild('currentPinInput', { static: false }) currentPinInput: NgOtpInputComponent;
  @ViewChild('newPinInput', { static: false }) newPinInput: NgOtpInputComponent;
  @ViewChild('confirmPinInput', { static: false }) confirmPinInput: NgOtpInputComponent;


  constructor(
    private UserService: UserService,
    private fb: FormBuilder,
    private googleEvent: EventTrackingService,
    private router: Router,
    private commonService: CommonService,
    @Inject(DOCUMENT) private _document
    ) {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Kolkata' ? 'Asia/Calcutta' : Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.timezoneForGA = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.locale = Intl.DateTimeFormat().resolvedOptions().locale;
    this.timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZoneName;
  }

  ngOnInit(): void {
    this._document.body.classList.add('profile-bg');
    this.language = this.commonService.languageChange();
    this.changePinForm = this.fb.group({
      'current_profile_pin': ['', [Validators.required, Validators.minLength(4)]],
      'new_profile_pin': ['', [Validators.required, Validators.minLength(4)]],
      'confirm_new_profile_pin': ['', [Validators.required, Validators.minLength(4)]]
    });



    let userData = localStorage.getItem('userInfo');
    console.log(userData);

  }

  ngOnDestroy() {
    // remove the class form body tag
    this._document.body.classList.remove('profile-bg');
  }

  get pinFormControls() {
    return this.changePinForm.controls;
  }

  closeModal() {
    try {
      document.querySelector("ngb-modal-window").classList.remove("show");
      document.querySelector("ngb-modal-backdrop").classList.remove("show");
      document.getElementById('nexgTV-app').click();
      document.querySelectorAll(".modal-backdrop").forEach(el => el.remove())
      document.querySelectorAll(".d-block").forEach(el => el.remove())
      document.body.style.overflow = "scroll";
    } catch (e) {

    }
  }


  setPin() {
    this.isPinSubmit = true;

    this.isCurrentPinInvalid = this.pinFormControls.current_profile_pin.invalid;
    this.isNewPinInvalid = this.pinFormControls.new_profile_pin.invalid;
    this.isConfirmPinInvalid = this.pinFormControls.confirm_new_profile_pin.invalid;
    if (this.changePinForm.invalid) {
      return;
    } 
    if (this.changePinForm.controls.new_profile_pin.value != this.changePinForm.controls.confirm_new_profile_pin.value) {
      return;
    } 
    let params = this.changePinForm.value;
    let url = 'profile/change/pin';
    console.log(params);
    this.UserService.changePrfilePin(url, params).subscribe(data => {
      if (data.response_code == 200 || data.response_code == 201) {
        this.isChangePinSuccess = true;
        this.api_response = data.response_code;
        // this.isSubmitPinValid = true;
        this.api_error = data.message;
        this.googleEvent.eventEmitter('Profile', 'Change', this.locale + ' - ' + this.timezoneForGA, 0, + '-' + this.username + '-' + this.locale + ' - ' + this.timezoneForGA + '-Change Pin Button click');

        this.closeModal();
        this.changePinSuccess.emit(data.message);
        console.log(data.message);

        // setTimeout(() =>{
        //   window.location.reload();
        // }, 2500);
      } else {
        this.api_error = data.message;
      }

    }, (error) => {
      // this.isSubmitPinValid = false;
      this.api_error = error.message;
    });
  }

  onCurrentPinChange(pin) {
    let currentPin = pin;
    this.changePinForm.get("current_profile_pin").setValue(currentPin);
    if (currentPin.length === 4) {
      // this.newPinInput.otpInput.nativeElement.focus();
      let eleId = this.newPinInput.getBoxId(0);
      this.newPinInput.focusTo(eleId);
    }
  }

  onPinChange(pin) {
    let fpin = pin;
    this.changePinForm.get("new_profile_pin").setValue(fpin);
    if (fpin.length === 4) {
      // this.confirmPinInput.otpInput.nativeElement.focus();
      let eleId = this.confirmPinInput.getBoxId(0);
      this.confirmPinInput.focusTo(eleId);
    }
  }

  onConfirmPinChange(pin) {
    let confirmPin = pin;
    this.changePinForm.get("confirm_new_profile_pin").setValue(confirmPin);
    // if (confirmPin.length === 4) {
    //   this.setPin();
    // }
  }



}
