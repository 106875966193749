<div class="container mt-4">
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="close popup_close" (click)="closeModal()" #closebutton>&times;</button>
        <div class="form_bg" style="min-height: auto;">
          <div class="set_pin">
            <label class="login_title login-mb-40">{{language.setPin}}</label>
            <form class="transparent_form" [formGroup]="setPinForm" autocomplete="off" (ngSubmit)="setPin()">
              <div class="otp_line form-group text-center" [ngClass]="(isPinInvalid && pinFormControls.profile_pin.invalid) && 'otp-invalid'">
                <label for="pin" class="text-white mb-0">{{language.pleaseEnterPin}}</label>
                <ng-otp-input #currentPinInput (onInputChange)="onPinChange($event)" [config]="{length:4,allowNumbersOnly:true}">
                </ng-otp-input>
                <div class="popup-error-height text-right">
                  <app-error 
                    *ngIf="isPinSubmit && (pinFormControls.profile_pin.hasError('required') || pinFormControls.profile_pin.invalid)"
                    [message]="language.pleaseEnterPin">
                  </app-error>
                </div>
              </div>
  
              <div class="otp_line form-group text-center" [ngClass]="(isConfirmPinInvalid && pinFormControls.confirm_profile_pin.invalid) && 'otp-invalid'">
                <label for="confirmProfilePin" class="text-white mb-0">{{language.confirmPin}}</label>
                <ng-otp-input #confirmPinInput (onInputChange)="onConfirmPinChange($event)" [config]="{length:4,allowNumbersOnly:true,disableAutoFocus:true}">
                </ng-otp-input>
                <div class="popup-error-height text-right">
                  <app-error
                    *ngIf="isPinSubmit && (pinFormControls.confirm_profile_pin.hasError('required') || pinFormControls.confirm_profile_pin.invalid)"
                    [message]="language.pleaseEnterConfirmPin">
                  </app-error>
  
                  <app-error 
                    *ngIf="(pinFormControls.confirm_profile_pin.valid && pinFormControls.profile_pin.valid) && (pinFormControls.confirm_profile_pin.value != pinFormControls.profile_pin.value)"
                    [message]="language.pinAndConfirmPinDoNotMatch">
                  </app-error>
                </div>
              </div>
              
              <div *ngIf="api_error">
                <app-error [message]="api_error"></app-error>
              </div>
  
              <button type="submit" class="btn btn-default mt-4 btn_height btn_hover profile-btn">
                <span>{{language.proceed}}</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  